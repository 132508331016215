import React, {useEffect,  useState } from 'react';
import {mergedVids} from '../output/allvideosmerged3';
import {cats} from '../output/allcategoriesmerged5';
import {chans} from '../output/allchannels3';

import {duration} from '../output/alldurationsmerged4';
import ReactPlayer from 'react-player/youtube';

const MAX_DUR = 50000


export function SearchApp() {

    const [bodyFocus, setBodyFocus] = useState("F");
    const [durationMax, setDurationMax] = useState(120)
    const [activeChannel, setActiveChannel] = useState('UCwrXi5ZknKThspJc-Gai04g')
    const [displayData, setDisplayData] = useState([]);
    const [channels] = useState(chans["items"])
    const [selectedVideo, setSelectedVideo] = useState(undefined);

    const bodyData = {"U":[],"L":[],"A":[],"F":[]};
    const categories = cats;
    const durationMap = duration;
    const vids = mergedVids;
    configureBodyData("U" );
    configureBodyData("L" );
    configureBodyData("A" );
    configureBodyData("F");
    useEffect(() =>{

        setDisplayData(bodyData["F"])

    }, [])



    return (
        <div>

            <div className="bg-gradient-to-b from-fuchsia-900 to-black w-full">
                <div className="w-11/12 mx-auto">
                    
                    {(selectedVideo !== undefined && selectedVideo!== null) &&
                    <div className={"fixed   z-50 w-full md:w-1/4 pl-2 bottom-[27px] " }>
                        <div className="player-wrapper">
                            <ReactPlayer controls={true} url={'https://www.youtube.com/watch?v=' + selectedVideo } width={ screen.width < 640? (3/4)*screen.width : screen.width/3} height={screen.width < 640? 0.56*screen.width*(3/4) : 0.56*screen.width/3}/>
                        </div>

                    </div>
                    }
                    <div className="block mt-px mb-6 ">
                        <a className=" text-white text-lg px-4  bg-cyan-600"  href = "./pilates/">PILATES</a>
                        <a className=" ml-2 text-white text-lg px-4 bg-emerald-600"  href = "./yoga/">YOGA</a>
                    </div>
                    <span key="title1"  className="block font-jost  mt-4 mb-2 text-4xl text-white ">Find The Best Home Workouts on YouTube</span>
                    <span key="subtitle1"  className="block font-jost  mt-4 mb-8 text-2xl text-white ">SELECT A BODY FOCUS AND TIME</span> 

                    <span key="filtertitle" className="block font-jost   text-l text-slate-400">FILTER BY BODY FOCUS:</span>

                    <div  key="btngroupparnt" className="w-5/6 flex  mt-px mb-10">
                        <button key ="bu" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm whitespace-nowrap font-medium rounded-l-md " + activeColor("U") }
                            onClick ={()=> {
                                setBodyFocus("U");
                                setDisplayData([...bodyData["U"]])
                                setSelectedVideo(undefined);

                                }}>
                            UPPER BODY
                        </button>
                        <button  key ="bl" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium whitespace-nowrap " + activeColor("L")}
                            onClick ={()=> {
                                setBodyFocus("L");
                                setDisplayData([...bodyData["L"]])
                                setSelectedVideo(undefined);
                                
                                }}>
                            LOWER 
                        </button>
                        <button key ="ba" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium whitespace-nowrap " + activeColor("A")}
                        onClick ={()=> {
                            setBodyFocus("A");
                            setDisplayData([...bodyData["A"]])
                            setSelectedVideo(undefined);
                            
                            }}>
                            CORE
                        </button>
                        <button key ="bf" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium rounded-r-md whitespace-nowrap "  + activeColor("F")}
                        onClick ={()=> {
                            setBodyFocus("F");
                            setDisplayData([...bodyData["F"]])
                            setSelectedVideo(undefined);

                            }}>
                            FULL BODY
                        </button>
                    </div>
                    <span key="durationlable" className="block font-jost   text-l text-slate-400">NO LONGER THAN:</span>
                    

                    <div  key="durbtngroupparnt" className="w-5/6 flex  mt-px mb-10">
                        <button key ="dur15" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm whitespace-nowrap font-medium rounded-l-md " + activeColorDuration(15) }
                            onClick ={()=> {
                                if(durationMax == 15){
                                    setDurationMax(MAX_DUR)
                                }else{
                                    setDurationMax(15);
                                }
                                setSelectedVideo(undefined);

                                }}>
                            15 MIN
                        </button>
                        <button  key ="dur30" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium whitespace-nowrap " + activeColorDuration(30)}
                            onClick ={()=> {
                                if(durationMax == 30){
                                    setDurationMax(MAX_DUR)
                                }else{
                                    setDurationMax(30);
                                }
                                setSelectedVideo(undefined);

                                }}>
                            30 MIN
                        </button>
                        <button key ="dur45" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium whitespace-nowrap " + activeColorDuration(45)}
                        onClick ={()=> {
                            if(durationMax == 45){
                                setDurationMax(MAX_DUR)
                            }else{
                                setDurationMax(45);
                            }
                            setSelectedVideo(undefined);

                            }}>
                            45 MIN
                        </button>

                        <button key ="dur120" className={"px-4 py-2  hover:bg-fuchsia-600 text-white text-xs md:text-sm font-medium whitespace-nowrap rounded-r-md " + activeColorDuration(120)}
                        onClick ={()=> {
                            if(durationMax == 120){
                                setDurationMax(MAX_DUR);
                            }else{
                                setDurationMax(120);
                            }
                            setSelectedVideo(undefined);

                            }}>
                            120 MIN
                        </button>
                        
                    </div>

                    {/* channels */}

                    <span key="tranlabel" className="block font-jost   text-l text-slate-400">TRAINER:</span>

                    <div  key="chanbtngroupparnt" className="flex flex-row  md:w-1/2 overflow-x-auto scrollbar-hide mt-px mb-10">

                        {channels?channels.map( (channy, i) => {
                            return(
                            <div key ={"chan"+channy.id + i} className = {" mb-2 px-px md:px-0  shrink-0 w-2/5 md:w-1/5 text-white text-xs md:text-sm  font-medium  content-center " + activeColorChannel(channy.id) }
                                onClick={()=>{

                                    if(activeChannel === channy.id){
                                        setActiveChannel(undefined)
                                    }else{
                                        setActiveChannel(channy.id)
                                        
                                    }
                                }}
                                >
                            <img key ={"chanimg"+channy.id + i} className="py-1 pt-4 md:hover:drop-shadow-lg h-auto m-auto circleimg" width="88" src ={channy.snippet.thumbnails.default.url} ></img>
                            <div key ={"chantitle"+channy.id + i} className="pb-2  py-1  px-1 uppercase text-center whitespace-nowrap "><span className="text-xs ">{channy.snippet.title}</span> </div>
                            </div>)
                        }) :<></>}
                    </div>

                    

                    <span key="vidcount" className="block font-jost    mb-px mt:2 text-xl text-white"> {countDisplayVids(displayData, durationMax, durationMap, activeChannel)} VIDEOS</span>
                </div>
            </div>
            <div className="w-11/12 mx-auto">
                {  <div key="gridparent" className={"grid grid-cols-2 md:grid-cols-3 gap-1 "}>

                {
                    
                    displayData? displayData.map( (v,index) => {
                        return(renderVid(v, index, durationMax, durationMap, selectedVideo, setSelectedVideo, activeChannel));
                        }): < ></> 
        

                    // return(renderVid(v, index));
                }

                </div> } 

            </div>
        </div>
      );

      function activeColor(T){
        return (bodyFocus !== undefined && bodyFocus ===T? "bg-fuchsia-600" : "bg-slate-400")
      }

      function activeColorDuration(T){
        return (durationMax !== undefined && durationMax === T? "bg-fuchsia-600" : "bg-slate-400")
      }

      function activeColorChannel(T){
        return (activeChannel !== undefined && activeChannel === T? "bg-fuchsia-600" : " bg-slate-400 faded")
      }

      function addRounded(i, length){
        console.log(" comparing   " + i + " " +  (length-1) );
        return i === length-1 ? "rounded-r-md" : "";
      }


      

      function configureBodyData(body, optionalSetter){

        let u = []

        categories[body].map( id => {
            let filtered = vids.filter( (v) => v.id.videoId === id)
            u.push(filtered?filtered[0]:undefined)
        });

        bodyData[body] = u;
        // let updadtedBodyData = Object.assign({}, bodyData);
        // setBodyData(updadtedBodyData);

        if(optionalSetter){
            optionalSetter(u)
        }

  }
  }

  const isVidWithinDurationSetting = (v, durationMax, durationMap) =>{
    if(durationMax === undefined){
        return true;
    }
    const vId = v.id.videoId;
    const vidDuration  = durationMap[vId];
    return vidDuration <= durationMax;
  }

  const isForSelectedChannel = (v, activeChannel) =>{
    if(activeChannel === undefined){
        return true
    }
    const isInChannel = (activeChannel !== undefined && v.snippet.channelId === activeChannel);
    
    return isInChannel
  }

  const countDisplayVids = (bodyVids, durationMax, durationMap, activeChannel) =>{

    return bodyVids.filter(v => {
        const vId = v.id.videoId;
        const vidDuration  = durationMap[vId];
        return vidDuration <= durationMax && (activeChannel === undefined || activeChannel === v.snippet.channelId)

    }).length;
  } 

  const renderVid = (v, i, durationMax, durationMap, selectedVideo, setSelectedVideo, activeChannel) =>{
    const hideThisVid = !isForSelectedChannel(v,activeChannel) ||  !isVidWithinDurationSetting(v, durationMax, durationMap);

    return    hideThisVid 
            ? <></> 
            :(<div  className=" group relative" key={"vid" + v.id.videoId + i} onClick={ async ()=>{ 
                // window.open('https://www.youtube.com/watch?v=' + v.id.videoId)
                
                    if(selectedVideo !== undefined){
                        console.log("clearing vid")
                        setSelectedVideo(undefined);
                    }else{
                        setSelectedVideo(v.id.videoId);
                    }
                }
            }  >
                <img alt={v.snippet.title} key={"img" + v.id.videoId + i} className={`object-contain transition w-full ease-in-out delay-50  ${(v.id.videoId === selectedVideo) ? "" : "faded"}`} src={screen.width < 786 ? v?.snippet.thumbnails.medium.url : v?.snippet.thumbnails.high.url}></img>
                <div key={"inner"+v.id.videoId} className={ `absolute top-1/4  group-hover:block   overflow-y-auto scrollbar-hide    transition-all ease-in-out delay-80  flex flex-col w-full  ${(v.id.videoId === selectedVideo) ? "opaquediv" : "transdiv"} `}>
                    <p key={"title"+v.id.videoId} className={`prevent-select  shrink text-shrink text-xs md:text-lg ${(v.id.videoId === selectedVideo || selectedVideo === undefined ) ? "text-slate-200" : "text-slate-400"}`}>{v.snippet.title}</p>

                    {/* <div  key={"lnk"+v.id.videoId} className="cursor-pointer text-white mt-2 text-md ">PLAY ON YOUTUBE</div> */}
                </div>
            </div>);
}


// pull down all 600 videos, organize, list

  //https://developers.google.com/youtube/v3/docs/videos/list?apix_params=%7B%22part%22%3A%5B%22snippet%2CcontentDetails%2Cstatistics%22%5D%2C%22id%22%3A%5B%22hBanCm-yqB8%22%5D%7D