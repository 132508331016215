
const chans = {
  "kind": "youtube#channelListResponse",
  "etag": "nLvniiOWGeL7Xm6chCUp3XzTxEI",
  "pageInfo": {
    "totalResults": 2,
    "resultsPerPage": 5
  },
  "items": [
    {
      "kind": "youtube#channel",
      "etag": "VkhoqfcPaut8Cn_n9oP-7zlDGSo",
      "id": "UCpis3RcTw6t47XO0R_KY4WQ",
      "snippet": {
        "title": "Caroline Girvan",
        "description": "Welcome to a channel focused on building a strong and balanced body. The destination will no longer be the focus...you will be too busy enjoying the journey!\n\nI am a mum of 2 beautiful children and a Certified Personal Trainer, Pre & Post Natal Specialist and Nutrition Coach who quite frankly, loves how all aspects of fitness can make me and others feel.... the physical changes occur as a by product! \n\nI have learnt so much from my own experiences such as regaining level of fitness following 2 childbirths, running marathons and ultra marathons across the globe, Ironman and triathlons and helping people be informed, grow and excel in their training!\n\nI hope you enjoy these daily videos and join me in this journey. \n\nCx\n\nSubscribe to my YouTube Channel, turn on notifications and never miss a video!\nInstagram: @carolinegirvan",
        "customUrl": "@carolinegirvan",
        "publishedAt": "2015-10-25T22:49:02Z",
        "thumbnails": {
          "default": {
            "url": "https://yt3.ggpht.com/Y-D_T2ypbS0TZBcNO9I6DzsLY0pKBgtlsOpjZ5TXL2tzum5cZz8wIeQIo-cEvXZaAPulCmCADQ=s88-c-k-c0x00ffffff-no-rj",
            "width": 88,
            "height": 88
          },
          "medium": {
            "url": "https://yt3.ggpht.com/Y-D_T2ypbS0TZBcNO9I6DzsLY0pKBgtlsOpjZ5TXL2tzum5cZz8wIeQIo-cEvXZaAPulCmCADQ=s240-c-k-c0x00ffffff-no-rj",
            "width": 240,
            "height": 240
          },
          "high": {
            "url": "https://yt3.ggpht.com/Y-D_T2ypbS0TZBcNO9I6DzsLY0pKBgtlsOpjZ5TXL2tzum5cZz8wIeQIo-cEvXZaAPulCmCADQ=s800-c-k-c0x00ffffff-no-rj",
            "width": 800,
            "height": 800
          }
        },
        "localized": {
          "title": "Caroline Girvan",
          "description": "Welcome to a channel focused on building a strong and balanced body. The destination will no longer be the focus...you will be too busy enjoying the journey!\n\nI am a mum of 2 beautiful children and a Certified Personal Trainer, Pre & Post Natal Specialist and Nutrition Coach who quite frankly, loves how all aspects of fitness can make me and others feel.... the physical changes occur as a by product! \n\nI have learnt so much from my own experiences such as regaining level of fitness following 2 childbirths, running marathons and ultra marathons across the globe, Ironman and triathlons and helping people be informed, grow and excel in their training!\n\nI hope you enjoy these daily videos and join me in this journey. \n\nCx\n\nSubscribe to my YouTube Channel, turn on notifications and never miss a video!\nInstagram: @carolinegirvan"
        },
        "country": "GB"
      }
    },
    {
      "kind": "youtube#channel",
      "etag": "To_0GFViz2mmY1H7Y3-v1xXUhHo",
      "id": "UCwrXi5ZknKThspJc-Gai04g",
      "snippet": {
        "title": "Juice & Toya",
        "description": "Workouts | Food | Motivation | Lifestyle | + More",
        "customUrl": "@juiceandtoya",
        "publishedAt": "2018-08-20T06:42:03Z",
        "thumbnails": {
          "default": {
            "url": "https://yt3.ggpht.com/6v6A__YZJ_8mfgFDSYrs8LKx3eq-d7GSyGhq-r2vBsWNBcZX4a57FiKr0HxYNxPKTYMozbLYTQ=s88-c-k-c0x00ffffff-no-rj",
            "width": 88,
            "height": 88
          },
          "medium": {
            "url": "https://yt3.ggpht.com/6v6A__YZJ_8mfgFDSYrs8LKx3eq-d7GSyGhq-r2vBsWNBcZX4a57FiKr0HxYNxPKTYMozbLYTQ=s240-c-k-c0x00ffffff-no-rj",
            "width": 240,
            "height": 240
          },
          "high": {
            "url": "https://yt3.ggpht.com/6v6A__YZJ_8mfgFDSYrs8LKx3eq-d7GSyGhq-r2vBsWNBcZX4a57FiKr0HxYNxPKTYMozbLYTQ=s800-c-k-c0x00ffffff-no-rj",
            "width": 800,
            "height": 800
          }
        },
        "localized": {
          "title": "Juice & Toya",
          "description": "Workouts | Food | Motivation | Lifestyle | + More"
        },
        "country": "US"
      }
    },
    {
      "kind": "youtube#channel",
      "etag": "uA7ls43cW0IvFyiL8yY9YWmH9Nw",
      "id": "UCE3qIUaYYSQfgZZZlFIenZw",
      "snippet": {
        "title": "DANIELPT FITNESS",
        "description": "Welcome to DANIELPT channel where I provide effective follow along workout videos focusing on strength, cardio and hybrid workouts. \n \nSubscribe to my YouTube Channel and turn on notifications so you don't miss a video🦾\n\nFor more personal workout videos check out my website where I provide 3x commentary workout videos every single week:\nwww.danielptfitness.com/homegym\n\nInstagram: @danielptfitness\n",
        "customUrl": "@danielptfitness",
        "publishedAt": "2017-04-10T18:29:51Z",
        "thumbnails": {
          "default": {
            "url": "https://yt3.ggpht.com/woy2pOlSMv9jl6b2hlevGkbuXxIv9vq6mH8UvU28XplsNjj3FT8eESvcsmzFPLdvAM_eG1Ae06U=s88-c-k-c0x00ffffff-no-rj",
            "width": 88,
            "height": 88
          },
          "medium": {
            "url": "https://yt3.ggpht.com/woy2pOlSMv9jl6b2hlevGkbuXxIv9vq6mH8UvU28XplsNjj3FT8eESvcsmzFPLdvAM_eG1Ae06U=s240-c-k-c0x00ffffff-no-rj",
            "width": 240,
            "height": 240
          },
          "high": {
            "url": "https://yt3.ggpht.com/woy2pOlSMv9jl6b2hlevGkbuXxIv9vq6mH8UvU28XplsNjj3FT8eESvcsmzFPLdvAM_eG1Ae06U=s800-c-k-c0x00ffffff-no-rj",
            "width": 800,
            "height": 800
          }
        },
        "localized": {
          "title": "DANIELPT FITNESS",
          "description": "Welcome to DANIELPT channel where I provide effective follow along workout videos focusing on strength, cardio and hybrid workouts. \n \nSubscribe to my YouTube Channel and turn on notifications so you don't miss a video🦾\n\nFor more personal workout videos check out my website where I provide 3x commentary workout videos every single week:\nwww.danielptfitness.com/homegym\n\nInstagram: @danielptfitness\n"
        },
        "country": "DE"
      }
    },
    {
      "kind": "youtube#channel",
      "etag": "uA7ls43cW0IvFyiL8yY9YWmH9Nw",
      "id": "UCOpsZxrmeDARilha1uq4slA",
      "snippet": {
        "title": "HEATHER ROBERTSON",
        "description": "Welcome to DANIELPT channel where I provide effective follow along workout videos focusing on strength, cardio and hybrid workouts. \n \nSubscribe to my YouTube Channel and turn on notifications so you don't miss a video🦾\n\nFor more personal workout videos check out my website where I provide 3x commentary workout videos every single week:\nwww.danielptfitness.com/homegym\n\nInstagram: @danielptfitness\n",
        "customUrl": "@danielptfitness",
        "publishedAt": "2017-04-10T18:29:51Z",
        "thumbnails": {
          "default": {
            "url": "https://yt3.googleusercontent.com/JcOB1LKS2mZW3cdGtL1-d56sckQkXVsWM54S4hUH-1aVrnHM_jyQY5AoGRVSElavwb2VeHWe=s176-c-k-c0x00ffffff-no-rj",
            "width": 88,
            "height": 88
          },
          "medium": {
            "url": "https://yt3.ggpht.com/woy2pOlSMv9jl6b2hlevGkbuXxIv9vq6mH8UvU28XplsNjj3FT8eESvcsmzFPLdvAM_eG1Ae06U=s240-c-k-c0x00ffffff-no-rj",
            "width": 240,
            "height": 240
          },
          "high": {
            "url": "https://yt3.ggpht.com/woy2pOlSMv9jl6b2hlevGkbuXxIv9vq6mH8UvU28XplsNjj3FT8eESvcsmzFPLdvAM_eG1Ae06U=s800-c-k-c0x00ffffff-no-rj",
            "width": 800,
            "height": 800
          }
        },
        "localized": {
          "title": "DANIELPT FITNESS",
          "description": "Welcome to DANIELPT channel where I provide effective follow along workout videos focusing on strength, cardio and hybrid workouts. \n \nSubscribe to my YouTube Channel and turn on notifications so you don't miss a video🦾\n\nFor more personal workout videos check out my website where I provide 3x commentary workout videos every single week:\nwww.danielptfitness.com/homegym\n\nInstagram: @danielptfitness\n"
        },
        "country": "DE"
      }
    },
  ]
};

  export {chans}