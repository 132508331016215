const mergedVids = [
  {
    "kind": "youtube#searchResult",
    "etag": "npnYHBSQ6FUezBaOMltFZBphWE4",
    "id": {
      "kind": "youtube#video",
      "videoId": "nBEUVz-epLk"
    },
    "snippet": {
      "publishedAt": "2022-05-22T21:16:34Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Introducing The FUEL Series | 6 Weeks / 30 Workouts FREE!",
      "description": "The FUEL Series is a 6 week series consisting of 5 x 30 minute workouts per week. FOCUS: Building of muscle. Increasing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nBEUVz-epLk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nBEUVz-epLk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nBEUVz-epLk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-22T21:16:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XKo7Znq6RUpx_MXOsLgRMGisQME",
    "id": {
      "kind": "youtube#video",
      "videoId": "_DAM-7Ctxcs"
    },
    "snippet": {
      "publishedAt": "2020-05-18T20:23:52Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "5 Minute Warm Up Routine",
      "description": "Hello everyone! Here is a simple follow along 5 minute warm up routine prior to exercise. If you follow along before your workouts, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_DAM-7Ctxcs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_DAM-7Ctxcs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_DAM-7Ctxcs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-18T20:23:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mbqhHdT_gp6f6rwEfXs7NjSMBJw",
    "id": {
      "kind": "youtube#video",
      "videoId": "OnrwAzr3jz8"
    },
    "snippet": {
      "publishedAt": "2021-01-01T20:05:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Introducing EPIC II | 10 Week Fitness &amp; Workout Program by Caroline Girvan",
      "description": "It's here!! We can officially begin!! Introducing the next instalment, EPIC II my FREE 10 week fitness and workout program!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OnrwAzr3jz8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OnrwAzr3jz8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OnrwAzr3jz8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-01T20:05:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "z9JJSjKVPlU75FpsPH2n--JtBU0",
    "id": {
      "kind": "youtube#video",
      "videoId": "ivDdVaZxOPo"
    },
    "snippet": {
      "publishedAt": "2021-12-24T18:39:37Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC Endgame | The Final EPIC Program - FREE 10 Week Programme",
      "description": "We've become stronger! This is it! The Final EPIC Program will be available for free on January 3rd 2022. Join me for one last ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ivDdVaZxOPo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ivDdVaZxOPo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ivDdVaZxOPo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-24T18:39:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XzK55jEHj3vmo-1zaJHjmegl6Ak",
    "id": {
      "kind": "youtube#video",
      "videoId": "c0VxUFHdYzs"
    },
    "snippet": {
      "publishedAt": "2020-09-21T07:15:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "5 Min Full Body Warm Up with Caroline Girvan",
      "description": "This is full body 5 min warm up you can follow along to prior to exercise. If you follow along before your workouts, you will quickly ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c0VxUFHdYzs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c0VxUFHdYzs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c0VxUFHdYzs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-21T07:15:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vr_b1ngE31RODvv0WabnRWYV8FU",
    "id": {
      "kind": "youtube#video",
      "videoId": "7yIqTeOyRx4"
    },
    "snippet": {
      "publishedAt": "2021-09-03T13:14:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC III Trailer | Caroline Girvan",
      "description": "Ready? I KNOW we are! 50 DAYS! 50 WORKOUTS! COUNTLESS CHALLENGES! Following the success of EPIC I, II and EPIC ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7yIqTeOyRx4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7yIqTeOyRx4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7yIqTeOyRx4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-03T13:14:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HyfGtG1yXcWwsnfFQZV3kPM5vKo",
    "id": {
      "kind": "youtube#video",
      "videoId": "JOoIsy8SX4c"
    },
    "snippet": {
      "publishedAt": "2021-09-06T03:45:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC 5 Minute Warm Up - Full Body | Caroline Girvan",
      "description": "This is a simple 5 minute warm up to follow along if you wish! We all have different bodies and areas where we may experience ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JOoIsy8SX4c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JOoIsy8SX4c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JOoIsy8SX4c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-06T03:45:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vl8tBoMH_4fAQpZzu4eJ8tcxtpU",
    "id": {
      "kind": "youtube#video",
      "videoId": "vj6SK1JVZYM"
    },
    "snippet": {
      "publishedAt": "2021-04-03T23:00:56Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC HEAT Program by Caroline Girvan | Trailer",
      "description": "EPIC Heat is now live. Click here to get started on Day 1: https://youtu.be/DUauPVlSO6g Download your FREE EPIC Heat Guide ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vj6SK1JVZYM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vj6SK1JVZYM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vj6SK1JVZYM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-03T23:00:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IntwvyOv9cmPswToeUokXIvKtaQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "dNKYXdSehio"
    },
    "snippet": {
      "publishedAt": "2022-06-13T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min LEGS CLUSTER SETS Workout | Day 11",
      "description": "Maybe different to other lower body workouts, this is all short sets with short rest\u2026 but this doesn't mean 'rushing' the reps or as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dNKYXdSehio/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dNKYXdSehio/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dNKYXdSehio/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-13T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WRDCXMtq9nPjQsrlUOCV6M2Tk9c",
    "id": {
      "kind": "youtube#video",
      "videoId": "wYlYVKCSM8I"
    },
    "snippet": {
      "publishedAt": "2020-09-19T15:59:56Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FREE 10 Week EPIC Workout Program + Guide | Caroline Girvan",
      "description": "Introducing my FREE 10 Week EPIC Program and 1 Week EPIC Beginner Series! Get your FREE Program Guide on my website: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wYlYVKCSM8I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wYlYVKCSM8I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wYlYVKCSM8I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-19T15:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qpxinqxCZ-kxmIOuBuer-8PD_DY",
    "id": {
      "kind": "youtube#video",
      "videoId": "N0RPCmtR_6Q"
    },
    "snippet": {
      "publishedAt": "2020-09-03T17:21:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min PUSH UP WORKOUT + TIPS | Beginner to Advanced",
      "description": "Wanting to progress in your push ups? This is an upper body and core workout to really help improve your push up game!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/N0RPCmtR_6Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/N0RPCmtR_6Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/N0RPCmtR_6Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-03T17:21:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KxZ-lI0HV80UQ5WgbnRRG0GSnN4",
    "id": {
      "kind": "youtube#video",
      "videoId": "2wnYsIf4sLU"
    },
    "snippet": {
      "publishedAt": "2020-09-27T04:45:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "5 Min HIIT Warm Up | Caroline Girvan",
      "description": "Let's get warmed up with my 5 minute HIIT warm up routine. \u25b7 Download EPIC Program Guide: https://carolinegirvan.com ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2wnYsIf4sLU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2wnYsIf4sLU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2wnYsIf4sLU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-27T04:45:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8i35trM4iEjcJTa8JKRKNDVfrjg",
    "id": {
      "kind": "youtube#video",
      "videoId": "lFmTGEwexPI"
    },
    "snippet": {
      "publishedAt": "2020-08-28T07:45:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "August 5 Min PLANK CHALLENGE - Final Day | Caroline Girvan",
      "description": "Here we go everyone... the 5 min plank challenge is now...! Final shot! It's been a challenge indeed! After certain workouts more ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lFmTGEwexPI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lFmTGEwexPI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lFmTGEwexPI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-28T07:45:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Gn_Iv6SoOOuexX4NzRlpag9GUhI",
    "id": {
      "kind": "youtube#video",
      "videoId": "lgpcpzGqW80"
    },
    "snippet": {
      "publishedAt": "2020-04-23T11:03:53Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DUMBBELL SHOULDER Workout at Home",
      "description": "At Home Dumbbell Shoulder Workout Hello everyone! If you are wanting to target your arms, then shoulders is a must as they ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lgpcpzGqW80/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lgpcpzGqW80/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lgpcpzGqW80/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-23T11:03:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jcSJT7ngeP-fV3HAquHET9brTWA",
    "id": {
      "kind": "youtube#video",
      "videoId": "I_-lf7Y1Agk"
    },
    "snippet": {
      "publishedAt": "2021-03-12T22:30:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC II - Debrief | What Next? Caroline Girvan",
      "description": "A quick EPIC II debrief and just a wee chat about what's coming up next! Whether you are relaxing post the Finale or not long into ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I_-lf7Y1Agk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I_-lf7Y1Agk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I_-lf7Y1Agk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-12T22:30:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RM2FdBIN9QF-ADP-ET2gDyDUhfs",
    "id": {
      "kind": "youtube#video",
      "videoId": "Wf7BDS2KHGs"
    },
    "snippet": {
      "publishedAt": "2021-04-05T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "9 Min Full Body Warm Up Routine | Caroline Girvan EPIC Heat",
      "description": "EPIC Heat Warm Up A simple, easy to follow, full body warm up routine to prepare your body for the work that is to come! Cx Don't ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Wf7BDS2KHGs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Wf7BDS2KHGs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Wf7BDS2KHGs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-05T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NPGrd7cEVbAHaVULKBHgMYbQ5Ig",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZUYWy8pvA_w"
    },
    "snippet": {
      "publishedAt": "2020-04-25T10:01:38Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Dumbbell Ab Workout at Home | 8 Minutes Follow Along",
      "description": "Hello everyone! This is a quick and targeted workout for the abdominal muscles and core with just one dumbbell! The weight you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZUYWy8pvA_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZUYWy8pvA_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZUYWy8pvA_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-25T10:01:38Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1wl9vCVmuUA-erL2vu2RiP2wJMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "iahkNmX6QJY"
    },
    "snippet": {
      "publishedAt": "2020-05-02T13:23:23Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Lower Abs Workout | 8 Minutes at Home",
      "description": "Lower Abs Workout | 8 Minutes at Home Hello everyone! If you wanting to tighten your lower abdominal area this video is a quick ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iahkNmX6QJY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iahkNmX6QJY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iahkNmX6QJY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-02T13:23:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PGRrvX8Cj50suKjeo1ca9ocOrV8",
    "id": {
      "kind": "youtube#video",
      "videoId": "_4TXcvnx32E"
    },
    "snippet": {
      "publishedAt": "2021-07-12T04:15:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min BRACED CORE AND ABS WORKOUT at Home | Caroline Girvan",
      "description": "This is a short yet challenging core and ab workout. We are training our core to 'fight' the arching of the lower back. Transverse ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_4TXcvnx32E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_4TXcvnx32E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_4TXcvnx32E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-12T04:15:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HKS_05RtpnTkaSLVsmpE3KdYnLg",
    "id": {
      "kind": "youtube#video",
      "videoId": "cMgJMx-2S0g"
    },
    "snippet": {
      "publishedAt": "2022-07-29T11:52:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Caroline Girvan Q&amp;A | Training Tips and Mindset",
      "description": "Hello everyone! I hope you are doing great! This is just a wee sit down as time passes very quickly, and I realise it's been a while ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cMgJMx-2S0g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cMgJMx-2S0g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cMgJMx-2S0g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-29T11:52:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eQEhnuR9o3_VVuDE3ks6yUH9Wh4",
    "id": {
      "kind": "youtube#video",
      "videoId": "SyhUwhh2t-w"
    },
    "snippet": {
      "publishedAt": "2021-05-13T04:30:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min TWISTED ABS Workout at Home - No Equipment / No Repeat",
      "description": "10 minutes ab and core workout to make your obliques and core ignite! I do an ab workout either post a main workout or on those ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SyhUwhh2t-w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SyhUwhh2t-w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SyhUwhh2t-w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-13T04:30:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yDF1TlDdPc1JIrn5WehvED-xzk4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Dg6PQpoaIaI"
    },
    "snippet": {
      "publishedAt": "2020-11-25T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 48 of EPIC | Intense Bodyweight and Dumbbell Upper Body Workout",
      "description": "Total upper body workout using bodyweight and dumbbells as resistance to help strengthen the arms, shoulders, chest, back and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Dg6PQpoaIaI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Dg6PQpoaIaI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Dg6PQpoaIaI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-25T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aOReMVA0TSAD-j_RZf-iVFsRmgo",
    "id": {
      "kind": "youtube#video",
      "videoId": "TjcTQgPmge0"
    },
    "snippet": {
      "publishedAt": "2020-10-06T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 12 of EPIC | Shoulder Workout with Dumbbells",
      "description": "Day 12 in the EPIC Program and it's shoulders! We will be using dumbbells to target our deltoids with single and double arm work, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TjcTQgPmge0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TjcTQgPmge0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TjcTQgPmge0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-06T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "J-OWIULclx0aG-Oepm0o7SnSH7E",
    "id": {
      "kind": "youtube#video",
      "videoId": "PzyzfjtNHb0"
    },
    "snippet": {
      "publishedAt": "2021-01-04T05:12:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min Full Body WARM UP with Caroline Girvan | Low Impact",
      "description": "A simple full body warm up perfect for those strength and resistance training sessions whether it is bodyweight or dumbbells!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PzyzfjtNHb0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PzyzfjtNHb0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PzyzfjtNHb0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-04T05:12:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Or1HF2EvgP_ve-SMmAuWy83nw6I",
    "id": {
      "kind": "youtube#video",
      "videoId": "JEEhNeOZr50"
    },
    "snippet": {
      "publishedAt": "2020-06-11T09:34:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min PUSH UP CHALLENGE at Home Workout | How many can you do?",
      "description": "Hello everyone! Wanting to get better at push ups? Wanting to be able to do more? Best way is to practise! This is a workout for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JEEhNeOZr50/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JEEhNeOZr50/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JEEhNeOZr50/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-11T09:34:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n_mn3ufGCE3QUUr2ZsStLUdSr2c",
    "id": {
      "kind": "youtube#video",
      "videoId": "hJg7q_Is8O4"
    },
    "snippet": {
      "publishedAt": "2020-10-11T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 15 of EPIC | 30 Min Tabata Full Body HIIT Workout at Home",
      "description": "Ready for a high intensity full body session? All you need is a mat and yourself ready to go! Let's get the heart rate up, challenge ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hJg7q_Is8O4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hJg7q_Is8O4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hJg7q_Is8O4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-11T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pA1rGZDryn1U3LcFClvWiUZFjv0",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qdlnxa35yLY"
    },
    "snippet": {
      "publishedAt": "2020-11-17T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 42 of EPIC | Dumbbell CAPPED Shoulders &amp; Core Strengthening Workout",
      "description": "Let's build the shoulders and tighten our core with this dumbbell and bodyweight workout! It's all about control lifting and lowering ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qdlnxa35yLY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qdlnxa35yLY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qdlnxa35yLY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-17T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GmlckNOd7hjM84L_qHgztmiKb10",
    "id": {
      "kind": "youtube#video",
      "videoId": "NPj7r6K7ykM"
    },
    "snippet": {
      "publishedAt": "2020-04-08T15:18:46Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BACK WORKOUT AT HOME | Dumbbells Only",
      "description": "Hello everyone! This is a super back workout for you all to try at home as part of your weekly routine. All you need is a small space ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NPj7r6K7ykM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NPj7r6K7ykM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NPj7r6K7ykM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-08T15:18:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8-Zn2w6zjb7sGV_ITDLhNSIMAhc",
    "id": {
      "kind": "youtube#video",
      "videoId": "tSAx_ySxOGc"
    },
    "snippet": {
      "publishedAt": "2020-08-04T15:44:35Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "8 Min ABS WORKOUT with Dumbbells at Home | AMRAP Routine",
      "description": "As many reps as possible ab routine for 8 minutes solid! No breaks! For this abs workout with dumbbells you will need a mat, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tSAx_ySxOGc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tSAx_ySxOGc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tSAx_ySxOGc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-04T15:44:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OAneyzTq2PfuDoKDewtbnL8PR6c",
    "id": {
      "kind": "youtube#video",
      "videoId": "bO83NFvXItk"
    },
    "snippet": {
      "publishedAt": "2020-09-07T17:32:35Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "500 REP ABS WORKOUT at Home | My 10 Favourite Ab Exercises",
      "description": "Join me for an intense targeted core and abs workout routine as we work through 10 of my favourite ab and core movements!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bO83NFvXItk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bO83NFvXItk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bO83NFvXItk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-07T17:32:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bACMGePc4QU8W3dgs-7TU_BsmGk",
    "id": {
      "kind": "youtube#video",
      "videoId": "aJVVELtDM7g"
    },
    "snippet": {
      "publishedAt": "2020-09-08T15:46:57Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min ARMS AND SHOULDERS Workout with DUMBBELLS",
      "description": "This 15 minute upper body workout is focusing on the shoulders and arms! A perfect workout to improve strength, increase ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aJVVELtDM7g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aJVVELtDM7g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aJVVELtDM7g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-08T15:46:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YVUgrxFO70QnrG8tOYXXgy3x-jA",
    "id": {
      "kind": "youtube#video",
      "videoId": "2mWlbd_ky7Q"
    },
    "snippet": {
      "publishedAt": "2020-10-18T05:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 20 of EPIC | Dumbbell HIIT Workout [30 min High Intensity]",
      "description": "High intensity, full body workout 30 minutes! No jumping, low impact, no repeat, dumbbell full body fun!! Grab your dumbbells, a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2mWlbd_ky7Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2mWlbd_ky7Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2mWlbd_ky7Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-18T05:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GZRjaNwEkWGkJ4cuz99Bvn673_w",
    "id": {
      "kind": "youtube#video",
      "videoId": "OCh-SM0vdLQ"
    },
    "snippet": {
      "publishedAt": "2021-04-20T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "OPPOSING Upper Body Workout / Dumbbells | EPIC Heat - Day 12",
      "description": "Antagonist pairing upper body session! Basically it's a very simple method of working a muscle group, then into the opposing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OCh-SM0vdLQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OCh-SM0vdLQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OCh-SM0vdLQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-20T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "v2Emxbp1YJ1WHNlWJxgcd0WkiW8",
    "id": {
      "kind": "youtube#video",
      "videoId": "9zIGzlVvLAg"
    },
    "snippet": {
      "publishedAt": "2021-04-25T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SEQUENCED HIIT Full Body Workout (with Low Impact Modifications) | EPIC Heat - Day 15",
      "description": "Mini 40 second complexes to challenge your agility, ability to react and transition, core & full body strength and endurance!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9zIGzlVvLAg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9zIGzlVvLAg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9zIGzlVvLAg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-25T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HCpKs4hqMkP6T8qDmcVkuIkhj5w",
    "id": {
      "kind": "youtube#video",
      "videoId": "iY4Ft9K7gZk"
    },
    "snippet": {
      "publishedAt": "2021-02-25T05:20:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "12 Min 4X4 ABS WORKOUT at Home / No Equipment",
      "description": "Roll out your mat and join me for a simple follow along abs workout to really make your ab muscles work! 4 movements, 4 set ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iY4Ft9K7gZk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iY4Ft9K7gZk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iY4Ft9K7gZk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-25T05:20:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VorhyQh-rxXFNGVDiVLuFLGHHD8",
    "id": {
      "kind": "youtube#video",
      "videoId": "4Q5JRHEoxcM"
    },
    "snippet": {
      "publishedAt": "2021-01-03T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Cardio &amp; HIIT WARM UP by Caroline Girvan | Follow Along",
      "description": "Let's get our bodies ready for some high intensity work! Simply follow along to this full body warm up routine! Cx Don't forget to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4Q5JRHEoxcM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4Q5JRHEoxcM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4Q5JRHEoxcM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-03T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gCj0o3mfYx02UvMFYcxCwfYRMrY",
    "id": {
      "kind": "youtube#video",
      "videoId": "i5fLIkBNgSw"
    },
    "snippet": {
      "publishedAt": "2020-11-08T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 35 of EPIC | Tabata Style HIIT Workout [NO EQUIPMENT]",
      "description": "High intensity, full body Tabata style workout hitting the entire body! No equipment! All you will need for this workout is a bottle of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/i5fLIkBNgSw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/i5fLIkBNgSw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/i5fLIkBNgSw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-08T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3GcNPxvyEnR8oDL0ZqZ85FPyNNs",
    "id": {
      "kind": "youtube#video",
      "videoId": "qh07zuq0rnQ"
    },
    "snippet": {
      "publishedAt": "2020-10-07T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 13 of EPIC | Hamstrings &amp; Glute Workout - Dumbbells + Band",
      "description": "Day 13 in the EPIC Program and it's a hamstrings and glute workout! We will be targeting the 3 main muscles of the glutes: the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qh07zuq0rnQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qh07zuq0rnQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qh07zuq0rnQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-07T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P9dRdj9R0wd6k8B6Fsb0QiWF1Ho",
    "id": {
      "kind": "youtube#video",
      "videoId": "8aVcTEern3c"
    },
    "snippet": {
      "publishedAt": "2021-04-11T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BURPEE BONANZA HIIT Workout / No Equipment | EPIC Heat - Day 5",
      "description": "Why do we love burpees so much? A full body movement, help build muscle and endurance, improve overall athleticism, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8aVcTEern3c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8aVcTEern3c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8aVcTEern3c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-11T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YrOIvme7oV4nbiTOsiwfzA-mOBM",
    "id": {
      "kind": "youtube#video",
      "videoId": "ReE9zLhksbc"
    },
    "snippet": {
      "publishedAt": "2022-05-08T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Superset Dumbbell Shoulder Workout | 25",
      "description": "Supersets\u2026 and a staple! You are guaranteed a solid, all round delt workout within 30 minutes with the focus on slow and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-08T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2C1R3CR4qWZUaCt1CTk8in_mngg",
    "id": {
      "kind": "youtube#video",
      "videoId": "X6gWQ1Lcvjg"
    },
    "snippet": {
      "publishedAt": "2020-07-13T18:07:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min DUMBBELL ARMS &amp; SHOULDER WORKOUT at Home | No Repeat",
      "description": "This is a no repeat arm and shoulder workout at home using only a pair of dumbbells and a mat! We will hit shoulders, the biceps ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X6gWQ1Lcvjg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X6gWQ1Lcvjg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X6gWQ1Lcvjg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-13T18:07:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I3pdkUNSRqvqrqrf4ipCDdxM5Q0",
    "id": {
      "kind": "youtube#video",
      "videoId": "y87vSUoIMGU"
    },
    "snippet": {
      "publishedAt": "2021-01-15T06:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNWIND 20 Min Full Body Stretch Routine | Caroline Girvan",
      "description": "20 Minute stretch... simply roll out your mat and relax. Great for when you feel tight, stiff or generally post any tough workout when ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y87vSUoIMGU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y87vSUoIMGU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y87vSUoIMGU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-15T06:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ENeulgf-cGhSkq-yvyL-gkDNPQs",
    "id": {
      "kind": "youtube#video",
      "videoId": "64Y98mkzAWk"
    },
    "snippet": {
      "publishedAt": "2020-04-09T21:42:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Minute SQUAT CHALLENGE | at Home Workout",
      "description": "Hello everyone! Here is a quick 10 minute squat challenge for you to follow along if you are wanting to target your thighs, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/64Y98mkzAWk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/64Y98mkzAWk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/64Y98mkzAWk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-09T21:42:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "H34GZ13ZFTe6RuQxvh0uY-MRz-8",
    "id": {
      "kind": "youtube#video",
      "videoId": "uGtSwgyi0oc"
    },
    "snippet": {
      "publishedAt": "2020-11-23T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 46 of EPIC | Dumbbell ABS and PUSH UPS WORKOUT [NO REPEAT]",
      "description": "Upper body workout with dumbbell abs and core combined! No repeat with 24 abs and core movements, and 24 push up ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uGtSwgyi0oc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uGtSwgyi0oc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uGtSwgyi0oc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-23T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MCup5CjYSkEevfMy7OU6tkaQD1Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "4Guz2MeWGpg"
    },
    "snippet": {
      "publishedAt": "2021-02-28T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TITANIC Tabata HIIT Workout / No Equipment | EPIC II - DAY 41",
      "description": "High intensity workout to improve athleticism, your conditioning and endurance! A mental challenge to also have fun with!! Tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4Guz2MeWGpg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4Guz2MeWGpg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4Guz2MeWGpg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-28T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RD4ygUPpruEQsV2R15TLJ61afrs",
    "id": {
      "kind": "youtube#video",
      "videoId": "hM2zyf_ijTQ"
    },
    "snippet": {
      "publishedAt": "2020-10-20T05:11:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 22 of EPIC | Dumbbell Shoulders &amp; Core Workout [Supersets]",
      "description": "Let's add definition to the arms with this shoulder workout with dumbbells combined with core strength and stability exercises with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hM2zyf_ijTQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hM2zyf_ijTQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hM2zyf_ijTQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-20T05:11:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BXG3MWuu5VVcyuFMQtxVxq-297U",
    "id": {
      "kind": "youtube#video",
      "videoId": "Z_dgrjRlD_4"
    },
    "snippet": {
      "publishedAt": "2021-04-22T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min AB WORKOUT with No Equipment (NOT abs in ten minutes)",
      "description": "10 minute ab workout to follow along! Abs in ten minutes? 6 minute abs workout to get abs in 14 days? You can't put a time scale ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Z_dgrjRlD_4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Z_dgrjRlD_4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Z_dgrjRlD_4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-22T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vUkhM8FsWsVBk0y5ClruAgFYqiM",
    "id": {
      "kind": "youtube#video",
      "videoId": "67T7IxbJjaQ"
    },
    "snippet": {
      "publishedAt": "2020-06-07T07:15:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Dumbbell HIIT Workout at Home | 15 Minutes",
      "description": "Hello everyone! Full body, high intensity, no repeat dumbbell HIIT workout with weights using lighter dumbbells only 15 minutes!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/67T7IxbJjaQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/67T7IxbJjaQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/67T7IxbJjaQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-07T07:15:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gS1inMCPAf33aZUhvW133eZdXLM",
    "id": {
      "kind": "youtube#video",
      "videoId": "bSblH5BTJCs"
    },
    "snippet": {
      "publishedAt": "2020-09-27T05:30:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 5 of EPIC | HIIT Full Body Workout No Equipment",
      "description": "Day 5 in the EPIC Program and it's a full body hiit workout session!!! This high intensity workout requires only a mat, it is suitable ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bSblH5BTJCs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bSblH5BTJCs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bSblH5BTJCs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-27T05:30:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LnTrb_BK16yJp9SQzVF44GKO030",
    "id": {
      "kind": "youtube#video",
      "videoId": "4CBxaRbA7bM"
    },
    "snippet": {
      "publishedAt": "2021-04-09T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Full Body FUSE Workout | EPIC Heat - Day 4",
      "description": "This is Day 4 in EPIC Heat and it's Glutes, back and chest primarily but a full body as every muscle will be working!! Strength, high ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4CBxaRbA7bM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4CBxaRbA7bM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4CBxaRbA7bM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-09T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "V767LC5vxlgVls42Wlhd5BQk9F8",
    "id": {
      "kind": "youtube#video",
      "videoId": "AL82Vqu_R9k"
    },
    "snippet": {
      "publishedAt": "2020-07-02T11:06:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min FIRM ABS WORKOUT at Home | No Equipment Rectus Abdominis Workout",
      "description": "This abs workout is a challenge!! It will focus on the upper and lower abs for 15 minutes with about 5 seconds rest between each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AL82Vqu_R9k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AL82Vqu_R9k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AL82Vqu_R9k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-02T11:06:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w5GF-Y4bsduo4uOwQMs-_Ntb79M",
    "id": {
      "kind": "youtube#video",
      "videoId": "S7MEouleC0I"
    },
    "snippet": {
      "publishedAt": "2021-01-17T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min HARD HIIT Full Body Workout at Home | EPIC II - Day 11",
      "description": "Let's hit this session hard!! High intensity for 30 minutes! Full body workout to get your heart rate up, challenge your agility, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S7MEouleC0I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S7MEouleC0I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S7MEouleC0I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-17T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oSFa3B42EdL0PfRQB9GMafSsQK8",
    "id": {
      "kind": "youtube#video",
      "videoId": "xQzgzKXtZ3E"
    },
    "snippet": {
      "publishedAt": "2021-02-25T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min BURPEE BLAST Cardio Workout / NO EQUIPMENT + NO REPEAT",
      "description": "Constant burpee blast cardio workout for twenty minutes! Why burpees? Because they are a full body movement to improve ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xQzgzKXtZ3E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xQzgzKXtZ3E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xQzgzKXtZ3E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-25T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zO1wjJrQxT36r2Wxf4qflZaqLPc",
    "id": {
      "kind": "youtube#video",
      "videoId": "fHlQ4CQIQwQ"
    },
    "snippet": {
      "publishedAt": "2020-10-19T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 21 of EPIC | Leg Workout at Home [Dumbbell Complex]",
      "description": "Defined legs.... let's strengthen our quads, hamstrings and glutes whilst burning lots of energy through dumbbell complexes with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fHlQ4CQIQwQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fHlQ4CQIQwQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fHlQ4CQIQwQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-19T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kWkP4CnKg3KkZE_iJSef1qb0YTY",
    "id": {
      "kind": "youtube#video",
      "videoId": "dqfovZuZVFc"
    },
    "snippet": {
      "publishedAt": "2021-03-29T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TREMBLING TRICEPS - 20 min Tricep Workout with Dumbbells",
      "description": "Let's target those triceps with trisets! Grab your dumbbells and roll out your mat to test your triceps and help increase strength!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dqfovZuZVFc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dqfovZuZVFc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dqfovZuZVFc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-29T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xzsaEaNf5zMesD4UrXK9ehR8jXs",
    "id": {
      "kind": "youtube#video",
      "videoId": "ME0cj3FTbms"
    },
    "snippet": {
      "publishedAt": "2020-12-04T21:07:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN SOLID ARMS &amp; SHOULDER WORKOUT with Dumbbells",
      "description": "Let's strengthen the shoulders, triceps and biceps in this 20 minute upper body workout! I have included shoulders in this arm ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ME0cj3FTbms/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ME0cj3FTbms/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ME0cj3FTbms/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-04T21:07:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YJ7jYoT7ZlHKiOI4vroyUsRQJC4",
    "id": {
      "kind": "youtube#video",
      "videoId": "X_zuWITrq88"
    },
    "snippet": {
      "publishedAt": "2020-05-18T18:01:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Full Body Stretch and Strength Routine | Increase Flexibility | 25 Mins",
      "description": "Hello everyone! 25 minute Stretch and Strength Routine I thought I'd share today my simple stretch and strength routine I like to do ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X_zuWITrq88/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X_zuWITrq88/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X_zuWITrq88/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-18T18:01:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lyymuvxtjpxKk-_C6w2-ojFCr3A",
    "id": {
      "kind": "youtube#video",
      "videoId": "qp15Z64Wf90"
    },
    "snippet": {
      "publishedAt": "2020-11-01T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 30 of EPIC | Intense No Jumping EMOM Full Body HIIT Workout",
      "description": "Intense no jumping EMOM high intensity workout. Every Minute On the Minute! Full body workout! High intensity! No jumping!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qp15Z64Wf90/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qp15Z64Wf90/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qp15Z64Wf90/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-01T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1S6wGZUu_YCHQvZebSWF2aCE1rU",
    "id": {
      "kind": "youtube#video",
      "videoId": "USNt7_BfTNc"
    },
    "snippet": {
      "publishedAt": "2020-10-27T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 27 of EPIC | Arms &amp; Abs Workout [DUMBBELLS + BODYWEIGHT]",
      "description": "Arm workout and abs workout in one! We will focus on strengthening and toning the triceps and abs during this bodyweight and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/USNt7_BfTNc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/USNt7_BfTNc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/USNt7_BfTNc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-27T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w9s8D3UCrQOl0fUH-orMIO6QDow",
    "id": {
      "kind": "youtube#video",
      "videoId": "z0eulElSJK0"
    },
    "snippet": {
      "publishedAt": "2021-03-31T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute BURNING Biceps Workout / Dumbbells - Caroline Girvan",
      "description": "Trisets for the biceps!! 5 variations of curls to target those biceps using trisets to truly make this a very intense session! This bicep ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/z0eulElSJK0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/z0eulElSJK0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/z0eulElSJK0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-31T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tKYB0d4IrejdOFuVp3PJoSKkrTM",
    "id": {
      "kind": "youtube#video",
      "videoId": "7demv-V-WSM"
    },
    "snippet": {
      "publishedAt": "2021-05-12T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min LEGIT Lats and Tricep Workout with Dumbbells | EPIC Heat - Day 28",
      "description": "The triceps and lat combination within this superset upper body workout is guaranteed for a challenging session! The lat muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7demv-V-WSM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7demv-V-WSM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7demv-V-WSM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-12T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3BKtMHm_kudltvr7om9uUOgoB2Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "Mx9RrNZv2TI"
    },
    "snippet": {
      "publishedAt": "2020-07-16T18:19:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min DUMBBELL UPPER BODY WORKOUT at Home",
      "description": "Upper Body Workout with Dumbbells at Home in 10 Minutes! Grab your dumbbells and a mat and let's go! We will be working our ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Mx9RrNZv2TI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Mx9RrNZv2TI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Mx9RrNZv2TI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-16T18:19:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LP97Ae0-8KfyHrs1bb_-fv11mgU",
    "id": {
      "kind": "youtube#video",
      "videoId": "mTKsxyHs2Jc"
    },
    "snippet": {
      "publishedAt": "2021-07-04T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNIQUE Unilateral HIIT Workout - Full Body, No Equipment | Caroline Girvan",
      "description": "Ready to challenge your speed, co-ordination, mental grit and stamina? The next 30 minutes of bodyweight resistance high ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mTKsxyHs2Jc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mTKsxyHs2Jc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mTKsxyHs2Jc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-04T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9fXhO69EtrtoxxDMHKzU4SwZ7S0",
    "id": {
      "kind": "youtube#video",
      "videoId": "TeNg1oTvlVM"
    },
    "snippet": {
      "publishedAt": "2021-12-16T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "12 Minute Dumbbell Deadbug AB WORKOUT at Home | Caroline Girvan",
      "description": "Exactly as it states! A workout involving primarily DEADBUG variations to target and strengthen the abdominal muscles and entire ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TeNg1oTvlVM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TeNg1oTvlVM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TeNg1oTvlVM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-16T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6qChu_yXKc-emFhEpAavjx6spiQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "UFxB7mIljgo"
    },
    "snippet": {
      "publishedAt": "2021-04-28T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DIAMOND Chest &amp; Core Workout - Advanced | EPIC Heat - Day 18",
      "description": "Chest press! Push ups! Flyes! Deadbug! Some of our favourites combined in a challenging 30 minutes to work your entire upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UFxB7mIljgo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UFxB7mIljgo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UFxB7mIljgo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-28T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "F9CB4Ria8f-xLnyBUL-f8vHpNpQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "9iyU0YTHxKY"
    },
    "snippet": {
      "publishedAt": "2021-04-07T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DEPLETED Lower Body Workout / Leg Day | EPIC Heat - Day 3",
      "description": "30 exercises to work through all focused on the lower body! A mixture of bodyweight and use of dumbbells to help build strength, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9iyU0YTHxKY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9iyU0YTHxKY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9iyU0YTHxKY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-07T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "80e32rIsb0HpNkUVcEOFf2Vqa4g",
    "id": {
      "kind": "youtube#video",
      "videoId": "exC2PUDk_HM"
    },
    "snippet": {
      "publishedAt": "2021-05-24T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PUMPED 30 Min Shoulder Workout with Dumbbells | EPIC Heat - Day 36",
      "description": "Superset shoulders to hit the delts from all angles! Pressing, raises and partials in this 30 min workout! Using our dumbbells to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/exC2PUDk_HM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/exC2PUDk_HM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/exC2PUDk_HM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-24T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nBd1fbODbTE0M4KNQCAslhzoBpg",
    "id": {
      "kind": "youtube#video",
      "videoId": "VCcar3MA07w"
    },
    "snippet": {
      "publishedAt": "2020-08-08T13:00:28Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min FULL BODY KETTLEBELL WORKOUT at Home | Caroline Girvan",
      "description": "Full Body Kettlebell Workout - 15 Minutes to work your entire body with 1 kettlebell! We will perform each of the following kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VCcar3MA07w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VCcar3MA07w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VCcar3MA07w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-08T13:00:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ta26ggQH8XwNag9hRXZ2t5SVZ_g",
    "id": {
      "kind": "youtube#video",
      "videoId": "AdNpljl8tOI"
    },
    "snippet": {
      "publishedAt": "2021-02-11T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "THE CRUNCH 15 Min ABS Workout / No Equipment - Caroline Girvan",
      "description": "15 minutes abs workout to follow along! Primarily targeting the upper abs with crunches! All you will need is your mat! Timer is on ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AdNpljl8tOI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AdNpljl8tOI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AdNpljl8tOI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-11T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tkzYQPA_WLnimuPm8e7nTVSyfjQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "c-iMaj-ue18"
    },
    "snippet": {
      "publishedAt": "2020-11-10T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 37 of EPIC | Dumbbell Lower Body Workout [BULGARIAN LUNGES]",
      "description": "It's EPIC leg day!! A bodyweight and dumbbell lower body workout with variations of Bulgarian Lunges, primarily targeting the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c-iMaj-ue18/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c-iMaj-ue18/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c-iMaj-ue18/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-10T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cMVbXVMK6QKS0BUvdRcDF7BdeUo",
    "id": {
      "kind": "youtube#video",
      "videoId": "UBPAek16pl4"
    },
    "snippet": {
      "publishedAt": "2020-08-31T10:30:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DUMBBELL DYNAMIC and ISOMETRIC Workout | 20 Min Upper Body",
      "description": "This dumbbell dynamic and isometric workout is using isometric training combined with dynamic movements to target your entire ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UBPAek16pl4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UBPAek16pl4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UBPAek16pl4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-31T10:30:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pkjAmx9ehRi5DdFn_NzWYfg616A",
    "id": {
      "kind": "youtube#video",
      "videoId": "UzWGNJZ23rI"
    },
    "snippet": {
      "publishedAt": "2020-08-02T08:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min FIERCE FULL BODY HIIT WORKOUT at Home | No Repeat",
      "description": "Let's get heart rate up, burn lots of calories and become stronger! 30 Min Full body HIIT Workout at Home This high intensity full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UzWGNJZ23rI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UzWGNJZ23rI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UzWGNJZ23rI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-02T08:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CDoThaLnqZkAhP2jAswnu-kzORY",
    "id": {
      "kind": "youtube#video",
      "videoId": "xPxCcwG56Po"
    },
    "snippet": {
      "publishedAt": "2022-04-17T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Back &amp; Biceps Workout - Rows, Curls | 10",
      "description": "Pull day let's do it! Packed with rows and curls to put load on the back and biceps! Slow, precise and controlled is the objective!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xPxCcwG56Po/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xPxCcwG56Po/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xPxCcwG56Po/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-17T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TEHETYF8Ki1V3Go1M1hyUv_msfk",
    "id": {
      "kind": "youtube#video",
      "videoId": "YEGHVqDpMpk"
    },
    "snippet": {
      "publishedAt": "2020-06-25T06:00:59Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min INTENSE LOWER ABS WORKOUT at Home | No Equipment",
      "description": "This follow along workout is an abdominal at home workout to strengthen your core and abdominal muscles. In order to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YEGHVqDpMpk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YEGHVqDpMpk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YEGHVqDpMpk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-25T06:00:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Zm3PO-1NT_lJKF2rIl8wXED2FdY",
    "id": {
      "kind": "youtube#video",
      "videoId": "k8_EmzD1trk"
    },
    "snippet": {
      "publishedAt": "2020-04-29T14:51:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "5 Minute Leg Workout | Follow Along with No Equipment",
      "description": "Hello everyone! Here is an absolute favourite of mine if I have 5 minutes and wanted to work my legs and include some calf work!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/k8_EmzD1trk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/k8_EmzD1trk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/k8_EmzD1trk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-29T14:51:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KHenAr-8RMpcZg4gv3vs26yn138",
    "id": {
      "kind": "youtube#video",
      "videoId": "YSqdzrmJAlw"
    },
    "snippet": {
      "publishedAt": "2021-08-26T04:30:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 MIN CLASSIC AB WORKOUT - Bodyweight Only | Caroline Girvan",
      "description": "This is a quick and effective 10 minute ab workout to help strengthen the abdominal muscles. Combining some of my favourites in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YSqdzrmJAlw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YSqdzrmJAlw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YSqdzrmJAlw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-26T04:30:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bDplNxG_Ql3L9j46FWKTIcRPDnc",
    "id": {
      "kind": "youtube#video",
      "videoId": "vup3fgCPG-I"
    },
    "snippet": {
      "publishedAt": "2021-04-30T04:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FORCEFUL Full Body Workout - Triset Drop Sets | EPIC Heat - Day 19",
      "description": "Triset Drop Sets! The entire lower body, back, shoulders and biceps mainly targeted through compound and isolation exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vup3fgCPG-I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vup3fgCPG-I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vup3fgCPG-I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-30T04:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YzN4ql_PEJAu9NkTAXB7zenXuV4",
    "id": {
      "kind": "youtube#video",
      "videoId": "5Rm92kXyae0"
    },
    "snippet": {
      "publishedAt": "2020-08-10T18:49:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "12 Min OBLIQUE AND CORE WORKOUT at Home | No Equipment",
      "description": "This workout is all about targeting the obliques along with the core! 12 minutes on a mat, 40 seconds of each movement with 5 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5Rm92kXyae0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5Rm92kXyae0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5Rm92kXyae0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-10T18:49:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ALIEhHQ-cV2TVaGJV16hyYbAP6c",
    "id": {
      "kind": "youtube#video",
      "videoId": "QNfW9VzfSOs"
    },
    "snippet": {
      "publishedAt": "2020-11-03T05:31:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 32 of EPIC | Obliques &amp; Shoulder Workout with Dumbbells",
      "description": "Shoulders and obliques workout here we come! We will be hitting our entire core abs abs with focus on the obliques followed by a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QNfW9VzfSOs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QNfW9VzfSOs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QNfW9VzfSOs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-03T05:31:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3U4VMC6U3-AsK17l26lo9sTuPc4",
    "id": {
      "kind": "youtube#video",
      "videoId": "CWh3j4eBvE8"
    },
    "snippet": {
      "publishedAt": "2021-02-03T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "INTENSE Hamstrings and Core Focus Workout | EPIC II - Day 24",
      "description": "Hamstring focused lower body session with a lot of work involving the glutes too and beginning with some core specific work!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CWh3j4eBvE8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CWh3j4eBvE8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CWh3j4eBvE8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-03T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wGunmH_60ePKmXaJ-zxjPEEXS2M",
    "id": {
      "kind": "youtube#video",
      "videoId": "CW90wmduINs"
    },
    "snippet": {
      "publishedAt": "2020-12-20T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN AGILE FULL BODY HIIT WORKOUT at Home | No Equipment",
      "description": "20 minutes of fun with this full body HIIT workout at home!!! Warm up, press play and let's feel amazing! Turn up the intensity as it's ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CW90wmduINs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CW90wmduINs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CW90wmduINs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-20T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "T0baa6DWdnRIjy0XZs1qztN_vxk",
    "id": {
      "kind": "youtube#video",
      "videoId": "3UupKOx-f9A"
    },
    "snippet": {
      "publishedAt": "2021-09-26T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BADASS BURPEES Full Body Dumbbell Cardio Workout | EPIC III Day 15",
      "description": "An action packed cardio workout involving burpees plus more! Cardiovascular fitness, co-ordination, balance and determination ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3UupKOx-f9A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3UupKOx-f9A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3UupKOx-f9A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-26T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "25UKpj5zK7Wr7dOWgvUm7SATcYk",
    "id": {
      "kind": "youtube#video",
      "videoId": "RgU102aSES0"
    },
    "snippet": {
      "publishedAt": "2021-02-08T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FOCUSED Front Delts &amp; ABS Workout / SHOULDERS | EPIC II - Day 27",
      "description": "Ab workout targeting the rectus abdominus muscles to begin then onto a shoulder workout focusing on those front delts! All you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RgU102aSES0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RgU102aSES0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RgU102aSES0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-08T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nG1M2rd4RnLUJMv37Qi2LJBD3_8",
    "id": {
      "kind": "youtube#video",
      "videoId": "BfIvU-1K0Uo"
    },
    "snippet": {
      "publishedAt": "2021-10-04T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUMMIT LEG DAY - Pyramid Training Leg Workout | EPIC III Day 21",
      "description": "Pyramid training to challenge you mentally and definitely physically! The entire lower body will be forced to work hard as we work ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BfIvU-1K0Uo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BfIvU-1K0Uo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BfIvU-1K0Uo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-04T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5ZsvcPPWC6_0oDzkA7_rGTzp-TM",
    "id": {
      "kind": "youtube#video",
      "videoId": "DUauPVlSO6g"
    },
    "snippet": {
      "publishedAt": "2021-04-05T04:15:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Uncomplicated Complexes LEG DAY Workout | EPIC Heat - Day 1",
      "description": "Day 1 in the EPIC Heat Program! Let's get started! Uncomplicated complexes for this leg day! Complexes on the legs are a sure ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DUauPVlSO6g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DUauPVlSO6g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DUauPVlSO6g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-05T04:15:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "e7ZHcJLwaRNt_K-g57Dwo78eqkw",
    "id": {
      "kind": "youtube#video",
      "videoId": "FJjT-FUlXVU"
    },
    "snippet": {
      "publishedAt": "2021-01-14T05:00:36Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min Core &amp; Abs Workout / Twenty PLANK Variations - No Equipment",
      "description": "Join me for 20 variations of plank! A strong core can't be underestimated... all of those squat to press, single leg balancing, lifting ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FJjT-FUlXVU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FJjT-FUlXVU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FJjT-FUlXVU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-14T05:00:36Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CKe-MEBvBWf_GQHzKhrN_NyRDRg",
    "id": {
      "kind": "youtube#video",
      "videoId": "nKzZLE6s1cs"
    },
    "snippet": {
      "publishedAt": "2020-09-25T07:30:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 4 of Beginner EPIC | 30 Min Dumbbell Full Body Workout",
      "description": "Day 4 in the EPIC Beginners Series for those of you wanting to progress to the full EPIC Program! For 30 minutes, we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nKzZLE6s1cs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nKzZLE6s1cs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nKzZLE6s1cs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-25T07:30:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SJsJvRnO0mLgAZV2DjfWcm0_1XM",
    "id": {
      "kind": "youtube#video",
      "videoId": "kK5QYRrJkGk"
    },
    "snippet": {
      "publishedAt": "2020-05-14T13:43:58Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Shoulder Workout at Home with Dumbbells | Follow Along with Me",
      "description": "Hello everyone! This a follow along shoulder workout at home with dumbbells. Building your shoulders are all part of creating a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kK5QYRrJkGk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kK5QYRrJkGk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kK5QYRrJkGk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-14T13:43:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pXetbp3TPtKAb33YGkr6gtoalVw",
    "id": {
      "kind": "youtube#video",
      "videoId": "DRu3iU6YJig"
    },
    "snippet": {
      "publishedAt": "2020-05-25T13:28:57Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1000 Rep Ab Challenge | Six Pack Workout",
      "description": "If you're new, please consider Subscribing to my Fitness Channel here: https://youtube.com/c/carolinegirvan/?sub_confirmation=1 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DRu3iU6YJig/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DRu3iU6YJig/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DRu3iU6YJig/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-25T13:28:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "e8pTYa8Q8EJ_QDugsqPq3g8pb5M",
    "id": {
      "kind": "youtube#video",
      "videoId": "5QHvyZM1gCg"
    },
    "snippet": {
      "publishedAt": "2021-02-14T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "RED HOT HIIT Workout Full Body / No Equipment | EPIC II - Day 31",
      "description": "Some fun coming for the next 30 minutes!! You be finish feeling accomplished, sweaty and proud! High intensity workout to burn a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5QHvyZM1gCg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5QHvyZM1gCg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5QHvyZM1gCg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-14T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qUi0ukv7mQQMeZsAgSDRqb_1nr0",
    "id": {
      "kind": "youtube#video",
      "videoId": "W0Zht0NC970"
    },
    "snippet": {
      "publishedAt": "2022-01-09T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "COSMIC Cardio Complex Workout - No Equipment | EPIC Endgame Day 5",
      "description": "Cardio complexes! Ready?! All you need is your body FULLY warmed up, your mat and a bottle of water nearby! Each complex ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W0Zht0NC970/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W0Zht0NC970/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W0Zht0NC970/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-09T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kh1Y_TTe7Y0vT5269DzWtye-a54",
    "id": {
      "kind": "youtube#video",
      "videoId": "7xN0WKP7Kus"
    },
    "snippet": {
      "publishedAt": "2021-05-16T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "HIGH-POWERED HIIT - Superset Full Body Workout | EPIC Heat - Day 30",
      "description": "SUPERSET HIIT! Dumbbells and bodyweight combined in supersets for a high intensity, action packed 30 minutes full of quick ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7xN0WKP7Kus/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7xN0WKP7Kus/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7xN0WKP7Kus/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-16T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RIQIu0sWj2zNdyBdS9O09WUwexE",
    "id": {
      "kind": "youtube#video",
      "videoId": "mUz_PESX9Lo"
    },
    "snippet": {
      "publishedAt": "2021-06-11T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "NO FEAR Full Body Workout with Dumbbells - Complexes | EPIC Heat - Day 49",
      "description": "Shoulders, back, arms, abs, quads, hamstrings and glutes all involved in one of my favourite training methods; complexes!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mUz_PESX9Lo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mUz_PESX9Lo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mUz_PESX9Lo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-11T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rEOXpCO20rRHmL4CZIWt8QQuY3k",
    "id": {
      "kind": "youtube#video",
      "videoId": "4AbbhmfcNEc"
    },
    "snippet": {
      "publishedAt": "2021-04-06T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUPERior Shoulders Workout / Dumbbells | EPIC Heat - Day 2",
      "description": "This shoulder session will work your shoulders until failure throughout! Hitting the shoulders through compound and isolation ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4AbbhmfcNEc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4AbbhmfcNEc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4AbbhmfcNEc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-06T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ew9btl4gypWF1pTvIWs319tjMvA",
    "id": {
      "kind": "youtube#video",
      "videoId": "3oeimlA6s68"
    },
    "snippet": {
      "publishedAt": "2020-10-22T16:20:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min ABS WORKOUT at Home [NO EQUIPMENT + NO REPEAT] Caroline Girvan",
      "description": "No equipment needed for this 20 minute at home abs workout. I do love some stretching and some extra ab work on my active rest ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3oeimlA6s68/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3oeimlA6s68/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3oeimlA6s68/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-22T16:20:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I2_2iVGwQvX3YFRHG1dUh59zgGY",
    "id": {
      "kind": "youtube#video",
      "videoId": "4NOV6YVbvB0"
    },
    "snippet": {
      "publishedAt": "2020-09-23T07:30:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 3 of Beginner EPIC | No Equipment Ab &amp; Core Workout",
      "description": "Day 3 in the EPIC Beginners Series featuring an ab and core workout for those of you wanting to progress to the full EPIC ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4NOV6YVbvB0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4NOV6YVbvB0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4NOV6YVbvB0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-23T07:30:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N1w4u_m4SrMgfrvhcpWQjYr4W58",
    "id": {
      "kind": "youtube#video",
      "videoId": "E1I3SNPKfRY"
    },
    "snippet": {
      "publishedAt": "2021-10-17T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CARDIO CHAOS Full Body Workout - Bodyweight Only | EPIC III Day 30",
      "description": "Ready for some sweat and cardio chaos? A challenge? 3 minute complexes that involve you being aware of your limits\u2026. the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E1I3SNPKfRY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E1I3SNPKfRY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E1I3SNPKfRY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-17T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uyl6prvYycKztoQBruHU2ZZzQSQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "hPi79mq_1rE"
    },
    "snippet": {
      "publishedAt": "2021-12-09T05:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute Tucked Abs Workout - Bodyweight Only | Caroline Girvan",
      "description": "Ready for 15 minutes working on the lower abdominal muscles primarily!? The timer will be on for 45 seconds each exercise ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hPi79mq_1rE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hPi79mq_1rE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hPi79mq_1rE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-09T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "959PxcWjiNr9r_ghQzza2C_RsWE",
    "id": {
      "kind": "youtube#video",
      "videoId": "5bgVXyP0IIM"
    },
    "snippet": {
      "publishedAt": "2021-07-14T04:15:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 MIN WEIGHTED ABS - Dumbbell Ab Workout | Caroline Girvan",
      "description": "Ten minutes of working on strengthening our ab muscles and entire core! A dumbbell ab workout perfect for post any workout or ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5bgVXyP0IIM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5bgVXyP0IIM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5bgVXyP0IIM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-14T04:15:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3r7LH7ri7MTfEMbyhhEWUSKOBYY",
    "id": {
      "kind": "youtube#video",
      "videoId": "cWNVP4Ui22g"
    },
    "snippet": {
      "publishedAt": "2022-01-12T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BRAWNY Back and Biceps Workout + Abs &amp; Core | EPIC Endgame Day 8",
      "description": "Here we go!! The back, those biceps and the entire core & abs will be working hard today! As you might have noticed, the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cWNVP4Ui22g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cWNVP4Ui22g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cWNVP4Ui22g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-12T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_sPc83ulqZv5cDOWSzaY7HJsxhw",
    "id": {
      "kind": "youtube#video",
      "videoId": "VA4laPv2CVo"
    },
    "snippet": {
      "publishedAt": "2021-03-19T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KETTLEBELL WORKOUT - Kick-ass Kettlebell Series | Day 5",
      "description": "Day 5 in Kick-ass Kettlebell Series and it's a solid strength workout using trisets to target the largest muscle groups in the body.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VA4laPv2CVo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VA4laPv2CVo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VA4laPv2CVo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-19T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VhX9Fc0Vbe0bfyltGvz8mph9Dog",
    "id": {
      "kind": "youtube#video",
      "videoId": "wH-YPotqDlg"
    },
    "snippet": {
      "publishedAt": "2020-08-12T16:32:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min INTENSE DUMBBELL SHOULDER WORKOUT at Home | Supersets",
      "description": "Shoulder supersets! 20 minutes shoulder workout at home using only a pair of dumbbells and a mat! We will hit shoulders at all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wH-YPotqDlg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wH-YPotqDlg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wH-YPotqDlg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-12T16:32:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sDF7tsUxDWsnDBCK84kaazqep5Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ix0BUFavq_0"
    },
    "snippet": {
      "publishedAt": "2020-05-20T09:51:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "500 Rep Lunge Challenge | Bodyweight and Dumbbells",
      "description": "Hello everyone! Follow along with me to complete the 500 rep lunge challenge with half being with dumbbells (for reference I'm ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ix0BUFavq_0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ix0BUFavq_0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ix0BUFavq_0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-20T09:51:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4cMM-UKP9tquQpeDGbdfGDpMpgA",
    "id": {
      "kind": "youtube#video",
      "videoId": "hRu9JAJnmkc"
    },
    "snippet": {
      "publishedAt": "2022-02-20T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TAKE-CHARGE Tabata HIIT Workout - Bodyweight | EPIC Endgame Day 35",
      "description": "Here we go!!! Fun ahead!! The entire body will be in action today with the heart rate elevated\u2026 short work period with short rest!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hRu9JAJnmkc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hRu9JAJnmkc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hRu9JAJnmkc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-20T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MtLe5erFSddCjhkpRxcVQkZETUY",
    "id": {
      "kind": "youtube#video",
      "videoId": "PWmj78iYlo8"
    },
    "snippet": {
      "publishedAt": "2021-03-24T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min ENGAGING ABS &amp; Core Workout | Dumbbells &amp; Bodyweight",
      "description": "15 minute NO REPEAT ab & core workout using bodyweight and dumbbell as resistance for a perfect ab routine to be completed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PWmj78iYlo8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PWmj78iYlo8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PWmj78iYlo8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-24T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7v1ywP5FgyR5IaX6GwAOkzXwh30",
    "id": {
      "kind": "youtube#video",
      "videoId": "496T-KtRAAU"
    },
    "snippet": {
      "publishedAt": "2020-11-09T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 36 of EPIC | BACK WORKOUT / BICEP WORKOUT with Dumbbells",
      "description": "Bicep workout and back workout with dumbbells! We will be doing rows, pulls, curls and pushes to strengthen our back and arms.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/496T-KtRAAU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/496T-KtRAAU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/496T-KtRAAU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-09T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Embgr4BzGEwCsAP_7dc2corgQgg",
    "id": {
      "kind": "youtube#video",
      "videoId": "Bl28i6fWljU"
    },
    "snippet": {
      "publishedAt": "2021-12-08T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Arms and Shoulders Workout with Dumbbells | Caroline Girvan",
      "description": "20 MINUTE SHOULDERS & ARMS workout! Here we go!! Ready for my style of finisher? The triceps will be officially done! Slow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Bl28i6fWljU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Bl28i6fWljU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Bl28i6fWljU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-08T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rl1IISK8TxckTKexlX5fJN1_GgU",
    "id": {
      "kind": "youtube#video",
      "videoId": "9seYxs-vu9s"
    },
    "snippet": {
      "publishedAt": "2020-10-25T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 25 of EPIC | HIIT Full Body Workout [60 EXERCISES NO REPEAT]",
      "description": "Ready for some high intensity fun with 60 exercises and limited rest in this no repeat HIIT workout, that requires maximum effort ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9seYxs-vu9s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9seYxs-vu9s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9seYxs-vu9s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-25T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "itUYsAijKH10C4GWHjmfPJUzusU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZvIJI_DY10g"
    },
    "snippet": {
      "publishedAt": "2021-10-03T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DEFIANCE Dumbbell HIIT Workout - Full Body | EPIC III Day 20",
      "description": "Controlling of the dumbbells throughout whilst pushing through with an elevated heartrate is the set up in this high intensity ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZvIJI_DY10g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZvIJI_DY10g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZvIJI_DY10g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-03T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wciJMxJdKjURMAGHy5oEVy760-8",
    "id": {
      "kind": "youtube#video",
      "videoId": "FQ4RLYVBvJA"
    },
    "snippet": {
      "publishedAt": "2022-01-16T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TITAN Tabata HIIT Workout - Bodyweight | EPIC Endgame Day 10",
      "description": "Here we go! It's TABATA time! Short bursts of high intensity! Pace is the PACE that challenges YOU! Cardio with bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FQ4RLYVBvJA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FQ4RLYVBvJA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FQ4RLYVBvJA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-16T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BNWYUPWfZhk8H9tiK2RLtiOB1J0",
    "id": {
      "kind": "youtube#video",
      "videoId": "zOPTihGAito"
    },
    "snippet": {
      "publishedAt": "2020-08-20T11:39:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min GLUTES and HAMSTRINGS WORKOUT at Home with Dumbbells",
      "description": "15 minute lower body dumbbell workout focusing on the hamstrings and glutes! This dumbbell hamstrings and glute workout will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zOPTihGAito/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zOPTihGAito/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zOPTihGAito/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-20T11:39:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZCd2nl1CYrvOUG2CU7frehQSnn8",
    "id": {
      "kind": "youtube#video",
      "videoId": "KzBZ02EAJvE"
    },
    "snippet": {
      "publishedAt": "2020-06-08T08:08:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Tricep Workout at Home with Dumbbells | 10 Minutes",
      "description": "Hello everyone! Follow along to this 10 minute tricep workout at home with dumbbells. The triceps make up approx 2/3rds of your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KzBZ02EAJvE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KzBZ02EAJvE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KzBZ02EAJvE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-08T08:08:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qJWv6jgOFVf2_qFf0691WImU0rc",
    "id": {
      "kind": "youtube#video",
      "videoId": "XTzgm-pMQ6I"
    },
    "snippet": {
      "publishedAt": "2021-01-09T18:10:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min Jumping Jack CARDIO WORKOUT at Home | Caroline Girvan",
      "description": "All you will need for this 20 minute cardio workout is a bottle of water handy and a mat!! The timer will be on continuously for 45 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XTzgm-pMQ6I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XTzgm-pMQ6I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XTzgm-pMQ6I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-09T18:10:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yKwYcI_AEDsIX1iSoh3VCqn8Wsk",
    "id": {
      "kind": "youtube#video",
      "videoId": "rkPHNhZ3rsw"
    },
    "snippet": {
      "publishedAt": "2021-03-16T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "COMPLEX KETTLEBELL WORKOUT - Kick-ass Kettlebell Series | Day 2",
      "description": "Day 2 in the Kick-ass Kettlebell Series and it's COMPLEXES! Each complex will be approx 3 minutes duration with 45 seconds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rkPHNhZ3rsw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rkPHNhZ3rsw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rkPHNhZ3rsw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-16T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "t1zrbqVOKkC4TRgtQrpE3371q6s",
    "id": {
      "kind": "youtube#video",
      "videoId": "_jz30UMocHs"
    },
    "snippet": {
      "publishedAt": "2020-10-26T05:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 26 of EPIC | Dumbbell Full Body Strength Workout [COMPLEXES]",
      "description": "Build lean muscle! Complexes are one of my favourite training methods to condition the body, increase strength and shed body fat ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_jz30UMocHs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_jz30UMocHs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_jz30UMocHs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-26T05:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-mzcBZ8n6_iPnfceDqEhk4jkhGk",
    "id": {
      "kind": "youtube#video",
      "videoId": "tCSEYY1RU7M"
    },
    "snippet": {
      "publishedAt": "2021-03-07T05:00:28Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC EMOM Full Body HIIT Workout / No Equipment | EPIC II - Day 46",
      "description": "EMOM day!!! High intensity for 30 minutes!! You will work hard but be so glad you did this!!! And there are different numbers For ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tCSEYY1RU7M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tCSEYY1RU7M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tCSEYY1RU7M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-07T05:00:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wRz6hRMrrH3pW5yR577AEk6pQ_0",
    "id": {
      "kind": "youtube#video",
      "videoId": "qwZV_29_ezI"
    },
    "snippet": {
      "publishedAt": "2021-07-13T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN CHEST AND SHOULDERS WORKOUT with Dumbbells | No Repeat",
      "description": "Perfect combination\u2026 chest and shoulders workout! A solid session targeting the chest with chest press & flyes then onto the delts ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qwZV_29_ezI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qwZV_29_ezI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qwZV_29_ezI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-13T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lYLwiaeHypapZXl5Fc7ntqHx9EE",
    "id": {
      "kind": "youtube#video",
      "videoId": "yWNSluLmyjI"
    },
    "snippet": {
      "publishedAt": "2020-06-10T12:24:50Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PLANK &amp; WALL SIT CHALLENGE at Home | Can you last 15 minutes?",
      "description": "Looking for a challenge? Wall sit challenge and plank challenge combined! All you need is a towel for your arms, wall and plenty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yWNSluLmyjI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yWNSluLmyjI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yWNSluLmyjI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-10T12:24:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rAzUFI2paRZbsKa-x4e97NfdUk4",
    "id": {
      "kind": "youtube#video",
      "videoId": "N9_11gKQGqM"
    },
    "snippet": {
      "publishedAt": "2021-03-26T05:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute HARD-WORKING Hamstrings | RDL Dumbbell Workout",
      "description": "Hamstrings, glutes, lower back and adductors all targeted in this 15 minute Romanian deadlift focused session! For this workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/N9_11gKQGqM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/N9_11gKQGqM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/N9_11gKQGqM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-26T05:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P0bDGRb_zO8MPt7uS6PiSV7drM0",
    "id": {
      "kind": "youtube#video",
      "videoId": "rxWRuEJhK2M"
    },
    "snippet": {
      "publishedAt": "2021-03-10T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ELEVATED Calisthenics Workout / Full Body | EPIC II - Day 49",
      "description": "Elevated calisthenics workout\u2026 exactly as it says! We will be using the yoga block/book for many of these exercises to make it ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rxWRuEJhK2M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rxWRuEJhK2M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rxWRuEJhK2M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-10T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yYqBeS_Hm3qWy65UxRNkQYeiHts",
    "id": {
      "kind": "youtube#video",
      "videoId": "QFwEQqbdCo4"
    },
    "snippet": {
      "publishedAt": "2020-09-02T15:57:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min STANDING ABS WORKOUT | Arms + Core with Dumbbells",
      "description": "We will be hitting our entire core in this 15 minute standing abs workout with dumbbells. Alongside the abs and core, the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QFwEQqbdCo4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QFwEQqbdCo4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QFwEQqbdCo4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-02T15:57:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GcmSpBxTitLCkQ18iN-RmSKlUO8",
    "id": {
      "kind": "youtube#video",
      "videoId": "uvlrHB00jz0"
    },
    "snippet": {
      "publishedAt": "2020-10-14T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 18 of EPIC | 40 Min Chest and Triceps Workout at Home",
      "description": "The arms and chest are targeted in Day 18 of the EPIC Program! Full of super sets throughout and finishing with bodyweight push ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uvlrHB00jz0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uvlrHB00jz0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uvlrHB00jz0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-14T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mHIHbFWKAPHWVnxjA4iDMrLrjR0",
    "id": {
      "kind": "youtube#video",
      "videoId": "9qLM-_uNQq8"
    },
    "snippet": {
      "publishedAt": "2021-06-06T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DYNAMITE Dumbbell HIIT Workout - Full Body | EPIC Heat - Day 45",
      "description": "Pressing, squatting, rowing, lunging, curling, planking... it's all here! 30 minutes dumbbell high intensity workout to push your limits ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9qLM-_uNQq8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9qLM-_uNQq8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9qLM-_uNQq8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-06T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eOee_mPEGWzp1nuNVu7RH0IubOI",
    "id": {
      "kind": "youtube#video",
      "videoId": "D_d1qyHATb0"
    },
    "snippet": {
      "publishedAt": "2020-08-17T08:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min FULL BODY DUMBBELL WORKOUT at Home | No Repeat",
      "description": "A full body dumbbell workout at home to hit the whole body in just 20 minutes! Grab your dumbbells and join me! In this no repeat ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D_d1qyHATb0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D_d1qyHATb0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D_d1qyHATb0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-17T08:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "znceuGR7l0zKtLHDJrDajB3gC5Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "YoLXWnTb9-c"
    },
    "snippet": {
      "publishedAt": "2021-05-28T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "COLOSSAL Full Body Workout - Strength &amp; Cardio | EPIC Heat - Day 39",
      "description": "Strength, cardio, core, coordination, endurance! It's all in this full body workout! Hamstrings, quads, glutes, shoulders, back and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YoLXWnTb9-c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YoLXWnTb9-c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YoLXWnTb9-c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-28T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wyTC4sKwWz944EPWXz6IJqIzius",
    "id": {
      "kind": "youtube#video",
      "videoId": "3aUVB9doc5s"
    },
    "snippet": {
      "publishedAt": "2021-10-22T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "POWERFUL Pyramid Training - Full Body Workout | EPIC III Day 34",
      "description": "A full body workout that allows you to spend extra sets on movements to build strength, endurance and muscle using pyramid sets ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3aUVB9doc5s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3aUVB9doc5s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3aUVB9doc5s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-22T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uk-cxLFMWqD4otu-eKZmbk6CYhY",
    "id": {
      "kind": "youtube#video",
      "videoId": "rma9n_xL1i0"
    },
    "snippet": {
      "publishedAt": "2020-12-16T21:00:33Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 MIN COMPOUND LEG WORKOUT with Dumbbells at Home | Follow Along",
      "description": "15 minute lower body compound leg workout comprising of compound movements to work many muscles at the same time!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rma9n_xL1i0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rma9n_xL1i0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rma9n_xL1i0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-16T21:00:33Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IMyBK4SYzoYM2SlByhR4c51tdS8",
    "id": {
      "kind": "youtube#video",
      "videoId": "xqeEtRuGKXg"
    },
    "snippet": {
      "publishedAt": "2020-08-13T18:05:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Min QUICK HIIT WORKOUT at Home | Full Body | No Equipment",
      "description": "A perfect quick 10 min HIIT session mid week 10 minute ex to get our heart rate up working our entire body, building muscle, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xqeEtRuGKXg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xqeEtRuGKXg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xqeEtRuGKXg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-13T18:05:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "amsNoYLhNBBNx1TjTmg3sfCwo-E",
    "id": {
      "kind": "youtube#video",
      "videoId": "hzvZrSZhvVg"
    },
    "snippet": {
      "publishedAt": "2020-07-18T10:13:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min LOWER BODY KETTLEBELL WORKOUT at Home | Caroline Girvan",
      "description": "This is a 15 minute follow along lower body kettlebell workout to target your quads, hamstrings, glutes and core! You can use 1 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hzvZrSZhvVg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hzvZrSZhvVg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hzvZrSZhvVg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-18T10:13:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NPHUJqWPYO9hOLCU5gKaprSBO3w",
    "id": {
      "kind": "youtube#video",
      "videoId": "jAGzqm6bw_Y"
    },
    "snippet": {
      "publishedAt": "2021-01-19T05:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min COMPLETE Chest &amp; Triceps Workout / Dumbbells | EPIC II - Day 13",
      "description": "Complete chest and tricep session to completely challenge your arms, shoulders, chest and core strength! This upper body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jAGzqm6bw_Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jAGzqm6bw_Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jAGzqm6bw_Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-19T05:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wv6rQoYmEca1HDAl4x4HLTDxUhE",
    "id": {
      "kind": "youtube#video",
      "videoId": "8rbPbPHHn8I"
    },
    "snippet": {
      "publishedAt": "2021-12-13T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Lots of Lunges 20 Minute Leg Workout | Dumbbells + Bodyweight",
      "description": "The quads, hamstrings, glutes and adductors will all be working hard during these 20 minutes! We will get a lot of work completed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8rbPbPHHn8I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8rbPbPHHn8I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8rbPbPHHn8I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-13T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "F3MJILC126hxq5RZxj0llERcOtA",
    "id": {
      "kind": "youtube#video",
      "videoId": "JtT6B8TIA-s"
    },
    "snippet": {
      "publishedAt": "2020-09-10T18:57:23Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min BODYWEIGHT BACK and CORE WORKOUT at Home | No Weights",
      "description": "Let's use our body to hit the muscles in our back as well as our core! This bodyweight back workout at home involves one of my ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JtT6B8TIA-s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JtT6B8TIA-s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JtT6B8TIA-s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-10T18:57:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZhRGTUlrqi4mUHZynZ3dJ4_nntM",
    "id": {
      "kind": "youtube#video",
      "videoId": "sX489tZQLc8"
    },
    "snippet": {
      "publishedAt": "2021-09-28T04:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FIERY Bodyweight &amp; Dumbbell Lower Body Workout | EPIC III Day 17",
      "description": "Combinations of dumbbells and bodyweight exercises to target those quads, hamstrings and glutes! The bodyweight exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sX489tZQLc8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sX489tZQLc8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sX489tZQLc8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-28T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rCiWi9HYq6YuUfr7WdD51eDkw_g",
    "id": {
      "kind": "youtube#video",
      "videoId": "UAPVxCMycvs"
    },
    "snippet": {
      "publishedAt": "2021-03-17T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "POWER Kettlebell Workout / Full Body - Kick-ass Kettlebell Series | Day 3",
      "description": "Day 3 in the Kick-ass Kettlebell Series! Kettlebell cleans, push press, squats and hip drive all present in this power kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UAPVxCMycvs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UAPVxCMycvs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UAPVxCMycvs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-17T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rtVqflEnH-eYLv5nT6In1D_P6pg",
    "id": {
      "kind": "youtube#video",
      "videoId": "AZlXUJzqbuk"
    },
    "snippet": {
      "publishedAt": "2021-06-27T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 MINUTE DUMBBELL HIIT WORKOUT + Bodyweight | Complex Series - Day 5",
      "description": "Dumbbell & bodyweight HIIT mini complexes!! Grab your light dumbbells or x2 filled water bottles, your mat and let's smash this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AZlXUJzqbuk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AZlXUJzqbuk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AZlXUJzqbuk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-27T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YEBskZNweq3LphVLRH4NcU0ea4g",
    "id": {
      "kind": "youtube#video",
      "videoId": "sZiZy6IWcuI"
    },
    "snippet": {
      "publishedAt": "2020-08-06T20:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min INTENSE ARM WORKOUT with Dumbbells at Home",
      "description": "This is a quick and targeted follow along intense arm workout with Dumbbells at home! If you wanting to tighten your arms and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sZiZy6IWcuI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sZiZy6IWcuI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sZiZy6IWcuI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-06T20:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "U5TTFStWXHOM-JAxJGv1Yzlps1g",
    "id": {
      "kind": "youtube#video",
      "videoId": "VKXozqD0KWA"
    },
    "snippet": {
      "publishedAt": "2021-07-12T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min TOTAL QUADS WORKOUT at Home with Dumbbells | Caroline Girvan",
      "description": "Time to put the quads to work! A 20 minute action packed workout to even save for future if wanting to simply grab those ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VKXozqD0KWA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VKXozqD0KWA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VKXozqD0KWA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-12T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "18tThvGWOUHn7405eBtAI3_XSmQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "4E2b-77cIf0"
    },
    "snippet": {
      "publishedAt": "2020-11-13T05:15:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 39 of EPIC | NO JUMPING Full Body Workout with Dumbbells | Trisets",
      "description": "1 HOUR TRI SET FULL BODY DUMBBELL WORKOUT... every muscle in the body will be targeted! A mixture of compound and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4E2b-77cIf0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4E2b-77cIf0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4E2b-77cIf0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-13T05:15:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ni8wK_AcKb8tzYOsTtZP_7rqLqo",
    "id": {
      "kind": "youtube#video",
      "videoId": "FoIXhQbT0lQ"
    },
    "snippet": {
      "publishedAt": "2022-02-15T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DISRUPTED Dumbbell Upper Body Workout | EPIC Endgame Day 32",
      "description": "The shoulders, back, chest and arms! The entire upper body plus abs as the finisher! Dumbbells, your mat and a lot of intensity!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FoIXhQbT0lQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FoIXhQbT0lQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FoIXhQbT0lQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-15T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N-1miR3K3VOO0Dnf7qr-6-ys17s",
    "id": {
      "kind": "youtube#video",
      "videoId": "C46vcfIC1VE"
    },
    "snippet": {
      "publishedAt": "2022-06-15T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min GLUTE &amp; HAMSTRING COMPLEX Workout | Day 13",
      "description": "Here we go! You know by the title, this format is going to be an awesome session for the entire lower body\u2026with a focus on the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C46vcfIC1VE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C46vcfIC1VE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C46vcfIC1VE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-15T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fEY3mRNCX7fmBcvpTNknZh9pCUs",
    "id": {
      "kind": "youtube#video",
      "videoId": "m9M4YdpDtSY"
    },
    "snippet": {
      "publishedAt": "2020-06-27T11:05:53Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "40 Min SHOULDER WORKOUT at Home with DUMBBELLS &amp; Core Strength",
      "description": "Core and shoulders supersets! 40 minutes shoulder workout at home using only a pair of dumbbells and a mat! We will hit ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/m9M4YdpDtSY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/m9M4YdpDtSY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/m9M4YdpDtSY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-27T11:05:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tG9qk-bl9ku3ZDeHbs0hbjbDKc8",
    "id": {
      "kind": "youtube#video",
      "videoId": "E7q28LMzDFI"
    },
    "snippet": {
      "publishedAt": "2020-09-29T05:30:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 7 of EPIC | Dumbbell Lower Body Workout - 40 Min Leg Day",
      "description": "Day 7 in the EPIC Program and it's leg day!!! We will be hitting our entire lower body and core with this leg workout with dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E7q28LMzDFI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E7q28LMzDFI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E7q28LMzDFI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-29T05:30:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5sryT38booQBGNX4tP0HFsyLtL4",
    "id": {
      "kind": "youtube#video",
      "videoId": "2kiS1Ph7d1s"
    },
    "snippet": {
      "publishedAt": "2022-02-18T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PEAKED Full Body Workout - Pyramid Training | EPIC Endgame Day 34",
      "description": "Pyramid training for a full body workout\u2026 and the timed format of pyramid sets means it is guaranteed that this workout will pass ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2kiS1Ph7d1s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2kiS1Ph7d1s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2kiS1Ph7d1s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-18T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-czuqZDcI7oqwC5qbr1pulBEwzk",
    "id": {
      "kind": "youtube#video",
      "videoId": "KS2-ph_2UCY"
    },
    "snippet": {
      "publishedAt": "2020-04-05T14:16:51Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "AT HOME UPPER BODY BAND WORKOUT",
      "description": "Hello everyone! Today I'm giving you some ideas of exercises you can do with a resistance band you may have at home!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KS2-ph_2UCY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KS2-ph_2UCY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KS2-ph_2UCY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-05T14:16:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "K_7BDyh0HrEmpl8Q2otPizhVPj4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Aq7qermvmXM"
    },
    "snippet": {
      "publishedAt": "2020-08-22T17:32:59Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute SHOULDER WORKOUT at Home or the Gym with Dumbbells",
      "description": "This is a 15 minute follow along dumbbell shoulder workout you can do at home or the gym! Building your shoulders are all part of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-22T17:32:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qABGdJjpLNKfkejiugIDLI7gsWU",
    "id": {
      "kind": "youtube#video",
      "videoId": "5i8y-_cbwgw"
    },
    "snippet": {
      "publishedAt": "2020-08-19T08:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min ABS WORKOUT at Home | No Equipment | No Repeat",
      "description": "This is a no repeat 30 minute ab workout and core workout you can do anywhere, focusing on the rectus abdominus muscles, the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-19T08:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CaS0ssnoFOdtqc6mGz_GI9Gg9hU",
    "id": {
      "kind": "youtube#video",
      "videoId": "0i5RILMI1Mk"
    },
    "snippet": {
      "publishedAt": "2022-04-12T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Shoulders &amp; Triceps Workout - Dumbbells | 7",
      "description": "Fundamental movements to provide stimuli for muscular hypertrophy, this workout will target the entire shoulder and triceps!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0i5RILMI1Mk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0i5RILMI1Mk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0i5RILMI1Mk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-12T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mM6BtzajT0xSK-I3GkRXo-VrlQs",
    "id": {
      "kind": "youtube#video",
      "videoId": "0PNWgEKN8Bg"
    },
    "snippet": {
      "publishedAt": "2020-09-22T08:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 2 of EPIC | Bodyweight &amp; Dumbbell Upper Body Workout",
      "description": "Day 2 in the EPIC Program and let's hit the shoulders, arms, chest, back & core! This 40 minute upper body workout combines ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-22T08:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YrOIvme7oV4nbiTOsiwfzA-mOBM",
    "id": {
      "kind": "youtube#video",
      "videoId": "ReE9zLhksbc"
    },
    "snippet": {
      "publishedAt": "2022-05-08T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Superset Dumbbell Shoulder Workout | 25",
      "description": "Supersets\u2026 and a staple! You are guaranteed a solid, all round delt workout within 30 minutes with the focus on slow and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ReE9zLhksbc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-08T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P3wsfWkW-4TSzy8fXoCkh_PFGNc",
    "id": {
      "kind": "youtube#video",
      "videoId": "sp65NrCYv_k"
    },
    "snippet": {
      "publishedAt": "2021-03-18T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KETTLEBELL CARDIO WORKOUT - Kick-ass Kettlebell Series | Day 4",
      "description": "Day 4 in the Kick-ass Kettlebell series and it's Kettlebell Cardio filled with supersets... this 30 minute workout will put your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-18T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mM6BtzajT0xSK-I3GkRXo-VrlQs",
    "id": {
      "kind": "youtube#video",
      "videoId": "0PNWgEKN8Bg"
    },
    "snippet": {
      "publishedAt": "2020-09-22T08:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 2 of EPIC | Bodyweight &amp; Dumbbell Upper Body Workout",
      "description": "Day 2 in the EPIC Program and let's hit the shoulders, arms, chest, back & core! This 40 minute upper body workout combines ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0PNWgEKN8Bg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-22T08:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P3wsfWkW-4TSzy8fXoCkh_PFGNc",
    "id": {
      "kind": "youtube#video",
      "videoId": "sp65NrCYv_k"
    },
    "snippet": {
      "publishedAt": "2021-03-18T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KETTLEBELL CARDIO WORKOUT - Kick-ass Kettlebell Series | Day 4",
      "description": "Day 4 in the Kick-ass Kettlebell series and it's Kettlebell Cardio filled with supersets... this 30 minute workout will put your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sp65NrCYv_k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-18T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "om1VOUpcWlFbIV0bElHGMOZESqU",
    "id": {
      "kind": "youtube#video",
      "videoId": "orH_lessqN0"
    },
    "snippet": {
      "publishedAt": "2021-03-21T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DEFIANT Dumbbell HIIT Workout / Full Body - Caroline Girvan",
      "description": "Prepare to work hard for 30 minutes and enjoy the elation of finishing this defiant challenge! Back, chest, shoulders, core, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/orH_lessqN0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/orH_lessqN0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/orH_lessqN0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-21T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fTWu5R9rNbxjONYs7HIIxllu0tE",
    "id": {
      "kind": "youtube#video",
      "videoId": "WD3FvnZpVig"
    },
    "snippet": {
      "publishedAt": "2020-08-27T18:30:21Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min BICEP WORKOUT with DUMBBELLS at Home | Caroline Girvan",
      "description": "Let's hit those biceps!! Follow along with this focused 20 minute bicep workout with dumbbells!!! I'm using 8kg in each hand for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WD3FvnZpVig/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WD3FvnZpVig/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WD3FvnZpVig/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-27T18:30:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RrxJw4qN8Ej9sXhSPUvQ-WaKDIE",
    "id": {
      "kind": "youtube#video",
      "videoId": "nGADUp5uyN0"
    },
    "snippet": {
      "publishedAt": "2020-11-27T05:00:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 49 of EPIC | Full body Strength and Stretch Workout [BODYWEIGHT ONLY - NO EQUIPMENT]",
      "description": "Full body bodyweight only with a full body stretch workout! Never underestimate how challenging it can be using your bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nGADUp5uyN0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nGADUp5uyN0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nGADUp5uyN0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-27T05:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kJ3aMBsZnl6pqAEtuy658XX18cU",
    "id": {
      "kind": "youtube#video",
      "videoId": "mlkfHgx-w-4"
    },
    "snippet": {
      "publishedAt": "2022-01-30T05:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DOMINANT Dumbbell Full Body HIIT Workout | EPIC Endgame Day 20",
      "description": "One dumbbell is only be needed for nearly all of this workout, apart form the finisher where we will need 2! Fast, precise, solid ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mlkfHgx-w-4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mlkfHgx-w-4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mlkfHgx-w-4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-30T05:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KXqkRFPMYilHS7Km_2GYsuhfjTc",
    "id": {
      "kind": "youtube#video",
      "videoId": "cS-bIr-6hQM"
    },
    "snippet": {
      "publishedAt": "2020-11-12T18:06:40Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 MIN ROCK HARD ABS WORKOUT | Core Strength at Home - Caroline Girvan",
      "description": "My abs and core feel rock solid after this quick routine! 15 minutes ab and core workout to target all muscles of the abs and to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cS-bIr-6hQM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cS-bIr-6hQM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cS-bIr-6hQM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-12T18:06:40Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "K_7BDyh0HrEmpl8Q2otPizhVPj4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Aq7qermvmXM"
    },
    "snippet": {
      "publishedAt": "2020-08-22T17:32:59Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute SHOULDER WORKOUT at Home or the Gym with Dumbbells",
      "description": "This is a 15 minute follow along dumbbell shoulder workout you can do at home or the gym! Building your shoulders are all part of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Aq7qermvmXM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-22T17:32:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ptdbnCSUfwWD3vZlDevxfrKIKhY",
    "id": {
      "kind": "youtube#video",
      "videoId": "EsmnhmMjJBc"
    },
    "snippet": {
      "publishedAt": "2022-01-24T05:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "STRUCTURED Shoulder Workout with Dumbbells | EPIC Endgame Day 16",
      "description": "A solid shoulder workout structured to hit all areas using dumbbells with some bodyweight exercises in there too! This is an ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EsmnhmMjJBc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EsmnhmMjJBc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EsmnhmMjJBc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-24T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ha2DkJ0d3qh1mfJx7R4zLRy_Dso",
    "id": {
      "kind": "youtube#video",
      "videoId": "lO9inXYCF9w"
    },
    "snippet": {
      "publishedAt": "2021-05-19T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DROP IT LOW Leg Day - Compound Leg Workout | EPIC Heat - Day 33",
      "description": "Drop it low! Going for depth on squats and lunges in this leg day! Lower body workout using compound movements to involve ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lO9inXYCF9w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lO9inXYCF9w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lO9inXYCF9w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-19T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qABGdJjpLNKfkejiugIDLI7gsWU",
    "id": {
      "kind": "youtube#video",
      "videoId": "5i8y-_cbwgw"
    },
    "snippet": {
      "publishedAt": "2020-08-19T08:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min ABS WORKOUT at Home | No Equipment | No Repeat",
      "description": "This is a no repeat 30 minute ab workout and core workout you can do anywhere, focusing on the rectus abdominus muscles, the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5i8y-_cbwgw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-19T08:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rIHnq7zDHZseGOq5u9XJzGLhx9Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "stuPkzWMdIg"
    },
    "snippet": {
      "publishedAt": "2020-05-08T18:24:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Minute Isometric Core Workout | No Equipment",
      "description": "Subscribe to my YouTube Fitness Channel here: https://youtube.com/c/carolinegirvan/?sub_confirmation=1 Isometric core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/stuPkzWMdIg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/stuPkzWMdIg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/stuPkzWMdIg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-08T18:24:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OhB30sb7X0zO6jHtnG_T_ybnOmo",
    "id": {
      "kind": "youtube#video",
      "videoId": "cxJAGfY5U3k"
    },
    "snippet": {
      "publishedAt": "2020-11-02T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 31 of EPIC | 45 Min Dumbbell Abs &amp; Glute Workout at Home",
      "description": "Welcome to week 7 in the EPIC Program!! Day 31 and it's glutes & ab workout combined! Hamstrings, core, abs, glutes all are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cxJAGfY5U3k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cxJAGfY5U3k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cxJAGfY5U3k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-02T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Qv3-ycD5GbvSBgql_KFwIwRKAnM",
    "id": {
      "kind": "youtube#video",
      "videoId": "OZxPvPDgmaI"
    },
    "snippet": {
      "publishedAt": "2021-09-27T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUREFIRE Shoulders Unilateral Workout - Dumbbell Delts | EPIC III Day 16",
      "description": "The front, side and rear delts are all targeted along with the triceps to assist with shoulder presses, lateral raises, flyes and partials ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OZxPvPDgmaI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OZxPvPDgmaI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OZxPvPDgmaI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-27T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MBll_CdweX2muFwER1rbZEFIdro",
    "id": {
      "kind": "youtube#video",
      "videoId": "MSORU3WZ9WI"
    },
    "snippet": {
      "publishedAt": "2022-02-08T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FIREWALKER Lower Body Workout - Leg Day | EPIC Endgame Day 27",
      "description": "Dumbbell and bodyweight throughout to challenge the entire lower body! Short duration complexes to target the hamstrings, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MSORU3WZ9WI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MSORU3WZ9WI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MSORU3WZ9WI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-08T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "g5EyG4XZUxgHylNVM-2xJeJ9Zb8",
    "id": {
      "kind": "youtube#video",
      "videoId": "LgF6SqLMQqc"
    },
    "snippet": {
      "publishedAt": "2021-05-07T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE Complex Full Body Workout | EPIC Heat - Day 24",
      "description": "Complexes.... I love these!! It truly becomes your own workout! Go at a pace that suits you as we work through exercises to target ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LgF6SqLMQqc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LgF6SqLMQqc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LgF6SqLMQqc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-07T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q97ABKblDvA-CcONMQiviOmuccg",
    "id": {
      "kind": "youtube#video",
      "videoId": "lPx1ftFkbKA"
    },
    "snippet": {
      "publishedAt": "2022-06-19T04:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min DYNAMITE Dumbbells &amp; Burpees HIIT Workout | Day 15",
      "description": "Exactly as it says! This is FUN! Sweaty! Demanding! And you'll love the short superset format that these 30 minutes bring!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lPx1ftFkbKA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lPx1ftFkbKA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lPx1ftFkbKA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-19T04:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Yd44uhyUnIdN-Qbr-Yhtxq_-Ax4",
    "id": {
      "kind": "youtube#video",
      "videoId": "pZ6aIZdV_To"
    },
    "snippet": {
      "publishedAt": "2021-01-20T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min Lower Abs &amp; Quads on FIRE Workout | EPIC II - Day 14",
      "description": "Quads and lower abs will be set on fire! A combination of bodyweight and dumbbells to target the entire lower body, specifically ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pZ6aIZdV_To/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pZ6aIZdV_To/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pZ6aIZdV_To/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-20T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GqILR5xwaMSNF7qm2bi4-sah8I8",
    "id": {
      "kind": "youtube#video",
      "videoId": "HZDEMwTocYY"
    },
    "snippet": {
      "publishedAt": "2020-07-11T09:24:56Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min LEG WORKOUT WITH DUMBBELLS | at Home Dumbbell Leg Workout",
      "description": "This at home leg workout with dumbbells is just over 15 minutes in total and will totally work your entire lower body! Your quads ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HZDEMwTocYY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HZDEMwTocYY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HZDEMwTocYY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-11T09:24:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kQP2e8ZcJ5bq14bjuHqbVuRZHwY",
    "id": {
      "kind": "youtube#video",
      "videoId": "1T3v_leyDIE"
    },
    "snippet": {
      "publishedAt": "2021-07-14T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN GLUTES &amp; HAMSTRINGS WORKOUT with Dumbbells | No Repeat",
      "description": "The hamstrings and glutes will be needed to work hard today! Using dumbbells and some bodyweight we will apply resistance in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1T3v_leyDIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1T3v_leyDIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1T3v_leyDIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-14T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cHn7FmW_5_bdCJTN3ULfZzMIPLM",
    "id": {
      "kind": "youtube#video",
      "videoId": "VE1JcBZBggw"
    },
    "snippet": {
      "publishedAt": "2022-06-07T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min UPPER BODY COMPLEX Workout | Day 7",
      "description": "Here we go!! Upper body day is here and what an experience this session will be! Every muscle in the upper body will be placed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VE1JcBZBggw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VE1JcBZBggw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VE1JcBZBggw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-07T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vw8K5haheDkHko9tJloxnKLP6gw",
    "id": {
      "kind": "youtube#video",
      "videoId": "YdB6lnybNyM"
    },
    "snippet": {
      "publishedAt": "2022-07-04T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Dumbbell LEG DAY COMPLEX Workout | Day 26",
      "description": "Complexes! Dumbbells! Leg day! A recipe for a solid sweaty session using slow and precise movements to work the quads, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YdB6lnybNyM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YdB6lnybNyM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YdB6lnybNyM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-04T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6w-MWDKuy05-pqg4fMwqH5z369c",
    "id": {
      "kind": "youtube#video",
      "videoId": "SkbNvum-6Ls"
    },
    "snippet": {
      "publishedAt": "2021-06-08T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUPREME Superset Leg Workout with Dumbbells | EPIC Heat - Day 47",
      "description": "Supersets! Partial reps! Dumbbells! Bodyweight! Isometrics! Burn out! You know your quads, hamstrings and glutes will all be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SkbNvum-6Ls/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SkbNvum-6Ls/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SkbNvum-6Ls/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-08T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZcB_gkSX96jyQ6J3lEDrmdL9DK4",
    "id": {
      "kind": "youtube#video",
      "videoId": "mi33rti-nxE"
    },
    "snippet": {
      "publishedAt": "2021-05-31T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GIRVANATED Glutes Workout - Giant Sets, Hip Thrusts | EPIC Heat - Day 41",
      "description": "Giant sets for the Glutes! 4 variations of an exercise to target the glutes and make the weights we have feel a lot heavier! Activation ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mi33rti-nxE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mi33rti-nxE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mi33rti-nxE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-31T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "l_6jKwgVhB39VY9qNCImR8UEo_o",
    "id": {
      "kind": "youtube#video",
      "videoId": "dSOvRW-llj8"
    },
    "snippet": {
      "publishedAt": "2020-08-18T08:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 MIN BOOTY WORKOUT at Home | Glute Activation | No Equipment",
      "description": "10 minute glute workout at home to isolate and activate the entire glute muscles, hips and hamstrings for booty activation. For this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dSOvRW-llj8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dSOvRW-llj8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dSOvRW-llj8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-18T08:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YJBhFZSKFEDnhwtIdW4jkuOJlb0",
    "id": {
      "kind": "youtube#video",
      "videoId": "dwDyUY4SXMg"
    },
    "snippet": {
      "publishedAt": "2020-10-21T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 23 of EPIC | Glutes and Abs Workout [DUMBBELL NO REPEAT]",
      "description": "Glutes and abs... 15 minute no rest ab exercises, specifically the upper abdominals, followed by glute activation, dumbbell glute ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dwDyUY4SXMg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dwDyUY4SXMg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dwDyUY4SXMg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-21T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zBhwqXXyoP6eK-AjMEMTVZdjIuc",
    "id": {
      "kind": "youtube#video",
      "videoId": "h2U5E2Gygig"
    },
    "snippet": {
      "publishedAt": "2022-01-18T05:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UPLIFTED Upper Body Dumbbell Workout | EPIC Endgame Day 12",
      "description": "Ready to work those arms, back and shoulders? Let's build muscle and grow even stronger! I think you will all enjoy this upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/h2U5E2Gygig/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/h2U5E2Gygig/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/h2U5E2Gygig/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-18T05:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TaewDBIDFrlcc9aG4iFbkdVEj8w",
    "id": {
      "kind": "youtube#video",
      "videoId": "eAi7EAE6-cU"
    },
    "snippet": {
      "publishedAt": "2021-06-17T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Minute ABSolute ABS and Core Workout at Home | Caroline Girvan",
      "description": "30 minutes ab workout to target the rectus abdominus muscles, the obliques and the strengthening of the core. The timer will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eAi7EAE6-cU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eAi7EAE6-cU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eAi7EAE6-cU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-17T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o5-2CCeeiknnpInJOV5CA4_5Fnw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Cwhmzdc40gw"
    },
    "snippet": {
      "publishedAt": "2021-09-21T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNSTOPPABLE UPPER BODY - Dumbbell Complexes | EPIC III Day 12",
      "description": "The chest, back, shoulders, biceps and triceps will all be on fire today! Complexes involving those compound movements at the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Cwhmzdc40gw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Cwhmzdc40gw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Cwhmzdc40gw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-21T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xB4ziXC0hZamyT-H6WiMoZ-I7BE",
    "id": {
      "kind": "youtube#video",
      "videoId": "hBanCm-yqB8"
    },
    "snippet": {
      "publishedAt": "2022-06-27T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Dumbbell LEG DAY CIRCUITS Workout | Day 21",
      "description": "One circuit! Two rounds! A lot of work is required from the entire lower body! Ready to smash this leg day?! All you will need for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hBanCm-yqB8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hBanCm-yqB8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hBanCm-yqB8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-27T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FUiZZWYB3x1e5PjZElkNIVGpuQQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "BAtaHTTyfPI"
    },
    "snippet": {
      "publishedAt": "2022-04-15T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Full Body Dumbbell Cardio Workout | 9",
      "description": "Ready to combine muscle building movements with cardio elements to really make you work hard for 30 minutes! For this workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BAtaHTTyfPI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BAtaHTTyfPI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BAtaHTTyfPI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-15T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D4xnA_G2IzB9_9ng-ijHLz5R1_U",
    "id": {
      "kind": "youtube#video",
      "videoId": "tT9B7-9TmnE"
    },
    "snippet": {
      "publishedAt": "2022-02-22T05:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "NEXT LEVEL Lower Body Workout with Dumbbells | EPIC Endgame Day 37",
      "description": "Giant sets, but taking each rep as it comes, one at a time. Allowing for moments to pause and reset to ensure your technique is the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tT9B7-9TmnE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tT9B7-9TmnE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tT9B7-9TmnE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-22T05:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JrSjKhNCJdIFOeQzY3ELoD3KEFk",
    "id": {
      "kind": "youtube#video",
      "videoId": "WomhbEXCYfQ"
    },
    "snippet": {
      "publishedAt": "2021-03-23T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute SUPERSET Upper Body &amp; Core Workout | No Equipment",
      "description": "15 minute upper body and core workout full of supersets! The entire core and upper body working with this bodyweight workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WomhbEXCYfQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WomhbEXCYfQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WomhbEXCYfQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-23T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7a3g8TE1fgTCmyPwct4Ng1J8ALg",
    "id": {
      "kind": "youtube#video",
      "videoId": "kSXA9iPfNOU"
    },
    "snippet": {
      "publishedAt": "2020-09-30T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 8 of EPIC | Dumbbell Upper Body Workout",
      "description": "Day 8 in the EPIC Program and it's a compound upper body day!!! We will be hitting our entire upper body and core with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kSXA9iPfNOU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kSXA9iPfNOU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kSXA9iPfNOU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-30T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "S2DjKafcH_r43eEcWn_Srl2KTDg",
    "id": {
      "kind": "youtube#video",
      "videoId": "l9_SoClAO5g"
    },
    "snippet": {
      "publishedAt": "2021-12-10T05:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Dumbbell Full Body Workout - No Repeat | Caroline Girvan",
      "description": "Technically no repeat but repetition on those muscles!! Mostly compound movements to recruit as many muscles as possible with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/l9_SoClAO5g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/l9_SoClAO5g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/l9_SoClAO5g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-10T05:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LNpWcog94JuGDwjOtwpBbk-0MaM",
    "id": {
      "kind": "youtube#video",
      "videoId": "l3SuTllp8nw"
    },
    "snippet": {
      "publishedAt": "2021-01-28T06:07:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min COOL CARDIO Workout / No Equipment - Caroline Girvan LISS",
      "description": "30 minutes of low intensity steady state cardio (LISS)! Work through these exercises at an easy, smooth and consistent pace!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/l3SuTllp8nw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/l3SuTllp8nw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/l3SuTllp8nw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-28T06:07:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_fAlpop0PBHF5ITX5SbZVNUGNOU",
    "id": {
      "kind": "youtube#video",
      "videoId": "l-QtDBhr4OE"
    },
    "snippet": {
      "publishedAt": "2022-01-28T05:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PRE-EXHAUST Dumbbell Full Body Workout | EPIC Endgame Day 19",
      "description": "It's full body day!! Let's have so fun!! Every muscle group will be worked hard today, to promote growth and strength! For a majority ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/l-QtDBhr4OE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/l-QtDBhr4OE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/l-QtDBhr4OE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-28T05:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OhFYHkTiupaF2ycjrY0jaQ96wtA",
    "id": {
      "kind": "youtube#video",
      "videoId": "xVj2Q2_2EJY"
    },
    "snippet": {
      "publishedAt": "2022-04-08T04:00:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Full Body Workout - Dumbbell Circuits | 4",
      "description": "A full body workout involving a circuit format\u2026but not as many reps as possible. This circuit will focus on how we perform each rep ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xVj2Q2_2EJY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xVj2Q2_2EJY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xVj2Q2_2EJY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-08T04:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SgfHpVAINRFFtdSu3x02Mo4ae7w",
    "id": {
      "kind": "youtube#video",
      "videoId": "Muh4Cyis_G8"
    },
    "snippet": {
      "publishedAt": "2021-01-03T05:15:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "40 Min HIIT + Low Intensity Cardio / No Equipment / No Repeat | EPIC II - DAY 1",
      "description": "Ready? Let's begin! 20 minutes of high intensity work followed by 20 minutes of steady state cardio to really work entire body and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Muh4Cyis_G8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Muh4Cyis_G8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Muh4Cyis_G8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-03T05:15:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4j_6iHQINwFLyNtPnjUuhJ1Zi7w",
    "id": {
      "kind": "youtube#video",
      "videoId": "CltJF-PFiiE"
    },
    "snippet": {
      "publishedAt": "2020-10-02T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 9 of EPIC | Full Body Workout with Dumbbells | 1 hour No Repeat",
      "description": "Day 9 in the EPIC Program and it's a 1 hour full body workout with Dumbbells!!! We will be hitting our entire body with many ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CltJF-PFiiE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CltJF-PFiiE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CltJF-PFiiE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-02T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tjOPT_96ib7ervqUWNxwXFsnxoI",
    "id": {
      "kind": "youtube#video",
      "videoId": "aAt8o5kYnCE"
    },
    "snippet": {
      "publishedAt": "2022-06-24T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Dumbbell Cardio Full Body Workout | Day 19",
      "description": "Strength, endurance, cardio, coordination, balance, control\u2026 30 minutes of a proper full body session to challenge your muscles ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aAt8o5kYnCE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aAt8o5kYnCE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aAt8o5kYnCE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-24T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Xs4iUKxCks-HNqsfw21O-CbLAIA",
    "id": {
      "kind": "youtube#video",
      "videoId": "UBlcDuodQXM"
    },
    "snippet": {
      "publishedAt": "2022-04-11T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Quad Workout - Dumbbell Leg Day | 6",
      "description": "This workout will combine many of those key movements that will, when performed safely and under appropriate and relative load ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UBlcDuodQXM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UBlcDuodQXM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UBlcDuodQXM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-11T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gGfZhw-bIz-VY2sud6sz11Vc70g",
    "id": {
      "kind": "youtube#video",
      "videoId": "miI70WR4FXo"
    },
    "snippet": {
      "publishedAt": "2020-12-18T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 MIN TRISETS Full Body Workout with Dumbbells | Strength Training",
      "description": "30 minutes full body let's go!!! Grab your dumbbells and let's work through trisets with compound and isolation exercises with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/miI70WR4FXo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/miI70WR4FXo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/miI70WR4FXo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-18T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5_nHOjWkWceEj6dynDCALuwJzMA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Cvchap5oZSE"
    },
    "snippet": {
      "publishedAt": "2021-12-05T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 minute Cardio Workout | Full Body Sweaty Complexes - No Equipment",
      "description": "CARDIO COMPLEXES to get you sweaty and heart pumping! A full body cardio workout that is different to HIIT workouts\u2026",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Cvchap5oZSE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Cvchap5oZSE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Cvchap5oZSE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-05T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-NVbowahD2I8-_BNxefeqVIWc7M",
    "id": {
      "kind": "youtube#video",
      "videoId": "Og_It9SD8XY"
    },
    "snippet": {
      "publishedAt": "2020-08-25T13:31:23Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min TRICEP WORKOUT with DUMBBELLS at Home | Caroline Girvan",
      "description": "Let's hit these triceps! We will build strength, endurance, and help tighten the backs of those arms for a defined upper body with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Og_It9SD8XY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Og_It9SD8XY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Og_It9SD8XY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-25T13:31:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Gm86u8P7Via1CICT0QrMEojL1R8",
    "id": {
      "kind": "youtube#video",
      "videoId": "OtdKpNDrzz0"
    },
    "snippet": {
      "publishedAt": "2021-04-26T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TANGIBLE Triceps and Delts Workout / Strength | EPIC Heat - Day 16",
      "description": "30 minutes to strengthen the shoulders and triceps, with focus on the side delts using dumbbells & bodyweight! For this shoulder ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OtdKpNDrzz0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OtdKpNDrzz0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OtdKpNDrzz0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-26T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UyymzyFdcD_z1tJJIbwBuMr6UIY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Uo7qX4U_akA"
    },
    "snippet": {
      "publishedAt": "2021-04-23T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNFORGIVING Full Body Workout with Dumbbells | EPIC Heat - Day 14",
      "description": "30 minutes in this unforgiving full body workout with dumbbells incorporating strength, endurance, core stability, core strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Uo7qX4U_akA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Uo7qX4U_akA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Uo7qX4U_akA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-23T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IU34cnxI6vhdPY7rE-O6X5k_L_U",
    "id": {
      "kind": "youtube#video",
      "videoId": "AXnbUMSDPH4"
    },
    "snippet": {
      "publishedAt": "2022-06-14T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min ANTAGONIST UPPER BODY Workout | Day 12",
      "description": "Ready to push and pull through the next 30 minutes with some staple compound lifts combined with tempo work, isometrics and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AXnbUMSDPH4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AXnbUMSDPH4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AXnbUMSDPH4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-14T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tgrFo1QtcUKSYAWt5Ay2uSpHVqk",
    "id": {
      "kind": "youtube#video",
      "videoId": "k9MiHyiEsuo"
    },
    "snippet": {
      "publishedAt": "2020-12-14T20:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 MIN STRONG ABS &amp; CORE WORKOUT with Dumbbell | Caroline Girvan",
      "description": "10 minute ab workout using a dumbbell for extra resistance! We will be focusing on the rectus abdominis muscle, the obliques and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/k9MiHyiEsuo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/k9MiHyiEsuo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/k9MiHyiEsuo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-14T20:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7Ci29DM0_WBA2o1_SvNS3RRvA1c",
    "id": {
      "kind": "youtube#video",
      "videoId": "Hww8Y6GxVbw"
    },
    "snippet": {
      "publishedAt": "2022-04-19T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Upper Body Chest and Triceps Workout | 12",
      "description": "The entire pectoral muscles, triceps, frontal delts and core will be targeted today! I love a chest day for so many reasons! I normally ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Hww8Y6GxVbw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Hww8Y6GxVbw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Hww8Y6GxVbw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-19T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "19tgTCr_SnZQMGLJPTqWv1t3mww",
    "id": {
      "kind": "youtube#video",
      "videoId": "8qIvT60fp8w"
    },
    "snippet": {
      "publishedAt": "2022-02-01T05:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PUSH THEN PULL Dumbbell Upper Body Workout | EPIC Endgame Day 22",
      "description": "The timer will be on for a majority of the supersets for 50 seconds per exercise, straighten into the next exercise for 50 seconds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8qIvT60fp8w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8qIvT60fp8w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8qIvT60fp8w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-01T05:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bMSu5sjx3ZMYafVFt47_E3DPvFY",
    "id": {
      "kind": "youtube#video",
      "videoId": "0ae_1TVyMDA"
    },
    "snippet": {
      "publishedAt": "2020-05-10T10:29:42Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute HIIT | Full Body Workout with No Equipment",
      "description": "Subscribe to my YouTube Fitness Channel here: https://youtube.com/c/carolinegirvan/?sub_confirmation=1 Hello everyone!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0ae_1TVyMDA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0ae_1TVyMDA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0ae_1TVyMDA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-10T10:29:42Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YpskUNEqghqr-6ijunX9-8KG_60",
    "id": {
      "kind": "youtube#video",
      "videoId": "4vs0U6Gb91Q"
    },
    "snippet": {
      "publishedAt": "2021-04-18T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DISCIPLINED Dumbbell HIIT Workout / Full Body | EPIC Heat - Day 10",
      "description": "30 minutes of controlling your dumbbells are you perform a wide variety of exercises or target the entire body and really elevate ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4vs0U6Gb91Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4vs0U6Gb91Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4vs0U6Gb91Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-18T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XYaL-pGAB2D1tWKvn5hoOpKeaoc",
    "id": {
      "kind": "youtube#video",
      "videoId": "QWpdKTt98yk"
    },
    "snippet": {
      "publishedAt": "2022-06-28T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Cluster Sets Upper Body Workout | Day 22",
      "description": "One set of a compound exercise, or isolation towards the end\u2026followed by 3 mini-sets of a complementary exercise to really turn ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QWpdKTt98yk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QWpdKTt98yk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QWpdKTt98yk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-28T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "liw4j6CYBwNP24YyHLXeYbMXkxw",
    "id": {
      "kind": "youtube#video",
      "videoId": "xbtAVsMdVSY"
    },
    "snippet": {
      "publishedAt": "2022-04-24T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Shoulder Workout - Compound &amp; Isolation | 15",
      "description": "A shoulder workout involving pressing, lifting, lowering, partials and flyes! Hitting all angles of the shoulders through slow and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xbtAVsMdVSY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xbtAVsMdVSY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xbtAVsMdVSY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-24T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ELLycPxaeVhy2OzrCTCyAGZ_eH8",
    "id": {
      "kind": "youtube#video",
      "videoId": "nab3Zv9G8qU"
    },
    "snippet": {
      "publishedAt": "2020-08-29T09:15:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min INNER + OUTER THIGH WORKOUT | Ankle Weights Optional",
      "description": "This 20 minute inner and outer thigh workout will help strengthen your surrounding hip muscles to improve performance and help ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nab3Zv9G8qU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nab3Zv9G8qU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nab3Zv9G8qU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-29T09:15:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SjndrPB6YYh-z5NqxJ--bXT_PWk",
    "id": {
      "kind": "youtube#video",
      "videoId": "46cYfOOwNc4"
    },
    "snippet": {
      "publishedAt": "2021-09-13T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUNDAMENTAL Back, Biceps and Core Workout - Dumbbells | EPIC III Day 6",
      "description": "The back, biceps and core will be targeted in this workout with dumbbells and bodyweight with unilateral exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/46cYfOOwNc4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/46cYfOOwNc4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/46cYfOOwNc4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-13T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QYHx8M2vnDQimLbrp63S7ZEe_fk",
    "id": {
      "kind": "youtube#video",
      "videoId": "OeWy_lpn_5A"
    },
    "snippet": {
      "publishedAt": "2021-02-23T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TOUGH Tempo Training / Leg Day Workout | EPIC II - Day 38",
      "description": "It's EPIC Leg Day!! I love playing with tempos.. with weights, running, HIIT and even variations of tempo training in ab workouts!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OeWy_lpn_5A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OeWy_lpn_5A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OeWy_lpn_5A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-23T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ADc30RqFJ8w6WdicRzpTZTj8R6U",
    "id": {
      "kind": "youtube#video",
      "videoId": "S3_3-cCqavc"
    },
    "snippet": {
      "publishedAt": "2021-05-18T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GIANT KILLER Upper Body Workout - Arms, Chest, Back, Shoulders | EPIC Heat - Day 32",
      "description": "Upper body GIANT sets! Arms, Chest, Back, Shoulders and Core! Increase strength, build muscle and improve muscular ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S3_3-cCqavc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S3_3-cCqavc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S3_3-cCqavc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-18T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1QREf7pY_6ZqhyRx5W1zxjUD6tA",
    "id": {
      "kind": "youtube#video",
      "videoId": "i9KZfnuGOjs"
    },
    "snippet": {
      "publishedAt": "2021-06-01T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "MAXIMAL Chest and Tricep Workout - Upper Body | EPIC Heat - Day 42",
      "description": "Ready for intensity in this chest and tricep workout?!! Chest press, tricep isolation and bodyweight exercises combined within ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/i9KZfnuGOjs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/i9KZfnuGOjs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/i9KZfnuGOjs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-01T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6-A-Z1eyz2G1pz97kGEfZwE22HI",
    "id": {
      "kind": "youtube#video",
      "videoId": "JZPzynsdYCE"
    },
    "snippet": {
      "publishedAt": "2021-12-14T05:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Dumbbell Upper Body Circuit Workout | Caroline Girvan",
      "description": "Just over 20 minutes. Back. Shoulders. Chest. Arms\u2026 all worked but in a very slow and controlled format. This is a PERFECT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JZPzynsdYCE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JZPzynsdYCE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JZPzynsdYCE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-14T05:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "u8_KYtFMVwcg03oU7npISoww3BE",
    "id": {
      "kind": "youtube#video",
      "videoId": "SB2dpt7MRhg"
    },
    "snippet": {
      "publishedAt": "2021-11-05T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "emPOWERed Full Body Giant Sets Strength &amp; Cardio Workout | EPIC III Day 44",
      "description": "Ready for a fantastic full body fun session? A lot of variety with a combination of some of our favourites to challenge every muscle!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SB2dpt7MRhg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SB2dpt7MRhg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SB2dpt7MRhg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-05T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JTmsTzQRJh_NqBy0Z9XI_Jt55P4",
    "id": {
      "kind": "youtube#video",
      "videoId": "A6U479X3oNY"
    },
    "snippet": {
      "publishedAt": "2021-04-16T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CONDITIONED Calisthenics Full Body Workout | EPIC Heat - Day 9",
      "description": "Who says you can't build strength at home with just your bodyweight? This no equipment full body workout is challenging whether ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/A6U479X3oNY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/A6U479X3oNY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/A6U479X3oNY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-16T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KomwcLKp4sIiawNVgDRGUzCKqg4",
    "id": {
      "kind": "youtube#video",
      "videoId": "CFF4vI0oGPg"
    },
    "snippet": {
      "publishedAt": "2022-04-13T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Glutes &amp; Hamstrings Workout - Hip Thrusts / RDL | 8",
      "description": "30 minutes involving resistance to specifically the glute and hamstring muscles through hip extension and hip hinge movement ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CFF4vI0oGPg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CFF4vI0oGPg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CFF4vI0oGPg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-13T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "melcYKlE88N8Mqc7CNtoTY6gRuQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "tczVC2rRO7U"
    },
    "snippet": {
      "publishedAt": "2022-04-05T04:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Upper Body Workout - Shoulders, Back, Chest | 2",
      "description": "Ready for some heavy lifting? Every part of your upper body will be under tension and load with a series of exercises to promote ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tczVC2rRO7U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tczVC2rRO7U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tczVC2rRO7U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-05T04:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZtVCkiI7g0rzxsyWA_Sie97QFgg",
    "id": {
      "kind": "youtube#video",
      "videoId": "DE5FRnqc4S4"
    },
    "snippet": {
      "publishedAt": "2021-09-07T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC UPPER BODY WORKOUT - Powerful Push &amp; Pull | EPIC III Day 2",
      "description": "Day 2 and we are 100% going to target those shoulders, back, chest, biceps and triceps with dumbbells and some bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DE5FRnqc4S4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DE5FRnqc4S4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DE5FRnqc4S4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-07T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CDOfb05XH9FE4c14-iYq8hk3YOE",
    "id": {
      "kind": "youtube#video",
      "videoId": "nxf8pGmNsVg"
    },
    "snippet": {
      "publishedAt": "2020-07-28T17:39:54Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min COMPLETE ABS WORKOUT at Home | Core, Obliques, Upper &amp; Lower",
      "description": "This is a 15 minute ab workout at home to focus on the stability and strength of your core and train your body on how to engage ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nxf8pGmNsVg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nxf8pGmNsVg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nxf8pGmNsVg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-28T17:39:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N2TdlORT7c48ti8aV0XugJ19POo",
    "id": {
      "kind": "youtube#video",
      "videoId": "gm4zVOaj37E"
    },
    "snippet": {
      "publishedAt": "2020-08-15T08:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Min INTENSE GLUTE WORKOUT at Home with Resistance Band",
      "description": "Grab your band and let's target our glutes!! This 20 minute glute resistance band workout will be focusing on targeting, isolating ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gm4zVOaj37E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gm4zVOaj37E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gm4zVOaj37E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-15T08:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FWTOH3IM04GCem0iVNWtpnQeBT0",
    "id": {
      "kind": "youtube#video",
      "videoId": "w8cSjkXkYRc"
    },
    "snippet": {
      "publishedAt": "2021-12-01T05:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Shoulder Workout with Dumbbells | Caroline Girvan",
      "description": "20 minutes for a solid shoulder session that will work all of the muscles of the shoulders including front, side and rear delts!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/w8cSjkXkYRc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/w8cSjkXkYRc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/w8cSjkXkYRc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-01T05:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EYQwPj9eBpSYWB3L1iD-NI7dSMQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "irEAeD16ez4"
    },
    "snippet": {
      "publishedAt": "2021-11-08T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PRODIGY Push Ups and Abs - Bodyweight Workout | EPIC III Day 46",
      "description": "It's push up day!!! Also known as chest, triceps, front delts and core all in one day! We are going to work through a big variety of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/irEAeD16ez4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/irEAeD16ez4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/irEAeD16ez4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-08T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WlDNNKKmEPwhWMdwL7NfXNF2MT8",
    "id": {
      "kind": "youtube#video",
      "videoId": "dQKiPKi8YUc"
    },
    "snippet": {
      "publishedAt": "2022-01-07T05:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "REVOLVE Dumbbell Full Body Circuits Workout | EPIC Endgame Day 4",
      "description": "Here we go! A full body circuits workout hitting those shoulders, back, chest, arms, core, quads, hamstrings and glutes, one big ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dQKiPKi8YUc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dQKiPKi8YUc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dQKiPKi8YUc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-07T05:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cbgfXkhi_s5OUnr_2-Jc3KQ7mpw",
    "id": {
      "kind": "youtube#video",
      "videoId": "xefU5YoKMoI"
    },
    "snippet": {
      "publishedAt": "2022-05-09T04:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Leg Workout - Circuits &amp; Step Ups | 26",
      "description": "Ready for tension to be applied on the quads, hamstrings and glutes? Leg day let's get ready! Goal: Perform every single rep with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xefU5YoKMoI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xefU5YoKMoI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xefU5YoKMoI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T04:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0SXSM82vhHub7K9qEvvPnfiJtiI",
    "id": {
      "kind": "youtube#video",
      "videoId": "ec_buXYn9So"
    },
    "snippet": {
      "publishedAt": "2021-01-10T05:00:21Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "INTENSE Full Body HIIT Workout with Weights | EPIC II - Day 6",
      "description": "Grab your dumbbells and let's smash this 30 minute high intensity full body session! All you will need is a pair of light dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ec_buXYn9So/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ec_buXYn9So/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ec_buXYn9So/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-10T05:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "R6Yiq8ffdKXgh9McONVmiBmIpYA",
    "id": {
      "kind": "youtube#video",
      "videoId": "RtarA6SpTaU"
    },
    "snippet": {
      "publishedAt": "2022-07-06T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min POSTERIOR CHAIN CLUSTERS Workout | Day 28",
      "description": "This workout is aimed at the hamstrings, glutes and entire back muscles using bodyweight and dumbbells. Mainly compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RtarA6SpTaU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RtarA6SpTaU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RtarA6SpTaU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-06T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_QnCD-6al0opZYdEKvwN5LAZJHU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hu_S-yNt_AE"
    },
    "snippet": {
      "publishedAt": "2021-10-20T04:00:25Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DYNAMITE Dynamic &amp; Isometric Leg Day Workout | EPIC III Day 33",
      "description": "Dynamic and isometric exercises combined within trisets to increase intensity but also challenges along the way to test you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hu_S-yNt_AE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hu_S-yNt_AE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hu_S-yNt_AE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-20T04:00:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TXFwdy9rXRZkGTt_9gWlZBze2dU",
    "id": {
      "kind": "youtube#video",
      "videoId": "RWzjUMWTpe0"
    },
    "snippet": {
      "publishedAt": "2021-04-02T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Minute POISED Ab Workout No Equipment - Caroline Girvan",
      "description": "Simply roll out your mat and prepare for a quick 10 minute ab workout to help strengthen the abdominal muscles. I hope you enjoy ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RWzjUMWTpe0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RWzjUMWTpe0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RWzjUMWTpe0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-02T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UsiBCnhF2dfVDXYSLLikDeg-q5Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "lVwWngzU498"
    },
    "snippet": {
      "publishedAt": "2021-01-05T05:53:29Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min Upper Body Workout / Dumbbells &amp; Bodyweight | EPIC II - DAY 3",
      "description": "Let's build the upper body strength!! Compound and isolation exercises with this upper body workout to majorly hit the chest, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lVwWngzU498/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lVwWngzU498/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lVwWngzU498/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-05T05:53:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I7AFTAKtQZUJHIpK5neA16bVZZc",
    "id": {
      "kind": "youtube#video",
      "videoId": "1WIah0t1Bzw"
    },
    "snippet": {
      "publishedAt": "2021-12-02T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Abs and Core Workout - Dumbbells + Bodyweight | Caroline Girvan",
      "description": "All you will need for this abdominal muscle targeting workout is your mat and a pair of lighter dumbbells. The dumbbells I am ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1WIah0t1Bzw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1WIah0t1Bzw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1WIah0t1Bzw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-02T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MhYqmETqRYlLZ0JPbt5XU3Ewabc",
    "id": {
      "kind": "youtube#video",
      "videoId": "TNVpBbjfQTE"
    },
    "snippet": {
      "publishedAt": "2022-01-23T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CRAZY CARDIO Full Body Workout - No Equipment | EPIC Endgame Day 15",
      "description": "Let's get sweaty! This is a fun, cardio workout\u2026 and I do actually mean that I think it was really fun! Using primarily trisets and our ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TNVpBbjfQTE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TNVpBbjfQTE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TNVpBbjfQTE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-23T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "K8PcLqURjiu-OdHagTphO6y55Sc",
    "id": {
      "kind": "youtube#video",
      "videoId": "WWu9E0terf4"
    },
    "snippet": {
      "publishedAt": "2021-12-18T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Glute Workout with Ankle Weights (Optional) | KNEE FRIENDLY",
      "description": "This glute workout will demand that you really focus\u2026 focus during each rep on that mind to muscle process in order to maximise ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WWu9E0terf4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WWu9E0terf4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WWu9E0terf4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-18T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jNOhb0pNvudQWjFH-vPhCaCd5TM",
    "id": {
      "kind": "youtube#video",
      "videoId": "mwYqXXzWHLg"
    },
    "snippet": {
      "publishedAt": "2021-11-02T04:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DISRUPT Full Upper Body Workout - Tempo Training | EPIC III Day 42",
      "description": "Tempo training with focus on a pause at the top of the movement, and as always controlling the lowering! Every muscle in the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mwYqXXzWHLg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mwYqXXzWHLg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mwYqXXzWHLg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-02T04:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vWmQc3wfOgcarxSGpF8NowU3WrA",
    "id": {
      "kind": "youtube#video",
      "videoId": "NdLIe-A1HLk"
    },
    "snippet": {
      "publishedAt": "2021-07-20T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE SHOULDERS AND ARMS - Intense Upper Body Workout | Day 2",
      "description": "The complete shoulder, biceps & triceps are worked in this upper body workout! 4 sets of each exercise and set rest periods to get ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NdLIe-A1HLk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NdLIe-A1HLk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NdLIe-A1HLk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-20T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P6KfWHfVIV0vJFWNpTwc3MGM8Yc",
    "id": {
      "kind": "youtube#video",
      "videoId": "a-V4Or5xyis"
    },
    "snippet": {
      "publishedAt": "2021-07-18T04:30:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CHILLED CARDIO WORKOUT | No Equipment, No Repeat, No Jumping",
      "description": "20 minute low intensity, low impact cardio with no rest! Steady state is the aim! This is a simple way to move your body without ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a-V4Or5xyis/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a-V4Or5xyis/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a-V4Or5xyis/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-18T04:30:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q-y7b51CdzphvFLadhLRddwuswE",
    "id": {
      "kind": "youtube#video",
      "videoId": "ovAf4tD6H6U"
    },
    "snippet": {
      "publishedAt": "2022-01-25T05:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BALANCED Lower Body Workout - Dumbbells &amp; Bodyweight | EPIC Endgame Day 17",
      "description": "The hamstrings and glutes get as much attention as those quads today with hinging movements and lunge variations! Compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ovAf4tD6H6U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ovAf4tD6H6U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ovAf4tD6H6U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-25T05:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6Jixbn-mZm5rgm6G47oaEcHmCew",
    "id": {
      "kind": "youtube#video",
      "videoId": "Rw7K5Kl53PI"
    },
    "snippet": {
      "publishedAt": "2021-10-26T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNLEASHED Unilateral Lower Body Workout | EPIC III Day 37",
      "description": "Unilateral lower body session to sizzle the quads, hamstrings and glutes! Unilateral training throughout from one side to the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Rw7K5Kl53PI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Rw7K5Kl53PI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Rw7K5Kl53PI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-26T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-y5uR7juSbJGjEd3RCN2Y7VKluk",
    "id": {
      "kind": "youtube#video",
      "videoId": "uZC3KbyGErk"
    },
    "snippet": {
      "publishedAt": "2022-03-08T05:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BIG-TIME Bulgarian Lunges - Leg Day Workout | EPIC Endgame Day 47",
      "description": "Ready to surprise yourself? A full workout dedicated to mostly Bulgarian lunges! Yes, mostly Bulgarian lunges though I think you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uZC3KbyGErk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uZC3KbyGErk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uZC3KbyGErk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-08T05:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7Wp6zOVut2A-HqL_H10FyhM8FpM",
    "id": {
      "kind": "youtube#video",
      "videoId": "U7VaKZSht28"
    },
    "snippet": {
      "publishedAt": "2022-03-01T05:00:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EFFECTUAL Upper Body Workout with Dumbbells | EPIC Endgame Day 42",
      "description": "This is a complete upper body workout! It's all in here today! We have pressing, rowing, flyes, pulls, curls, raises and some abs ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U7VaKZSht28/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U7VaKZSht28/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U7VaKZSht28/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-01T05:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2a0R-aN-WqD6uwfYiJaMVWewdVs",
    "id": {
      "kind": "youtube#video",
      "videoId": "tfgeRvBRtME"
    },
    "snippet": {
      "publishedAt": "2022-05-10T04:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Upper Body Workout - Antagonist Sets | 27",
      "description": "Essentially 90 seconds before you hit the back again, and likewise with the chest however due to it being antagonist training and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tfgeRvBRtME/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tfgeRvBRtME/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tfgeRvBRtME/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-10T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tBHeygO4MdWVTe4rgDS4241QYDE",
    "id": {
      "kind": "youtube#video",
      "videoId": "opvXwdtMFUs"
    },
    "snippet": {
      "publishedAt": "2021-06-07T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNSTOPPABLE Upper Body Workout - Arms, Shoulders &amp; Back | EPIC Heat - Day 46",
      "description": "The back, shoulders and arms targeted within giant sets! Dumbbell compound and isolation exercises to build strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/opvXwdtMFUs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/opvXwdtMFUs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/opvXwdtMFUs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-07T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N5HLjdJk86wECsNdPr4131i2BEM",
    "id": {
      "kind": "youtube#video",
      "videoId": "mUI4hXTmAkw"
    },
    "snippet": {
      "publishedAt": "2020-07-15T17:00:23Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min DUMBBELL ABS WORKOUT at Home | Follow Along No Repeat",
      "description": "This no repeat, dumbbell abs workout is a targeted routine for the abdominal muscles and core with just one dumbbell! The weight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mUI4hXTmAkw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mUI4hXTmAkw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mUI4hXTmAkw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-15T17:00:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TThz-8HbCoSs1vXZmjB-MherDNA",
    "id": {
      "kind": "youtube#video",
      "videoId": "_UFvhXTN2-U"
    },
    "snippet": {
      "publishedAt": "2022-04-26T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Complete Upper Body Workout | 17",
      "description": "A complete upper body muscle building workout in 30 minutes! And this is far from rising through the reps\u2026 the slower the better.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_UFvhXTN2-U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_UFvhXTN2-U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_UFvhXTN2-U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-26T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FqZNkwHVOUnBci8QUC0UKMbHHEg",
    "id": {
      "kind": "youtube#video",
      "videoId": "OuBMFYx5ncE"
    },
    "snippet": {
      "publishedAt": "2020-09-23T08:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 3 of EPIC | Bodyweight Core &amp; Abs Workout",
      "description": "Day 3 in the EPIC Program and let's hit the abs and core! This core and abs workout will involve using our bodyweight as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-23T08:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kYveyAsaY2IJ8hD_9D0n1DGzYS8",
    "id": {
      "kind": "youtube#video",
      "videoId": "b_hxDF9RA6U"
    },
    "snippet": {
      "publishedAt": "2021-09-14T04:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "RESILIENT LEG DAY WORKOUT - Lower Body Complexes - Dumbbells | EPIC III Day 7",
      "description": "Prepare for total leg annihilation with complexes all performed at a slow pace to minimise momentum and promote great form and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/b_hxDF9RA6U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/b_hxDF9RA6U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/b_hxDF9RA6U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-14T04:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c1fihiPYCDQLjg3QHyp7HwIdOT8",
    "id": {
      "kind": "youtube#video",
      "videoId": "mPU0jit0Zek"
    },
    "snippet": {
      "publishedAt": "2021-04-27T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EFFECTIVE Leg Day Collective - Compound Leg Workout | EPIC Heat - Day 17",
      "description": "The hamstrings, glutes, quads and calves will be challenged during nearly all of these exercises within this leg day session!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mPU0jit0Zek/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mPU0jit0Zek/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mPU0jit0Zek/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-27T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cNVVKAdEhnxOIyR10o6iL5PAUNw",
    "id": {
      "kind": "youtube#video",
      "videoId": "bOJpvux_klI"
    },
    "snippet": {
      "publishedAt": "2022-05-13T04:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Final Full Body Workout - Hypertrophy | 29",
      "description": "Ready to put your strength and control to the test? Slow paced, precise movements and chance to consider an increase in some ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bOJpvux_klI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bOJpvux_klI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bOJpvux_klI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-13T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GN_jFPQjJkK_h80L21FBAAuSnWQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "cApi13gQCJc"
    },
    "snippet": {
      "publishedAt": "2020-09-28T08:04:33Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 6 of EPIC | Dumbbell Arms and Abs Workout 40 Minute",
      "description": "Day 6 in the EPIC Program and we are hitting our arms and abs!!! The triceps and biceps will be tested with isolating exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cApi13gQCJc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cApi13gQCJc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cApi13gQCJc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-28T08:04:33Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n1vh88RPyj90YTHHgAQBXiyUzT0",
    "id": {
      "kind": "youtube#video",
      "videoId": "zyeiUJX99PQ"
    },
    "snippet": {
      "publishedAt": "2020-11-15T05:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 40 of EPIC | 30 Min BURNNNNNN HIIT WORKOUT with Dumbbells [NO JUMPING]",
      "description": "Are you ready for EPIC HIIT. Let's go! Grab those dumbbells for an intense full body burn! Every muscle will be on fire in this full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zyeiUJX99PQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zyeiUJX99PQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zyeiUJX99PQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-15T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AXe3FVDpi_HXP3RffXvw1Gsy0uM",
    "id": {
      "kind": "youtube#video",
      "videoId": "FXetW-Lv0P4"
    },
    "snippet": {
      "publishedAt": "2022-06-10T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Full-on Full Body Circuits Workout | Day 9",
      "description": "Lower body then moving to the upper body\u2026 we have compound movements and some isolation in here too with some ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FXetW-Lv0P4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FXetW-Lv0P4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FXetW-Lv0P4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-10T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0y8cGKKiuOfoOWgxVOLJWwxJiiM",
    "id": {
      "kind": "youtube#video",
      "videoId": "xpZ8eg6SBPQ"
    },
    "snippet": {
      "publishedAt": "2021-10-15T04:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TROJAN TRISETS Full Body Workout - Dumbbells | EPIC III Day 29",
      "description": "A full body workout with a combination of compound lifts and isolation exercises using mostly dumbbells as resistance with a triset ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xpZ8eg6SBPQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xpZ8eg6SBPQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xpZ8eg6SBPQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-15T04:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "y4HvBopNeFHd6WmeO0UPkCRP2OI",
    "id": {
      "kind": "youtube#video",
      "videoId": "pRY8DmcheYs"
    },
    "snippet": {
      "publishedAt": "2022-07-03T04:00:28Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Full Body DUMBBELL HIIT CARDIO Workout | Day 25",
      "description": "Ready for the sweat!? The timer will be on for short periods of work lasting 20 seconds each with 10 seconds of rest in between!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pRY8DmcheYs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pRY8DmcheYs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pRY8DmcheYs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-03T04:00:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I5LFHytKJq7-lFGYxTebpuoZZq8",
    "id": {
      "kind": "youtube#video",
      "videoId": "KzZILhT_YvY"
    },
    "snippet": {
      "publishedAt": "2021-07-16T04:30:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BICEP BLOW UP - Biceps Workout at Home with Dumbbells | No Repeat",
      "description": "This no repeat bicep blow up includes wide curls, cross body curls, palms up curls and my favourite; hammer curls!! Along with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KzZILhT_YvY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KzZILhT_YvY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KzZILhT_YvY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-16T04:30:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cBRNfQAx7RpPQNaLEsurMaCGGH8",
    "id": {
      "kind": "youtube#video",
      "videoId": "ucTDJRJjFQ4"
    },
    "snippet": {
      "publishedAt": "2022-05-02T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Dumbbell Leg Day Workout - Stepups | 21",
      "description": "Here we go! It's Leg day! A combination of added resistance and some bodyweight to help build muscle and strength in the lower ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ucTDJRJjFQ4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ucTDJRJjFQ4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ucTDJRJjFQ4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-02T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Hw1wFePlDsz5Tj18O6VN78La6k8",
    "id": {
      "kind": "youtube#video",
      "videoId": "NMiKAPf4xyE"
    },
    "snippet": {
      "publishedAt": "2022-06-17T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Dumbbell FULL BODY TEMPO Workout | Day 14",
      "description": "Tempo\u2026 it's going to be a fun 30 minutes! Slower-paced reps, isometrics, movements requiring explosive drive and others more ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NMiKAPf4xyE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NMiKAPf4xyE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NMiKAPf4xyE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-17T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5hXTMU9cLkI6Jxd66r_YW8nph3o",
    "id": {
      "kind": "youtube#video",
      "videoId": "krdnsJ4_OaU"
    },
    "snippet": {
      "publishedAt": "2022-05-31T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min UPPER BODY CIRCUITS &amp; Cluster Sets Workout | Day 2",
      "description": "A circuit then onto cluster sets! You know it's going to be eventful! Chest, back, shoulders and triceps will majorly work hard today ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/krdnsJ4_OaU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/krdnsJ4_OaU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/krdnsJ4_OaU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-31T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CeaouL05MTSX4WrKUJRAAsBOK5c",
    "id": {
      "kind": "youtube#video",
      "videoId": "X7rd__u-NJU"
    },
    "snippet": {
      "publishedAt": "2021-11-12T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "penULTIMATE Full Body Workout - Dumbbell Complexes | EPIC III Day 49",
      "description": "A full body workout that we all will love! Short duration complexes to build strength, endurance and muscle! Go at your own pace ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X7rd__u-NJU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X7rd__u-NJU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X7rd__u-NJU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-12T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1qy7uj7MYiSX1K7BxqIhnSw4enc",
    "id": {
      "kind": "youtube#video",
      "videoId": "SZaggsg2zUY"
    },
    "snippet": {
      "publishedAt": "2021-12-20T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute HARD Upper Body Workout with Dumbbells | Caroline Girvan",
      "description": "20 minutes may seem a short duration for a workout but when you combine slow and controlled movements with great technique ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SZaggsg2zUY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SZaggsg2zUY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SZaggsg2zUY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-20T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dLVc0dyfAFiELpZVww8q1f4YMo0",
    "id": {
      "kind": "youtube#video",
      "videoId": "B-XyUrXEaCw"
    },
    "snippet": {
      "publishedAt": "2022-05-01T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Superset Arms, Abs and Core Workout | 20",
      "description": "The dumbbells I am using for your reference are 2 x 12.5kg and 2 x 8kg each! The timer will be on for superset format through with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/B-XyUrXEaCw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/B-XyUrXEaCw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/B-XyUrXEaCw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-01T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FqZNkwHVOUnBci8QUC0UKMbHHEg",
    "id": {
      "kind": "youtube#video",
      "videoId": "OuBMFYx5ncE"
    },
    "snippet": {
      "publishedAt": "2020-09-23T08:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 3 of EPIC | Bodyweight Core &amp; Abs Workout",
      "description": "Day 3 in the EPIC Program and let's hit the abs and core! This core and abs workout will involve using our bodyweight as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OuBMFYx5ncE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-23T08:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MGKvDjcC3K4EZImnOS5Sf3leZIQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "pA3Q-KA4rDA"
    },
    "snippet": {
      "publishedAt": "2022-01-14T05:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FLUID Full Body Workout with Dumbbells | EPIC Endgame Day 9",
      "description": "Here we go! It's full body!! Full of variety, transitions and challenge! A combination of dumbbells & bodyweight! Bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pA3Q-KA4rDA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pA3Q-KA4rDA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pA3Q-KA4rDA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-14T05:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ycbE1pz15KNv7okQlPLJxnUAXaw",
    "id": {
      "kind": "youtube#video",
      "videoId": "rRugP_hkkE0"
    },
    "snippet": {
      "publishedAt": "2020-12-13T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN HIIT DUMBBELL WORKOUT | Full Body with Caroline Girvan",
      "description": "20 minutes dumbbell fun! Full body, high intensity workout to get you sweaty! The dumbbells I am using for your reference as 6kg ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rRugP_hkkE0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rRugP_hkkE0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rRugP_hkkE0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-13T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vQX0oJhYSttt-VGLqQ-AGYuarrA",
    "id": {
      "kind": "youtube#video",
      "videoId": "4WnoS2fA7i0"
    },
    "snippet": {
      "publishedAt": "2021-12-03T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Full Body Complexes and Sets Workout | Dumbbells",
      "description": "A full body workout to work all of those major muscle groups using dumbbells for resistance and a format combining timed sets ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4WnoS2fA7i0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4WnoS2fA7i0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4WnoS2fA7i0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-03T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rPuYIoZdWB7we84JQki1-OMr2wA",
    "id": {
      "kind": "youtube#video",
      "videoId": "SXE-3rg33SM"
    },
    "snippet": {
      "publishedAt": "2022-06-05T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min DUMBBELL CARDIO Workout - Giant Sets | Day 5",
      "description": "Some smooth transitions that will really zone in on the same muscle group per set but challenge you cardiovascularly throughout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SXE-3rg33SM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SXE-3rg33SM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SXE-3rg33SM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-05T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xXQcZGLjsGzBarr1WDcKBdlA6-U",
    "id": {
      "kind": "youtube#video",
      "videoId": "knrmFCI4XiI"
    },
    "snippet": {
      "publishedAt": "2022-04-25T04:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Hamstrings Workout - Hip Extension &amp; Flexion | 16",
      "description": "Hamstrings it is! A combination of hip flexion and extension to put load on our hamstrings using dumbbells. Although structured ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/knrmFCI4XiI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/knrmFCI4XiI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/knrmFCI4XiI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-25T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lNsVFA3ecC6U0THBOkKyP4ls3tg",
    "id": {
      "kind": "youtube#video",
      "videoId": "OV3ScwKtveo"
    },
    "snippet": {
      "publishedAt": "2022-01-04T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BOLD Upper Body Workout with Dumbbells | EPIC Endgame Day 2",
      "description": "Every muscle in the upper body will be challenged! Particularly the shoulder, back, chest and arm muscles! Following day 1, this is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OV3ScwKtveo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OV3ScwKtveo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OV3ScwKtveo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-04T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WlPSL14oBf9IxuYExijPS5J4H4Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "SFBiPoJU8Io"
    },
    "snippet": {
      "publishedAt": "2022-01-10T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TORCHED Triceps &amp; Chest Workout - Dumbbells | EPIC Endgame Day 6",
      "description": "A chest & tricep workout, with a lot of focus equally split between the chest & triceps today! The staple will be targeting the triceps ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SFBiPoJU8Io/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SFBiPoJU8Io/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SFBiPoJU8Io/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-10T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w1Bpkvd_b6g3P3N6hWt_rYVQ4NM",
    "id": {
      "kind": "youtube#video",
      "videoId": "T1chd7xhsHw"
    },
    "snippet": {
      "publishedAt": "2022-06-20T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min SUPERSET QUADS Workout - Dumbbells | Day 16",
      "description": "Here we go! Quads galore today! Isometrics, step ups, squats and a few full body movements to get our heart rate up! The timer ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T1chd7xhsHw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T1chd7xhsHw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T1chd7xhsHw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-20T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9Ren4HZp42KPleC5TiKfkdjoO6Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "SjSJU-LOLf8"
    },
    "snippet": {
      "publishedAt": "2022-04-29T04:00:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Muscle Building Full Body Workout | 19",
      "description": "Compound movements all formatted in a way to keep intensity at a high level through a variety of exercises all targeting the same ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SjSJU-LOLf8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SjSJU-LOLf8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SjSJU-LOLf8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-29T04:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qN36v0LHIeBLc1r0IasJGlO0bbU",
    "id": {
      "kind": "youtube#video",
      "videoId": "okSsQ3pfHlU"
    },
    "snippet": {
      "publishedAt": "2021-11-14T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Minute CARDIO PARTY HIIT Workout - Bodyweight | EPIC III Finale",
      "description": "Time to PARTY! A full body sweaty session to celebrate completing 50 very demanding workouts! Every muscle has been ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/okSsQ3pfHlU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/okSsQ3pfHlU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/okSsQ3pfHlU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-14T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "G25pRXk8RbXZPacTXaGvKZzXgic",
    "id": {
      "kind": "youtube#video",
      "videoId": "KPgU8P7M0Rs"
    },
    "snippet": {
      "publishedAt": "2022-01-17T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALVES INCLUDED Lower Body Workout - Legs | EPIC Endgame Day 11",
      "description": "The format will be trisets, using bodyweight and dumbbells as resistance to make the quads, hamstrings, glutes & calves work ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KPgU8P7M0Rs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KPgU8P7M0Rs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KPgU8P7M0Rs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-17T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4mXmB7GMd97Ju-52iZsl3ioUBeM",
    "id": {
      "kind": "youtube#video",
      "videoId": "8Q-MuK7ptOI"
    },
    "snippet": {
      "publishedAt": "2021-06-02T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "INTENSIVE Quads and Calves Workout / LEG DAY | EPIC Heat - Day 43",
      "description": "Quads and calves combo full of compound movements, isometrics, and fun supersets that will challenge your quads and calves to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8Q-MuK7ptOI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8Q-MuK7ptOI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8Q-MuK7ptOI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-02T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5vSG8zBUQeI4QCKacpZX5clx-ks",
    "id": {
      "kind": "youtube#video",
      "videoId": "Q0twJLyCA7Y"
    },
    "snippet": {
      "publishedAt": "2020-09-25T08:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 4 of EPIC | 1 Hour Dumbbell Full Body Workout Core Focus",
      "description": "Day 4 in the EPIC Program and it's a full body workout session with dumbbells and bodyweight! This workout is going to challenge ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Q0twJLyCA7Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Q0twJLyCA7Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Q0twJLyCA7Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-25T08:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BTfcCjNUKFKQE-YCSmiAN3LOomA",
    "id": {
      "kind": "youtube#video",
      "videoId": "LubR2sFpT_E"
    },
    "snippet": {
      "publishedAt": "2022-05-06T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Full Body Circuits Workout - Dumbbells | 24",
      "description": "Circuits, sweat, serious intensity\u2026 let's go! We simply have 5 supersets in the circuit and we simply perform this for 3 rounds!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LubR2sFpT_E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LubR2sFpT_E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LubR2sFpT_E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-06T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0F394FfobvoR4gWAftFmDXpmTFs",
    "id": {
      "kind": "youtube#video",
      "videoId": "fQ1vigkZ4uU"
    },
    "snippet": {
      "publishedAt": "2022-06-08T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min POSTERIOR CHAIN Circuits Workout | Day 8",
      "description": "2 circuits! First circuit aimed towards the glutes and hamstrings, second circuit the back! This workout will involve hinging during ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fQ1vigkZ4uU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fQ1vigkZ4uU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fQ1vigkZ4uU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-08T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eotWzcYNIVy0_qpy8ueaNLcxLD8",
    "id": {
      "kind": "youtube#video",
      "videoId": "TFjLCQMdNng"
    },
    "snippet": {
      "publishedAt": "2021-02-01T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Dumbbell Lower Body COMPLEX Workout / Leg Day | EPIC II - Day 22",
      "description": "Glutes on fire, hard working hamstrings and quality quad exercises all on this session today! All you will need for this leg session ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TFjLCQMdNng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TFjLCQMdNng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TFjLCQMdNng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-01T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9YyhvWeWIf4GiSoticizhevjnwg",
    "id": {
      "kind": "youtube#video",
      "videoId": "DMf5EdnAG4Y"
    },
    "snippet": {
      "publishedAt": "2022-01-26T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PUMPED UP Arms &amp; Core Workout - Dumbbells | EPIC Endgame Day 18",
      "description": "Isolation exercises galore for those triceps & biceps using a combination of dumbbell & bodyweight exercises to demand that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DMf5EdnAG4Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DMf5EdnAG4Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DMf5EdnAG4Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-26T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mArCh8BDjU6hlkkl_o6PYzSdc0c",
    "id": {
      "kind": "youtube#video",
      "videoId": "gbCJq7NBK-g"
    },
    "snippet": {
      "publishedAt": "2021-10-18T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GIANT Glutes and Hamstrings Sets Workout | EPIC III Day 31",
      "description": "Hip thrusts, sumo squads, Romanian deadlifts and bodyweight exercises all combined into giant sets to make this an very ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gbCJq7NBK-g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gbCJq7NBK-g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gbCJq7NBK-g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-18T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4PWyb78TgkZBZWNIIGuISzCE0pQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "nUpBLnFo4uM"
    },
    "snippet": {
      "publishedAt": "2022-06-22T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min GLUTES &amp; HAMSTRING CIRCUITS Workout | Day 18",
      "description": "This is one of those workouts that require focus! Intentional lifting and controlling each rep in a way that suits you and your body.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nUpBLnFo4uM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nUpBLnFo4uM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nUpBLnFo4uM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-22T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DLAI8Kg0Xlt37pnkAAfp4gPvAUw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Un7qQVAqIX8"
    },
    "snippet": {
      "publishedAt": "2021-05-30T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 MIN TURBO TABATA Workout - Full Body HIIT / No Equipment | EPIC Heat - Day 40",
      "description": "Sweaty! High intensity! Core! 30 minutes! No equipment! Low impact options! Tabata timer style so 20/10 on the clock! The timer ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Un7qQVAqIX8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Un7qQVAqIX8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Un7qQVAqIX8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-30T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "r4R7O3Ol_wkQpUUYVanl8Z9xtIg",
    "id": {
      "kind": "youtube#video",
      "videoId": "BOy5tURU3wo"
    },
    "snippet": {
      "publishedAt": "2021-12-19T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute HOT HIIT Full Body Workout at Home | No Equipment",
      "description": "HOT HIIT! Oh yessss!!! Let's get some sweat streaming! The timer will be on for 30 seconds per exercise with 15 seconds rest in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BOy5tURU3wo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BOy5tURU3wo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BOy5tURU3wo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-19T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Q4Pex7SvhaifLnia1g1YKJyZL4U",
    "id": {
      "kind": "youtube#video",
      "videoId": "KAEALlzcMcQ"
    },
    "snippet": {
      "publishedAt": "2022-04-22T07:54:52Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Unilateral Training Full Body Workout | 14",
      "description": "A solid full body workout involving much focus on unilateral movements to increase your awareness of how each side performs, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KAEALlzcMcQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KAEALlzcMcQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KAEALlzcMcQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-22T07:54:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vVNrGP4ZixJlIMVHtyOCcPMgRso",
    "id": {
      "kind": "youtube#video",
      "videoId": "OnsQF94h3z0"
    },
    "snippet": {
      "publishedAt": "2022-07-10T04:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series Finale 30 Min NO REPEAT Dumbbell HIIT Workout | Day 30",
      "description": "Here we go! 40 exercises, 30 seconds of work, 15 seconds of rest\u2026 I've got my 2 x 6kgs ready to rock day 30! No repeat, so ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OnsQF94h3z0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OnsQF94h3z0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OnsQF94h3z0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-10T04:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_D8Dg8cshfD0LUKkRsqNHdPbSFw",
    "id": {
      "kind": "youtube#video",
      "videoId": "3qScLxwUAz4"
    },
    "snippet": {
      "publishedAt": "2022-06-29T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Glutes &amp; Hamstrings Tempo Workout | Day 23",
      "description": "Slow, fast, slow, fast\u2026 here we go! Glutes and hamstrings will be working using various techniques! All of the staple compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3qScLxwUAz4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3qScLxwUAz4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3qScLxwUAz4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-29T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OHuaz3cmULgopnuvn-qb8gqM5m8",
    "id": {
      "kind": "youtube#video",
      "videoId": "VjGOiFr5oAk"
    },
    "snippet": {
      "publishedAt": "2020-04-20T11:44:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "at Home GLUTE WORKOUT | Dumbbells Only &amp; Booty Focused",
      "description": "Booty Focused at Home Glute Workout with Dumbbells Only Hello everyone! Here's a great all rounder workout to target your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VjGOiFr5oAk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VjGOiFr5oAk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VjGOiFr5oAk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-20T11:44:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XToe2S6mfKlrdAoE9tkUyoP-N7Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "LTfPk1q5A6A"
    },
    "snippet": {
      "publishedAt": "2020-10-16T05:02:49Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 19 of EPIC | Full Body Workout - 1 Hour [Dumbbell Complex]",
      "description": "1 hour full body dumbbell workout Day 19 let's do this!! There is a 'dumbbell complex' every 4th exercise that incorporates 4 main ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LTfPk1q5A6A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LTfPk1q5A6A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LTfPk1q5A6A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-16T05:02:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kidwtH97rje6d7Y9f1pQdJZ6qLY",
    "id": {
      "kind": "youtube#video",
      "videoId": "WLS2N2yzxrQ"
    },
    "snippet": {
      "publishedAt": "2022-06-26T04:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min MEGA BODYWEIGHT HIIT Workout | Day 20",
      "description": "Bodyweight only! And boy, is this a sweaty session!! Short intervals combined with slightly longer durations! And exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WLS2N2yzxrQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WLS2N2yzxrQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WLS2N2yzxrQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-26T04:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P2DVy9FGKeYQogO_6gsK9iTO2RM",
    "id": {
      "kind": "youtube#video",
      "videoId": "4ksSKiAOjkw"
    },
    "snippet": {
      "publishedAt": "2021-10-25T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SERIOUS SUPERSETS Chest &amp; Tricep Workout - Upper Body | EPIC III Day 36",
      "description": "So much more than just chest & triceps, this upper body workout will strengthen & build muscle in your shoulders and core!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4ksSKiAOjkw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4ksSKiAOjkw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4ksSKiAOjkw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-25T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EShH6XmfF_B0xzRafENTt1qU2Mw",
    "id": {
      "kind": "youtube#video",
      "videoId": "cw5xfZSYDVk"
    },
    "snippet": {
      "publishedAt": "2021-09-17T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FULLY LOADED Full Body Workout - Dumbbells | EPIC III Day 9",
      "description": "For this demanding full body session you will need a pair of dumbbells, your mat, a chair for only a small amount of sets of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cw5xfZSYDVk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cw5xfZSYDVk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cw5xfZSYDVk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-17T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hXPDupCFvkHjJjTrpBifJkAdkRA",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZQfJ36EXX2s"
    },
    "snippet": {
      "publishedAt": "2022-04-20T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Posterior Chain Workout - Glutes, Hamstrings, Back | 13",
      "description": "30 minutes all aimed at building muscle within the posterior chain! The posterior chain is basically all of the muscles within the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZQfJ36EXX2s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZQfJ36EXX2s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZQfJ36EXX2s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-20T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "A1a5B-aXDjaAewr97NkPv8VcN9k",
    "id": {
      "kind": "youtube#video",
      "videoId": "u3CPcI60G_U"
    },
    "snippet": {
      "publishedAt": "2022-06-21T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min GIANT Upper Body Dumbbell Workout | Day 17",
      "description": "Movements involving many muscles at once, others one muscle group only, variations, points of constant time under tension and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/u3CPcI60G_U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/u3CPcI60G_U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/u3CPcI60G_U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-21T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "s2K11fwjv8VW7JTEcDJxiIh9IHA",
    "id": {
      "kind": "youtube#video",
      "videoId": "EgtXh-YfGvY"
    },
    "snippet": {
      "publishedAt": "2021-08-20T04:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "INTENSE TEMPO FULL BODY WORKOUT - Dumbbells | Tempo Week Day 4",
      "description": "Shoulders, back, chest, arms, glutes, hamstrings, quads and abs\u2026. All challenged in this full body workout using tempo variations ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EgtXh-YfGvY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EgtXh-YfGvY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EgtXh-YfGvY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-20T04:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1-6D-eTwVKXEuSVBloI6wiITJTE",
    "id": {
      "kind": "youtube#video",
      "videoId": "GViX8riaHX4"
    },
    "snippet": {
      "publishedAt": "2020-08-03T12:21:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min FULL BODY DUMBBELL WORKOUT at Home | Muscle Building",
      "description": "This is a follow along 30 minute full body dumbbell workout that will target every muscle group with compound movement to help ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GViX8riaHX4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GViX8riaHX4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GViX8riaHX4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-03T12:21:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w9JCLE2W5DjoeJzymy6e2ntd_0c",
    "id": {
      "kind": "youtube#video",
      "videoId": "VMRp3iWly98"
    },
    "snippet": {
      "publishedAt": "2021-10-05T04:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ENDURING Upper Body Workout - Complex Training | EPIC III Day 22",
      "description": "Compound movements paired with isolation exercises to target all areas of the upper body using 3 minute complexes, dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VMRp3iWly98/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VMRp3iWly98/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VMRp3iWly98/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-05T04:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0_Ye2omn1h4pdjd8-myScSbwCo8",
    "id": {
      "kind": "youtube#video",
      "videoId": "KsDYoQUKsYw"
    },
    "snippet": {
      "publishedAt": "2022-02-25T05:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALM &amp; COLLECTED Calisthenics Full Body Workout | EPIC Endgame Day 39",
      "description": "Calm and collected is the overall feeling in this workout. It is intense but in order to perform these movements as best you can and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KsDYoQUKsYw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KsDYoQUKsYw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KsDYoQUKsYw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-25T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bnsTMjz-Q6luAL0R4wLzldzqj5A",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZrrBLJubmZ8"
    },
    "snippet": {
      "publishedAt": "2022-02-07T05:00:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KILLER Chest and Tricep Workout with Dumbbells | EPIC Endgame Day 26",
      "description": "'Killer' is in the title as this was my thoughts within 3 minutes! A lot of work focused on triceps and the chest, whilst involving the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZrrBLJubmZ8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZrrBLJubmZ8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZrrBLJubmZ8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-07T05:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CP2-IRVC8_Tmt_qC3A2dw9C07lg",
    "id": {
      "kind": "youtube#video",
      "videoId": "eJMOcJIbIMs"
    },
    "snippet": {
      "publishedAt": "2022-02-27T05:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DISTINCT Dumbbell HIIT Workout - Full Body | EPIC Endgame Day 40",
      "description": "We all love the combination of lighter dumbbells and faster paced movements that still require control with strength and stamina!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eJMOcJIbIMs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eJMOcJIbIMs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eJMOcJIbIMs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-27T05:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jjHCQFhOVpCVFKHcX4bfg8BPVLo",
    "id": {
      "kind": "youtube#video",
      "videoId": "81VgTxc9nHM"
    },
    "snippet": {
      "publishedAt": "2022-02-21T05:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SHOULDERS OF STEEL Dumbbell Shoulder Workout | EPIC Endgame Day 36",
      "description": "Delts\u2026 let's do this! We will work at a controlled pace to promote muscle building in the entire shoulder region! The core and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/81VgTxc9nHM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/81VgTxc9nHM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/81VgTxc9nHM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-21T05:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XReONOCS70rzagzJf6x7Abk8OiE",
    "id": {
      "kind": "youtube#video",
      "videoId": "0tN8gx1_7sI"
    },
    "snippet": {
      "publishedAt": "2021-12-15T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Bodyweight Leg Workout | Calisthenics Giant Sets",
      "description": "We will be using the giant set format that you all probably love by now if you have been training with me for awhile! The timer will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0tN8gx1_7sI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0tN8gx1_7sI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0tN8gx1_7sI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-15T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vsUVYndAykzpwpQ6dkYoMR7IWgc",
    "id": {
      "kind": "youtube#video",
      "videoId": "y-sq9j9W7g8"
    },
    "snippet": {
      "publishedAt": "2022-07-08T04:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min NO REPEAT Full Body Workout | Day 29",
      "description": "No repeat but as always, this doesn't mean random movements 'thrown together'\u2026 these exercises are programmed to provide ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y-sq9j9W7g8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y-sq9j9W7g8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y-sq9j9W7g8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-08T04:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zKpLx7m7-SSizgSwa5vRQI7JsUo",
    "id": {
      "kind": "youtube#video",
      "videoId": "7VVQFIn6g0A"
    },
    "snippet": {
      "publishedAt": "2020-07-14T19:59:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min OUTER &amp; INNER THIGH WORKOUT at Home | Ankle Weights (Optional)",
      "description": "This no repeat at home workout will target your adductor and abductors muscles as well as your glutes! Ankle weight workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7VVQFIn6g0A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7VVQFIn6g0A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7VVQFIn6g0A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-14T19:59:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5uum694ZIMpTczMqKVoSFc5ZcmA",
    "id": {
      "kind": "youtube#video",
      "videoId": "160TSBg4tOM"
    },
    "snippet": {
      "publishedAt": "2021-01-27T05:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min STRONG Shoulder Workout with Dumbbells | EPIC II - Day 19",
      "description": "Strong shoulders session! This workout will make your entire shoulders work hard to push through! Focusing on strengthening of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/160TSBg4tOM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/160TSBg4tOM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/160TSBg4tOM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-27T05:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bF_o8WSzw8nPCwZyWPAH6M--gkM",
    "id": {
      "kind": "youtube#video",
      "videoId": "sh4mQMCt9U4"
    },
    "snippet": {
      "publishedAt": "2021-09-29T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ALL ARMS &amp; ABS Workout - Bodyweight / Dumbbells | EPIC III Day 18",
      "description": "It's all about those biceps, triceps and abs in this workout!! We will work through this complex training to target the arms and abs ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sh4mQMCt9U4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sh4mQMCt9U4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sh4mQMCt9U4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-29T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wwlcLkgjEC5jpnaXhPdWa-qiKmM",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZZcUnu121hs"
    },
    "snippet": {
      "publishedAt": "2022-02-04T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALCULATED Dumbbell Full Body Workout | EPIC Endgame Day 24",
      "description": "It's complexes!! 2 minutes per complex! 2 exercises per complex! Strength, co-ordination, smooth transitioning and focus all are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZZcUnu121hs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZZcUnu121hs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZZcUnu121hs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-04T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "A_MATclNiNfy9tlcI4yqlF_-Cyk",
    "id": {
      "kind": "youtube#video",
      "videoId": "1gBjEyOT65Q"
    },
    "snippet": {
      "publishedAt": "2022-07-01T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Dumbbell Full Body CORE Workout | Day 24",
      "description": "All of our major muscle groups, along with full body movements to involve the core, but you will be so focused on the move itself ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1gBjEyOT65Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1gBjEyOT65Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1gBjEyOT65Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-01T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "61_Y7snv83EV-9syhkkpaHQ4YNs",
    "id": {
      "kind": "youtube#video",
      "videoId": "vJJ_kktux1Y"
    },
    "snippet": {
      "publishedAt": "2021-10-29T04:00:34Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CORE Calisthenics Full Body Workout [NO EQUIPMENT] | EPIC III Day 39",
      "description": "Full body calisthenics workout using only our body as resistance to increase strength, build muscle, strengthen the core and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vJJ_kktux1Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vJJ_kktux1Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vJJ_kktux1Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-29T04:00:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gsy8DXjGPf7kjZRMRbSXrzA31dU",
    "id": {
      "kind": "youtube#video",
      "videoId": "iMSEqGDIecI"
    },
    "snippet": {
      "publishedAt": "2022-02-28T05:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "QUINTESSENTIAL Quads and Calves Workout | EPIC Endgame Day 41",
      "description": "Quads, calves, then a finisher combining a squat hold with calf raises! It's going to be a solid session on the lower body! For this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iMSEqGDIecI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iMSEqGDIecI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iMSEqGDIecI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-28T05:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W5LuTCCGOzDy8ubxpJRp6skNeFc",
    "id": {
      "kind": "youtube#video",
      "videoId": "sKyYyeFl6lo"
    },
    "snippet": {
      "publishedAt": "2022-06-03T04:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min Full Body Workout - PHA Training | Day 4",
      "description": "Upper body, lower body, upper body and so on! We will welcome a longer duration between revisiting compound upper and lower ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sKyYyeFl6lo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sKyYyeFl6lo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sKyYyeFl6lo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-03T04:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "82zjcP5RpZ7k_2mpRwhPhoblckM",
    "id": {
      "kind": "youtube#video",
      "videoId": "2U79hWhlf1M"
    },
    "snippet": {
      "publishedAt": "2021-06-04T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FEEL THE HEAT No Repeat Full Body Workout - CARDIO | EPIC Heat - Day 44",
      "description": "Full body, no repeat dumbbell cardio workout! But no repeat doesn't mean less intensity! These exercises are positioned within ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2U79hWhlf1M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2U79hWhlf1M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2U79hWhlf1M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-04T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HmQeN2A6-o6VaW_bZ-5bKpmDhf4",
    "id": {
      "kind": "youtube#video",
      "videoId": "rzNWh3siwmY"
    },
    "snippet": {
      "publishedAt": "2022-07-05T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min TEMPO - Shoulders, Chest &amp; Triceps Workout | Day 27",
      "description": "It's all about the slow eccentric (controlling the negative portion), followed by a faster, almost explosive tempo on the concentric ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rzNWh3siwmY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rzNWh3siwmY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rzNWh3siwmY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-05T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XxxpSuUFj1bWt1uhoEl45hZ-3gE",
    "id": {
      "kind": "youtube#video",
      "videoId": "uJp6kbyn9ok"
    },
    "snippet": {
      "publishedAt": "2022-01-19T05:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "HELLO Hamstrings &amp; Glutes Dumbbell Workout | EPIC Endgame Day 13",
      "description": "Aiming to increase not only strength and muscle but improving your mind to the muscle connection really will lead to more reward.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uJp6kbyn9ok/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uJp6kbyn9ok/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uJp6kbyn9ok/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-19T05:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7cHivxx9LQEtogXhZBP9IB-dXYw",
    "id": {
      "kind": "youtube#video",
      "videoId": "tn0s_dk-t2I"
    },
    "snippet": {
      "publishedAt": "2021-01-04T05:37:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "50 Min Compound Leg Workout / Dumbbells &amp; Bodyweight | EPIC II - DAY 2",
      "description": "Let's fire up the legs with dumbbells and bodyweight compound exercises to work the quads, hamstrings, glutes and of course the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tn0s_dk-t2I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tn0s_dk-t2I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tn0s_dk-t2I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-04T05:37:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x8-YhuoBgOri3AjTcE47rwHJEGY",
    "id": {
      "kind": "youtube#video",
      "videoId": "EYCorwoB9r0"
    },
    "snippet": {
      "publishedAt": "2021-05-26T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ANNIHILATION Arm Workout - 30 Min Biceps + Triceps | EPIC Heat - Day 38",
      "description": "Arm annihilation! Let's do it! Biceps and triceps all within trisets in this dumbbell workout with some bodyweight exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EYCorwoB9r0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EYCorwoB9r0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EYCorwoB9r0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-26T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WxH1Rx-Mar6H_eICapbbaveaZ3o",
    "id": {
      "kind": "youtube#video",
      "videoId": "o038TsenCX4"
    },
    "snippet": {
      "publishedAt": "2021-12-17T05:00:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Dumbbell Full Body Workout | Strength + Cardio",
      "description": "A 20 minute workout combining resistance training and a staple of a full body movement! For this full body sweaty session you will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/o038TsenCX4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/o038TsenCX4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/o038TsenCX4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-17T05:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7T3vnafhV-X1ddQR3ldIhcVuQFU",
    "id": {
      "kind": "youtube#video",
      "videoId": "2Qd-LJNJYWA"
    },
    "snippet": {
      "publishedAt": "2022-04-06T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Glute Workout - Dumbbell Lower Body | 3",
      "description": "The glutes stabilise our hips and pelvis and are required to provide the correct alignment to the pelvis. Even balancing on one ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2Qd-LJNJYWA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2Qd-LJNJYWA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2Qd-LJNJYWA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-06T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "d002Pl5mmSB6zoWNZkwylVBHJ7g",
    "id": {
      "kind": "youtube#video",
      "videoId": "Bf8uyQOmqU8"
    },
    "snippet": {
      "publishedAt": "2020-06-12T11:55:51Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min LEGS AND GLUTES WORKOUT at Home | Ankle Weights Optional",
      "description": "Hello everyone! This is a 30 minute follow along at home workout hitting the quads and hamstrings with focus on the glutes!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Bf8uyQOmqU8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Bf8uyQOmqU8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Bf8uyQOmqU8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-12T11:55:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DN9iJBodSZm_ks1XrwjihLLiXII",
    "id": {
      "kind": "youtube#video",
      "videoId": "M_NqDxgjCH4"
    },
    "snippet": {
      "publishedAt": "2021-08-22T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TEMPO HIIT Cardio Workout - No Equipment | Tempo Week Day 5",
      "description": "TEMPO HIIT here we go! It's time to have some fun! lots of changes in tempo particularly explosive from static, exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M_NqDxgjCH4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M_NqDxgjCH4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M_NqDxgjCH4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-22T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NnMmkqbs4Z7zegjNAsADdhgYKME",
    "id": {
      "kind": "youtube#video",
      "videoId": "NCm9nqetRBA"
    },
    "snippet": {
      "publishedAt": "2021-02-10T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "THE PUMP WORKOUT / Upper Body Complexes | EPIC II - Day 29",
      "description": "Ready for that pumped feeling? My entire upper body was feeling that extra blood flow pumping to the muscles! Shoulders, chest ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NCm9nqetRBA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NCm9nqetRBA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NCm9nqetRBA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-10T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "C_pnamu8K5rAX4L3hv3hfgMZB5w",
    "id": {
      "kind": "youtube#video",
      "videoId": "yKlGfmR4T28"
    },
    "snippet": {
      "publishedAt": "2021-12-24T05:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Christmas Strength &amp; Cardio Workout | Caroline Girvan",
      "description": "Let's finish this! It's Christmas Eve and 20 minutes of fun is ahead! A sweaty combination of resistance training and cardio all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yKlGfmR4T28/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yKlGfmR4T28/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yKlGfmR4T28/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-24T05:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CjsDeMTbzG6wcSErEkvHOSsCYEU",
    "id": {
      "kind": "youtube#video",
      "videoId": "t_iFWSIsbB4"
    },
    "snippet": {
      "publishedAt": "2021-08-18T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TEMPO BACK AND BICEPS Workout - Dumbbells | Tempo Week Day 3",
      "description": "Tempo training back and biceps! Training your back brings many benefits; including reduced risk of injury that comes from ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/t_iFWSIsbB4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/t_iFWSIsbB4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/t_iFWSIsbB4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-18T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LUselgjLpy7pFRV_oh29NcuLBsw",
    "id": {
      "kind": "youtube#video",
      "videoId": "pY4MAI6oHZA"
    },
    "snippet": {
      "publishedAt": "2021-01-25T05:00:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min ARM DAY Workout with Dumbbells | EPIC II - Day 17",
      "description": "It's EPIC ARM DAY! Let's strengthen the arms, build muscle and focus on mastering our control and form in this arm workout, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pY4MAI6oHZA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pY4MAI6oHZA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pY4MAI6oHZA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-25T05:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TuRrviESDNHXYLr4Eju2hmD-OHo",
    "id": {
      "kind": "youtube#video",
      "videoId": "jKDkcdXAgio"
    },
    "snippet": {
      "publishedAt": "2021-05-10T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "HIGH CALIBRE Hamstrings, Back &amp; Glutes Workout | EPIC Heat - Day 26",
      "description": "Upper, middle and lower back, hamstrings, glutes and of course core all will be strengthened during this posterior chain dumbbell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jKDkcdXAgio/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jKDkcdXAgio/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jKDkcdXAgio/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-10T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W1136yfOrAlswAugCbzTEFKZx1E",
    "id": {
      "kind": "youtube#video",
      "videoId": "9LIAhxQHmak"
    },
    "snippet": {
      "publishedAt": "2020-09-05T10:59:59Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min Full Body KETTLEBELL WORKOUT | Supersets",
      "description": "Ready for a sweaty full body kettlebell workout? This workout will hit all the major muscle groups and get the heart rate up with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9LIAhxQHmak/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9LIAhxQHmak/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9LIAhxQHmak/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-05T10:59:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aMjK9QwiYy7ffJA3sRl6PfSpphU",
    "id": {
      "kind": "youtube#video",
      "videoId": "lEl9MToXwt0"
    },
    "snippet": {
      "publishedAt": "2021-06-29T04:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Minute Unilateral Shoulders &amp; Arms Workout at Home | Caroline Girvan",
      "description": "Shoulders, triceps & biceps! A dumbbell unilateral workout straight to the point! Pressing, raising, curling and extending to target ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lEl9MToXwt0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lEl9MToXwt0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lEl9MToXwt0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-29T04:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_OGmgSEmipt5YJsvNScd15MIW2s",
    "id": {
      "kind": "youtube#video",
      "videoId": "H_yT7r0NKo4"
    },
    "snippet": {
      "publishedAt": "2022-03-06T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EUPHORIC EMOM HIIT Workout - Advanced | EPIC Endgame Day 45",
      "description": "Every minute on the minute! Speed, endurance, pacing, co-ordination and resilience\u2026 not often is an EMOM HIIT workout on the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H_yT7r0NKo4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H_yT7r0NKo4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H_yT7r0NKo4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-06T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x-jJ5tp_7Ts1jzcDyqNoJjfX_Yg",
    "id": {
      "kind": "youtube#video",
      "videoId": "QBoWC7lgiqM"
    },
    "snippet": {
      "publishedAt": "2020-07-27T17:01:36Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min GLUTE &amp; OUTER THIGH WORKOUT at Home with Band",
      "description": "This 30 minute workout will focus on targeting, isolating and activating the glute medius and glute minimus. These exercises are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QBoWC7lgiqM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QBoWC7lgiqM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QBoWC7lgiqM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-27T17:01:36Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XnrYq7zpyr8N7qDan7WHaP4K-5c",
    "id": {
      "kind": "youtube#video",
      "videoId": "qpXtoGTlKYE"
    },
    "snippet": {
      "publishedAt": "2020-06-01T15:50:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "500 Rep Arm Challenge | Band or Dumbbells at Home Workout",
      "description": "Hello everyone! Today's workout is a 500 rep arm challenge to work through at your own pace if you wish! I have included ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qpXtoGTlKYE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qpXtoGTlKYE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qpXtoGTlKYE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-01T15:50:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VQC8msWWkNMMdzt8qpLbsjrddeA",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZZI__bqlBkQ"
    },
    "snippet": {
      "publishedAt": "2021-12-07T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Dumbbell Leg Workout at Home - Squats, RDLs | Caroline Girvan",
      "description": "It's time for some squats and RDLs! Yes\u20262 main exercises with lots of variations to make this 20 minute leg workout intense yet ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZZI__bqlBkQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZZI__bqlBkQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZZI__bqlBkQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-07T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N28mkJaQegOUeyk9zLRTDPubOIA",
    "id": {
      "kind": "youtube#video",
      "videoId": "LP6ajLZidD0"
    },
    "snippet": {
      "publishedAt": "2021-02-24T05:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DEAD ARMS &amp; Shoulder Workout / Upper Body | EPIC II - Day 39",
      "description": "Shoulders, biceps and triceps hit in this upper body dumbbell and bodyweight shoulder and arm workout! Presses, raises, curls ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LP6ajLZidD0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LP6ajLZidD0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LP6ajLZidD0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-24T05:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6yqD52gMkUTdNHSla9UV5g9vw7w",
    "id": {
      "kind": "youtube#video",
      "videoId": "a84K4PYiYyc"
    },
    "snippet": {
      "publishedAt": "2021-05-14T04:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "STRENGTH &amp; SWEAT Full Body Workout - Circuits | EPIC Heat - Day 29",
      "description": "A full body workout using compounds movements to begin followed by more dynamic movements to target the major muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a84K4PYiYyc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a84K4PYiYyc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a84K4PYiYyc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-14T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PSNa3lm6WzUzI_yM3KavGBsnCnE",
    "id": {
      "kind": "youtube#video",
      "videoId": "JzdIBkcjSyI"
    },
    "snippet": {
      "publishedAt": "2022-06-12T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min HARD &#39;N HIGHER Intensity Workout | Day 10",
      "description": "Higher Intensity (HIT), is exactly as you will feel within the first superset! We are using supersets today but in a slightly different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JzdIBkcjSyI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JzdIBkcjSyI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JzdIBkcjSyI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-12T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EoS57GuK7M6Jx_CxmDTqIDKfpB0",
    "id": {
      "kind": "youtube#video",
      "videoId": "EN-zuVg4mok"
    },
    "snippet": {
      "publishedAt": "2022-04-27T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Dumbbell Glute Workout - Supersets | 18",
      "description": "A 30 minute glute workout hitting the entire glutes using different movement patterns, weight for resistance and supersets for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EN-zuVg4mok/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EN-zuVg4mok/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EN-zuVg4mok/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-27T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1PFzjj4A5GuLTSKn33w70EArQs8",
    "id": {
      "kind": "youtube#video",
      "videoId": "jnqr9D4Egho"
    },
    "snippet": {
      "publishedAt": "2021-07-11T04:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SWEAT FULL BODY HIIT WORKOUT with Dumbbells | Circuit Series Day 5",
      "description": "A high intensity circuit to work your whole body for 30 minutes combining bodyweight and dumbbells for an intense session!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jnqr9D4Egho/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jnqr9D4Egho/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jnqr9D4Egho/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-11T04:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MZjzQyDP85MwCmYVfVPOE7yil3Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "kaNktg56JaI"
    },
    "snippet": {
      "publishedAt": "2021-07-16T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DIVERSE 30 MIN FULL BODY WORKOUT - Dumbbells &amp; Bodyweight | No Repeat",
      "description": "So many exercises within this 30 minute full body workout to hit those legs, glutes, shoulders, back and arms! Mostly dumbbells as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kaNktg56JaI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kaNktg56JaI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kaNktg56JaI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-16T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jbU9ueGAWATDKWomWgd1rLm7M0E",
    "id": {
      "kind": "youtube#video",
      "videoId": "xJ6Q34k3yAU"
    },
    "snippet": {
      "publishedAt": "2022-03-09T05:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PERFECT Push Ups and Abs Workout - Bodyweight | EPIC Endgame Day 48",
      "description": "Chest, shoulders, triceps, core and support from the lower body! Push ups are much more than just chest! This workout is a variety ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xJ6Q34k3yAU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xJ6Q34k3yAU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xJ6Q34k3yAU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-09T05:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "flcyPRXzrs_iUvrQcCbyvk2qqTA",
    "id": {
      "kind": "youtube#video",
      "videoId": "UxfX7Z4am1Q"
    },
    "snippet": {
      "publishedAt": "2020-12-21T15:28:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 MIN FULL BODY KETTLEBELL WORKOUT at Home | Caroline Girvan",
      "description": "45 minute Kettlebell full body workout to fire all of your muscles and require a lot of stability and strength of your core! You will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UxfX7Z4am1Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UxfX7Z4am1Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UxfX7Z4am1Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-21T15:28:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OgDDdVmhHxhDMn0Cqih4QJOCd2o",
    "id": {
      "kind": "youtube#video",
      "videoId": "XfzwkPkWwBQ"
    },
    "snippet": {
      "publishedAt": "2021-10-13T04:00:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DIAMOND Chest, Triceps and Abs Workout - Upper Body | EPIC III Day 28",
      "description": "Here we go! A muscle building session for the upper body and those ab muscles! The chest, triceps, front delts, core and abs all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XfzwkPkWwBQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XfzwkPkWwBQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XfzwkPkWwBQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-13T04:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "U10bK6mcZI7DOBrsLJ6v2aCpiAo",
    "id": {
      "kind": "youtube#video",
      "videoId": "wg3FIcByhrs"
    },
    "snippet": {
      "publishedAt": "2021-05-25T04:00:25Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNREPEATED 30 MIN Leg Workout - No Repeat | EPIC Heat - Day 37",
      "description": "Ready for a complete leg session!? So much variety with no repeat exercises to help strengthen the quads, hamstrings and glutes ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wg3FIcByhrs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wg3FIcByhrs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wg3FIcByhrs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-25T04:00:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "H8ExKnN41Bpo32FWrBxLs6Du5wo",
    "id": {
      "kind": "youtube#video",
      "videoId": "9hVq72ZZLng"
    },
    "snippet": {
      "publishedAt": "2020-09-22T07:30:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 2 of Beginner EPIC | No Equipment Upper Body Workout",
      "description": "Day 2 in the EPIC Beginners Series for those of you wanting to progress to the full EPIC Program! For 30 minutes, we will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9hVq72ZZLng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9hVq72ZZLng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9hVq72ZZLng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-22T07:30:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_HFiUlfWDd-EnzG0ObeTW0COs4Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "79z6y5U5w2E"
    },
    "snippet": {
      "publishedAt": "2021-12-04T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Glute Workout - Complexes | Hip Thrusts, RDLs &amp; Sumos",
      "description": "Yes! Glute specific complexes with afew hamstring exercises within! I personally love giant sets, complexes, supersets, trisets or ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/79z6y5U5w2E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/79z6y5U5w2E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/79z6y5U5w2E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-04T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xGQdvTyC6jH7ahLf9Zl27QpQzSY",
    "id": {
      "kind": "youtube#video",
      "videoId": "xShZ2K5hSL8"
    },
    "snippet": {
      "publishedAt": "2021-10-11T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "MUSCLE BUILDING Back and Bicep Workout | EPIC III Day 26",
      "description": "Today we focus on the back & biceps! Increased strength, muscle gains, balanced back of body in rest ion to the front bit also ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xShZ2K5hSL8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xShZ2K5hSL8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xShZ2K5hSL8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-11T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WyNa44wXmMhBQq2IFyN3pg42ld8",
    "id": {
      "kind": "youtube#video",
      "videoId": "6dZZFzVMIGY"
    },
    "snippet": {
      "publishedAt": "2022-01-11T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "LIFTED Lower Body Workout - Dumbbell Leg Day | EPIC Endgame Day 7",
      "description": "A comprehensive and intense lower body session to make those hamstrings, quads, glutes and core work hard! All performed at a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6dZZFzVMIGY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6dZZFzVMIGY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6dZZFzVMIGY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-11T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9cCLNVOy81WHU6UlIPjOt3kaKhA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Zx45s3kUrCc"
    },
    "snippet": {
      "publishedAt": "2022-02-06T05:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KNOCKOUT HIIT Cardio Full Body Workout | EPIC Endgame Day 25",
      "description": "Ready to knockout this combat workout? Plenty of jabs, kicks and unsuspecting swift manoeuvres with maximum follow through to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Zx45s3kUrCc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Zx45s3kUrCc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Zx45s3kUrCc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-06T05:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PjCnQpALCg8l3AhzzdbL5y38T5g",
    "id": {
      "kind": "youtube#video",
      "videoId": "dCpUXnerMf4"
    },
    "snippet": {
      "publishedAt": "2020-05-28T15:22:52Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Abs and Glutes Workout Combo | 30 Minutes with Mini Band",
      "description": "Hello everyone! 2 in 1 sort this awesome workout! Glutes and abs! First 5 minutes abs, followed by 10 minutes glutes with band, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dCpUXnerMf4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dCpUXnerMf4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dCpUXnerMf4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-28T15:22:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IhKha8i3-2wa_ZcxFY4y9YKxa8o",
    "id": {
      "kind": "youtube#video",
      "videoId": "Is1ZKY_ABJk"
    },
    "snippet": {
      "publishedAt": "2021-09-24T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SWEAT MAKER Dumbbell Cardio Full Body Workout | EPIC III Day 14",
      "description": "A full body cardio session that will involve a mixture of lifting and cardio! Shoulders, chest, back, quads and hamstrings all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Is1ZKY_ABJk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Is1ZKY_ABJk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Is1ZKY_ABJk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-24T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "u3XVXdM4i8NXo1YzDgipGkbbqO8",
    "id": {
      "kind": "youtube#video",
      "videoId": "dnHSrYHT0Zk"
    },
    "snippet": {
      "publishedAt": "2022-05-03T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Upper Body Chest and Back Workout | 22",
      "description": "No frills. Straight to the point! Although back and chest, the arms, shoulders and core are challenged! Push and pull movements in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dnHSrYHT0Zk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dnHSrYHT0Zk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dnHSrYHT0Zk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-03T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MXBykROgIk21x2eFq7ypBPB8YTg",
    "id": {
      "kind": "youtube#video",
      "videoId": "ajHLGnCoxl8"
    },
    "snippet": {
      "publishedAt": "2020-12-15T20:08:48Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 MIN PUSH ARM WORKOUT | Bodyweight Only - Biceps and Triceps",
      "description": "15 minutes and we will work on adding strength to the upper body, primarily the arms using only our own bodyweight as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ajHLGnCoxl8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ajHLGnCoxl8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ajHLGnCoxl8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-15T20:08:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uBHsNQ8ofiRkCBVMlU-tQZMU_iI",
    "id": {
      "kind": "youtube#video",
      "videoId": "V_cqoVYN6wU"
    },
    "snippet": {
      "publishedAt": "2021-01-13T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BUILD Back and Biceps Workout at Home / Dumbbells | EPIC II - Day 9",
      "description": "Let's build our back and biceps! Over 40 minutes solely dedicated to strengthening the back and biceps through dumbbells and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V_cqoVYN6wU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V_cqoVYN6wU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V_cqoVYN6wU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-13T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-eVthkE8dqo3ChGV_f9MZMBClmc",
    "id": {
      "kind": "youtube#video",
      "videoId": "I2C5g8u8t0o"
    },
    "snippet": {
      "publishedAt": "2021-07-26T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE BACK AND BICEPS - Intense Upper Body Workout | Day 6",
      "description": "One of my favourite back and biceps workouts for sure! Slow paced, focusing on lengthening and contracting the muscles of back ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I2C5g8u8t0o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I2C5g8u8t0o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I2C5g8u8t0o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-26T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nfhugrVYFL4aFZdRjqzW77JJWt0",
    "id": {
      "kind": "youtube#video",
      "videoId": "WnP79Rolnpg"
    },
    "snippet": {
      "publishedAt": "2021-03-15T05:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "KETTLEBELL LEG WORKOUT - Kick-ass Kettlebell Series | Day 1",
      "description": "Day 1 in the Kick-ass Kettlebell Series and it's kettlebell leg day! Hamstrings, quads, and of course glutes will be hit! Strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WnP79Rolnpg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WnP79Rolnpg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WnP79Rolnpg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-15T05:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mnCgaNpQTU4x0p_0n4GRv2c6P_M",
    "id": {
      "kind": "youtube#video",
      "videoId": "LS-iJMsGcVY"
    },
    "snippet": {
      "publishedAt": "2021-03-09T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "LEGENDARY Leg Workout / No Frills Leg Day | EPIC II - Day 48",
      "description": "Get ready to smash this leg session! Hamstrings, quads, glutes... those dumbbells will feel a lot heavier by the end of each set with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LS-iJMsGcVY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LS-iJMsGcVY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LS-iJMsGcVY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-09T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oy2Y5uNGas1RjgG1t7r7UFzZmeo",
    "id": {
      "kind": "youtube#video",
      "videoId": "Jgm6QYWYY3E"
    },
    "snippet": {
      "publishedAt": "2022-03-13T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "The EPIC Endgame Finale - Strength and Cardio Workout | Day 50",
      "description": "Strength, endurance, power, balance, co-ordination, isometrics\u2026. Plus more! So many ways of training we love all in this workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Jgm6QYWYY3E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Jgm6QYWYY3E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Jgm6QYWYY3E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-13T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Hf3ZmQk9ljfNQQT3gkoktivoAk4",
    "id": {
      "kind": "youtube#video",
      "videoId": "6E36kid8Abw"
    },
    "snippet": {
      "publishedAt": "2020-07-07T10:27:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min TRICEPS &amp; CHEST WORKOUT | Strong &amp; Lean Series Day 2",
      "description": "Welcome to Day 2 of the Strong & Lean at Home Series! This is day 2 of a 5 day Series focusing on strength using only your own ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6E36kid8Abw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6E36kid8Abw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6E36kid8Abw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-07T10:27:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eEmqRoO4AhrLyioPWbD0fJxywyo",
    "id": {
      "kind": "youtube#video",
      "videoId": "afyWDReMPno"
    },
    "snippet": {
      "publishedAt": "2022-01-03T05:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PROPER Leg Day Workout with Dumbbells | EPIC Endgame Day 1",
      "description": "Exactly as it says\u2026 it's all about the legs and a PROPER good leg day! This is day 1 and we will be completing mainly compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/afyWDReMPno/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/afyWDReMPno/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/afyWDReMPno/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-03T05:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AwyWsXRa8hAdwgyJsGYzf3DTKjM",
    "id": {
      "kind": "youtube#video",
      "videoId": "K-tLY96Jq6o"
    },
    "snippet": {
      "publishedAt": "2022-05-04T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Superset Glutes and Hamstrings Workout | 23",
      "description": "Supersets today!! I hope you are ready to set your hamstrings and glutes alight! Primarily compound movements with some ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/K-tLY96Jq6o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/K-tLY96Jq6o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/K-tLY96Jq6o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-04T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KaZKCJLcK5OC6J5Zcpfh8oKjjhw",
    "id": {
      "kind": "youtube#video",
      "videoId": "QYQZhxfqwKg"
    },
    "snippet": {
      "publishedAt": "2021-01-11T05:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min Shoulders and Triceps Workout with Dumbbells | EPIC II - Day 7",
      "description": "Shoulders and the triceps will be targeted during this upper body dumbbell workout along with some bodyweight only exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QYQZhxfqwKg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QYQZhxfqwKg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QYQZhxfqwKg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-11T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "40Ob4rrFYXGdpkZEb5s5uSQ8G54",
    "id": {
      "kind": "youtube#video",
      "videoId": "icoe6C2E-aY"
    },
    "snippet": {
      "publishedAt": "2022-10-09T09:44:45Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN Dumbbell Full Body Workout - Compound Movements | NO REPEAT",
      "description": "A perfect no repeat full body workout involves compound movements that affect many muscles within each rep! Quads, hamstrings ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/icoe6C2E-aY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/icoe6C2E-aY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/icoe6C2E-aY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-09T09:44:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wGKsGsAPWViQszeZc0LDyIyRFZI",
    "id": {
      "kind": "youtube#video",
      "videoId": "myizDz0c5XA"
    },
    "snippet": {
      "publishedAt": "2021-10-24T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EXTREME Tabata HIIT Workout - No Repeat + No Equipment | EPIC III Day 35",
      "description": "60 exercises! No repeat! Tabata style timer! Short work period! Short rest! Ready to get sweaty?! All you will need is a lot of energy ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/myizDz0c5XA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/myizDz0c5XA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/myizDz0c5XA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-24T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1-I2G__8g7B6145T9qTKPpcemZU",
    "id": {
      "kind": "youtube#video",
      "videoId": "SCxNnWW2zB8"
    },
    "snippet": {
      "publishedAt": "2022-04-04T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Leg Workout - Dumbbell Leg Day | 1",
      "description": "Day 1 in The IRON Series and we begin with a complete leg day workout involving some of the main dumbbell movements to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SCxNnWW2zB8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SCxNnWW2zB8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SCxNnWW2zB8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-04T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HU6827Bc5zHLS_JPv1Zb-hsMQoM",
    "id": {
      "kind": "youtube#video",
      "videoId": "HBGb1OP50us"
    },
    "snippet": {
      "publishedAt": "2021-07-06T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UPPER BODY WORKOUT with Dumbbells - Muscle Building | Circuit Series Day 2",
      "description": "Total upper body attack!! The back, chest, shoulders, biceps and triceps will feel the Impact of this dumbbell circuit workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HBGb1OP50us/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HBGb1OP50us/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HBGb1OP50us/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-06T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Y6lROuzCMSXd8NOGFnhETAXfsXs",
    "id": {
      "kind": "youtube#video",
      "videoId": "8W7U_XT_Pa0"
    },
    "snippet": {
      "publishedAt": "2021-12-11T05:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Minute TARGETED Glute Workout - Dumbbells | Caroline Girvan",
      "description": "GLUTES ON FIRE in 15 minutes! Focusing on not just lifting the weight, but the movement pattern from the bottom right to the top ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8W7U_XT_Pa0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8W7U_XT_Pa0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8W7U_XT_Pa0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-11T05:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8JCXU2MIvWpg5W8FrtunawuFvYs",
    "id": {
      "kind": "youtube#video",
      "videoId": "wtng-cHn_24"
    },
    "snippet": {
      "publishedAt": "2021-08-10T04:00:19Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PYRAMID SHOULDERS &amp; ARMS WORKOUT - Hypertrophy | Pyramid Series Day 2",
      "description": "Pyramid training shoulders and arms! This workout is for strengthening and building hypertrophy in the shoulders, biceps and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wtng-cHn_24/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wtng-cHn_24/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wtng-cHn_24/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-10T04:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sXl9UXy9eqIPBhO10xF0d8CpeXo",
    "id": {
      "kind": "youtube#video",
      "videoId": "aaU3U1hYAo4"
    },
    "snippet": {
      "publishedAt": "2021-07-25T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE HIIT - High Intensity Full Body Workout | Day 5",
      "description": "Here we go! Sweat session alright!! Real high intensity interval session with timer on for 20 seconds of work, 10 seconds rest!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aaU3U1hYAo4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aaU3U1hYAo4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aaU3U1hYAo4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-25T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1agvMmRPVxT0eF4g2XsxiNCiygk",
    "id": {
      "kind": "youtube#video",
      "videoId": "5Sb_u1pwpcI"
    },
    "snippet": {
      "publishedAt": "2021-10-19T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUPER INTENSE Shoulder &amp; Arm Supersets Workout | EPIC III Day 32",
      "description": "Super sets! Super intense session! This upper body workout will demand a lot from your shoulders, biceps and triceps with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5Sb_u1pwpcI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5Sb_u1pwpcI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5Sb_u1pwpcI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-19T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TtjPWunmh02v8C27xqadX4aiTfI",
    "id": {
      "kind": "youtube#video",
      "videoId": "zmr0XPLzMsw"
    },
    "snippet": {
      "publishedAt": "2022-03-04T05:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "THE FLOW Full Body Workout with Dumbbells | EPIC Endgame Day 44",
      "description": "This workout is all about the flow! Full body flows along with compound movements to not only build strength but test your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zmr0XPLzMsw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zmr0XPLzMsw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zmr0XPLzMsw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-04T05:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UnuIIHXSTMYT7GBZVpj6tD5IOcQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Fm2Ow2ZaXak"
    },
    "snippet": {
      "publishedAt": "2021-03-28T04:30:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1000 REPS Ab Challenge / INTENSE ABS WORKOUT - Caroline Girvan",
      "description": "1000 rep ab & core challenge! A perfect workout as a challenge to work through at your own pace! The abdominal muscles and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Fm2Ow2ZaXak/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Fm2Ow2ZaXak/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Fm2Ow2ZaXak/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-28T04:30:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jlkrWQSHQBej6JIB9Gu1S1q5euA",
    "id": {
      "kind": "youtube#video",
      "videoId": "588-C4bEL28"
    },
    "snippet": {
      "publishedAt": "2021-07-23T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE FULL BODY WORKOUT - Intense Strength Training | Day 4",
      "description": "Full body workout targeting the major muscle groups using compound lifts and sets of 4 throughout! Back, shoulders, chest and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/588-C4bEL28/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/588-C4bEL28/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/588-C4bEL28/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-23T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zBxioS0BBLMVI-Zs4FCZnzqg7B0",
    "id": {
      "kind": "youtube#video",
      "videoId": "R2W1EhJrvj4"
    },
    "snippet": {
      "publishedAt": "2022-05-30T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FUEL Series 30 Min GIANT LEG DAY Workout - Dumbbells | Day 1",
      "description": "FUEL Day 1\u2026 Let's Go! Dumbbells, your mat, chair for Bulgarian lunges and lots of energy\u2026 everything you will need for FUEL ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/R2W1EhJrvj4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/R2W1EhJrvj4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/R2W1EhJrvj4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-30T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5T56IkH2eMsaJK85WSof5SQOOiM",
    "id": {
      "kind": "youtube#video",
      "videoId": "oB4adffKNx4"
    },
    "snippet": {
      "publishedAt": "2021-09-08T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC ABS AND GLUTES - Dumbbell Workout, Hip Thrusts | EPIC III Day 3",
      "description": "One of the most intense glute workouts I have personally ever done! I don't say this unless I mean it! I am sure you will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oB4adffKNx4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oB4adffKNx4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oB4adffKNx4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-08T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NrOaJNZy_GUPSkbSuwHkZiT0Yjk",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ohd3GAgYDKI"
    },
    "snippet": {
      "publishedAt": "2021-08-26T04:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Glute Ankle Weight Workout - Knee Friendly | Pure Endure Day 4",
      "description": "Ankle weights on, ready to isolate the glutes, hamstrings, adductors and abductors! Mostly unilateral, this workout requires a focus ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ohd3GAgYDKI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ohd3GAgYDKI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ohd3GAgYDKI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-26T04:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8haa7DvbvdJuY4UerE-VRpEfgW0",
    "id": {
      "kind": "youtube#video",
      "videoId": "YfvAL6caTs0"
    },
    "snippet": {
      "publishedAt": "2021-12-22T05:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Tricep Workout with Dumbbells - NO Push Ups | Upper Body",
      "description": "Yes! Not a single push up in this upper body workout! The triceps will be challenged through isolation movements involving a pair ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YfvAL6caTs0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YfvAL6caTs0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YfvAL6caTs0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-22T05:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kh8TZ1M0cxqU5GhPpCHmydvWV2Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "OT1jUshlxgM"
    },
    "snippet": {
      "publishedAt": "2020-05-04T17:00:24Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Back Workout at Home | Inverted Rows",
      "description": "Back workout at home | Inverted Rows | Follow Along Hello everyone! Wanting a strong, toned and shapely back? Then try ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OT1jUshlxgM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OT1jUshlxgM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OT1jUshlxgM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-04T17:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tvVPp4zRMqj3aBWhtrL7q5HZluo",
    "id": {
      "kind": "youtube#video",
      "videoId": "ydqBtKlM9iQ"
    },
    "snippet": {
      "publishedAt": "2021-10-31T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Minute FRIGHTFUL Dumbbell Cardio HIIT Workout | EPIC III Day 40",
      "description": "30 minutes of high intensity interval training with light dumbbells! Elevations in heart rate, major muscle groups working, core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ydqBtKlM9iQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ydqBtKlM9iQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ydqBtKlM9iQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-31T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o11Lmm58QQgAruiVYAlfvBDoVGc",
    "id": {
      "kind": "youtube#video",
      "videoId": "9xA0yL0KXkU"
    },
    "snippet": {
      "publishedAt": "2022-05-11T04:00:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Dumbbell Glute Workout - Iron Glutes | 28",
      "description": "Zoning in on the glutes today with a series of exercises to promote the building of strength and muscle within the glutes, adductors ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9xA0yL0KXkU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9xA0yL0KXkU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9xA0yL0KXkU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-11T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vBj1VIefm261bGx_USQNgurwoKA",
    "id": {
      "kind": "youtube#video",
      "videoId": "PLSVz456neY"
    },
    "snippet": {
      "publishedAt": "2020-06-04T15:59:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN GLUTE WORKOUT | Grow Your Glutes at Home with Band &amp; Dumbbell",
      "description": "Hello everyone! This glute workout is targeting the glutes whilst also working the legs and core throughout. This workout is more ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PLSVz456neY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PLSVz456neY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PLSVz456neY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-04T15:59:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "72LcKQJ5OTAHt_3eLRyukEQFCm0",
    "id": {
      "kind": "youtube#video",
      "videoId": "-2DkbkIyq5c"
    },
    "snippet": {
      "publishedAt": "2021-06-22T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "40 MINUTE UPPER BODY WORKOUT - Arms, Shoulders, Chest, Back | Complex Series - Day 2",
      "description": "Shoulders! Back! Chest! Arms! Core! A demanding 40 minute upper body workout using primarily dumbbells for resistance to build ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-2DkbkIyq5c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-2DkbkIyq5c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-2DkbkIyq5c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-22T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YKFh-ubTVCXTQe8YZgdm7WoS9vM",
    "id": {
      "kind": "youtube#video",
      "videoId": "fMOqRNJSUkE"
    },
    "snippet": {
      "publishedAt": "2021-10-27T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BIGGER Back and Bicep Workout - Unilateral Training | EPIC III Day 38",
      "description": "Rows, sweeps and curls for the lats, rhomboids, biceps and even the rear delts! The upper body muscles of the back & biceps be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fMOqRNJSUkE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fMOqRNJSUkE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fMOqRNJSUkE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-27T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rwKxbiEJkZMR_Ck2IeLkbztmbgU",
    "id": {
      "kind": "youtube#video",
      "videoId": "zrO1Z0Z3WGA"
    },
    "snippet": {
      "publishedAt": "2021-01-06T05:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Cardio Dumbbell Full Body Workout / Core Focus | EPIC II - DAY 4",
      "description": "Time to hit every muscle in the body! Abs and core to begin before moving into lots of hybrid and full body exercises all requiring ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zrO1Z0Z3WGA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zrO1Z0Z3WGA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zrO1Z0Z3WGA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-06T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ecGNT-41pxsL1dvk873VSD8tp2A",
    "id": {
      "kind": "youtube#video",
      "videoId": "b7uDhHZ5qps"
    },
    "snippet": {
      "publishedAt": "2020-09-11T08:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour FULL BODY DUMBBELL WORKOUT at Home | Caroline Girvan",
      "description": "This 1 hour full body dumbbell workout will hit every single muscle in your body to help make you stronger, build muscle and burn ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/b7uDhHZ5qps/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/b7uDhHZ5qps/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/b7uDhHZ5qps/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-11T08:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1D0zatMgss2U7ZhczpbryH0_5yE",
    "id": {
      "kind": "youtube#video",
      "videoId": "P176RbZhzj0"
    },
    "snippet": {
      "publishedAt": "2021-09-15T04:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UPPER BODY DESTROYER Workout - Shoulders, Chest, Triceps | EPIC III Day 8",
      "description": "Shoulders, chest and tricep supersets so you know it's going to be fun! Shoulder press, tricep press, chest press\u2026 lots of pressing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/P176RbZhzj0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/P176RbZhzj0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/P176RbZhzj0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-15T04:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OBU1IxbuPDWmaHvUNZebx7C8Dzg",
    "id": {
      "kind": "youtube#video",
      "videoId": "ScInpT_5dIQ"
    },
    "snippet": {
      "publishedAt": "2021-11-19T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min MIGHTY Full Body Workout - Dumbbells | Caroline Girvan",
      "description": "A full body workout hitting all of the major muscle groups in the upper body, and using static lunges as a staple to target the entire ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ScInpT_5dIQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ScInpT_5dIQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ScInpT_5dIQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-19T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uEcf57nxpnSCLoue8Po9NjlgeLs",
    "id": {
      "kind": "youtube#video",
      "videoId": "tM0ldJbDBpU"
    },
    "snippet": {
      "publishedAt": "2020-07-12T08:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min EXPLOSIVE FULL BODY HIIT WORKOUT at Home | No Equipment",
      "description": "30 minutes only? Yes! 30 minutes and I can almost guarantee you won't be doing anymore training after this full body workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tM0ldJbDBpU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tM0ldJbDBpU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tM0ldJbDBpU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-12T08:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mM8GOQeYvoofS8fsNpVHBzgzix0",
    "id": {
      "kind": "youtube#video",
      "videoId": "hGaYKeFecM0"
    },
    "snippet": {
      "publishedAt": "2021-09-06T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "EPIC LEG DAY - Dumbbell Leg Workout | EPIC III Program Day 1",
      "description": "EPIC LEG DAY let's go!!! It's all in today's session! The quads! Hamstrings! Glutes! 40 minutes of tension on the lower body!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hGaYKeFecM0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hGaYKeFecM0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hGaYKeFecM0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-06T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ShFEXHg4Zm_q91Pp6dsJ-KoFi0k",
    "id": {
      "kind": "youtube#video",
      "videoId": "jqUGUYpxQ7U"
    },
    "snippet": {
      "publishedAt": "2021-07-18T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "THRILLING 30 MIN HIIT WORKOUT at Home | No Equipment + No Repeat",
      "description": "Fast paced! Full body workout! High intensity! Ready to challenge your body and mind with this 30 minute HIIT workout! All you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jqUGUYpxQ7U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jqUGUYpxQ7U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jqUGUYpxQ7U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-18T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uHLm0ApD1kuf8ms4VhLMxjqR6Ow",
    "id": {
      "kind": "youtube#video",
      "videoId": "BTAxkaYHaRU"
    },
    "snippet": {
      "publishedAt": "2022-02-02T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GAINFUL Glutes and Core Workout - Dumbbells | EPIC Endgame Day 23",
      "description": "Giant sets, circuits, compound & isolation, dumbbells and bodyweight! It's all combined in this lower body workout targeting the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BTAxkaYHaRU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BTAxkaYHaRU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BTAxkaYHaRU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-02T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5pxOsfEPr9KDKs2Iuc2xd1vN19o",
    "id": {
      "kind": "youtube#video",
      "videoId": "6tIoUPpqurg"
    },
    "snippet": {
      "publishedAt": "2021-07-19T04:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE LEG DAY - Intense Leg Workout with Dumbbells | Day 1",
      "description": "Yes! It's Beastmode LEG DAY! Focusing on the quads however hamstrings and glutes will be involved throughout! For this lower ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6tIoUPpqurg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6tIoUPpqurg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6tIoUPpqurg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-19T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HR96f-IvE0hft6YkQ6GvACRKeYE",
    "id": {
      "kind": "youtube#video",
      "videoId": "DzAbMCpu1yo"
    },
    "snippet": {
      "publishedAt": "2020-08-21T08:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 HOUR INTENSE FULL BODY WORKOUT | Low Impact &amp; No Equipment",
      "description": "This full body workout is a perfect workout for advanced to beginners - everyone!! A complete range different exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DzAbMCpu1yo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DzAbMCpu1yo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DzAbMCpu1yo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-21T08:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1edS0OtoO_YNaHuxa9AA1KP2Fp8",
    "id": {
      "kind": "youtube#video",
      "videoId": "n1G4uzmbnEo"
    },
    "snippet": {
      "publishedAt": "2022-02-11T05:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FORTIFIED Full Body Workout - Strength &amp; Cardio | EPIC Endgame Day 29",
      "description": "Resistance training to promote muscle building in every major muscle group with some cardio to increase that heart rate!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/n1G4uzmbnEo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/n1G4uzmbnEo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/n1G4uzmbnEo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-11T05:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "i4E6RMNr4A3jyAPJdgwGXoMxKjs",
    "id": {
      "kind": "youtube#video",
      "videoId": "c_-v1fYJGO8"
    },
    "snippet": {
      "publishedAt": "2020-09-21T08:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 1 of EPIC | Bodyweight &amp; Dumbbell Lower Body Workout",
      "description": "Here we go! Day 1 in the EPIC Program and what better way to begin than leg day with this lower body workout! This is a 40 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c_-v1fYJGO8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c_-v1fYJGO8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c_-v1fYJGO8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-21T08:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zSeejWv1Q06sZGvR6qgcHf3ZGGg",
    "id": {
      "kind": "youtube#video",
      "videoId": "Su2lsHRzOJM"
    },
    "snippet": {
      "publishedAt": "2021-11-03T04:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DROP SET DESTROYER Leg Workout - Dumbbells | EPIC III Day 43",
      "description": "Here we go!! Drop sets format for leg day!! The quads, hamstrings, calves and glutes are all in on the action today! Action packed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Su2lsHRzOJM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Su2lsHRzOJM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Su2lsHRzOJM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-03T04:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "97G6rn6Ycy8wrwnKBnYVRMh3naM",
    "id": {
      "kind": "youtube#video",
      "videoId": "wj79DgaiG8E"
    },
    "snippet": {
      "publishedAt": "2021-03-01T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SWEATY Circuits - Dumbbells / Bodyweight Full Body Workout | EPIC II - Day 42",
      "description": "RDLs, Bulgarian lunges, Maker, Snatch.. just a few of our favourites moves in this full body circuit to hit your full body with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wj79DgaiG8E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wj79DgaiG8E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wj79DgaiG8E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-01T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MJcPZE-PRGCum1m943OXpb4h8Qc",
    "id": {
      "kind": "youtube#video",
      "videoId": "kZDvg92tTMc"
    },
    "snippet": {
      "publishedAt": "2020-12-09T18:04:44Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 MIN PUMPING CARDIO WORKOUT | Full Body - No Equipment at Home",
      "description": "Let's burn some energy with this 30 minute cardio full body workout... no equipment needed. Simply follow along and have fun!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kZDvg92tTMc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kZDvg92tTMc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kZDvg92tTMc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-09T18:04:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "stTfYglmzQYXSl228YXJvJTb28c",
    "id": {
      "kind": "youtube#video",
      "videoId": "fkQq8A_zrgU"
    },
    "snippet": {
      "publishedAt": "2021-02-05T05:00:17Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour Dumbbell Full Body CARDIO Complex Workout | EPIC II - Day 25",
      "description": "60 minutes of short complexes in this dumbbell full body workout to work every muscle group in the body, combined with cardio to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fkQq8A_zrgU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fkQq8A_zrgU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fkQq8A_zrgU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-05T05:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MAKSZLUajuogyLSuvT6ZW8US6Mc",
    "id": {
      "kind": "youtube#video",
      "videoId": "VYR0qdcQOlc"
    },
    "snippet": {
      "publishedAt": "2021-11-10T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GIANT Sets Upper Body Workout with Dumbbells - CORE | EPIC III Day 48",
      "description": "A full upper body workout with giant sets to target the shoulders, chest, triceps, back, biceps and core! A few partial reps as always ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VYR0qdcQOlc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VYR0qdcQOlc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VYR0qdcQOlc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-10T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7pPjxVTMqhaSabSX5YTlp2CXR9E",
    "id": {
      "kind": "youtube#video",
      "videoId": "DAT-AnmG_sk"
    },
    "snippet": {
      "publishedAt": "2022-01-31T05:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "QUALITY Quads &amp; Calves - Dumbbell Leg Workout | EPIC Endgame Day 21",
      "description": "Let's slow things down and embrace that feeling of the quadriceps lifting and lowering you as we work through compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DAT-AnmG_sk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DAT-AnmG_sk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DAT-AnmG_sk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-31T05:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5usQz2jN5aHq6BWQ0xA8tjPhBfQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "FGtEYf1wefA"
    },
    "snippet": {
      "publishedAt": "2021-10-06T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SLOW &#39;N INTENSE Hamstrings &amp; Glute Workout | EPIC III Day 23",
      "description": "The glutes and hamstrings will be intensely activated, isolated and engaged throughout this workout through exercises such as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FGtEYf1wefA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FGtEYf1wefA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FGtEYf1wefA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-06T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fP9pRtM-fE5yl9u4KQf7QmQs5u0",
    "id": {
      "kind": "youtube#video",
      "videoId": "z3h_3UOBAVU"
    },
    "snippet": {
      "publishedAt": "2021-05-23T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "NOTORIOUS No Repeat Full Body HIIT Workout / No Equipment + No Jumping Options | EPIC Heat - Day 35",
      "description": "40 exercises, this 30 minute NO REPEAT HIIT workout is packed with some of my favourites for a super high intensity workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/z3h_3UOBAVU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/z3h_3UOBAVU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/z3h_3UOBAVU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-23T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yBmGMubihaJMoWCiRAT5Er2XC4Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "cLoYpI8HAI4"
    },
    "snippet": {
      "publishedAt": "2021-02-21T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "THE DROP HIIT Cardio Full Body Workout | EPIC II - Day 36",
      "description": "High intensity, followed by low intensity cardio session! HIIT followed by a more steady state cardio to work your entire body, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cLoYpI8HAI4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cLoYpI8HAI4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cLoYpI8HAI4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-21T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Qn-hzkzOluxVlUVZ8k-sfEcpNjw",
    "id": {
      "kind": "youtube#video",
      "videoId": "wNXkyrHPAIE"
    },
    "snippet": {
      "publishedAt": "2020-06-23T12:25:53Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "40 Min ADVANCED ABS &amp; GLUTES WORKOUT | with Ankle Weights (Optional)",
      "description": "This is a 40 minute advanced glutes and abs workout you can follow along to at home, solely focusing on the glutes and core!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wNXkyrHPAIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wNXkyrHPAIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wNXkyrHPAIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-23T12:25:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mgVr6W93b2xbyz1PyPuyniiR8cQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ob9q89SMfpA"
    },
    "snippet": {
      "publishedAt": "2021-02-26T05:00:09Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FULL THROTTLE Full Body Dumbbell Workout / Cardio | EPIC II - Day 40",
      "description": "A fully challenging, full body dumbbell workout that will test your fitness level, strength and determination! Burning a lot of energy ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ob9q89SMfpA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ob9q89SMfpA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ob9q89SMfpA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-26T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qM7QfewORdyD_5zy-rXF-Zgalh4",
    "id": {
      "kind": "youtube#video",
      "videoId": "hoQIGdCHilM"
    },
    "snippet": {
      "publishedAt": "2022-02-09T05:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BOSSED Back, Biceps and Abs Workout | EPIC Endgame Day 28",
      "description": "Ready for a serious back workout? It might appear there isn't a lot of bicep exercises but yet the biceps will have been working ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hoQIGdCHilM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hoQIGdCHilM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hoQIGdCHilM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-09T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6oznbYZcEgjXa0C7iN5vmjlS2wk",
    "id": {
      "kind": "youtube#video",
      "videoId": "ibq_LYl-mX4"
    },
    "snippet": {
      "publishedAt": "2021-12-12T05:00:14Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute SWEATY Dumbbell Cardio Workout - Full Body at Home",
      "description": "Muscular endurance! Core stability! Stamina! Body awareness! Controlling of the dumbbell! Lots of energy! That's what you'll need ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ibq_LYl-mX4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ibq_LYl-mX4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ibq_LYl-mX4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-12T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CoTW7EtIe2g7taizli1077H3vFU",
    "id": {
      "kind": "youtube#video",
      "videoId": "lPfkhDM9AYU"
    },
    "snippet": {
      "publishedAt": "2021-07-21T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE HAMSTRINGS AND GLUTES - Intense Lower Body Workout | Day 3",
      "description": "Hamstrings and glutes workout, packed with hip thrusts, sumo squats and Romanian deadlifts! 4 sets of each exercise with a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lPfkhDM9AYU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lPfkhDM9AYU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lPfkhDM9AYU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-21T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WSzoMjGOh9k_kVWTaIZtrG2fxLs",
    "id": {
      "kind": "youtube#video",
      "videoId": "E2lCbbe45iQ"
    },
    "snippet": {
      "publishedAt": "2021-01-18T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Hamstring &amp; GLUTE Isolation Workout / Dumbbells | EPIC II - Day 12",
      "description": "Let's isolate, activate and engage the glutes and hamstrings! This workout is really demand a lot from the glutes in particular... but ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E2lCbbe45iQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E2lCbbe45iQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E2lCbbe45iQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-18T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q02DlsZQv3DtD_2ztt61kNLkPdc",
    "id": {
      "kind": "youtube#video",
      "videoId": "6DQLwfyp6yY"
    },
    "snippet": {
      "publishedAt": "2022-03-07T05:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "UNBREAKABLE Upper Body Workout - Shoulders, Chest &amp; Triceps | EPIC Endgame Day 46",
      "description": "An upper body workout focusing primarily on those shoulders! Flyes will be the exercise directed at the chest with skullcrushers on ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6DQLwfyp6yY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6DQLwfyp6yY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6DQLwfyp6yY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-07T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TpC-436r2jWZnYfCvfskIj63qSg",
    "id": {
      "kind": "youtube#video",
      "videoId": "-GvfP5sAd7s"
    },
    "snippet": {
      "publishedAt": "2021-05-17T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "TORCHED Lower Body Workout - Legs &amp; Glutes | EPIC Heat - Day 31",
      "description": "Glutes, hamstrings, quads in complex format! Be prepared to feel every muscle in your lower body during this 30 minute intense ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-GvfP5sAd7s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-GvfP5sAd7s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-GvfP5sAd7s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-17T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zKHNDApFhcSUJDpxPuBzJOc6gNs",
    "id": {
      "kind": "youtube#video",
      "videoId": "I9LGlZkHL3o"
    },
    "snippet": {
      "publishedAt": "2021-01-15T05:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "POWERFUL Full Body workout with Dumbbells | EPIC II - Day 10",
      "description": "My favourite 1 hour dumbbell workout so far! Strength, power, endurance, core strength, coordination, balance and ability to push ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I9LGlZkHL3o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I9LGlZkHL3o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I9LGlZkHL3o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-15T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2bziq9Mtg_JJbIr8NSpTmkn3HBM",
    "id": {
      "kind": "youtube#video",
      "videoId": "7VeZdb2eDkA"
    },
    "snippet": {
      "publishedAt": "2021-08-17T04:00:04Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BRUTAL LEG DAY - Dumbbell Leg Workout | Tempo Week Day 2",
      "description": "TEMPO LEG DAY\u2026 we will turn up the heat in this workout! Every muscle in the lower body will be worked today! Slow pace, full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7VeZdb2eDkA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7VeZdb2eDkA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7VeZdb2eDkA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-17T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jqWmjV_1groE2SgsRuJJpowNG_k",
    "id": {
      "kind": "youtube#video",
      "videoId": "QMNyn2n2vAM"
    },
    "snippet": {
      "publishedAt": "2021-11-01T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ENGAGING Glutes and Core Workout - Dumbbells | EPIC III Day 41",
      "description": "Ready to isolate, activate and engage the entire glutes and core? 3 minute complexes to increase the reps yet allow for you to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QMNyn2n2vAM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QMNyn2n2vAM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QMNyn2n2vAM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-01T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2jt_k8rx2ngmkQ-lNn_s61FzZAU",
    "id": {
      "kind": "youtube#video",
      "videoId": "iNCDSjmx9WE"
    },
    "snippet": {
      "publishedAt": "2021-08-16T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SHOULDERS, CHEST &amp; TRICEPS Workout - Dumbbells | Tempo Week Day 1",
      "description": "Shoulders, triceps and chest are all challenged today! A combination of compound exercises to recruit various muscles and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iNCDSjmx9WE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iNCDSjmx9WE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iNCDSjmx9WE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-16T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1S4J9D1-MjQrHHNelTyDdPU1J4Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "hFMV7e16W38"
    },
    "snippet": {
      "publishedAt": "2021-08-09T04:00:11Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "PYRAMID LEG DAY - Dumbbell Leg Workout | Pyramid Series Day 1",
      "description": "Pyramid leg day! Let's hit it! The pace is slow. The focus on maintaining perfect form. We will work on hypertrophy in the entire ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hFMV7e16W38/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hFMV7e16W38/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hFMV7e16W38/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-09T04:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uDcWlBOl8SQiYol7Z8uAW8WH0Zc",
    "id": {
      "kind": "youtube#video",
      "videoId": "SkKmgnfzO90"
    },
    "snippet": {
      "publishedAt": "2021-10-01T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUPER Full Body Workout - Dumbbell Supersets | EPIC III Day 19",
      "description": "Supersets for a super session! All of the major muscle groups will be working very hard during this full body dumbbell workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SkKmgnfzO90/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SkKmgnfzO90/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SkKmgnfzO90/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-01T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kmheKFDS4-jqy3VCH3zMqhzXnOc",
    "id": {
      "kind": "youtube#video",
      "videoId": "PyN1atlMMSg"
    },
    "snippet": {
      "publishedAt": "2022-02-23T05:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "AMAZING Arms and Abs Workout with Dumbbells | EPIC Endgame Day 38",
      "description": "All the arms and abs!! This is going to be one of those workouts that you will feel the extra blood actually pumping to the arms!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PyN1atlMMSg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PyN1atlMMSg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PyN1atlMMSg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-23T05:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I7l36nFkpWL8qtemxOsi_jejH_8",
    "id": {
      "kind": "youtube#video",
      "videoId": "stX_DX9d2GA"
    },
    "snippet": {
      "publishedAt": "2022-01-21T05:00:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "FULL-ON Full Body Dumbbell Workout | EPIC Endgame Day 14",
      "description": "Every muscle in your body will be hit today! Trisets involving dumbbells and our own bodyweight to bring resistance training and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/stX_DX9d2GA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/stX_DX9d2GA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/stX_DX9d2GA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-21T05:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mJDOXCEH1NT5cwEHgSooOW4UDFk",
    "id": {
      "kind": "youtube#video",
      "videoId": "kO_b0D8P1Jg"
    },
    "snippet": {
      "publishedAt": "2021-12-06T05:00:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Superset Back Workout with Dumbbells | Caroline Girvan",
      "description": "A 20 minute BACK WORKOUT with dumbbells using supersets to target the back along with the core and rear delts! Supersets are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kO_b0D8P1Jg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kO_b0D8P1Jg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kO_b0D8P1Jg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-06T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MU9GxHmUMQj7j5aTqviEwd5Ldkc",
    "id": {
      "kind": "youtube#video",
      "videoId": "NcMnvT58ZAo"
    },
    "snippet": {
      "publishedAt": "2021-06-25T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 HOUR FULL BODY WORKOUT at Home - Dumbbells, Bodyweight | Complex Series - Day 4",
      "description": "Ready for one hour hitting the entire body? Quads, hamstrings, glutes, back, chest, shoulders, arms and core using dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NcMnvT58ZAo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NcMnvT58ZAo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NcMnvT58ZAo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-25T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2ZTzpFCfDostiyVG0rGnSWY2pM0",
    "id": {
      "kind": "youtube#video",
      "videoId": "jtVoxojcaLY"
    },
    "snippet": {
      "publishedAt": "2021-09-22T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DEMANDING Glutes and Hamstrings Workout / Knee Friendly | EPIC III Day 13",
      "description": "Hamstrings and glutes supersets involving hip thrusts, RDLs and elevated bridges! There are no squats or lunges in this workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jtVoxojcaLY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jtVoxojcaLY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jtVoxojcaLY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-22T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ecnBmaCDCaXbMCB9TlY4QUTDJwQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "pNqJ3ZAU178"
    },
    "snippet": {
      "publishedAt": "2021-09-20T04:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "QUIVERING QUADS &amp; CALVES Workout - Leg Day | EPIC III Day 11",
      "description": "It's leg day and we are focusing on the quads and calves! Oh yessss!!! The timer will be on for 50 seconds of work, straight into ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pNqJ3ZAU178/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pNqJ3ZAU178/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pNqJ3ZAU178/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-20T04:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WS-KUbpL-v9UJUyMMZaSrcALSdc",
    "id": {
      "kind": "youtube#video",
      "videoId": "X95VrEp4VRw"
    },
    "snippet": {
      "publishedAt": "2021-08-02T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALISTHENICS LEG WORKOUT - Bodyweight Only Leg Day | Day One",
      "description": "Leg day trisets!!! Using only our bodyweight as resistance\u2026 these 40 minutes will demand so much from your quads, hamstrings, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X95VrEp4VRw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X95VrEp4VRw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X95VrEp4VRw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-02T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Jh-3Ickkxq7zHfMvt_3y6smPwug",
    "id": {
      "kind": "youtube#video",
      "videoId": "ExfFozZMheI"
    },
    "snippet": {
      "publishedAt": "2020-06-29T16:45:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min DUMBBELL CHEST &amp; TRICEPS WORKOUT at Home",
      "description": "This triceps workout at home will focus on the backs of the arms but this is also a dumbbell chest workout to increase strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ExfFozZMheI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ExfFozZMheI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ExfFozZMheI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-29T16:45:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wt-ubnb8MhKqHUwJOyCdiHRgu4I",
    "id": {
      "kind": "youtube#video",
      "videoId": "ImI63BUUPwU"
    },
    "snippet": {
      "publishedAt": "2020-10-31T12:30:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min CARDIO WORKOUT at Home [LOW IMPACT STEADY STATE] LISS",
      "description": "A perfect 30 minute full body no repeat, low impact steady state cardio workout at home (LISS) that requires a continuous, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ImI63BUUPwU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ImI63BUUPwU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ImI63BUUPwU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-31T12:30:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OE3VI3hgvq160x2FREFihnb1BW8",
    "id": {
      "kind": "youtube#video",
      "videoId": "p9GPq3g5IDQ"
    },
    "snippet": {
      "publishedAt": "2021-05-13T04:00:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min CONSTANT CARDIO Workout at Home - No Repeat / No Jumping / Low Impact",
      "description": "No jumping! No repeat! Burn energy! Feel awesome! 30 minute low impact, steady cardio! This cardio workout is ideal for active ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/p9GPq3g5IDQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/p9GPq3g5IDQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/p9GPq3g5IDQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-13T04:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-0ezMh2B4SzsJ6KjQs0S3CKhPwI",
    "id": {
      "kind": "youtube#video",
      "videoId": "ld3x6ccLs2Q"
    },
    "snippet": {
      "publishedAt": "2020-12-24T03:14:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Caroline&#39;s CHRISTMAS CARDIO WORKOUT | 30 Minutes / No Equipment",
      "description": "Let's get sweaty, burn some energy, test your endurance, strengthen your core and feel ready for the holidays with this 30 min ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ld3x6ccLs2Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ld3x6ccLs2Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ld3x6ccLs2Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-24T03:14:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JFdpruVKX9U5IcoH5epKaP9LGZA",
    "id": {
      "kind": "youtube#video",
      "videoId": "ggsQtVbIpDw"
    },
    "snippet": {
      "publishedAt": "2020-11-29T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Day 50 of EPIC | Full Body HARDCORE HIIT WORKOUT Part III - FINALE",
      "description": "This is it... Day 50! You've one last challenge to see how far you've came! Let's see how good your form can be whilst your heart ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ggsQtVbIpDw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ggsQtVbIpDw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ggsQtVbIpDw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-29T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "A_-qWZdJCwvXiXH4XUhA1x_nwLY",
    "id": {
      "kind": "youtube#video",
      "videoId": "V_RtNkbsPBc"
    },
    "snippet": {
      "publishedAt": "2021-07-13T04:15:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 MIN TRICEP WORKOUT with Dumbbells - No Repeat | Caroline Girvan",
      "description": "Nothing but the triceps here! 15 minutes perfect as a finisher to any chest workout or simply on its own as a quick yet intense ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V_RtNkbsPBc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V_RtNkbsPBc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V_RtNkbsPBc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-13T04:15:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o0U1y0v7OmWF85RIYrX07iK59ks",
    "id": {
      "kind": "youtube#video",
      "videoId": "bJe02JUvl9s"
    },
    "snippet": {
      "publishedAt": "2022-03-11T05:00:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "SUPERSET Full Body Workout with Dumbbells | EPIC Endgame Day 49",
      "description": "Squats, RDLs, rows, presses.. all within supersets! This is our kind of workout! Structured, all major muscle groups, combination of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bJe02JUvl9s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bJe02JUvl9s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bJe02JUvl9s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-11T05:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OwJ3A_wsHUD9Xh6dXrGuTKBgFIQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "s2K__b6aCiQ"
    },
    "snippet": {
      "publishedAt": "2022-01-05T05:00:31Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "ONLY THE GLUTES Workout with Dumbbells | EPIC Endgame Day 3",
      "description": "Aiming to target predominantly those glutes! The glutes are the largest muscle group we have and we use these muscles to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s2K__b6aCiQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s2K__b6aCiQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s2K__b6aCiQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-05T05:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iC7sqIGOdVT2NMLLo8uqv9D_Ahs",
    "id": {
      "kind": "youtube#video",
      "videoId": "tWjBnQX3if0"
    },
    "snippet": {
      "publishedAt": "2020-04-30T14:51:49Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "5 Minute Push Ups Workout at Home",
      "description": "5 minute push ups | follow along | chest & tricep workout Hello everyone! Pushups... my favourite!!! \u2764\ufe0f They are one of best ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tWjBnQX3if0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tWjBnQX3if0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tWjBnQX3if0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-30T14:51:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hFkBE5vFYB-wYW3MYIoo0Gf0iPw",
    "id": {
      "kind": "youtube#video",
      "videoId": "ujvMfmP7GgU"
    },
    "snippet": {
      "publishedAt": "2021-07-30T04:00:18Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE FULL BODY - Strength &amp; Sweat Dumbbell Cardio Workout | Day 9",
      "description": "Ready for a full-on full body sweat session to leave you sweaty yet feeling so happy to have completed such a demanding ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ujvMfmP7GgU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ujvMfmP7GgU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ujvMfmP7GgU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-30T04:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6DycV2YsIcVxH3tuooWsrhA3YIM",
    "id": {
      "kind": "youtube#video",
      "videoId": "ret7vpmnWyM"
    },
    "snippet": {
      "publishedAt": "2021-11-09T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GIANT Lower Body Workout - Glutes, Quads, Hamstrings | EPIC III Day 47",
      "description": "The hamstrings, glutes & quads hopefully are ready for an awesome workout!! An action packed workout combining compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ret7vpmnWyM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ret7vpmnWyM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ret7vpmnWyM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-09T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "H0xVZx6JanYahmj0JaOy3Udalvk",
    "id": {
      "kind": "youtube#video",
      "videoId": "qSvZVwvQkmw"
    },
    "snippet": {
      "publishedAt": "2020-04-05T14:19:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "AT HOME GLUTE WORKOUT BOOTY BAND",
      "description": "Hello everyone! Today I'm showing you a great workout to hit gluteal muscles, thighs, core and hamstrings. This is follow along all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qSvZVwvQkmw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qSvZVwvQkmw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qSvZVwvQkmw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-05T14:19:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P-jLo502hFPdV9i2BIH_7DcjMWw",
    "id": {
      "kind": "youtube#video",
      "videoId": "6-JcYiGYgrI"
    },
    "snippet": {
      "publishedAt": "2022-03-02T05:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "GROWING Glutes and Hamstrings Workout | EPIC Endgame Day 43",
      "description": "A very intense workout building and strengthening the glutes and hamstrings\u2026 ready? Compound movements to get as much ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6-JcYiGYgrI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6-JcYiGYgrI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6-JcYiGYgrI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-02T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N1wXXd3Fy7KiHZNj1-DSQYEFTp4",
    "id": {
      "kind": "youtube#video",
      "videoId": "caF0YqFU3Rg"
    },
    "snippet": {
      "publishedAt": "2020-07-09T07:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min BACK &amp; SHOULDER WORKOUT | Strong &amp; Lean Series Day 4",
      "description": "Welcome to Day 4 of the Strong & Lean at Home Series! This is day 4 of a 5 day Series focusing on strength using only your own ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/caF0YqFU3Rg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/caF0YqFU3Rg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/caF0YqFU3Rg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-09T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IsnYqizdsC0ukQjAxu8tWFjjQ1I",
    "id": {
      "kind": "youtube#video",
      "videoId": "j5VPV_f03O4"
    },
    "snippet": {
      "publishedAt": "2021-06-21T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "LEG DAY Workout with Dumbbells | Complex Series - Day 1",
      "description": "Leg day! Let's do this!! Dumbbell & bodyweight combined for a challenge! Complexes to work through at your own pace!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j5VPV_f03O4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j5VPV_f03O4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j5VPV_f03O4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-21T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nTAcxzZamksLlA1ZNcgPcFQqc3k",
    "id": {
      "kind": "youtube#video",
      "videoId": "rtoEW_7klSw"
    },
    "snippet": {
      "publishedAt": "2021-06-23T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "40 MINUTE GLUTE WORKOUT - Dumbbell + Band | Complex Series - Day 3",
      "description": "Glutes, glutes and more glutes!! 'Mind to the muscle connection' will be the focus in this 40 minute glute workout, performing each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rtoEW_7klSw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rtoEW_7klSw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rtoEW_7klSw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-23T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AA8KNJtCvlbZ0bW8V56dMl_cBoo",
    "id": {
      "kind": "youtube#video",
      "videoId": "qdYGi4XsoE8"
    },
    "snippet": {
      "publishedAt": "2020-12-04T00:18:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 MIN KNEE FRIENDLY LEG WORKOUT with Dumbbells | NO JUMPING",
      "description": "20 min knee friendly LEG WORKOUT targeting the areas surrounding the knee: hamstrings, glutes, quads and calves. This 20 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qdYGi4XsoE8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qdYGi4XsoE8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qdYGi4XsoE8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-04T00:18:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NwqvIv_-gzF0Nbji2W01HmCBdiA",
    "id": {
      "kind": "youtube#video",
      "videoId": "8zejwPNp7dQ"
    },
    "snippet": {
      "publishedAt": "2020-04-22T18:52:29Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1000 Squat Challenge | at Home 1000 Bodyweight Squats Workout",
      "description": "1000 rep bodyweight squat challenge Hello everyone! Firstly, I want to say this is a challenge, and whether you are aiming for the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8zejwPNp7dQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8zejwPNp7dQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8zejwPNp7dQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-22T18:52:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Mv6h3f6wMsVvJ4wH7od5OjcFboA",
    "id": {
      "kind": "youtube#video",
      "videoId": "ckOcS33roeA"
    },
    "snippet": {
      "publishedAt": "2021-02-15T05:00:21Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Lower Body Workout - Calves, Hamstrings, Glutes &amp; Quads | EPIC II - Day 32",
      "description": "Lunges and calves leg day!! The entire lower body will be working hard in this superset session! Hamstrings, glutes, quads and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ckOcS33roeA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ckOcS33roeA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ckOcS33roeA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-15T05:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DSbf_Dt4381IDUnGvT0l-Tqir9U",
    "id": {
      "kind": "youtube#video",
      "videoId": "UEWEYeJGkLM"
    },
    "snippet": {
      "publishedAt": "2021-07-27T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "BEASTMODE LOWER BODY WORKOUT - Legs, Quads, Hamstrings, Glutes | Day 7",
      "description": "Lower body session with scheduled rest periods and reps to support hypertrophy\u2026.let's put the work in! Demanding a lot from the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UEWEYeJGkLM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UEWEYeJGkLM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UEWEYeJGkLM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-27T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XKD3FpyCG0V5p2tHtWRoY2XVPRQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "x41HT2pd83M"
    },
    "snippet": {
      "publishedAt": "2020-07-06T09:19:47Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Min QUAD FOCUSED LEG WORKOUT | Strong &amp; Lean Series Day 1",
      "description": "Welcome to Day 1 of the Strong & Lean at Home Series! This is day 1 of a 5 day Series focusing on strength using only your own ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/x41HT2pd83M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/x41HT2pd83M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/x41HT2pd83M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-06T09:19:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I0FGjHaMO7OYdmFLHy-30Mt1pmQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "wAd2pu2N6Cs"
    },
    "snippet": {
      "publishedAt": "2021-05-24T19:57:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "WE ARE 1 MILLION WORKOUT || 30 Min Full Body - No Equipment, No Jumping, No Repeat, Calisthenics",
      "description": "How else could we celebrate 1 million of us together but a full body, bodyweight only intense workout! No repeat, no equipment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wAd2pu2N6Cs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wAd2pu2N6Cs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wAd2pu2N6Cs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-24T19:57:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-_7ORr5PofLzj1LtEvNLtZ5srg0",
    "id": {
      "kind": "youtube#video",
      "videoId": "d81BLEzBXOg"
    },
    "snippet": {
      "publishedAt": "2021-07-09T04:00:22Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 HOUR FULL BODY WORKOUT with Dumbbells - Strength &amp; Cardio | Circuit Series Day 4",
      "description": "Ready to put the work in? 60 minutes of strength and endurance, with emphasis on thinking about alignment of your entire body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/d81BLEzBXOg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/d81BLEzBXOg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/d81BLEzBXOg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-09T04:00:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JTaExaT-R8Z4gzHbjDNSl72AYEw",
    "id": {
      "kind": "youtube#video",
      "videoId": "w8ZaOs94_fg"
    },
    "snippet": {
      "publishedAt": "2021-08-08T04:00:16Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "HIIT WORKOUT - Bodyweight Only | Calisthenics Series Day 5",
      "description": "Here we go! 30 minutes of high intensity interval training to test your stamina, speed, coordination and overall athleticism!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/w8ZaOs94_fg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/w8ZaOs94_fg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/w8ZaOs94_fg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-08T04:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wNYZSRiqD7boIOLtwSlSamtXW7s",
    "id": {
      "kind": "youtube#video",
      "videoId": "e8XBtrv92fw"
    },
    "snippet": {
      "publishedAt": "2020-07-31T09:08:29Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour DUMBBELL FULL BODY WORKOUT at Home | No Jumping",
      "description": "This is a 1 hour full body dumbbell workout! No jumping! Low impact! Using only light dumbbells or filled water bottles as ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/e8XBtrv92fw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/e8XBtrv92fw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/e8XBtrv92fw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-31T09:08:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bkYOlBfh568i9Qb4Uba9_nh_9dE",
    "id": {
      "kind": "youtube#video",
      "videoId": "UjUkBJyqo9o"
    },
    "snippet": {
      "publishedAt": "2021-06-28T04:00:06Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "45 Minute Unilateral Leg Workout at Home with Dumbbells | Caroline Girvan",
      "description": "For this leg session, you will need a pair of dumbbells, a chair for Bulgarian lunges and step ups, a yoga block/thick book and a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UjUkBJyqo9o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UjUkBJyqo9o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UjUkBJyqo9o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-28T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sgk_DsIPSQQz_KwEEJwGiSY_XjA",
    "id": {
      "kind": "youtube#video",
      "videoId": "X-YEZGZ0XMM"
    },
    "snippet": {
      "publishedAt": "2021-08-03T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALISTHENICS UPPER BODY &amp; CORE WORKOUT - Bodyweight Only | Day Two",
      "description": "The shoulders, chest, back and arms all working along with the core to make this a demanding bodyweight workout indeed!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X-YEZGZ0XMM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X-YEZGZ0XMM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X-YEZGZ0XMM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-03T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HoYX5tWL3Duj3WcQtQ5X2LHa4OY",
    "id": {
      "kind": "youtube#video",
      "videoId": "lBq4TwynUuY"
    },
    "snippet": {
      "publishedAt": "2020-05-07T12:33:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DUMBBELL LEGS AND GLUTES WORKOUT | 14 Minutes Follow Along Fitness Channel",
      "description": "Dumbbell Legs and Glutes Workout | 14 Minutes | Follow Along Hello everyone! The gluteus maximus is the largest muscle in the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lBq4TwynUuY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lBq4TwynUuY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lBq4TwynUuY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-07T12:33:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PTOsnNYjmIjlpCkU_rb1q6DR_oc",
    "id": {
      "kind": "youtube#video",
      "videoId": "AhxDtySwlZo"
    },
    "snippet": {
      "publishedAt": "2020-07-24T08:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour FULL BODY WORKOUT at Home | Low Impact &amp; No Jumping",
      "description": "This is a 1 hour full body workout at home with no equipment, no jumping, low impact and apartment friendly. Using our own ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AhxDtySwlZo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AhxDtySwlZo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AhxDtySwlZo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-24T08:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6iNydbf2XWGA9t3VIYz16LBJPGQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "4MTgA7veAs0"
    },
    "snippet": {
      "publishedAt": "2021-04-04T07:46:27Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "HIIT The Glutes - High Intensity Glute Workout - No Equipment",
      "description": "HIIT the glutes! A fun take on HIIT! We will be completing movements involving the entire lower body, primarily the glutes however ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4MTgA7veAs0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4MTgA7veAs0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4MTgA7veAs0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-04T07:46:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Rerica9ydfCKmAfQvfnQ9OwRaRM",
    "id": {
      "kind": "youtube#video",
      "videoId": "jBhZWX91bec"
    },
    "snippet": {
      "publishedAt": "2020-05-27T12:17:32Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 Minute Dumbbell Chest Workout at Home",
      "description": "New here? I am currently uploading daily workouts. Never miss a video by Subscribing to my Channel here: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jBhZWX91bec/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jBhZWX91bec/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jBhZWX91bec/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-27T12:17:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZYxxJd4Xm7dUf1Kq5uNmkKfjrNQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "WUzdhcwT8uM"
    },
    "snippet": {
      "publishedAt": "2020-09-21T07:30:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "DAY 1 of Beginner EPIC | No Equipment Lower Body Workout",
      "description": "Here we go! Day 1 in the EPIC Beginners Series for those of you wanting to progress to the full EPIC Program! We are starting ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WUzdhcwT8uM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WUzdhcwT8uM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WUzdhcwT8uM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-21T07:30:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9f4CYIEO_qRo2biLB7GPnuIArE4",
    "id": {
      "kind": "youtube#video",
      "videoId": "8Mzm52VdXkM"
    },
    "snippet": {
      "publishedAt": "2021-03-14T05:00:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min HIGH KNEES Full Body HIIT Workout - No Equipment",
      "description": "30 minutes of hard work then you can relax! Time will pass really quickly so give it your all! Intervals of 30 seconds with 15 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8Mzm52VdXkM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8Mzm52VdXkM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8Mzm52VdXkM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-14T05:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hP-g-Kgz8EqPWIzVtXovQwn5fko",
    "id": {
      "kind": "youtube#video",
      "videoId": "lIyMZBgUcz8"
    },
    "snippet": {
      "publishedAt": "2020-06-19T08:02:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour STRENGTH &amp; STRETCH WORKOUT at Home | Day Five of Five",
      "description": "Welcome to the last day, day 5 of this 5 day 1 hour at Home Workout Series! Last in the series is an hour stretching workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lIyMZBgUcz8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lIyMZBgUcz8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lIyMZBgUcz8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-19T08:02:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZWTH8X4wAcbBIyrZJMEgU3-Xviw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Z42K5PmpH7k"
    },
    "snippet": {
      "publishedAt": "2021-07-05T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "LEG DAY WORKOUT with Dumbbells - Strong Legs | Circuit Series Day 1",
      "description": "So many of our favourite exercises in today's leg workout! We will strengthen the quads, hamstrings and glutes as we perform a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Z42K5PmpH7k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Z42K5PmpH7k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Z42K5PmpH7k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-05T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NFojyldFrQKZCmKkV6ANo0xRXI4",
    "id": {
      "kind": "youtube#video",
      "videoId": "s9l-32mIVY4"
    },
    "snippet": {
      "publishedAt": "2020-08-30T08:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "MAXIMUM HIIT WORKOUT | 30 Min Full Body - No Equipment at Home",
      "description": "I have called this HIIT 'maximum' for a reason... I was pushed to the max during the lunges and push up! I was very glad to reach ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s9l-32mIVY4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s9l-32mIVY4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s9l-32mIVY4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-30T08:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Y3KmI8logtZBLh8u2zqy2F9IOwo",
    "id": {
      "kind": "youtube#video",
      "videoId": "cj1hnHOJdNI"
    },
    "snippet": {
      "publishedAt": "2021-08-04T04:00:10Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALISTHENICS GLUTES &amp; HAMSTRINGS WORKOUT - Bodyweight Only | Day Three",
      "description": "Bodyweight glutes & hamstrings! Sounds easy option? This workout is not! If you are able to really connect with the glute muscles ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cj1hnHOJdNI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cj1hnHOJdNI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cj1hnHOJdNI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-04T04:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rB7Nsd2jRCKobB8OB2PYEChM8jA",
    "id": {
      "kind": "youtube#video",
      "videoId": "BHNtFY-MSnU"
    },
    "snippet": {
      "publishedAt": "2020-07-17T09:56:53Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour FULL BODY WORKOUT at Home | No Jumping, No Equipment, No Repeat",
      "description": "This is a 1 hour full body workout! No repeat! No equipment! No jumping! Let's smash this! This real time workout is a combination ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BHNtFY-MSnU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BHNtFY-MSnU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BHNtFY-MSnU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-17T09:56:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MltYpBLc50XwNe_txq0EKMMjJVY",
    "id": {
      "kind": "youtube#video",
      "videoId": "FgqmrQ2rnfM"
    },
    "snippet": {
      "publishedAt": "2021-08-29T04:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "500 Rep Cardio Challenge - Full Bodyweight Workout | Pure Endure Day 6",
      "description": "500 rep cardio workout! 5 exercises, 100 reps each! It's all about moderate pace! Simple movements, each one performed strict ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FgqmrQ2rnfM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FgqmrQ2rnfM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FgqmrQ2rnfM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-29T04:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8oH9r9LKTU_KW5zRp2u62QPQZWQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "_fXF7LNpTrQ"
    },
    "snippet": {
      "publishedAt": "2020-07-05T08:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour HARDCORE FULL BODY HIIT WORKOUT at Home | No Equipment",
      "description": "Here we go everyone!! 60 minutes of full body workout fun!! This high intensity workout will test you mentally and physically!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_fXF7LNpTrQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_fXF7LNpTrQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_fXF7LNpTrQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-05T08:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nuneFNdFR9QJQSLwYc1hv4g4wJU",
    "id": {
      "kind": "youtube#video",
      "videoId": "_aZ1rMKECwA"
    },
    "snippet": {
      "publishedAt": "2021-08-27T04:00:12Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "500 REP Full Body Challenge - Total Body Workout | Pure Endure Day 5",
      "description": "500 reps, 10 exercises, legs, shoulders, back, chest, arms and abs! Let's hit our full body, build some muscle, become stronger ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_aZ1rMKECwA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_aZ1rMKECwA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_aZ1rMKECwA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-27T04:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "y7VyCHNlDC0oJB6wZ7XfPh1KjIU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hRpnK0JXY1g"
    },
    "snippet": {
      "publishedAt": "2020-06-17T11:16:35Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour GLUTES &amp; LOWER ABS WORKOUT at Home | Day Three of Five",
      "description": "Welcome to day 3 of the 5 day 1 hour at home workout series! Third in the series is glutes, hamstrings and lower abs workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hRpnK0JXY1g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hRpnK0JXY1g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hRpnK0JXY1g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-17T11:16:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "g4uEWJqVLo711LR3qyVRi8d3AXA",
    "id": {
      "kind": "youtube#video",
      "videoId": "QngVT1PPPew"
    },
    "snippet": {
      "publishedAt": "2020-06-16T08:33:35Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour UPPER BODY &amp; UPPER ABS WORKOUT at Home | Day Two of Five",
      "description": "Welcome to day 2 of the 5 day 1 hour at home workout series! Second in the series is upper body workout and upper abs workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QngVT1PPPew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QngVT1PPPew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QngVT1PPPew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-16T08:33:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gEkxeQl4Q3anZr_myZjmsR9uMiM",
    "id": {
      "kind": "youtube#video",
      "videoId": "RURSnhLTB5k"
    },
    "snippet": {
      "publishedAt": "2021-08-23T04:00:30Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1000 REP Lower Body Challenge - Leg &amp; Glutes Workout | Pure Endure Day 1",
      "description": "1000 REP LOWER BODY let's hit it! Legendary leg day alright! We will work together through these 10 exercises at a pace that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RURSnhLTB5k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RURSnhLTB5k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RURSnhLTB5k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-23T04:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RzBzb2SksTK_NTtVHpVMEFgkHD4",
    "id": {
      "kind": "youtube#video",
      "videoId": "VgbOCmWT34Q"
    },
    "snippet": {
      "publishedAt": "2020-08-14T08:00:05Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour FULL BODY WORKOUT at Home | No Repeat and Low Impact",
      "description": "This full body workout at home is full of different exercises and movements to work your entire body. No equipment is needed and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VgbOCmWT34Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VgbOCmWT34Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VgbOCmWT34Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-14T08:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BqEAguOQmVR9Lw45eRJ4XSdxNt8",
    "id": {
      "kind": "youtube#video",
      "videoId": "UZmKzVl5Few"
    },
    "snippet": {
      "publishedAt": "2021-11-26T03:45:01Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour Full Body Workout at Home - Calisthenics | NO EQUIPMENT, NO JUMPING",
      "description": "Here we go! 60 minutes! 30 supersets! All you need is your body as resistance! Easy right? The timer will be on for 50 seconds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UZmKzVl5Few/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UZmKzVl5Few/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UZmKzVl5Few/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-26T03:45:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sxFn8D38ewoh0szPImEQOn4hTW4",
    "id": {
      "kind": "youtube#video",
      "videoId": "esgCIwNyY_U"
    },
    "snippet": {
      "publishedAt": "2020-08-28T08:00:08Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour FULL BODY WORKOUT | No Equipment + No Jumping at Home",
      "description": "Let's work our entire body with this follow along workout using only our bodyweight as the resistance! This is a full body workout at ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/esgCIwNyY_U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/esgCIwNyY_U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/esgCIwNyY_U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-28T08:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TiFoEObjATm42_U-mUYk78olIQ4",
    "id": {
      "kind": "youtube#video",
      "videoId": "S-m7TY5nKo4"
    },
    "snippet": {
      "publishedAt": "2020-06-18T09:02:26Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "1 Hour ISOMETRIC FULL BODY WORKOUT at Home | Day Four of Five",
      "description": "Welcome to day 4 of the 5 day 1 hour at home workout series! Fourth in the series is an hour isometric workout. This at home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S-m7TY5nKo4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S-m7TY5nKo4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S-m7TY5nKo4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-18T09:02:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rMqTa70IVc9DzFsny2eKRLWsE9U",
    "id": {
      "kind": "youtube#video",
      "videoId": "a--_yI932uo"
    },
    "snippet": {
      "publishedAt": "2021-08-06T04:00:00Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "CALISTHENICS FULL BODY WORKOUT - Bodyweight Complexes | Day Four",
      "description": "It's day 4 in the Calisthenics Series and it's time for a full body workout! The format is one of our favourites\u2026. Complexes! We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a--_yI932uo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a--_yI932uo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a--_yI932uo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-06T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vTA_PuJIaeDc5_erLm54NQ2RthU",
    "id": {
      "kind": "youtube#video",
      "videoId": "Dx4CQVyZC2s"
    },
    "snippet": {
      "publishedAt": "2020-04-10T15:09:15Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "at Home SHOULDER WORKOUT",
      "description": "At home shoulder workout Hello everyone! Here is a super workout you can do at home and all you need is a chair and your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Dx4CQVyZC2s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Dx4CQVyZC2s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Dx4CQVyZC2s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-10T15:09:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bLzL2fth7q2p9VsSYqocBH5v-qQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "4BmNLWsjzi0"
    },
    "snippet": {
      "publishedAt": "2020-05-03T08:00:02Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "Dumbbell Cardio HIIT Workout | at Home",
      "description": "Dumbbell Cardio HIIT Workout | at Home | Under 25 Mins Hello everyone! I hope you all doing well and keeping positive! Today's ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4BmNLWsjzi0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4BmNLWsjzi0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4BmNLWsjzi0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-03T08:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iJ-rAfFwbG7Gr-H-Q43NgwrseIk",
    "id": {
      "kind": "youtube#video",
      "videoId": "MLW1CqdTbv0"
    },
    "snippet": {
      "publishedAt": "2021-08-25T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Minute Stability Ball Workout - Hamstrings &amp; Abs | Pure Endure Day 3",
      "description": "A 30 minute workout to demand so much from your hamstrings you will be looking forward to the abs portion! A great hamstring ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MLW1CqdTbv0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MLW1CqdTbv0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MLW1CqdTbv0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-25T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PF_r6BPVZBu62nyH5rU4PdttETY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Wxd5couUEM8"
    },
    "snippet": {
      "publishedAt": "2020-12-10T19:55:49Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "10 MIN BARBELL COMPLEX WORKOUT // with Dumbbell Alternative",
      "description": "Short and efficient full body dumbbell or barbell complex workout! 4 compound movements, allowing you to hit more muscles in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Wxd5couUEM8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Wxd5couUEM8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Wxd5couUEM8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-10T19:55:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-Oaj_lAMZMNJNR_n6uaJKuGsJyc",
    "id": {
      "kind": "youtube#video",
      "videoId": "qPtnAnH5pNc"
    },
    "snippet": {
      "publishedAt": "2020-06-20T18:15:20Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "30 Min FULL BODY STABILITY BALL WORKOUT at Home",
      "description": "Dust off your stability ball and let's get an awesome workout on hitting the hamstrings, glutes, core, abs and upper body!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qPtnAnH5pNc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qPtnAnH5pNc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qPtnAnH5pNc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-20T18:15:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "avTXeaM2gsKwih0PMlaQRrkkbc8",
    "id": {
      "kind": "youtube#video",
      "videoId": "jLTE4nZ1ocU"
    },
    "snippet": {
      "publishedAt": "2020-09-12T13:08:03Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "15 Min FULL BODY RESISTANCE BAND WORKOUT at Home",
      "description": "Let's work our full body with just that resistance band you have at home! This 15 minutes full body resistance band workout will hit ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jLTE4nZ1ocU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jLTE4nZ1ocU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jLTE4nZ1ocU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-12T13:08:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4M3WysWhK_Hw7x2gu5uHIsuQ_IM",
    "id": {
      "kind": "youtube#video",
      "videoId": "tf685ggJv9k"
    },
    "snippet": {
      "publishedAt": "2020-06-06T13:36:58Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "20 Minute Dumbbell Back and Biceps Workout at Home",
      "description": "Hello everyone! This dumbbell back workout at home is exactly what I would be doing in the gym to build strength and add ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tf685ggJv9k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tf685ggJv9k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tf685ggJv9k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-06T13:36:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SZAdP-yPOp6HqBSma7ZywTILke8",
    "id": {
      "kind": "youtube#video",
      "videoId": "9N3kVU_tj7s"
    },
    "snippet": {
      "publishedAt": "2020-05-11T17:06:44Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "12 Min BODYWEIGHT HAMSTRINGS Workout | Follow Along",
      "description": "Subscribe to my YouTube Fitness Channel here: https://youtube.com/c/carolinegirvan/?sub_confirmation=1 Hello everyone!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9N3kVU_tj7s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9N3kVU_tj7s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9N3kVU_tj7s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-11T17:06:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hMFSXceDhM-bHJY637PlWOi6zkA",
    "id": {
      "kind": "youtube#video",
      "videoId": "8IXA5aauDfs"
    },
    "snippet": {
      "publishedAt": "2020-04-17T06:55:49Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "at Home ROMANIAN DEADLIFT | Workout &amp; How To",
      "description": "At home Dumbbell Romanian Deadlift Workout & Tutorial. Hello everyone! This video is all about the Romanian Deadlift!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8IXA5aauDfs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8IXA5aauDfs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8IXA5aauDfs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-17T06:55:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SGYw_CSbJibiO3qZODgZSM-Pyt0",
    "id": {
      "kind": "youtube#video",
      "videoId": "U-OYrmX2-k0"
    },
    "snippet": {
      "publishedAt": "2022-04-10T04:00:07Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series 30 Min Arms and Abs Workout - Biceps, Triceps | 5",
      "description": "Rows, presses and push ups\u2026 all help build the muscle in the biceps and triceps but we all love an arm specific workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U-OYrmX2-k0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U-OYrmX2-k0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U-OYrmX2-k0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-10T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jKWxvjU6bTJHBQX88-8o0TcZBTA",
    "id": {
      "kind": "youtube#video",
      "videoId": "P4cMTLqtRII"
    },
    "snippet": {
      "publishedAt": "2022-05-15T04:00:13Z",
      "channelId": "UCpis3RcTw6t47XO0R_KY4WQ",
      "title": "IRON Series Finale 30 Min Arms, Abs and Core Workout | 30",
      "description": "Finishing the Iron Series with my favourite workout of the series! This workout was everything that I personally love in an arm ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/P4cMTLqtRII/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/P4cMTLqtRII/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/P4cMTLqtRII/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Caroline Girvan",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-15T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "T6J7Yf2PQMUYMZuOi2DmztdRutE",
    "id": {
      "kind": "youtube#video",
      "videoId": "HHMxmsAxWnM"
    },
    "snippet": {
      "publishedAt": "2022-07-02T17:20:28Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "HOLD THAT HEAT CHALLENGE #shorts",
      "description": "Try to do this sequence for the entire song: Tag your workout partner to try this with and share it with us when you do! Song: Hold ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HHMxmsAxWnM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HHMxmsAxWnM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HHMxmsAxWnM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-02T17:20:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QqpzzcOQiBhp12LSNDoN-Z31XRg",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qtc-xnYruP0"
    },
    "snippet": {
      "publishedAt": "2023-01-31T15:18:28Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "One Day At A Time",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qtc-xnYruP0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qtc-xnYruP0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qtc-xnYruP0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-31T15:18:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XbmvfdsWqybFAZmHtm2FOGsmW7I",
    "id": {
      "kind": "youtube#video",
      "videoId": "93hA20m0uts"
    },
    "snippet": {
      "publishedAt": "2023-01-26T01:37:24Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "BEHIND THE SCENES WITH JUICE &amp; TOYA",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/93hA20m0uts/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/93hA20m0uts/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/93hA20m0uts/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-26T01:37:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wRi2bbvRPKYEvf39QDPZYEXUgWU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZTQ4nwC33vY"
    },
    "snippet": {
      "publishedAt": "2022-08-15T23:42:02Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "18 Mountain Climber Variations To Fire Up Your Abs \ud83d\udd25#shorts",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZTQ4nwC33vY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZTQ4nwC33vY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZTQ4nwC33vY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-15T23:42:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5Fi0QzeNfbsbjAfsJnFkT_SYh2g",
    "id": {
      "kind": "youtube#video",
      "videoId": "RwL1od2WRnU"
    },
    "snippet": {
      "publishedAt": "2023-02-03T03:07:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "PSYCO-LOGICAL, WHO\u2019S WHO? \ud83d\ude02 #juiceandtoya",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RwL1od2WRnU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RwL1od2WRnU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RwL1od2WRnU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-03T03:07:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "us8Nkz5XxfNBtDJvU4aNT1qGQ-Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "P0Go5YKhjwc"
    },
    "snippet": {
      "publishedAt": "2022-04-06T19:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Common Exercises That You May Need Help With // FORM CHECK",
      "description": "In this video, we flip this switch and go into full trainer mode by taking you through some common exercises that many people ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/P0Go5YKhjwc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/P0Go5YKhjwc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/P0Go5YKhjwc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-06T19:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pmvdu1vKHcZ67EN-ftut_SFazWs",
    "id": {
      "kind": "youtube#video",
      "videoId": "kwg-fLG35r8"
    },
    "snippet": {
      "publishedAt": "2022-06-11T00:00:24Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Questions &amp; Coffee - Answering YOUR Commonly Asked Questions [Episode 3]",
      "description": "We're back with another round of Questions & Coffee--where we answer some of your most commonly asked questions. Grab a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kwg-fLG35r8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kwg-fLG35r8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kwg-fLG35r8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-11T00:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PnwxA7VpBniftZS_EVv2qgjuzZs",
    "id": {
      "kind": "youtube#video",
      "videoId": "vRZdDalRz0U"
    },
    "snippet": {
      "publishedAt": "2022-05-20T14:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Foam Roller Session [ Guided For Beginners]",
      "description": "Tap in to this foam roller routine for a 10 minute full body recovery session. This session is guided for those who are new to foam ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vRZdDalRz0U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vRZdDalRz0U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vRZdDalRz0U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-20T14:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GJ9D4jPT_zwPMMPEvGmG1qj_ov8",
    "id": {
      "kind": "youtube#video",
      "videoId": "HfAM2A3WVWc"
    },
    "snippet": {
      "publishedAt": "2022-11-28T23:59:06Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Our First Juice &amp; Toya Live Event in Los Angeles!",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HfAM2A3WVWc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HfAM2A3WVWc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HfAM2A3WVWc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-28T23:59:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7zMg-qLLffJniJhSlCTKvzV1-yE",
    "id": {
      "kind": "youtube#video",
      "videoId": "PD48b2urV0M"
    },
    "snippet": {
      "publishedAt": "2023-03-08T14:08:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "THE 6-WEEK LEVEL UP PROGRAM \ud83d\udd25",
      "description": "THE 6 WEEK LEVEL UP PROGRAM: https://my.playbookapp.io/juice-toya/program/13449/purchase.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PD48b2urV0M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PD48b2urV0M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PD48b2urV0M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-08T14:08:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qfK4EK69tjI_O0jF0e8pkp5msRg",
    "id": {
      "kind": "youtube#video",
      "videoId": "IXichzb01QM"
    },
    "snippet": {
      "publishedAt": "2022-06-27T23:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Arms &amp; Abs [No Equipment + Modifications]",
      "description": "Tap in with us for a quick but effective 10 Minute Arms and Abs Workout . This workout is all about targeting the entire upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IXichzb01QM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IXichzb01QM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IXichzb01QM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-27T23:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dfShAnSU1M_DM2yhJ_6AbNaCUa0",
    "id": {
      "kind": "youtube#video",
      "videoId": "CarBFqx3uzU"
    },
    "snippet": {
      "publishedAt": "2022-05-31T23:30:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Abs Strength Workout [With Modifications]",
      "description": "Check in weekly with this 15 minute ab strength workout if you need a way to build up your core strength over time   This workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CarBFqx3uzU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CarBFqx3uzU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CarBFqx3uzU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-31T23:30:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1_NvlU7tT1EJFx38MlhVR7Tctmg",
    "id": {
      "kind": "youtube#video",
      "videoId": "KOvE5oAubpA"
    },
    "snippet": {
      "publishedAt": "2022-11-08T00:30:06Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Stretch &amp; Mobility [DAILY ROUTINE]",
      "description": "Tap in with us daily for a 10 minute stretch and mobility routine that will help reduce any pain, tension, or soreness. This session ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KOvE5oAubpA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KOvE5oAubpA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KOvE5oAubpA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-08T00:30:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZXW1BZYFG4XSlMwKM7DDB_EHeQw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Lq-a6kww0b4"
    },
    "snippet": {
      "publishedAt": "2022-05-17T23:00:14Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Recovery Stretch [Daily Routine]",
      "description": "This 10 minute full body recovery stretch is a great as a cool down or recovery session between training days. This is also a very ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Lq-a6kww0b4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Lq-a6kww0b4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Lq-a6kww0b4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-17T23:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "StycNbv9cjPpwB0g9I0EiwaBiBU",
    "id": {
      "kind": "youtube#video",
      "videoId": "HEKjiLNhZNY"
    },
    "snippet": {
      "publishedAt": "2022-04-01T00:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Things You Should Know About Our WORKOUTS",
      "description": "We read through hundreds of comments daily and we noticed that many of you had similar questions in regards to our workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HEKjiLNhZNY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HEKjiLNhZNY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HEKjiLNhZNY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-01T00:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kdZ80_FdKw--00ogwDeL5mjvTQI",
    "id": {
      "kind": "youtube#video",
      "videoId": "1E9fFGnuvu0"
    },
    "snippet": {
      "publishedAt": "2022-07-25T23:30:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Juice Takes Toya Through a Training Session [Wedding Prep Vlog]",
      "description": "With our wedding only days away, we thought it would be fun to show how we train outside of the workouts we post on YouTube.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1E9fFGnuvu0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1E9fFGnuvu0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1E9fFGnuvu0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-25T23:30:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kZHKNukmMUYXm9wLBadF9oIYH9s",
    "id": {
      "kind": "youtube#video",
      "videoId": "vhPN4WnmILg"
    },
    "snippet": {
      "publishedAt": "2022-12-02T00:20:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Juice &amp; Toya Forever \ud83d\udc8d\ud83e\udd0d Wedding video coming soon!",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vhPN4WnmILg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vhPN4WnmILg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vhPN4WnmILg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-02T00:20:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZzpWqIJiV-WJhNzFHCnwwXMAEds",
    "id": {
      "kind": "youtube#video",
      "videoId": "MjQIm3IO5HU"
    },
    "snippet": {
      "publishedAt": "2022-12-27T00:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "New Year Program, Major Updates, and 2023 Content!",
      "description": "We've had a wild journey to end this year, so we want to provide you with a quick update to give you some insight on Juice's injury ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MjQIm3IO5HU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MjQIm3IO5HU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MjQIm3IO5HU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-27T00:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vQ2Mbhn6KnrOGJvNksbnTHiJrJw",
    "id": {
      "kind": "youtube#video",
      "videoId": "yvqdKOLiR_w"
    },
    "snippet": {
      "publishedAt": "2022-11-16T00:30:06Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Isometric FINISHER Workout [Low Impact]",
      "description": "Got some extra juice in the tank? Finish your workout with this full body isometric FINISHER workout. This is a great way to add ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yvqdKOLiR_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yvqdKOLiR_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yvqdKOLiR_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-16T00:30:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BEnOaVSPQOfv7onEj6qpDP0PrjA",
    "id": {
      "kind": "youtube#video",
      "videoId": "o_YwpJm-otw"
    },
    "snippet": {
      "publishedAt": "2022-05-02T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Light Weight Dumbbell Workout [Full Body//Compound Exercises]",
      "description": "Tap with us for a 20 minute full body dumbbell workout that WILL work your full body body! This workout is designed for light ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/o_YwpJm-otw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/o_YwpJm-otw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/o_YwpJm-otw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-02T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AGt0ApgzNlwPXl81xpzMW_Y7L90",
    "id": {
      "kind": "youtube#video",
      "videoId": "NDOlPdyZLMg"
    },
    "snippet": {
      "publishedAt": "2022-04-17T23:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Upper Body Dumbbell Workout [Strength Training]",
      "description": "Tap in with us for a 15 minute upper body dumbbell workout that will help you build muscle (tone) and strength! This workout is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NDOlPdyZLMg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NDOlPdyZLMg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NDOlPdyZLMg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-17T23:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dXucStgFJuFODSNQQvJfr69BbMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "zVK1QxXPmaM"
    },
    "snippet": {
      "publishedAt": "2022-05-09T23:00:08Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Tabata Workout [Strength/HIIT/Isometric/Cardio]",
      "description": "Tap in with us for our first ever Full Body Tabata Workout! We decided to remix a traditional Tabata format by breaking up this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zVK1QxXPmaM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zVK1QxXPmaM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zVK1QxXPmaM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T23:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DurLh2LYAz3ZXCjjprVCDKNR6Ak",
    "id": {
      "kind": "youtube#video",
      "videoId": "jSdIHQHhCVc"
    },
    "snippet": {
      "publishedAt": "2022-10-13T20:00:07Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Juice &amp; Toya X One Body LA Anniversary 4 Week Challenge!",
      "description": "Join us as we celebrate 5 years of our personal training business \u2014 One Body LA! Throughout these 5 years, we have had the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jSdIHQHhCVc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jSdIHQHhCVc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jSdIHQHhCVc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-13T20:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dcwFm4tqKCvZhZB9ul3E0x7qcWo",
    "id": {
      "kind": "youtube#video",
      "videoId": "y-uAqSyMbnQ"
    },
    "snippet": {
      "publishedAt": "2022-04-13T13:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Lower Body &amp; Abs Strength Workout [Dumbbells OR Bodyweight]",
      "description": "Tap into this lower body strength workout for a solid leg day pump! We take you through lower body strength training exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y-uAqSyMbnQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y-uAqSyMbnQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y-uAqSyMbnQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-13T13:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ysyi9G1ahYsD9yWRTqy9v8KrRCs",
    "id": {
      "kind": "youtube#video",
      "videoId": "QxIjxD42TWw"
    },
    "snippet": {
      "publishedAt": "2022-05-24T16:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Dumbbell Strength &amp; Cardio-HIIT [Full Body Conditioning]",
      "description": "This is the ultimate 30 minute strength and conditioning workout for a full body training session! This workout is great for improving ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QxIjxD42TWw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QxIjxD42TWw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QxIjxD42TWw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-24T16:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Na7MGGfBHjDKooMyiWM6n4kqwRc",
    "id": {
      "kind": "youtube#video",
      "videoId": "UjwKKl157hw"
    },
    "snippet": {
      "publishedAt": "2022-04-25T14:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Bodyweight Strength Workout [FULL BODY // No Equipment]",
      "description": "Tap in to this 20 Minute strength workout that requires NO equipment. This full body flow is has a mixture of active movements and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UjwKKl157hw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UjwKKl157hw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UjwKKl157hw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-25T14:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wTjYEhGClKi37ezjmzynYWlo_AQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "BLKZwvObX_U"
    },
    "snippet": {
      "publishedAt": "2023-01-25T00:05:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Abs For Beginners [Daily Core Strength Workout]",
      "description": "Tap in with us for a 10 minute ab circuit that's fit for all levels of fitness, especially beginners! If you are new to working out, or have ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BLKZwvObX_U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BLKZwvObX_U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BLKZwvObX_U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-25T00:05:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h3q6VtdpEKEh5Oh6B_T_ZxEJdAw",
    "id": {
      "kind": "youtube#video",
      "videoId": "hUtRSj1mFsc"
    },
    "snippet": {
      "publishedAt": "2023-02-28T16:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Abs To The Beat Workout [ADVANCED/High Intensity]",
      "description": "Many of you loved our Step to the Beat workouts, so we thought we'd remix it and give you Abs to the Beat! This video will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hUtRSj1mFsc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hUtRSj1mFsc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hUtRSj1mFsc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-28T16:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PP-Y_7fhVMSaSIb57KPrYX9S98o",
    "id": {
      "kind": "youtube#video",
      "videoId": "cs2o62zccLY"
    },
    "snippet": {
      "publishedAt": "2022-10-25T00:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Strength Workout [Bodyweight with Bench/Step]",
      "description": "Tap in with us for 20 minutes of FULL BODY strength using a Bench or Step! All movements will be sued by the bench and will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cs2o62zccLY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cs2o62zccLY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cs2o62zccLY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-25T00:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6RKORBS1nPXZMXaS7ZJV3Kr580o",
    "id": {
      "kind": "youtube#video",
      "videoId": "CHXk-ZaLrCI"
    },
    "snippet": {
      "publishedAt": "2022-08-29T23:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Walking/Step Workout [Low Impact HIIT/Warm-Up/Finisher]",
      "description": "Tap in with us for our first walking/stepping HIIT workout! This workout is very versatile and can be used as a workout, warm up, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CHXk-ZaLrCI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CHXk-ZaLrCI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CHXk-ZaLrCI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-29T23:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mrR96FMZq_6wK3cZX783XqaUWvE",
    "id": {
      "kind": "youtube#video",
      "videoId": "pHesd3IMTNI"
    },
    "snippet": {
      "publishedAt": "2022-07-07T16:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell Tabata Workout [ADVANCED]",
      "description": "Tap in with us for a Full Body Dumbbell Tabata Workout! We broke up this workout into 4 groups of strength/HIIT exercises that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pHesd3IMTNI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pHesd3IMTNI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pHesd3IMTNI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-07T16:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EI-m7Au7Car8WAq6LApxe3lyFJs",
    "id": {
      "kind": "youtube#video",
      "videoId": "UgZi2nDglmA"
    },
    "snippet": {
      "publishedAt": "2020-08-24T16:00:30Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Macronutrients 101 [What Macros Are and Why They&#39;re Important]",
      "description": "In this video, we give you a brief overview of what macros are and why they're important to your body. If you're someone who ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UgZi2nDglmA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UgZi2nDglmA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UgZi2nDglmA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-24T16:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CMiGtDgtCP_65Oya5gdHoM_9-6o",
    "id": {
      "kind": "youtube#video",
      "videoId": "cyJBHhjF3Jo"
    },
    "snippet": {
      "publishedAt": "2022-07-12T23:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Full Body Kettlebell HIIT Workout [ADVANCED Strength &amp; Cardio]",
      "description": "Tap into this 25 minute, FULL BODY kettlebell HIIT workout! This is a great workout for overall strength and conditioning. All you'll ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cyJBHhjF3Jo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cyJBHhjF3Jo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cyJBHhjF3Jo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-12T23:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JSQHQKltfm97vB2k5R4TqF_uk5Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "WNUvx5y-1QE"
    },
    "snippet": {
      "publishedAt": "2022-10-19T23:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body CARDIO HIIT Workout [ADVANCED + LOW IMPACT]",
      "description": "Tap in for a fun 30 Minute FULL BODY Cardio-HIIT workout! This workout will get your heart rate up with a mixture of high intensity ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WNUvx5y-1QE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WNUvx5y-1QE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WNUvx5y-1QE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-19T23:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_vM6GpdUVBZBOo3DRQyU89NW-Nc",
    "id": {
      "kind": "youtube#video",
      "videoId": "vr0g1-V6xec"
    },
    "snippet": {
      "publishedAt": "2022-11-11T03:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell Strength Workout [EMOM Style]",
      "description": "Tap in with us for another 30 Minute Full Body Dumbbell Strength Workout! The format of this workout is EMOM style which stands ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vr0g1-V6xec/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vr0g1-V6xec/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vr0g1-V6xec/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-11T03:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NjaKAgC8mXQNbtRbNqNb4NpffX0",
    "id": {
      "kind": "youtube#video",
      "videoId": "DUbdcKZ6e3E"
    },
    "snippet": {
      "publishedAt": "2022-03-28T13:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "45 Minute Strength &amp; Conditioning Workout [Dumbbells + Cardio-HIIT]",
      "description": "You HAVE to tap into this 45 minute strength and conditioning workout for a full body training session This workout is designed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DUbdcKZ6e3E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DUbdcKZ6e3E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DUbdcKZ6e3E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-28T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wPLSWd9v6Wwo40azkflDnnOFVCk",
    "id": {
      "kind": "youtube#video",
      "videoId": "AjAVhtQ2vok"
    },
    "snippet": {
      "publishedAt": "2022-08-23T00:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell Strength Workout [NO REPEAT]",
      "description": "Tap in with us for a FULL BODY dumbbell strength training workout. This workout was designed to help build strength, muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AjAVhtQ2vok/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AjAVhtQ2vok/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AjAVhtQ2vok/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-23T00:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WATorgzzGo_uKIFFYhnAvvfNQj8",
    "id": {
      "kind": "youtube#video",
      "videoId": "GrP1JuUnVeY"
    },
    "snippet": {
      "publishedAt": "2023-01-11T00:30:07Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Upper Body Dumbbell Tabata Workout [ADVANCED HIIT]",
      "description": "Tap in with us for an Upper Body Dumbbell Tabata Workout! We broke up this workout into 4 groups of 3 strength/HIIT exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GrP1JuUnVeY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GrP1JuUnVeY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GrP1JuUnVeY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-11T00:30:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ym90alNW9j6aGQwqkv2D-0wWS6I",
    "id": {
      "kind": "youtube#video",
      "videoId": "t-3H2KnjH6E"
    },
    "snippet": {
      "publishedAt": "2022-03-22T13:15:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Beginner Kettlebell Workout [Full Body Strength Training]",
      "description": "Kettlebells are one of our favorite pieces of equipment and is something we use in almost every workout that we do personally.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/t-3H2KnjH6E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/t-3H2KnjH6E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/t-3H2KnjH6E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-22T13:15:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "N7FVX64v4AKllTusC3sYWql61iw",
    "id": {
      "kind": "youtube#video",
      "videoId": "otiEptaKikE"
    },
    "snippet": {
      "publishedAt": "2022-06-14T23:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Low Impact HIIT Workout [NO Jumping //NO Equipment]",
      "description": "Tap in with us for a 30 minute FULL BODY HIIT workout that is suitable for all fitness levels---advanced and beginners!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/otiEptaKikE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/otiEptaKikE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/otiEptaKikE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-14T23:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cQPFYTONAhDKNDZezOMlQwPNBME",
    "id": {
      "kind": "youtube#video",
      "videoId": "0Yx_qbbzM0c"
    },
    "snippet": {
      "publishedAt": "2022-11-29T20:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Upper Body Strength Workout [No Equipment]",
      "description": "Tap in with us for an effective upper body strength training workout . This workout is all about targeting the entire upper body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0Yx_qbbzM0c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0Yx_qbbzM0c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0Yx_qbbzM0c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-29T20:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n3nPySHX-D5RLjyuHPfp4-cUHJM",
    "id": {
      "kind": "youtube#video",
      "videoId": "D7HjHW8cA_A"
    },
    "snippet": {
      "publishedAt": "2022-10-05T23:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute &#39;Step To the Beat&#39; Workout [LOW IMPACT //Standing HIIT]",
      "description": "Tap in with us for another walking/stepping HIIT workout! This workout is fun, interactive, and perfect for all ages and levels of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D7HjHW8cA_A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D7HjHW8cA_A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D7HjHW8cA_A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-05T23:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EgChfAcaN3XRyVcQGy78Z5fKOhQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "9FBIaqr7TjQ"
    },
    "snippet": {
      "publishedAt": "2022-09-12T23:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Strength Workout [No Equipment + Modifications]",
      "description": "Tap in with us for 30 minutes of FULL BODY strength that requires NO equipment. This full body flow is has a mixture of active ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9FBIaqr7TjQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9FBIaqr7TjQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9FBIaqr7TjQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-12T23:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0_XPaTZEsN9BKcTd1idjYw8KX9E",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZU3oBHKwFrw"
    },
    "snippet": {
      "publishedAt": "2023-01-17T16:00:07Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Light Weight Dumbbell HIIT Workout [Full Body/Low Impact]",
      "description": "Tap with us for a 30 minute full body dumbbell workout that WILL work your full body body! This workout was designed for light ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZU3oBHKwFrw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZU3oBHKwFrw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZU3oBHKwFrw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-17T16:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "41BoCHuWf8f0AoUYtHXwCIYHZt4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Xkq-Bcor84o"
    },
    "snippet": {
      "publishedAt": "2023-02-06T17:00:07Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Full Body Cardio HIIT Workout [ADVANCED]",
      "description": "Tap in with us for a fun 25 Minute High Intensity Cardio workout that's designed to get your heart rate up, build endurance, and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Xkq-Bcor84o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Xkq-Bcor84o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Xkq-Bcor84o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-06T17:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q2iP477D0IxjHpL-zCar1Isw4RY",
    "id": {
      "kind": "youtube#video",
      "videoId": "D26xO1k6oW0"
    },
    "snippet": {
      "publishedAt": "2022-09-06T23:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute FULL BODY Medicine Ball HIIT Workout",
      "description": "Tap in with us for a 20 minute HIIT workout with a medicine ball! This workout gives you the best of both worlds with strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D26xO1k6oW0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D26xO1k6oW0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D26xO1k6oW0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-06T23:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "00ny5-9u3W1qDblv1BlPx1PZ8gU",
    "id": {
      "kind": "youtube#video",
      "videoId": "B7-F2UcHmqk"
    },
    "snippet": {
      "publishedAt": "2023-01-04T20:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "60 Minute Full Body Dumbbell Strength Workout [With Audio Cues]",
      "description": "Tap in with us for this 60 minute Full Body Strength Workout! This is an all-in-one workout that you can do daily to improve your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/B7-F2UcHmqk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/B7-F2UcHmqk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/B7-F2UcHmqk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-04T20:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1aXuldgFMPj0DpGxswgrt84XfK8",
    "id": {
      "kind": "youtube#video",
      "videoId": "yyasWsuZ-W8"
    },
    "snippet": {
      "publishedAt": "2023-01-31T00:30:07Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Lower Body Dumbbell Tabata Workout [ADVANCED// LOW IMPACT]",
      "description": "Tap in with us for a Lower Body Dumbbell Tabata Workout! We broke up this workout into 5 groups of 3 strength/HIIT exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yyasWsuZ-W8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yyasWsuZ-W8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yyasWsuZ-W8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-31T00:30:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CXrqcjsFWduGLdyCfo1nVcz62F4",
    "id": {
      "kind": "youtube#video",
      "videoId": "hh9v510MLR0"
    },
    "snippet": {
      "publishedAt": "2020-03-10T16:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Morning vs. Evening Workouts: Find Out Which is Best For You",
      "description": "Knowing the best time to workout is usually pretty easy to figure out based on your daily schedule. However, we wanted to present ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hh9v510MLR0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hh9v510MLR0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hh9v510MLR0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-10T16:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qES5ZJtyTKn7XLZDMAajocgapAc",
    "id": {
      "kind": "youtube#video",
      "videoId": "uvUJKoudsnk"
    },
    "snippet": {
      "publishedAt": "2023-03-15T16:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute DUMBBELL Arms &amp; Abs EMOM Workout [Circuit Strength Training]",
      "description": "Tap in with us for a 25 Minute Arms & Abs Dumbbell Strength Workout The format of this workout is EMOM style which stands ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uvUJKoudsnk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uvUJKoudsnk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uvUJKoudsnk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-15T16:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jkJb-goVY0l1rtM5mPF6y5fJHOM",
    "id": {
      "kind": "youtube#video",
      "videoId": "g1ZUfLLpdOo"
    },
    "snippet": {
      "publishedAt": "2023-02-17T19:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "BEAT YOUR SETBACK WITH THESE 4 TIPS",
      "description": "Setbacks happen to the best of us, and in many cases, thy can derail you from the path of reaching your fitness goals. In this video ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g1ZUfLLpdOo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g1ZUfLLpdOo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g1ZUfLLpdOo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-17T19:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "L5_u_rLzK97l5htJF6BbRu_BCms",
    "id": {
      "kind": "youtube#video",
      "videoId": "mdFM2msfsbw"
    },
    "snippet": {
      "publishedAt": "2022-09-26T23:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute FULL BODY Resistance Band Workout [Strength Training]",
      "description": "Tap in with us for a quick 20 minute full body strength workout using resistance bands! This is a high intensity workout that will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mdFM2msfsbw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mdFM2msfsbw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mdFM2msfsbw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-26T23:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FQIMBovzOIJJk6zvyFK7saI4QFY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Vvo17Utx1Lw"
    },
    "snippet": {
      "publishedAt": "2023-02-23T01:30:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Step To The Beat Workout [1 Million Subscriber Party/ LOW IMPACT]",
      "description": "Celebrate 1 MILLION Subscribers with us by tapping into this 30 Minute Step to the beat workout! This workout is all about having ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Vvo17Utx1Lw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Vvo17Utx1Lw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Vvo17Utx1Lw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-23T01:30:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n3n-kVNaOw7IPsjXQpk0hxdUTbg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WweAHgNmFLA"
    },
    "snippet": {
      "publishedAt": "2020-11-19T15:17:37Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Juice &amp; Toya Channel Update: Living in a New Normal",
      "description": "On our channel, we aim to post at least 1 video per week to ensure that everyone who follows our content is able to learn more ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WweAHgNmFLA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WweAHgNmFLA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WweAHgNmFLA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-19T15:17:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oBJbV2Pncy8RJ5tb6FxeP2WDBy0",
    "id": {
      "kind": "youtube#video",
      "videoId": "5uqiu6nOS0w"
    },
    "snippet": {
      "publishedAt": "2019-07-01T20:39:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Welcome to Juice &amp; Toya",
      "description": "Welcome to Juice and Toya! Our channel is all about inspiring you to be the healthiest version of yourself, both mentally and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5uqiu6nOS0w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5uqiu6nOS0w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5uqiu6nOS0w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-01T20:39:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ro7D3BNqTEGXCDpzqSclhfoHTZI",
    "id": {
      "kind": "youtube#video",
      "videoId": "2WR0RTuxZH4"
    },
    "snippet": {
      "publishedAt": "2021-02-11T17:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Quick and Easy Salmon 3 Ways (Go-To Recipes For Lunch &amp; Dinner)",
      "description": "Many of you asked about what we eat throughout the week to supplement our training, and salmon is something that we don't go ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2WR0RTuxZH4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2WR0RTuxZH4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2WR0RTuxZH4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-11T17:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Sa59Bj6HOgmFuVvdXq76ZiHNZqE",
    "id": {
      "kind": "youtube#video",
      "videoId": "E9_xmcrFPlg"
    },
    "snippet": {
      "publishedAt": "2021-07-14T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Lower Back Stretch (For Pain + Tension Relief)",
      "description": "If you have severe lower back pain or tension, take 15 minutes to try out this lower back stretch. This stretch was designed for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E9_xmcrFPlg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E9_xmcrFPlg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E9_xmcrFPlg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-14T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eSiYaj--PgqtXz83XTqv5fBMEFs",
    "id": {
      "kind": "youtube#video",
      "videoId": "KEXvqo3VGmc"
    },
    "snippet": {
      "publishedAt": "2020-07-27T21:28:25Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Overcome the MYTH of Getting Bulky With Strength Training (&amp; The TRUE Science Behind Weight Lifting)",
      "description": "Understandably, a lot of women are skeptical about strength training because they are intimidated and fearful of getting too \"bulky\" ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KEXvqo3VGmc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KEXvqo3VGmc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KEXvqo3VGmc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-27T21:28:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "avvLSznX63ae-nMng-xk2O4KcBA",
    "id": {
      "kind": "youtube#video",
      "videoId": "xK8ww0geTug"
    },
    "snippet": {
      "publishedAt": "2022-01-30T20:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "5 Tips To Help Improve Your Mental Health",
      "description": "Although working out and eating healthy is very important to our fitness journey, the mental health aspect of fitness is often ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xK8ww0geTug/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xK8ww0geTug/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xK8ww0geTug/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-30T20:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mO-ydPLs6asze1BFuZfBh8UcD78",
    "id": {
      "kind": "youtube#video",
      "videoId": "D8O7myqk0Bw"
    },
    "snippet": {
      "publishedAt": "2021-10-06T16:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "What I Eat in a Day // A Day in The Life With Toya",
      "description": "We finally took some time to film our daily meals to give you an authentic look at what we eat and why we eat it. Even though we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D8O7myqk0Bw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D8O7myqk0Bw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D8O7myqk0Bw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-06T16:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8Jj6xzB1iJjhuyRQqjgy66Tsr1k",
    "id": {
      "kind": "youtube#video",
      "videoId": "-jU3MbiEQV0"
    },
    "snippet": {
      "publishedAt": "2021-09-28T14:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "A Tour of The Juice &amp; Toya App! (Content Updates)",
      "description": "We recently dropped our BRAND NEW Juice and Toya App and many of you had tons of questions and feedback since then.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-jU3MbiEQV0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-jU3MbiEQV0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-jU3MbiEQV0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-28T14:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "p5_VPBtNQW-ZLI9vHhZpN7CDDRU",
    "id": {
      "kind": "youtube#video",
      "videoId": "1MBYC0vtChM"
    },
    "snippet": {
      "publishedAt": "2022-03-07T14:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body FINISHER Workout [Advanced//With Modifications]",
      "description": "If you have a little left in the tank following a workout, you can add this 10 minute full body finisher for a complete workout. This is a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1MBYC0vtChM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1MBYC0vtChM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1MBYC0vtChM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-07T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RYplGJpzRaGT3Xq-Hvj5JO9as-4",
    "id": {
      "kind": "youtube#video",
      "videoId": "S6SZUmvGU4k"
    },
    "snippet": {
      "publishedAt": "2022-03-14T14:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "8 Minute Full Body Mobility Routine // Flexibility &amp; Range Of Motion",
      "description": "Building flexibility and mobility is VERY important to getting the most out of every training session. So in this video,, we will take ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S6SZUmvGU4k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S6SZUmvGU4k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S6SZUmvGU4k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-14T14:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NXSzJGZD8pviy6YkOBxkI6ZZSfY",
    "id": {
      "kind": "youtube#video",
      "videoId": "y62RlnrvoeI"
    },
    "snippet": {
      "publishedAt": "2020-08-13T14:30:04Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "The Most UNDERRATED Healthy Snack [Easy Low Calorie Snack Option]",
      "description": "We have had a ton of requests to start incorporating more nutrition on our channel, so we wanted to start by sharing one of our ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y62RlnrvoeI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y62RlnrvoeI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y62RlnrvoeI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-13T14:30:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qMEKfchMFApPMIftgvzgwfD_xkQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "ixZVPFJWnRY"
    },
    "snippet": {
      "publishedAt": "2021-01-27T19:30:56Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Pre-Workout Stretch/Warm-Up Routine",
      "description": "Follow along with us for a full body 8-10 minute stretch that will be effective for any workout that you're going into. This warm-up ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ixZVPFJWnRY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ixZVPFJWnRY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ixZVPFJWnRY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-27T19:30:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1kuJyd6wiYjyD5DTcqEIDafalA0",
    "id": {
      "kind": "youtube#video",
      "videoId": "jW2n8v4Cb-k"
    },
    "snippet": {
      "publishedAt": "2022-02-01T23:56:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "One Body LA // lululemon Official Merch [Available 2.2.22]",
      "description": "We are so excited to announce we've partnered with lululemon to bring you One Body LA x Juice & Toya official Merch! For years ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jW2n8v4Cb-k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jW2n8v4Cb-k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jW2n8v4Cb-k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-01T23:56:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bss80KKHucWXrRngKwy93515mIE",
    "id": {
      "kind": "youtube#video",
      "videoId": "mAncobUtZE4"
    },
    "snippet": {
      "publishedAt": "2020-09-22T17:45:00Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Full Body Barbell Cardio Workout [Light Weight Barbell Complex]",
      "description": "This is one of our go-to full body workouts when we want to take a break from heavy lifting, switch up the pace, and focus more on ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mAncobUtZE4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mAncobUtZE4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mAncobUtZE4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-22T17:45:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kQwHkS3Oa9szUmWw76PP9ilXwjg",
    "id": {
      "kind": "youtube#video",
      "videoId": "HEJEn4JHEHk"
    },
    "snippet": {
      "publishedAt": "2020-03-25T19:00:02Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Home Workout HACKS and TIPS: For All Fitness Levels!",
      "description": "Working out at home can get repetitive and boring, so we came up with 10 home workout hacks/tips that will add some assistance ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HEJEn4JHEHk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HEJEn4JHEHk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HEJEn4JHEHk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-25T19:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9rXYtMruoh1WHU1s0IrNq0Cl4iE",
    "id": {
      "kind": "youtube#video",
      "videoId": "Lnywredkr7o"
    },
    "snippet": {
      "publishedAt": "2020-04-29T18:45:44Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "FULL BODY Dumbbell Only Workout (Dumbbell Complex)",
      "description": "This full body dumbbell workout is one of our favorite go to workouts because it's efficient, customizable, and challenging! In this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Lnywredkr7o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Lnywredkr7o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Lnywredkr7o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-29T18:45:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kwWJtHskrlH4px-N31OWXXP4MUY",
    "id": {
      "kind": "youtube#video",
      "videoId": "9Er3R9_gZRA"
    },
    "snippet": {
      "publishedAt": "2020-05-24T20:20:00Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Core/Ab Exercises Using A FOAM ROLLER (For Advanced and Beginners)",
      "description": "With limited equipment and most gyms still locked down due to Covid-19, we have to get pretty creative with what we already have ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9Er3R9_gZRA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9Er3R9_gZRA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9Er3R9_gZRA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-24T20:20:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jBZtGU-SrRrXb0prywrx9soRjM8",
    "id": {
      "kind": "youtube#video",
      "videoId": "AIYY1napKKI"
    },
    "snippet": {
      "publishedAt": "2021-09-17T13:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Questions &amp; Coffee - Answering YOUR Commonly Asked Questions",
      "description": "On our channel, we do our best to answer every single comment, question, or feedback that you have for our content. With that, we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AIYY1napKKI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AIYY1napKKI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AIYY1napKKI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-17T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BEHetcJBslVLny6n5dWg2zJnQcg",
    "id": {
      "kind": "youtube#video",
      "videoId": "NBVC-0mU2yc"
    },
    "snippet": {
      "publishedAt": "2021-06-30T15:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "8 Minute Abs [For All Fitness Levels]",
      "description": "Tap in with us for 8 Minute Abs! This is a quick ab circuit that will target your entire core with just 8 exercises. This routine was ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NBVC-0mU2yc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NBVC-0mU2yc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NBVC-0mU2yc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-30T15:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CW8d7T_uNX4L6FZiizh9prYP7Ww",
    "id": {
      "kind": "youtube#video",
      "videoId": "pPlFSqXnbls"
    },
    "snippet": {
      "publishedAt": "2021-09-21T12:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "5 Minute Active/Dynamic Warm-Up Stretch",
      "description": "Follow along for a quick 5-7 minute Active & Dynamic warm up stretch that you can do before any HIIT, strength, or cardio workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pPlFSqXnbls/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pPlFSqXnbls/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pPlFSqXnbls/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-21T12:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nbz3dHA9kCWC2TcooOeEKpA13bM",
    "id": {
      "kind": "youtube#video",
      "videoId": "8I46WVdMoyc"
    },
    "snippet": {
      "publishedAt": "2020-07-20T19:38:42Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Lower Body Dumbbell Workout (High Intensity Drop Set Burnout)",
      "description": "In this video, we structured our lower body dumbbell workout into drop sets to really fire up our legs. This is an efficient way to add ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8I46WVdMoyc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8I46WVdMoyc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8I46WVdMoyc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-20T19:38:42Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_5q25RtGBd5FIy9WGUx6QuvHHMc",
    "id": {
      "kind": "youtube#video",
      "videoId": "zQ2DwKTw7YU"
    },
    "snippet": {
      "publishedAt": "2022-03-19T00:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "What We Eat in A Day //A Full Day of Eating",
      "description": "We both had a rare day with a light schedule so we decided to give you an authentic look at what we eat and why we eat it.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zQ2DwKTw7YU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zQ2DwKTw7YU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zQ2DwKTw7YU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-19T00:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4KFKZZui6OjIJYRrpG_026zBNO4",
    "id": {
      "kind": "youtube#video",
      "videoId": "BxOHAk3e3l4"
    },
    "snippet": {
      "publishedAt": "2020-04-14T01:05:42Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Stretch &amp; Mobility: (For Daily Routine or Pre/Post Workout)",
      "description": "In this video, we're taking you through a quick full body stretch routine that you can do daily to improve your mobility and flexibility.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BxOHAk3e3l4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BxOHAk3e3l4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BxOHAk3e3l4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-14T01:05:42Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lcD2wnvVuk4sq2DvTCAUdQaBkXc",
    "id": {
      "kind": "youtube#video",
      "videoId": "K5Is87jijIE"
    },
    "snippet": {
      "publishedAt": "2021-07-26T13:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Dumbbell Shoulder Circuit [Strength/Muscle Toning Workout]",
      "description": "Tap in with us for this quick 10 minute circuit if you're looking to build strength and tone your shoulders All you need is a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/K5Is87jijIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/K5Is87jijIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/K5Is87jijIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-26T13:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "erjZUVzKssio7-QfE9mAj6pa0IQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Y7MKwSEH7BA"
    },
    "snippet": {
      "publishedAt": "2021-11-19T14:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "What I Eat in a Day // A Day in The Life With Juice",
      "description": "In this video, we will take you through some examples of what I (Juice ) eat in a day along with what a \"day in the life\" of a personal ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Y7MKwSEH7BA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Y7MKwSEH7BA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Y7MKwSEH7BA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-19T14:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ldUgJT1qfkwdn29a_zyN4i4Pjo8",
    "id": {
      "kind": "youtube#video",
      "videoId": "XYtntRKzzRY"
    },
    "snippet": {
      "publishedAt": "2020-04-04T16:52:03Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Complete Ab Workout At-Home: For Advanced and Beginners (No Equipment)",
      "description": "This 20 minute (At-home) ab workout will show you that you don't need much time or equipment to fire up your abs . This ab ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XYtntRKzzRY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XYtntRKzzRY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XYtntRKzzRY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-04T16:52:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5z1V0_4tKzRuOeVN2Sj9pxoyYlE",
    "id": {
      "kind": "youtube#video",
      "videoId": "mnzaEyTm-MA"
    },
    "snippet": {
      "publishedAt": "2021-05-25T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Lightweight Dumbbell Workout [Advanced HIIT]",
      "description": "Follow along with us for a quick 10 minute lightweight dumbbell workout that will work the entire body This workout is designed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mnzaEyTm-MA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mnzaEyTm-MA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mnzaEyTm-MA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-25T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n5Za4OZ1fp6PmLmmZwS8FsF8ngg",
    "id": {
      "kind": "youtube#video",
      "videoId": "aLl-on0DAAo"
    },
    "snippet": {
      "publishedAt": "2020-03-19T22:48:53Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute FULL BODY Home Workout: High-Intensity Follow Along (Bodyweight Only)",
      "description": "This 20 minute bodyweight home workout is further proof that you don't need any fancy equipment or a lot of time to get in a great ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aLl-on0DAAo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aLl-on0DAAo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aLl-on0DAAo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-19T22:48:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZYfd9EgOVTYq0aKvggQEz4QrMXs",
    "id": {
      "kind": "youtube#video",
      "videoId": "UIRTPXj1Q1U"
    },
    "snippet": {
      "publishedAt": "2020-11-11T00:55:29Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Lower Body Stretch Routine (For Tight Hamstrings &amp; Hip Flexors)",
      "description": "Since being under lockdown for COVID, many of us have transitioned to working and learning from home. With that, we tend to sit ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UIRTPXj1Q1U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UIRTPXj1Q1U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UIRTPXj1Q1U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-11T00:55:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jOLwMuYk-sP5Z1VQ1tVr_p-PLzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "-zAxbvQYElQ"
    },
    "snippet": {
      "publishedAt": "2021-07-19T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Dumbbell Tempo HIIT Workout (Full Body/Light Weight)",
      "description": "Tap in with us for a quick 10 Minute Full Body Dumbbell HIIT Workout that WILL burn calories and work up a good sweat!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-zAxbvQYElQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-zAxbvQYElQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-zAxbvQYElQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-19T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Hdz0Z2icrLWC43TTxUMPpLrvm-I",
    "id": {
      "kind": "youtube#video",
      "videoId": "W-DOxqS6NHg"
    },
    "snippet": {
      "publishedAt": "2021-10-27T00:45:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Medicine Ball HIIT Workout (With Modifications/NO REPEAT)",
      "description": "Tap in with us for a full body, high intensity workout using only a medicine ball (slam ball)! We have included lower impact ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W-DOxqS6NHg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W-DOxqS6NHg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W-DOxqS6NHg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-27T00:45:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WX9NvURKUtr19DGopree50FqLKY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Vrbp3-TScIA"
    },
    "snippet": {
      "publishedAt": "2019-08-21T22:45:42Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Recovery - Our First Vlog!",
      "description": "For our first vlog, we wanted to share an inside look at what we do during our time off. This is the first of many to come so let us ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Vrbp3-TScIA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Vrbp3-TScIA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Vrbp3-TScIA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-21T22:45:42Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "C2kWSzoFeC6QjXuhNUcxM5P3ueU",
    "id": {
      "kind": "youtube#video",
      "videoId": "yM_7EFy1qgI"
    },
    "snippet": {
      "publishedAt": "2020-12-12T17:45:49Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Min Full Body HIIT Workout (Low Impact + No Equipment )",
      "description": "Follow along with Toya for a low impact, full body hiit workout! This workout is apartment friendly and requires no equipment.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yM_7EFy1qgI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yM_7EFy1qgI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yM_7EFy1qgI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-12T17:45:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FQ-NIfSVpzYVol_nG-msutrppC0",
    "id": {
      "kind": "youtube#video",
      "videoId": "XSvialMWZQo"
    },
    "snippet": {
      "publishedAt": "2021-06-08T19:00:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Tempo HIIT Workout (Full Body/No Equipment/No Repeat)",
      "description": "Tap in with us for a quick 15 Minute Full Body HIIT Workout that WILL burn calories and work up a good sweat! This workout is all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XSvialMWZQo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XSvialMWZQo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XSvialMWZQo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-08T19:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qmcgo2uQKf_KwZL8aVUNwCtPVL0",
    "id": {
      "kind": "youtube#video",
      "videoId": "Kpd9ik93Sxk"
    },
    "snippet": {
      "publishedAt": "2021-04-21T13:00:18Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Upper Body Stretch Routine [Chest/Shoulders/Back &amp; More]",
      "description": "Tap in with us for a 10-15 minute stretch routine that will help alleviate any tension or tightness all throughout your upper body!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Kpd9ik93Sxk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Kpd9ik93Sxk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Kpd9ik93Sxk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-21T13:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oem-OwW_4Zdxj__Wkqi7Y8mqfYo",
    "id": {
      "kind": "youtube#video",
      "videoId": "SSPrYPiOsKI"
    },
    "snippet": {
      "publishedAt": "2019-11-05T23:07:35Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Daily Stretch Routine",
      "description": "Mobility and flexibility are a huge factor that determines how we move and how we feel. So we came up with a daily, full-body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SSPrYPiOsKI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SSPrYPiOsKI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SSPrYPiOsKI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-05T23:07:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NXN1VHmY7wCXCjDQ_n3U2GAciC8",
    "id": {
      "kind": "youtube#video",
      "videoId": "eH7y80pH-Xg"
    },
    "snippet": {
      "publishedAt": "2023-03-07T01:57:08Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "THE LEVEL UP 6-WEEK PROGRAM + LIVE Q&amp;A",
      "description": "Join us Monday March 6th at 5pm PST for a live session as we discuss our new 6-week training program. During this time we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eH7y80pH-Xg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eH7y80pH-Xg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eH7y80pH-Xg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-07T01:57:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZJMEcpllN-TTmMSXw-IDzj7txGY",
    "id": {
      "kind": "youtube#video",
      "videoId": "yj62gGM1T_0"
    },
    "snippet": {
      "publishedAt": "2021-05-09T16:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "First Gym Workout In Over A Year! [The Gym Life -Vlog #2]",
      "description": "It has been over a year since we last worked out inside of gym and we finally got our chance! This is just a snippet of what our ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yj62gGM1T_0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yj62gGM1T_0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yj62gGM1T_0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-09T16:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BRk90XLKA5T3Jf0Pe66W6Vx9WTw",
    "id": {
      "kind": "youtube#video",
      "videoId": "sUZRuW8e16I"
    },
    "snippet": {
      "publishedAt": "2019-08-29T00:47:32Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute FULL BODY Barbell Workout: (Fully Customizable and Efficient )",
      "description": "Here is one of our favorite go-to workouts when our time is a bit limited at the gym. This series is great if you're looking to build ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sUZRuW8e16I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sUZRuW8e16I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sUZRuW8e16I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-29T00:47:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qQVtinZmil1ZbKAf2pJ7uY-pDpg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WwsEXA2L4qc"
    },
    "snippet": {
      "publishedAt": "2021-05-16T19:00:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Upper Body Dumbbell Burnout [Muscle Building Workout]",
      "description": "Tap in with us for a quick 10 minute upper body burnout workout . This workout will target the chest, back, biceps, shoulders, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WwsEXA2L4qc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WwsEXA2L4qc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WwsEXA2L4qc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-16T19:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VkbbRHAmN5wTRYId_w9PncWryek",
    "id": {
      "kind": "youtube#video",
      "videoId": "cpLjSH9XHP8"
    },
    "snippet": {
      "publishedAt": "2021-01-03T16:47:57Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Juice and Toya Channel Update: A Look Into 2021",
      "description": "We want to take this time to reflect on 2020 and give you insight on what to expect from this channel in 2021. We are truly ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cpLjSH9XHP8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cpLjSH9XHP8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cpLjSH9XHP8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-03T16:47:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IeavoR80oSczHmbnakNGt26IhCk",
    "id": {
      "kind": "youtube#video",
      "videoId": "utAqR9-dmh0"
    },
    "snippet": {
      "publishedAt": "2022-01-19T16:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Active Recovery Workout [Strength &amp; Stretching]",
      "description": "Tap in with us for a 15-20 minute full body active recovery workout when you're experiencing stiffness/soreness or just want to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/utAqR9-dmh0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/utAqR9-dmh0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/utAqR9-dmh0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-19T16:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cCK_k2Gpf5ShGTBAgRpsJxC7PQ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "PBbwUT_1SUQ"
    },
    "snippet": {
      "publishedAt": "2020-02-12T19:00:05Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "4 Ways to Track Your Progress (Without A Scale)",
      "description": "The scale is one of the most commonly used methods for tracking progress. However, we know how tricky and discouraging it can ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PBbwUT_1SUQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PBbwUT_1SUQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PBbwUT_1SUQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-12T19:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mjencKN1TYPt3E35Gh4k4E5HgNg",
    "id": {
      "kind": "youtube#video",
      "videoId": "bHi-tsCtHqc"
    },
    "snippet": {
      "publishedAt": "2021-11-23T15:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Resistance Band Workout [Strength Training]",
      "description": "Tap in with us for a quick 15 minute full body strength workout using resistance bands! This is a high intensity workout that will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bHi-tsCtHqc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bHi-tsCtHqc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bHi-tsCtHqc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-23T15:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FlOlBys_VvadXThVk0IEixNImMc",
    "id": {
      "kind": "youtube#video",
      "videoId": "E0fDh1hV4rw"
    },
    "snippet": {
      "publishedAt": "2020-04-21T22:06:48Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute At Home Glute/Butt Workout | NO EQUIPMENT",
      "description": "Follow along with me for an at home workout that's really going fire up your glutes! With all of the gyms being shut down due to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E0fDh1hV4rw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E0fDh1hV4rw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E0fDh1hV4rw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-21T22:06:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kxQpNwGvIzfUNB0jA19kss7Z4kE",
    "id": {
      "kind": "youtube#video",
      "videoId": "s6dIsx6j4AE"
    },
    "snippet": {
      "publishedAt": "2021-04-01T13:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute ARMS and ABS Workout (No Repeat + Modifications)",
      "description": "Tap in with us for a quick 10 Minute Arms and Abs Workout . This workout is all about targeting the entire upper body while ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s6dIsx6j4AE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s6dIsx6j4AE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s6dIsx6j4AE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-01T13:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Xnwgsou3maocmRjw4dCDDsUaZOA",
    "id": {
      "kind": "youtube#video",
      "videoId": "sgwGSSz83SY"
    },
    "snippet": {
      "publishedAt": "2021-10-11T13:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Dumbbell Chest &amp; Triceps Workout [Upper Body Strength]",
      "description": "Tap in to this 10 minute upper body strength workout that specifically targets the chest and triceps. This dumbbell routine designed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sgwGSSz83SY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sgwGSSz83SY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sgwGSSz83SY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-11T13:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TIgEwZoyLztxdD2Is7gSWjSdCjs",
    "id": {
      "kind": "youtube#video",
      "videoId": "Z8kz3la3nbs"
    },
    "snippet": {
      "publishedAt": "2021-03-22T13:00:17Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Medicine Ball HIIT Workout (No Repeat + Modifications)",
      "description": "Follow along with us for a 15 minute Full Body Medicine Ball/Slam Ball HIIT Workout! In this workout, we take you through upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Z8kz3la3nbs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Z8kz3la3nbs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Z8kz3la3nbs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-22T13:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q5j4LEOgRtI3UsECNqevLqg_4Ok",
    "id": {
      "kind": "youtube#video",
      "videoId": "7-zjc6dAufg"
    },
    "snippet": {
      "publishedAt": "2021-04-26T13:00:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute &quot;Core-dio&quot; Workout (High Intensity Core/Abs)",
      "description": "Tap in with us for a 15 minute high intensity core workout or what we like to call CORE-DIO!   This is a quick, intense workout that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7-zjc6dAufg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7-zjc6dAufg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7-zjc6dAufg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-26T13:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZkXkn0dL-m9yzf69-2YoHPiR5-I",
    "id": {
      "kind": "youtube#video",
      "videoId": "lGYT00uTDqY"
    },
    "snippet": {
      "publishedAt": "2021-07-12T13:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Ab Strength Workout (Core Training/All Fitness Levels)",
      "description": "Check in weekly with this 15 minute ab strength workout if you need a way to build up your core strength over time   This workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lGYT00uTDqY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lGYT00uTDqY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lGYT00uTDqY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-12T13:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qwUUEXTAkmCT9y5sU9tTfH6G42Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "J3Qd-Q9WcMY"
    },
    "snippet": {
      "publishedAt": "2021-09-30T13:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Finisher Workout [Advanced HIIT]",
      "description": "If you ever have a little left in the tank following a workout, you can add this 10 minute full body finisher for a complete workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/J3Qd-Q9WcMY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/J3Qd-Q9WcMY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/J3Qd-Q9WcMY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-30T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pFXds-L6-nqYIaQYs8xHkM_N4_o",
    "id": {
      "kind": "youtube#video",
      "videoId": "y48grJpUIxg"
    },
    "snippet": {
      "publishedAt": "2021-07-28T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Mini Band Booty Warm-Up/Workout",
      "description": "Tap in for a quick 10 Minute mini band circuit that will fire up the glutes as a workout or warm up   The glutes are the biggest ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y48grJpUIxg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y48grJpUIxg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y48grJpUIxg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-28T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jmpfgtv48MD1LYgQjwi9ofT9bc0",
    "id": {
      "kind": "youtube#video",
      "videoId": "IvmaekQfKiw"
    },
    "snippet": {
      "publishedAt": "2020-12-30T16:49:51Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Full Body Cardio Workout (No Equipment)",
      "description": "Follow along with us for a 15 minute full body cardio workout if you're looking to elevate your heart rate and work up a sweat!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IvmaekQfKiw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IvmaekQfKiw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IvmaekQfKiw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-30T16:49:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rIlXkYgRiY4UOknuNBcYUS2HeD4",
    "id": {
      "kind": "youtube#video",
      "videoId": "g2jocInockU"
    },
    "snippet": {
      "publishedAt": "2020-09-08T15:00:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute High Intensity Abs/Core Workout [For Advanced and Beginners]",
      "description": "Follow along with us for a 10 minute high intensity core/ab workout! We give you 2 different variations of each exercise so this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g2jocInockU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g2jocInockU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g2jocInockU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-08T15:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uHdhmVw6TZkGoE0LZ22lP3ed3Sw",
    "id": {
      "kind": "youtube#video",
      "videoId": "uyxOhWTln_s"
    },
    "snippet": {
      "publishedAt": "2021-01-24T19:00:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute High Intensity Cardio Workout (With Modifications)",
      "description": "Follow along with us for a quick 15 minute high intensity cardio workout that WILL work up a sweat in no time! You can do this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uyxOhWTln_s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uyxOhWTln_s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uyxOhWTln_s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-24T19:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ndm2uTMyjWTb_3uhZbbWBMlFT44",
    "id": {
      "kind": "youtube#video",
      "videoId": "jbtREQi8iwI"
    },
    "snippet": {
      "publishedAt": "2021-11-26T05:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Questions &amp; Coffee - Answering YOUR Commonly Asked Questions [Episode 2]",
      "description": "We're back with another round of Questions & Coffee--where we answer some of your most commonly asked questions. Grab a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jbtREQi8iwI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jbtREQi8iwI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jbtREQi8iwI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-26T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D3mizPCC3DuTlEtG_MfTnXbgLMA",
    "id": {
      "kind": "youtube#video",
      "videoId": "rCmW1bsCMWc"
    },
    "snippet": {
      "publishedAt": "2021-11-15T15:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Dumbbell Drop Set Workout [Strength Training]",
      "description": "Tap in for a full body drop set workout that's great for building muscle, strength and burning a lot of calories This is also a great ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rCmW1bsCMWc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rCmW1bsCMWc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rCmW1bsCMWc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-15T15:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jdrjLqBFFCDviqQ2JY92mwO7VVs",
    "id": {
      "kind": "youtube#video",
      "videoId": "mf1xQS_Zlkc"
    },
    "snippet": {
      "publishedAt": "2021-02-16T14:00:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Dumbbell Workout NO REPEAT (Strength &amp; Conditioning)",
      "description": "Follow along with us for a 20 minute full body dumbbell workout that will challenge your overall strength and conditioning! This will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mf1xQS_Zlkc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mf1xQS_Zlkc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mf1xQS_Zlkc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-16T14:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "27PcX2Bo2ujgYF_CkG31RSUOlAQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "38sCE5PMo_w"
    },
    "snippet": {
      "publishedAt": "2021-02-01T06:10:49Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute High Intensity AMRAP Workout (No Equipment + Low Impact)",
      "description": "This 15 minute high intensity workout was designed to push your body to the limit! We wanted to switch things up and introduce ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/38sCE5PMo_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/38sCE5PMo_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/38sCE5PMo_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-01T06:10:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oB5DKaSmnzRWlLZ28AVevMjAfi4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Huq6i9gscrk"
    },
    "snippet": {
      "publishedAt": "2021-01-12T23:49:33Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Lower Body Dumbbell Workout (For All Fitness Levels)",
      "description": "Tap in with us for a 15 minute lower body dumbbell workout that will for sure fire up your legs! This workout is designed for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Huq6i9gscrk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Huq6i9gscrk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Huq6i9gscrk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-12T23:49:33Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hNLCBRaJvj6lxASNqbpFKno-V9I",
    "id": {
      "kind": "youtube#video",
      "videoId": "1wDbADvGOAQ"
    },
    "snippet": {
      "publishedAt": "2021-06-10T19:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "The Basics of Protein Shakes [+ Our Go-to Protein &amp; Smoothie Recipes]",
      "description": "We received numerous requests to drop a video about protein intake, shakes and smoothie recipes, so we decided to give you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1wDbADvGOAQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1wDbADvGOAQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1wDbADvGOAQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-10T19:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z4oqtoGSw-PYVdOpQskKlGEzXEU",
    "id": {
      "kind": "youtube#video",
      "videoId": "PFGeohvbNq8"
    },
    "snippet": {
      "publishedAt": "2020-12-09T13:00:21Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Dumbbell Workout (Light Weight)",
      "description": "Follow along with us for a 20 minute full body dumbbell workout that WILL work your entire body! This workout is designed for light ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PFGeohvbNq8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PFGeohvbNq8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PFGeohvbNq8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-09T13:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4Y7gJ4wV-mFOLS-_2HiMvgfTXHw",
    "id": {
      "kind": "youtube#video",
      "videoId": "owhvNm37Mv4"
    },
    "snippet": {
      "publishedAt": "2020-06-09T19:54:23Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute FULL BODY HIIT Workout (Bodyweight/No Equipment)",
      "description": "Here is a full body hiit workout that will definitely work up a sweat in just 20 minutes! You can do this high intensity workout pretty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/owhvNm37Mv4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/owhvNm37Mv4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/owhvNm37Mv4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-09T19:54:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dbWGzWZtgdgMY3mBXYCcUa_vPSo",
    "id": {
      "kind": "youtube#video",
      "videoId": "fbJL4_ccF9Q"
    },
    "snippet": {
      "publishedAt": "2021-06-28T16:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Lower Body Dumbbell HIIT Workout (With Modifications)",
      "description": "Tap in with us for a quick 20 minute lower body dumbbell hiit workout! This is a fun and challenging workout that will target your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fbJL4_ccF9Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fbJL4_ccF9Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fbJL4_ccF9Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-28T16:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MU4RLKxXeklRBhrEcVX0tCHFufo",
    "id": {
      "kind": "youtube#video",
      "videoId": "fkJNvdZ3CbQ"
    },
    "snippet": {
      "publishedAt": "2021-11-11T00:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Dumbbell Back &amp; Bicep Workout [Strength Training]",
      "description": "Tap in to this 10 minute upper body strength workout that specifically targets the back & biceps. This dumbbell routine designed to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fkJNvdZ3CbQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fkJNvdZ3CbQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fkJNvdZ3CbQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-11T00:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RZWkx-P99Mw4Y5hRpdacxQQb97g",
    "id": {
      "kind": "youtube#video",
      "videoId": "RPbscYct3I4"
    },
    "snippet": {
      "publishedAt": "2021-02-03T15:14:21Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Upper Body Dumbbell Workout [Build Muscle &amp; Strength]",
      "description": "Follow along with us for a 20 minute upper body dumbbell workout that will help you build muscle (tone) and strength!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RPbscYct3I4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RPbscYct3I4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RPbscYct3I4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-03T15:14:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Q8XBHD_OkkbwYt85U7l4Ns9jJDM",
    "id": {
      "kind": "youtube#video",
      "videoId": "1-EQ3cYrLMo"
    },
    "snippet": {
      "publishedAt": "2022-03-02T01:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Our Much Needed Hawaii Vacation [VLOG] //Celebrating Our Engagement &amp; 500K!",
      "description": "Many of you asked for more vlogs, so we thought we'd give you a glimpse into our long overdue vacation! We hope to give you a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1-EQ3cYrLMo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1-EQ3cYrLMo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1-EQ3cYrLMo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-02T01:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8y1zvf3liLUlf9XbqwYjwTuHvlA",
    "id": {
      "kind": "youtube#video",
      "videoId": "fu4ZL6DQpPk"
    },
    "snippet": {
      "publishedAt": "2021-04-11T19:00:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body HIIT Workout (Low Impact/No Equipment)",
      "description": "Tap in with us for a 30 minute FULL BODY HIIT workout that is suitable for all fitness levels---advanced and beginners!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fu4ZL6DQpPk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fu4ZL6DQpPk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fu4ZL6DQpPk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-11T19:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "16B0k5tfYjGLFai4YzwDFz24YbQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "QpcZEskn6Fg"
    },
    "snippet": {
      "publishedAt": "2021-09-07T12:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Kettlebell HIIT Workout (Advanced Strength Training)",
      "description": "Follow along with us for a 25 minute, FULL BODY, NO REPEAT kettlebell workout! This is a great workout for overall strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QpcZEskn6Fg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QpcZEskn6Fg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QpcZEskn6Fg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-07T12:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MDyiMio_MBe5909t2rDnsrHjg9M",
    "id": {
      "kind": "youtube#video",
      "videoId": "uSyH0dZu8TM"
    },
    "snippet": {
      "publishedAt": "2021-08-17T23:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Cardio-HIIT Workout [With Modifications]",
      "description": "Join us for 30 minutes of fun with this cardio-hiit workout! This is an efficient full body routine that's built to take your cardio game to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uSyH0dZu8TM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uSyH0dZu8TM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uSyH0dZu8TM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-17T23:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lleQCGQryO8TgzyN5ANWoPfCe9I",
    "id": {
      "kind": "youtube#video",
      "videoId": "xqVBoyKXbsA"
    },
    "snippet": {
      "publishedAt": "2020-09-15T14:00:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Full Body Dumbbell Workout [Strength and Conditioning]",
      "description": "Follow along with us for a 15 minute full body dumbbell workout that will challenge your level of strength and conditioning!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xqVBoyKXbsA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xqVBoyKXbsA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xqVBoyKXbsA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-15T14:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "J6gsrHNBIe-8EoyGYclHoxS79g4",
    "id": {
      "kind": "youtube#video",
      "videoId": "eiMOxvZKyvM"
    },
    "snippet": {
      "publishedAt": "2021-09-13T12:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Push-Up Progression Workout [Beginner Strength Training]",
      "description": "This 10 minute workout is made for people who struggle with push-ups and don't know how to progress to do them effectively.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eiMOxvZKyvM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eiMOxvZKyvM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eiMOxvZKyvM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-13T12:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KspyqLCw1B4XNRWBfpM01MUaZXs",
    "id": {
      "kind": "youtube#video",
      "videoId": "N4HbeyDChFw"
    },
    "snippet": {
      "publishedAt": "2021-01-10T18:30:02Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Strength Workout (No Equipment)",
      "description": "Follow along with us for a 20 minute full body STRENGTH workout if you're looking to tone up and build strength! This workout is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/N4HbeyDChFw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/N4HbeyDChFw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/N4HbeyDChFw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-10T18:30:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nWtJLB7raQHmof42HxQUKPN0WAk",
    "id": {
      "kind": "youtube#video",
      "videoId": "_TUzOF8Nexk"
    },
    "snippet": {
      "publishedAt": "2021-08-23T23:15:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute &quot;Core-dio&quot; Workout [High Intensity Abs]",
      "description": "Tap in with us for a 20 minute high intensity core workout or what we like to call CORE-DIO! This is an intense workout that targets ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_TUzOF8Nexk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_TUzOF8Nexk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_TUzOF8Nexk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-23T23:15:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NRK-BGsj-_SPhhOImtmSnPF5LM4",
    "id": {
      "kind": "youtube#video",
      "videoId": "M7qogNry8t4"
    },
    "snippet": {
      "publishedAt": "2021-08-05T19:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Active Recovery Workout [No Equipment]",
      "description": "Tap in with us for a 20 minute full body active recovery workout when you're experiencing stiffness/soreness or just want to keep ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M7qogNry8t4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M7qogNry8t4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M7qogNry8t4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-05T19:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fcnXqAIh2opqg074qpnge-zrmDU",
    "id": {
      "kind": "youtube#video",
      "videoId": "gSgh41DY4HI"
    },
    "snippet": {
      "publishedAt": "2021-06-21T13:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Slider HIIT Workout (Advanced/No Repeat)",
      "description": "Tap in with us for another 20 Minute Slider HIIT Workout! Exercise sliders are great for advancing bodyweight exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gSgh41DY4HI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gSgh41DY4HI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gSgh41DY4HI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-21T13:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2ZRAVfwT26mcvt6maenSctEfqaM",
    "id": {
      "kind": "youtube#video",
      "videoId": "PUs5vQ2Osbo"
    },
    "snippet": {
      "publishedAt": "2021-02-23T17:30:17Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Bodyweight HIIT Workout (For Advanced and Beginners)",
      "description": "Follow along with us for a 30 minute full body HIIT workout that will help increase your overall strength and endurance.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PUs5vQ2Osbo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PUs5vQ2Osbo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PUs5vQ2Osbo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-23T17:30:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "g4P5N7YF_nlyUMH8ghht-49Jouw",
    "id": {
      "kind": "youtube#video",
      "videoId": "XC3LJwbGteE"
    },
    "snippet": {
      "publishedAt": "2021-08-10T10:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Drop Set Dumbbell Workout [Advanced Strength Training]",
      "description": "Tap in for a drop set workout that's great for building muscle, strength and burning a lot of calories This is also a great way to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XC3LJwbGteE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XC3LJwbGteE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XC3LJwbGteE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-10T10:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1330vm5Z7eWldo8loIX45Q1bGY0",
    "id": {
      "kind": "youtube#video",
      "videoId": "oxOJZzd9OcQ"
    },
    "snippet": {
      "publishedAt": "2021-03-01T14:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Kettlebell Workout (With Modifications)",
      "description": "Follow along with us for a 20 minute FULL BODY kettlebell workout that is great for overall strength and conditioning. All you need ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oxOJZzd9OcQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oxOJZzd9OcQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oxOJZzd9OcQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-01T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jxFnCRFfe27Vmhs0QGOFc1WlDok",
    "id": {
      "kind": "youtube#video",
      "videoId": "mt-12thGyJ4"
    },
    "snippet": {
      "publishedAt": "2021-05-18T13:00:14Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Full Body Kettlebell HIIT Workout [Advanced]",
      "description": "Follow along with us for a 25 minute, FULL BODY, NO REPEAT kettlebell workout! This is a great workout for overall strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mt-12thGyJ4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mt-12thGyJ4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mt-12thGyJ4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-18T13:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9WSzl8RbyV0_OgCYD_tIUSTcaVs",
    "id": {
      "kind": "youtube#video",
      "videoId": "L6M0j6AwDGQ"
    },
    "snippet": {
      "publishedAt": "2021-01-17T22:15:37Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body HIIT Workout (No Equipment + Modifications)",
      "description": "Follow along with us for an efficient 30 minute bodyweight HIIT workout that will target your full body and work up a sweat in no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/L6M0j6AwDGQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/L6M0j6AwDGQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/L6M0j6AwDGQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-17T22:15:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pOzrn_PdZsJ-frcXDyDnrxoE_U4",
    "id": {
      "kind": "youtube#video",
      "videoId": "SFXzweD4fKg"
    },
    "snippet": {
      "publishedAt": "2021-03-08T14:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Dumbbell Booty Workout (Advanced With Modifications)",
      "description": "Follow along with us for a 20 minute lower body workout with emphasis on the booty/glutes! All you need for this workout is a pair ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SFXzweD4fKg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SFXzweD4fKg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SFXzweD4fKg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-08T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "B2FFJwzpVWpEvSMeDUmIfz5SRBc",
    "id": {
      "kind": "youtube#video",
      "videoId": "Lj8v-wsNMU4"
    },
    "snippet": {
      "publishedAt": "2021-10-21T00:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Dumbbell Booty and Abs Workout [Strength Training]",
      "description": "Tap in with us for a quick 20 minute workout focused on both the booty and the abs   These are the most requested muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Lj8v-wsNMU4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Lj8v-wsNMU4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Lj8v-wsNMU4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-21T00:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "r_07_eZnPNLZrMXoqEmu2O4IeLU",
    "id": {
      "kind": "youtube#video",
      "videoId": "IU913YMDXCk"
    },
    "snippet": {
      "publishedAt": "2022-01-04T01:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Cardio-HIIT \u201cDance\u201d Workout [Our Engagement Celebration]",
      "description": "This isn't just any dance workout, this is THE workout that we celebrated our recent engagement with! With we picked 10 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IU913YMDXCk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IU913YMDXCk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IU913YMDXCk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-04T01:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wi_5y_t4GK51Cns_s9Nw0GuaW_M",
    "id": {
      "kind": "youtube#video",
      "videoId": "jT4pdhefoXk"
    },
    "snippet": {
      "publishedAt": "2021-04-07T13:00:26Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "10 Minute Full Body Partner Stretch (A Quick Daily Routine)",
      "description": "If you need a little extra accountability when it comes to stretching, grab a partner and tap in to our quick 10 minute full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jT4pdhefoXk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jT4pdhefoXk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jT4pdhefoXk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-07T13:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SXtJE0yOgyRCzlAlS3UXHiLcpfk",
    "id": {
      "kind": "youtube#video",
      "videoId": "4sUGg9mcMGU"
    },
    "snippet": {
      "publishedAt": "2021-05-11T14:00:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell Workout NO REPEAT (Advanced)",
      "description": "Tap in with us for a 30 minute FULL BODY dumbbell workout that will challenge your overall strength and conditioning! Enjoy this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4sUGg9mcMGU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4sUGg9mcMGU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4sUGg9mcMGU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-11T14:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W8LIIcaXMlWXd3BQV_Lj8y3n2pE",
    "id": {
      "kind": "youtube#video",
      "videoId": "tgm_QjUiwlk"
    },
    "snippet": {
      "publishedAt": "2022-01-25T23:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Cardio HIIT Workout [NO REPEAT]",
      "description": "Tap in with us for a fun 30 Minute FULL BODY Cardio-HIIT workout! This workout will get your heart rate up with a mixture of high ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tgm_QjUiwlk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tgm_QjUiwlk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tgm_QjUiwlk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-25T23:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NQKhBM6QbEiHif7dLQ9lBRF0bbU",
    "id": {
      "kind": "youtube#video",
      "videoId": "EohSREiJGXQ"
    },
    "snippet": {
      "publishedAt": "2021-08-16T13:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell HIIT Workout [Advanced]",
      "description": "Grab a set of dumbbells and tap in for a 30 Minute Dumbbell HIIT Workout that will help build your overall strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EohSREiJGXQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EohSREiJGXQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EohSREiJGXQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-16T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SHF8hph7boHgVAk8kOj3Qe2avtU",
    "id": {
      "kind": "youtube#video",
      "videoId": "UDaq2gnrnlo"
    },
    "snippet": {
      "publishedAt": "2021-12-17T01:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "WE&#39;RE ENGAGED! // How Juice Turned A Workout Video Into A Proposal \ud83d\udc8d",
      "description": "We're finally engaged! We wanted to share a mini vlog of how I (Juice) tricked Toya into doing a workout that was disguised as a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UDaq2gnrnlo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UDaq2gnrnlo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UDaq2gnrnlo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-17T01:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Vc1IUol0HvWBK0VbKWTIm4qXyAY",
    "id": {
      "kind": "youtube#video",
      "videoId": "qs9wyBQrNYA"
    },
    "snippet": {
      "publishedAt": "2021-07-08T01:15:13Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "45 Minute Full Body Dumbbell Workout [Strength Training]",
      "description": "Tap in with us for a 45 minute full body dumbbell strength training workout if you're looking build strength/muscle, endurance, and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qs9wyBQrNYA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qs9wyBQrNYA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qs9wyBQrNYA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-08T01:15:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pw5ZVXDCLQZYAWHsa8p1qtY4mtU",
    "id": {
      "kind": "youtube#video",
      "videoId": "_PRk8DH2_mY"
    },
    "snippet": {
      "publishedAt": "2021-11-30T00:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Lower Body Dumbbell Strength Workout",
      "description": "Tap in with us for a 20 minute lower body dumbbell workout that will for sure fire up your legs! This workout is designed for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_PRk8DH2_mY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_PRk8DH2_mY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_PRk8DH2_mY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-30T00:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6BdAKO5X_rc1D4J6aU4iz0tqDhg",
    "id": {
      "kind": "youtube#video",
      "videoId": "dhCwHlL32Jw"
    },
    "snippet": {
      "publishedAt": "2022-01-11T16:00:12Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell Strength Workout [NO REPEAT]",
      "description": "Tap in with us for a 30 minute full body dumbbell workout if you're looking build strength/muscle, endurance, and burn a lot of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dhCwHlL32Jw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dhCwHlL32Jw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dhCwHlL32Jw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-11T16:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hAgVehACHusoebv-1n3LBDNzswA",
    "id": {
      "kind": "youtube#video",
      "videoId": "8aXwejFPDTw"
    },
    "snippet": {
      "publishedAt": "2021-08-08T22:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Full Body Strength Workout [No Equipment]",
      "description": "25 Minute Full Body Strength Workout [No Equipment] Tap in with us for a 25 Minute workout focused on building strength with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8aXwejFPDTw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8aXwejFPDTw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8aXwejFPDTw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-08T22:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9gTpWjj2QRW4LsOoaY__cBI46Qo",
    "id": {
      "kind": "youtube#video",
      "videoId": "9lVBk1gS6qc"
    },
    "snippet": {
      "publishedAt": "2021-04-13T14:00:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Cardio Workout (High Intensity With Modifications)",
      "description": "Tap in with us for an efficient 20 Minute cardio workout that will definitely turn up the heat . This workout will target the full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9lVBk1gS6qc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9lVBk1gS6qc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9lVBk1gS6qc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-13T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SLww0o1v--lTM9LHESPOPD6QJPM",
    "id": {
      "kind": "youtube#video",
      "videoId": "z9JuN06wj14"
    },
    "snippet": {
      "publishedAt": "2021-06-23T13:00:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Resistance Band Strength Workout (High Intensity/No Repeat)",
      "description": "Tap in with us for a quick 20 minute full body strength workout using resistance bands! This is a high intensity workout that will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/z9JuN06wj14/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/z9JuN06wj14/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/z9JuN06wj14/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-23T13:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HHwWT-_xDcqAywI9hQntWSI4qCs",
    "id": {
      "kind": "youtube#video",
      "videoId": "326GR2A7q-s"
    },
    "snippet": {
      "publishedAt": "2021-08-25T23:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Kid-Friendly HIIT Workout [Ages 5 +]",
      "description": "Join us for a 15 Minute HIIT workout that is friendly for Kids to join in! This is a fun, full body workout to do as a family, group, PE ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/326GR2A7q-s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/326GR2A7q-s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/326GR2A7q-s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-25T23:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bxb0ynogyzhDPCQicJxlOaIIBFk",
    "id": {
      "kind": "youtube#video",
      "videoId": "CGuTMuArYGI"
    },
    "snippet": {
      "publishedAt": "2022-02-08T20:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell HIIT Workout [ADVANCED]",
      "description": "Grab a set of dumbbells and tap in for a 30 Minute Dumbbell HIIT Workout that WILL help build your overall strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CGuTMuArYGI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CGuTMuArYGI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CGuTMuArYGI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-08T20:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6jHF6oPW4toD8UnaDyn6lDH7VE8",
    "id": {
      "kind": "youtube#video",
      "videoId": "IEyXbt_ZbF4"
    },
    "snippet": {
      "publishedAt": "2021-11-02T13:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Kettlebell Strength Workout [Advanced]",
      "description": "Follow along with us for a 25 minute FULL BODY kettlebell strength workout! This is a great workout for building overall strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IEyXbt_ZbF4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IEyXbt_ZbF4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IEyXbt_ZbF4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-02T13:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gW15fKdLfL48Fd5ave2U-Idhj98",
    "id": {
      "kind": "youtube#video",
      "videoId": "Cp2I4InoSCE"
    },
    "snippet": {
      "publishedAt": "2021-04-20T15:18:33Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Bodyweight AMRAP Workout [High Intensity Challenge]",
      "description": "We're back with another AMRAP (as many reps as possible) workout which will help increase your strength and endurance   ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Cp2I4InoSCE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Cp2I4InoSCE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Cp2I4InoSCE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-20T15:18:33Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pn2cHGohHBzLnMIEnVLtJp3KXLA",
    "id": {
      "kind": "youtube#video",
      "videoId": "W-vD82mdd_U"
    },
    "snippet": {
      "publishedAt": "2020-08-07T20:56:59Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Full Body Medicine Ball HIIT Workout + Giveaway (For Advanced and Beginners)",
      "description": "In this video, we take you guys through a full body high intensity workout using only a medicine ball! We will also show you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W-vD82mdd_U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W-vD82mdd_U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W-vD82mdd_U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-07T20:56:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OaByawWAy47mNUDvFsrh1h-8JyM",
    "id": {
      "kind": "youtube#video",
      "videoId": "M0uO8X3_tEA"
    },
    "snippet": {
      "publishedAt": "2021-10-13T23:30:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Cardio HIIT Workout [NO REPEAT]",
      "description": "Tap in with us for a fun 20 Minute high intensity cardio workout This workout contains a mixture of high intensity strength, cardio, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M0uO8X3_tEA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M0uO8X3_tEA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M0uO8X3_tEA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-13T23:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aNKEgQKGtj6oVuLZ8nxdZjAbiMc",
    "id": {
      "kind": "youtube#video",
      "videoId": "UoYLPbPi938"
    },
    "snippet": {
      "publishedAt": "2021-10-04T14:30:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "60 Minute Full Body Dumbbell Workout [Strength &amp; Conditioning]",
      "description": "Tap in with us for 50-60 minutes of full body dumbbell strength training. All you need is a light to moderate pair of dumbbells or ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UoYLPbPi938/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UoYLPbPi938/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UoYLPbPi938/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-04T14:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "88CrAnFj4ebCLkb5q7xWNHWZ9jM",
    "id": {
      "kind": "youtube#video",
      "videoId": "Q2cMMnUuKYQ"
    },
    "snippet": {
      "publishedAt": "2021-04-05T00:30:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Strength Workout (No Equipment/No Repeat)",
      "description": "Tap in with us for a 20 minute FULL BODY strength building workout that requires no equipment! This workout focuses on 'time ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Q2cMMnUuKYQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Q2cMMnUuKYQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Q2cMMnUuKYQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-05T00:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "f4lGi2fNbhsLFhPsrjM1uHSc39k",
    "id": {
      "kind": "youtube#video",
      "videoId": "GY1JhB9BEkk"
    },
    "snippet": {
      "publishedAt": "2021-08-01T22:00:09Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Beginner Dumbbell Workout [With Modifications]",
      "description": "Tap in with us for a 30 minute dumbbell strength workout---especially if you're a beginner who needs guidance with weight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GY1JhB9BEkk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GY1JhB9BEkk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GY1JhB9BEkk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-01T22:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UqV8T4qDzaXEKOCi6hipJAOoK3E",
    "id": {
      "kind": "youtube#video",
      "videoId": "55EfZ7RDhf8"
    },
    "snippet": {
      "publishedAt": "2021-05-04T14:30:15Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Resistance Band Strength Workout (With Modifications)",
      "description": "Tap in with us for a 30 minute full body strength workout using resistance bands! In this workout, we will target the upper body, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/55EfZ7RDhf8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/55EfZ7RDhf8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/55EfZ7RDhf8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-04T14:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hazNQnkJvK7kMNk1iyNc0oywBms",
    "id": {
      "kind": "youtube#video",
      "videoId": "s7LwfKnjxto"
    },
    "snippet": {
      "publishedAt": "2020-10-29T12:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "15 Minute Medicine Ball HIIT Workout [For All Fitness Levels]",
      "description": "In this video, we take you through a full body, high intensity workout using only a medicine ball! We included beginner and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s7LwfKnjxto/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s7LwfKnjxto/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s7LwfKnjxto/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-29T12:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3zVLBk_wpSCOko0L03FSVe0CF_Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "RQ9lzmJ4Dlc"
    },
    "snippet": {
      "publishedAt": "2021-03-15T13:00:17Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "25 Minute Full Body Plyo HIIT Workout (Advanced + No Equipment)",
      "description": "Follow along with us for a 25 minute full body (no equipment) PLYO-HIIT workout if you're looking to get your heart rate up and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RQ9lzmJ4Dlc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RQ9lzmJ4Dlc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RQ9lzmJ4Dlc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-15T13:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sI7MVjtlLabkHMo4vbhbYJnt9XI",
    "id": {
      "kind": "youtube#video",
      "videoId": "rEEZB68GTs0"
    },
    "snippet": {
      "publishedAt": "2021-06-15T16:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "30 Minute Full Body Dumbbell AMRAP [Advanced High Intensity Workout]",
      "description": "We wanted to switch things up and have you join our workout for a change   This is a full body dumbbell workout that we do ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rEEZB68GTs0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rEEZB68GTs0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rEEZB68GTs0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-15T16:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Vy3UzPkeAgWgSuUQx663AVvPlMA",
    "id": {
      "kind": "youtube#video",
      "videoId": "7kqtFmSsX9Q"
    },
    "snippet": {
      "publishedAt": "2020-06-30T19:49:58Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "We Turned Our Workout Into A NIKE Commercial | Anniversary Vlog + Behind The Scenes",
      "description": "Every year for our anniversary, we celebrate by filming and editing a cinematic workout video. This year, we decided to change it ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7kqtFmSsX9Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7kqtFmSsX9Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7kqtFmSsX9Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-30T19:49:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D0CjuomewJveaI-rCn6jckhOESA",
    "id": {
      "kind": "youtube#video",
      "videoId": "YH3nAHCKJHg"
    },
    "snippet": {
      "publishedAt": "2020-02-25T21:45:03Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Lower Body Dumbbell Workout: (For At-Home or Gym)",
      "description": "This workout has a combination of strength, stability, endurance, which makes it a great all in one leg day. It was designed to be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YH3nAHCKJHg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YH3nAHCKJHg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YH3nAHCKJHg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-25T21:45:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fG0gbs0I7KK4xHuTwvyTt1GMLPQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "SSQvPKzIFHw"
    },
    "snippet": {
      "publishedAt": "2020-09-29T16:24:02Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "8 Barbell Exercises That Target Your Glutes (+ Tips and Cues)",
      "description": "If you're not already, try out these 8 effective barbell exercises that WILL target your glutes. Whether you're in need of different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SSQvPKzIFHw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SSQvPKzIFHw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SSQvPKzIFHw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-29T16:24:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WTGMTQ-EVNs43IPeafLHU1KytYw",
    "id": {
      "kind": "youtube#video",
      "videoId": "CNU3Y2Rib5U"
    },
    "snippet": {
      "publishedAt": "2021-03-30T13:00:16Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Exercise Slider HIIT Workout (+ Modifications)",
      "description": "Tap in with us for a 20 Minute Slider HIIT Workout! Exercise sliders are great for advancing bodyweight exercises and isolating ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CNU3Y2Rib5U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CNU3Y2Rib5U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CNU3Y2Rib5U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-30T13:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2YC50Tojyrd0ORku37CgElogT_o",
    "id": {
      "kind": "youtube#video",
      "videoId": "Yd9_FWt4-i8"
    },
    "snippet": {
      "publishedAt": "2021-09-09T17:30:11Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "Update of Toya&#39;s Lupus Journey [Your Story Our Fight Podcast]",
      "description": "In November of 2020, I shared my initial diagnosis of early stage lupus. Since then, it has been a daily journey of navigating and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Yd9_FWt4-i8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Yd9_FWt4-i8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Yd9_FWt4-i8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-09T17:30:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eblH34z_7HKHIu7HvX8wdb5oA0s",
    "id": {
      "kind": "youtube#video",
      "videoId": "QIg-p2Jxu8c"
    },
    "snippet": {
      "publishedAt": "2019-10-09T22:02:34Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "THE 100 REP CHALLENGE - and why you should try it!",
      "description": "As tough as this workout may have looked, it was definitely fun and fulfilling in the end. We want to personally challenge you to do ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QIg-p2Jxu8c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QIg-p2Jxu8c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QIg-p2Jxu8c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-09T22:02:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qPcl9ijrasDWWa6WP25E8BGB_FQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "d4nZy4maiIg"
    },
    "snippet": {
      "publishedAt": "2020-09-01T15:00:10Z",
      "channelId": "UCwrXi5ZknKThspJc-Gai04g",
      "title": "20 Minute Full Body Partner Workout (High-Intensity/No Equipment)",
      "description": "This 20 minute full body partner workout is fast-paced, fun and challenging! The workout is strength based with no equipment but ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/d4nZy4maiIg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/d4nZy4maiIg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/d4nZy4maiIg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Juice & Toya",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-01T15:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pEyuPJL2JuRj0aLcFX8UrMjkeNI",
    "id": {
      "kind": "youtube#video",
      "videoId": "0lEADrqPPNM"
    },
    "snippet": {
      "publishedAt": "2022-05-09T17:18:58Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "LEGS + HIPS WARM UP Routine Follow Along  (Warm Up Before Leg Day)",
      "description": "In this LEGS + HIPS WARM UP Routine Follow Along I would like to share with you the dynamic and static movements that I ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0lEADrqPPNM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0lEADrqPPNM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0lEADrqPPNM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T17:18:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uR6DGbOwAzRP6cn2pbqNujQHu3A",
    "id": {
      "kind": "youtube#video",
      "videoId": "F4XPUzc2_sQ"
    },
    "snippet": {
      "publishedAt": "2022-11-14T21:43:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "14 november 2022",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/F4XPUzc2_sQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/F4XPUzc2_sQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/F4XPUzc2_sQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-14T21:43:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "als9rLDzvkZx4kXi3zbCfh7xF4k",
    "id": {
      "kind": "youtube#video",
      "videoId": "4qE8KAQzVw0"
    },
    "snippet": {
      "publishedAt": "2022-07-06T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute INTENSE BODYWEIGHT + KETTLEBELL Workout (ADVANCED HIIT\ud83d\udd25Burn Up to 500 Calories)",
      "description": "In this 30 minute INTENSE BODYWEIGHT + KETTLEBELL workout we will have a 50/50 combination of kettlebell & bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4qE8KAQzVw0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4qE8KAQzVw0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4qE8KAQzVw0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-06T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LVRDR56wZCf-YqMsbampwR_aHSk",
    "id": {
      "kind": "youtube#video",
      "videoId": "A4uEghThXOc"
    },
    "snippet": {
      "publishedAt": "2022-05-20T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Min Dumbbell CORE &amp; ABS Workout (DYNAMIC Dumbbells + Bodyweight Workout)",
      "description": "In this 15 Min Bodyweight + Dumbbell CORE & ABS Workout we will be having 15x dynamic core and ab exercises. We will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/A4uEghThXOc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/A4uEghThXOc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/A4uEghThXOc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-20T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WEYIy2hRpCsysgqq6K6gniJUaGA",
    "id": {
      "kind": "youtube#video",
      "videoId": "r6Y68hdzr_w"
    },
    "snippet": {
      "publishedAt": "2023-03-02T12:43:57Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "2 maart 2023",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/r6Y68hdzr_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/r6Y68hdzr_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/r6Y68hdzr_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-02T12:43:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ucfsMvO3zgwxgvw7sKz58bYOwUI",
    "id": {
      "kind": "youtube#video",
      "videoId": "ORmcE-Uc_IM"
    },
    "snippet": {
      "publishedAt": "2022-04-29T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 MIN DYNAMIC ABS WORKOUT",
      "description": "In this 15 MIN DYNAMIC ABS WORKOUT we will be having 15x different movements to target the abdominals and the core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ORmcE-Uc_IM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ORmcE-Uc_IM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ORmcE-Uc_IM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-29T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bbBCXUW2ONTNVX4_NaordFXjChk",
    "id": {
      "kind": "youtube#video",
      "videoId": "csbOMK95a00"
    },
    "snippet": {
      "publishedAt": "2023-02-23T19:12:38Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "23 februari 2023",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/csbOMK95a00/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/csbOMK95a00/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/csbOMK95a00/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-23T19:12:38Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "a1iRQBBLLJdekBJpntVn4vF6Qtw",
    "id": {
      "kind": "youtube#video",
      "videoId": "9C8k12QnzWI"
    },
    "snippet": {
      "publishedAt": "2023-02-05T21:47:50Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "The Current Chest Routine",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9C8k12QnzWI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9C8k12QnzWI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9C8k12QnzWI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-05T21:47:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w4RIyQ7ITZ40YqDF0PXjpv_ZbnA",
    "id": {
      "kind": "youtube#video",
      "videoId": "KPLBBIKly-w"
    },
    "snippet": {
      "publishedAt": "2022-08-15T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min DOUBLE SAVAGE Bodyweight FULL BODY HIIT (Burn Up To 500+ Calories\ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 Min DOUBLE SAVAGE Bodyweight FULL BODY HIIT we will have 2x blocks of super challenging exercises. Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KPLBBIKly-w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KPLBBIKly-w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KPLBBIKly-w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-15T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ce3pSHZvSe1Sw1pl8YsTPwDYK-A",
    "id": {
      "kind": "youtube#video",
      "videoId": "fEKLwfYJXBY"
    },
    "snippet": {
      "publishedAt": "2022-05-30T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SAVAGE Bodyweight FULL BODY HIIT Workout (Burn Up To 500+ Calories\ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 min SAVAGE Bodyweight FULL BODY HIIT Workout we will be having 2x18 challenging movements. In This workout we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fEKLwfYJXBY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fEKLwfYJXBY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fEKLwfYJXBY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-30T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3td50nW60WjcpxOWqwm_Ig-Ytt8",
    "id": {
      "kind": "youtube#video",
      "videoId": "CoB1Vx4ZpCc"
    },
    "snippet": {
      "publishedAt": "2022-07-18T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min EXTREME SAVAGE BODYWEIGHT HIIT (\ud83d\udd25Burn Up To 500 Calories\ud83d\udd25)",
      "description": "In this 30 min SAVAGE BODYWEIGHT HIIT we will have 2x blocks of exercises. Each Block contains 5x supersets and we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CoB1Vx4ZpCc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CoB1Vx4ZpCc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CoB1Vx4ZpCc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-18T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "t5hzqmqiyO7JC6d8GqcAA080_Io",
    "id": {
      "kind": "youtube#video",
      "videoId": "6Bui2IaOOwE"
    },
    "snippet": {
      "publishedAt": "2022-06-20T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min ADVANCED BODYWEIGHT + KETTLEBELL HIIT Workout (Burn 500+ Calories\ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 Min ADVANCED BODYWEIGHT + KETTLEBELL HIIT Workout we will be having 2x blocks of 12x movements.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6Bui2IaOOwE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6Bui2IaOOwE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6Bui2IaOOwE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-20T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EL6QS-kM57F4frAi76H664LhSRo",
    "id": {
      "kind": "youtube#video",
      "videoId": "w1_hhew639g"
    },
    "snippet": {
      "publishedAt": "2022-03-28T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute Commentary Bodyweight HIIT Workout (\ud83d\udcaaIntense HIIT... NOT for Everyone!\ud83e\udd75)",
      "description": "This 30 minute Commentary Bodyweight HIIT Workout is from my website https://www.danielptfitness.com/homegym where I ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/w1_hhew639g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/w1_hhew639g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/w1_hhew639g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-28T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VcwTbBBnv3GbYFh07N6uWOfj1Nw",
    "id": {
      "kind": "youtube#video",
      "videoId": "odwP4sNcEds"
    },
    "snippet": {
      "publishedAt": "2022-05-27T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute LUNGE MARATHON  Kettlebell LEG Workout (\ud83e\udd75KETTLEBELL or DUMBBELL HIGH IMPACT WORKOUT\ud83e\udd75)",
      "description": "In this challenging 30 Minute LUNGE MARATHON Kettlebell LEG Workout we will have 13x Rounds Of LUNGES in total! And of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/odwP4sNcEds/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/odwP4sNcEds/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/odwP4sNcEds/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-27T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CvtRxp8mqJwhdB4tw6IcwX5eR8Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "a71HoGW2tuQ"
    },
    "snippet": {
      "publishedAt": "2023-02-02T21:09:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "My Current Back Routine",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a71HoGW2tuQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a71HoGW2tuQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a71HoGW2tuQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-02T21:09:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gFitCbovoy4xsanfMnNaLduIqTo",
    "id": {
      "kind": "youtube#video",
      "videoId": "lPoNIeRaJa0"
    },
    "snippet": {
      "publishedAt": "2022-04-04T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute CHALLENGING Kettlebell HIIT Workout (\ud83d\udd25\ud83d\udd25Bodyweight + Kettlebell HIIT\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 minute CHALLENGING Kettlebell HIIT Workout we will have 30x movements in total, 20 with the kettlebell and 10 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lPoNIeRaJa0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lPoNIeRaJa0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lPoNIeRaJa0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-04T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aS4xILZaUwTqlRn4z6SETSeR4HM",
    "id": {
      "kind": "youtube#video",
      "videoId": "Nob-Iop8QVc"
    },
    "snippet": {
      "publishedAt": "2022-04-22T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT workout for BEGINNERS ( 20 minute | No Equipment Workout )",
      "description": "In this 20 Min HIIT workout for BEGINNERS we will be having a mixture of cardio and strength movements without any equipment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Nob-Iop8QVc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Nob-Iop8QVc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Nob-Iop8QVc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-22T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VxM3iMNvBhswpeYdNNI8Yo2wEcs",
    "id": {
      "kind": "youtube#video",
      "videoId": "eTBK07ePMqo"
    },
    "snippet": {
      "publishedAt": "2022-07-08T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 min DYNAMIC Dumbbell ABS workout ( Active Core Routine )",
      "description": "In this 15 min DYNAMIC Dumbbell ABS workout we will have 15x active and challenging core exercises. We will be performing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eTBK07ePMqo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eTBK07ePMqo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eTBK07ePMqo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-08T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4v5REUlGaLcy1DJmNoAeujsQ4VY",
    "id": {
      "kind": "youtube#video",
      "videoId": "C51vzMtPJPs"
    },
    "snippet": {
      "publishedAt": "2022-07-29T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min SAVAGE KETTLEBELL Strength + BODYWEIGHT Cardio HIIT  (\ud83d\udd25Burn Up To 500 Calories\ud83d\udd25)",
      "description": "In this 30 Min SAVAGE KETTLEBELL Strength + BODYWEIGHT Cardio HIIT we will have 2x blocks of exercises. Each Block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C51vzMtPJPs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C51vzMtPJPs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C51vzMtPJPs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-29T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "p7Vvd3kAdrYnjw95ycEjLoQaS1A",
    "id": {
      "kind": "youtube#video",
      "videoId": "-LQn_Id0UaY"
    },
    "snippet": {
      "publishedAt": "2022-05-16T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min HARDCORE FULL BODY Bodyweight HIIT Workout (Burn Up To 500+ Calories\ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 min HARDCORE FULL BODY Bodyweight HIIT Workout we will be having 2x10 challenging movements for two rounds.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-LQn_Id0UaY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-LQn_Id0UaY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-LQn_Id0UaY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-16T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LtmXqaFF7vairsf2Z3lAG6iVlZ8",
    "id": {
      "kind": "youtube#video",
      "videoId": "vlmfFsZ-6wE"
    },
    "snippet": {
      "publishedAt": "2022-11-28T07:01:24Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "28 november 2022",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vlmfFsZ-6wE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vlmfFsZ-6wE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vlmfFsZ-6wE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-28T07:01:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ecQfmwx7QMnaA9TQUXcu3WgrBFU",
    "id": {
      "kind": "youtube#video",
      "videoId": "uZ2OzcqF588"
    },
    "snippet": {
      "publishedAt": "2022-03-30T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Minute DUMBBELL COMPOUND Workout  ( MAJOR MUSCLES + ABS \ud83e\uddbe)",
      "description": "In this 35 minute DUMBBELL COMPOUND Workout we will put the focus on the major muscle groups of the human body. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uZ2OzcqF588/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uZ2OzcqF588/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uZ2OzcqF588/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-30T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FaA6WfTr5bRA57mn8cVICB6CfdA",
    "id": {
      "kind": "youtube#video",
      "videoId": "g6wHVHrqDnI"
    },
    "snippet": {
      "publishedAt": "2023-02-26T19:30:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "26 februari 2023",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g6wHVHrqDnI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g6wHVHrqDnI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g6wHVHrqDnI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-26T19:30:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P5gEw7CHnfod5PaFV9xuXQBfFBg",
    "id": {
      "kind": "youtube#video",
      "videoId": "f3Nc_RgWb9U"
    },
    "snippet": {
      "publishedAt": "2022-07-11T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute KOMBAT Bodyweight HIIT Workout | No Equipment KILLER HIIT (\ud83d\udd25\ud83d\udd25\ud83d\udd25Up To 500 Calories )",
      "description": "In this 30 minute KOMBAT Bodyweight HIIT Workout we will be having two blocks of workout. Each Block contains a 2x bundle of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/f3Nc_RgWb9U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/f3Nc_RgWb9U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/f3Nc_RgWb9U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-11T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kTTp2i10UHi6TqjFxUFtf-hL6aQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "GteGi9ZbGbw"
    },
    "snippet": {
      "publishedAt": "2022-05-25T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min FULL BODY KETTLEBELL HIIT (BODYWEIGHT + KETTLEBELL ADVANCED HIIT\ud83d\udd25500+ CALORIE\ud83d\udd25)",
      "description": "In this 30 Min Full Body KETTLEBELL + BODYWEIGHT HIIT workout we will be having 14x movements in total. We will have 4x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GteGi9ZbGbw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GteGi9ZbGbw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GteGi9ZbGbw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-25T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6BP5TGxxjhLLb6JY5fX41zwgdA8",
    "id": {
      "kind": "youtube#video",
      "videoId": "rAfkUZuCAqI"
    },
    "snippet": {
      "publishedAt": "2022-04-08T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Minute Dumbbell LEG WORKOUT (\ud83d\udd25ADVANCED STRENGTH ROUTINE\ud83d\udd25)",
      "description": "In this short but definitely Intense and advanced 15 Minute Dumbbell LEG workout we will be having 16x movements in total with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rAfkUZuCAqI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rAfkUZuCAqI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rAfkUZuCAqI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-08T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vVeL1CMlR2CO1xWbMsbglZZACH4",
    "id": {
      "kind": "youtube#video",
      "videoId": "jIz2IBQ2ydg"
    },
    "snippet": {
      "publishedAt": "2022-05-13T05:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute SQUAT MARATHON Kettlebell LEG Workout ( \ud83e\udd75\u200bDIEHARDS ONLY!\ud83e\udd75\u200b)",
      "description": "In this challenging 30 Minute SQUAT MARATHON Kettlebell LEG Workout we will have 13x Rounds Of Goblet Squats in total\u2026",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jIz2IBQ2ydg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jIz2IBQ2ydg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jIz2IBQ2ydg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-13T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "J1UHCtdm9NO_K6XVGZrUh1vg6SA",
    "id": {
      "kind": "youtube#video",
      "videoId": "_inpDcgIpbM"
    },
    "snippet": {
      "publishedAt": "2022-05-11T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute FULL BODY DUMBBELL HIIT WORKOUT (\ud83d\udd25BODYWEIGHT + DUMBBELL BURN up to 500 Calories\ud83d\udd25)",
      "description": "In this 30 Minute FULL BODY DUMBBELL HIIT WORKOUT We will be having a mixture of cardio and strength movements.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_inpDcgIpbM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_inpDcgIpbM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_inpDcgIpbM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-11T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8Gl0VhMQpvfEZ04uDTU9ZDyOqV4",
    "id": {
      "kind": "youtube#video",
      "videoId": "GRAFAs8ppDY"
    },
    "snippet": {
      "publishedAt": "2022-08-08T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min SAVAGE FULL BODY HIIT workout  (No Equipment \ud83d\udd25Burn Up to 500 Calories)",
      "description": "In this 30 min SAVAGE FULL BODY HIIT workout we will have 2x blocks of workout. Each block contains 2x 4x exercises for two ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GRAFAs8ppDY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GRAFAs8ppDY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GRAFAs8ppDY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-08T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lgP8EG__fyPSSxvrwBScHq49oQU",
    "id": {
      "kind": "youtube#video",
      "videoId": "xejbYB6rMbM"
    },
    "snippet": {
      "publishedAt": "2022-04-15T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "SUPERB LOWER BODY Long Resistance Band Workout (\ud83e\uddbf20 Minute Follow Along Workout\ud83e\uddbf)",
      "description": "In this 20 minute LOWER BODY Long Resistance Band Workout We will be having 20 movements in total. We will perform each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xejbYB6rMbM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xejbYB6rMbM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xejbYB6rMbM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-15T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bLmIGDJ3aLa04zsRTxYGXZ8JmYQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Jtz54kvKmkE"
    },
    "snippet": {
      "publishedAt": "2022-03-23T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Kettlebell + Bodyweight Full Body Workout (ABS &amp; Strength Supersets)",
      "description": "In this 30 minute Kettlebell + Bodyweight Full Body Workout we will Mix the abs exercises with the upper body slow paste strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Jtz54kvKmkE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Jtz54kvKmkE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Jtz54kvKmkE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-23T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "y-NQrEsuqMALj9M-LDm-uSJ1v1U",
    "id": {
      "kind": "youtube#video",
      "videoId": "gh5trJFDHlQ"
    },
    "snippet": {
      "publishedAt": "2022-04-11T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SAVAGE Bodyweight CRUSHER HIIT Workout (\ud83d\udd25\ud83d\udd25 ADVANCED FULL BODY HIIT\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 minute SAVAGE Bodyweight CRUSHER HIIT Workout we are trying to get the heart rate as high as we can and push it to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gh5trJFDHlQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gh5trJFDHlQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gh5trJFDHlQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-11T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eefqL08Sr66cH2k0b2I8cN-U7pc",
    "id": {
      "kind": "youtube#video",
      "videoId": "iHl23aV9r-I"
    },
    "snippet": {
      "publishedAt": "2022-04-25T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 MINUTE DUMBBELL HIIT WORKOUT + Bodyweight (\ud83d\udd25BURN up to 500 Calories\ud83d\udd25)",
      "description": "In this 30 MINUTE DUMBBELL HIIT WORKOUT + Bodyweight We will be having a mixture of cardio and strength movements.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iHl23aV9r-I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iHl23aV9r-I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iHl23aV9r-I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-25T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Qa5JQxu8MM0eCIvvSkk4z95EJ_8",
    "id": {
      "kind": "youtube#video",
      "videoId": "9HvXVWZNkZk"
    },
    "snippet": {
      "publishedAt": "2022-08-22T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min BANGER Bodyweight HIIT Workout (No REPEAT HIIT -\ud83d\udd25Burn Up to 500 Kcal\ud83d\udd25)",
      "description": "In this 30 min BANGER Bodyweight HIIT Workout we will have 2x blocks of workout. Each block contains 18x exercises for 35 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9HvXVWZNkZk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9HvXVWZNkZk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9HvXVWZNkZk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-22T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OqCDGsfwW9w_cBM3Am7WCWfhksA",
    "id": {
      "kind": "youtube#video",
      "videoId": "TRWc4d8JJ0A"
    },
    "snippet": {
      "publishedAt": "2023-02-11T18:34:53Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Current Biceps Routine",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TRWc4d8JJ0A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TRWc4d8JJ0A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TRWc4d8JJ0A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-11T18:34:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Svqq6jCln4kInKV2eEKOzK5057Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "shqrxAmGvmc"
    },
    "snippet": {
      "publishedAt": "2022-11-23T07:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Min ROBUST Dumbbell ABS Workout | No Repeat (Upper ABS, Lower ABS &amp; Side Obliques)",
      "description": "In this 15 Min ROBUST Dumbbell ABS Workout we have 20x movement of 35 seconds with 15 seconds rest in between. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/shqrxAmGvmc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/shqrxAmGvmc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/shqrxAmGvmc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-23T07:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uYoL6pKkkYmRTJI5eigK0sJOG9c",
    "id": {
      "kind": "youtube#video",
      "videoId": "LW5N-ts-LPw"
    },
    "snippet": {
      "publishedAt": "2022-06-03T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 minute BODYWEIGHT Workout (No Jump, No Equipment, Low Impact)",
      "description": "In this 35 minute BODYWEIGHT Workout we will be having a low impact workout focusing on the legs, upper body ( Mainly push ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LW5N-ts-LPw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LW5N-ts-LPw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LW5N-ts-LPw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-03T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LRpgHtrjPguorYGEynpAw0XNdU0",
    "id": {
      "kind": "youtube#video",
      "videoId": "UZg33RjbPqE"
    },
    "snippet": {
      "publishedAt": "2022-07-22T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min FULL CONTROL KETTLEBELL STRENGTH WORKOUT (\ud83e\uddbeBuild Muscle &amp; Burn Calories\ud83d\udd25)",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UZg33RjbPqE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UZg33RjbPqE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UZg33RjbPqE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-22T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rzP-7Yp2P57MPByJn7IcseFlQ6s",
    "id": {
      "kind": "youtube#video",
      "videoId": "psxCLg8Y7Jw"
    },
    "snippet": {
      "publishedAt": "2023-02-21T08:30:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min COMPACT FULL BODY HIIT (\ud83d\udd25No Repeat | No Equipment\ud83d\udd25)",
      "description": "In this 30 Min COMPACT FULL BODY HIIT we will have a 12x bundle of 3x movements that will have some characteristics similar ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/psxCLg8Y7Jw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/psxCLg8Y7Jw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/psxCLg8Y7Jw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-21T08:30:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uYkIO-HH_PrSY2imxa343BJbIVo",
    "id": {
      "kind": "youtube#video",
      "videoId": "SZ_YibbfBIU"
    },
    "snippet": {
      "publishedAt": "2022-07-04T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SUPER INTENSE Bodyweight HIIT Workout (\ud83d\udd25\ud83d\udd25\ud83d\udd25Burn 500+ Calories)",
      "description": "In this 30 minute SUPER INTENSE Bodyweight HIIT Workout we will challenge the muscles and the cardiovascular system once ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SZ_YibbfBIU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SZ_YibbfBIU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SZ_YibbfBIU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-04T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "umO2YQSmpZUAYnt04C2XEgRFhBs",
    "id": {
      "kind": "youtube#video",
      "videoId": "6P3wTOT1cfw"
    },
    "snippet": {
      "publishedAt": "2022-09-12T05:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min SAVAGE Bodyweight HIIT |  NO REPEAT HARDCORE (\ud83d\udd25BURN IT UP\ud83d\udd25)",
      "description": "In this 30 min SAVAGE Bodyweight HIIT we will have 2x blocks of workout. Each block contains 20x exercises and 5x Versions of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6P3wTOT1cfw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6P3wTOT1cfw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6P3wTOT1cfw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-12T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RbVLn0n57QM6_C_6LYQhytticy8",
    "id": {
      "kind": "youtube#video",
      "videoId": "-X0uENfKp1A"
    },
    "snippet": {
      "publishedAt": "2022-05-23T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min HIGH IMPACT FULL BODY DUMBBELL Workout (BUILD MUSCLE &amp; BURN FAT)",
      "description": "In this 40 min HIGH IMPACT FULL BODY DUMBBELL Workout for maximizing the impact of the workout we will be mainly ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-X0uENfKp1A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-X0uENfKp1A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-X0uENfKp1A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-23T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0l7emthDbAXJwHzWQv9cFsekyDA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ic1Yoq7fe-o"
    },
    "snippet": {
      "publishedAt": "2022-04-01T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 minute PULL UPPER BODY Kettlebell Workout ( Slow Paced Strength Routine\ud83e\uddbe)",
      "description": "In this Upper Body Pull Workout we will have for 15 minutes straight, Different exercises for the back, Shoulders & biceps. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ic1Yoq7fe-o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ic1Yoq7fe-o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ic1Yoq7fe-o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-01T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zFhYIFXCgzuZQQv5VBUs7wxj88Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "-7ZtMPk9tQU"
    },
    "snippet": {
      "publishedAt": "2022-04-27T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Hardcore HIIT [Burn up to 500+ Calories!] No Equipment",
      "description": "In this 30 Min Hardcore HIIT [Burn up to 500+ Calories!] No Equipment We will be having a mixture of cardio and strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-7ZtMPk9tQU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-7ZtMPk9tQU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-7ZtMPk9tQU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-27T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jcTWFKuZOqz3URhVsUZoEGTxqQY",
    "id": {
      "kind": "youtube#video",
      "videoId": "vqVZB5bvQLg"
    },
    "snippet": {
      "publishedAt": "2022-08-19T05:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body MIXED Kettlebell Workout (No Repeat COMPACT Workout)",
      "description": "In this 30 min Full Body MIXED Kettlebell Workout we will have a mixture of Strength , Cardio and Abs. We will have two blocks ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vqVZB5bvQLg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vqVZB5bvQLg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vqVZB5bvQLg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-19T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CZJYys8aUy_fLq-f-qXXhMBlM3I",
    "id": {
      "kind": "youtube#video",
      "videoId": "Gx3uWC3WAzQ"
    },
    "snippet": {
      "publishedAt": "2022-08-10T05:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Kettlebell + Bodyweight Full Body HIIT ( Strength + Cardio Combo \ud83d\udd25 Burn Up To 500 Calories)",
      "description": "In this 30 Min Kettlebell + Bodyweight Full Body HIIT we will have 2x blocks of exercises. Each block contains 18x exercises, each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Gx3uWC3WAzQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Gx3uWC3WAzQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Gx3uWC3WAzQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-10T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "C-lPIAhK956F_WafP9DwmbCbO3Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "dFWVXYvwGlI"
    },
    "snippet": {
      "publishedAt": "2023-03-14T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Combination DUMBBELL FULL BODY Workout ( Intense Dynamic Routine )",
      "description": "In this 35 min Combination DUMBBELL FULL BODY Workout will have 4x bundle of 4x exercises for 2x rounds in a row with 2x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dFWVXYvwGlI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dFWVXYvwGlI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dFWVXYvwGlI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-14T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zb2cLhkgY1YP0YpYtF1AZyIMZVg",
    "id": {
      "kind": "youtube#video",
      "videoId": "OjMGdsf5vjM"
    },
    "snippet": {
      "publishedAt": "2022-12-17T07:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min TRIPLE Full Body HIIT | No Equipment No Repeat",
      "description": "In this 30 Min TRIPLE Full Body HIIT | No Equipment No Repeat we will have a 12x bundle of 3x movements: the first movement is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OjMGdsf5vjM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OjMGdsf5vjM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OjMGdsf5vjM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-17T07:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kd4Zm5FS2EUqHt3q53L001F0ap0",
    "id": {
      "kind": "youtube#video",
      "videoId": "90QGwNg5cRc"
    },
    "snippet": {
      "publishedAt": "2022-04-20T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Full Body Dumbbell Workout Burn fat + Build Muscle  (\ud83e\uddbeHIGH IMPACT\ud83d\udc4a)",
      "description": "In this 40 min Full Body Dumbbell Workout Burn fat + Build Muscle we will be having 20x movements and 10x supersets in total.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/90QGwNg5cRc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/90QGwNg5cRc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/90QGwNg5cRc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-20T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hp-MRTeOqFpULjFYXj8k8afkDY4",
    "id": {
      "kind": "youtube#video",
      "videoId": "DRdnFMDPuvY"
    },
    "snippet": {
      "publishedAt": "2022-12-14T20:55:31Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "14 december 2022",
      "description": "",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DRdnFMDPuvY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DRdnFMDPuvY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DRdnFMDPuvY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-14T20:55:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NYK-MFYpA9K5mXpTyZRlOLPYJyo",
    "id": {
      "kind": "youtube#video",
      "videoId": "CUW7iYh5z4U"
    },
    "snippet": {
      "publishedAt": "2022-07-25T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute HELL of a HIIT | Intense Bodyweight Workout (\ud83d\udd25Burn up to 500 Calories)",
      "description": "In this 30 minute Hell of a HIIT | Intense Bodyweight Workout we have 2x blocks. Each block contains 3x bundles of 3x exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CUW7iYh5z4U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CUW7iYh5z4U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CUW7iYh5z4U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-25T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lRSB8MfgSvMVgsPx-xHtdLR_tjk",
    "id": {
      "kind": "youtube#video",
      "videoId": "b6khbeioWbE"
    },
    "snippet": {
      "publishedAt": "2022-06-01T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min FULL BODY  DUMBBELL workout Build Muscle (\ud83e\uddbeHIGH IMPACT\ud83e\uddbe)",
      "description": "In this 40 min FULL BODY DUMBBELL workout Build Muscle workout we will be targeting chest, back, legs, shoulders, triceps, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/b6khbeioWbE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/b6khbeioWbE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/b6khbeioWbE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-01T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NkcidRH69SPN_fKzrVdvX6w5DCE",
    "id": {
      "kind": "youtube#video",
      "videoId": "Yz2F6qdPz9s"
    },
    "snippet": {
      "publishedAt": "2022-05-02T05:00:30Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "36 Min HYBRID BODYWEIGHT + KETTLEBELL WORKOUT ( Burn Up to 500 Calories! )",
      "description": "In this 36 Min HYBRID BODYWEIGHT + KETTLEBELL WORKOUT we will have a mixture of bodyweight and kettlebell strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Yz2F6qdPz9s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Yz2F6qdPz9s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Yz2F6qdPz9s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-02T05:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zFZta44XMotLat8J5az6F5jJAXU",
    "id": {
      "kind": "youtube#video",
      "videoId": "UYbaFxFWzF8"
    },
    "snippet": {
      "publishedAt": "2022-11-17T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body KETTLEBELL Strength + Explosive Workout (COMPACT + ABS)",
      "description": "In this 30 min Full Body KETTLEBELL Strength + Explosive Workout we will have a combination of Upper & Lower Body Strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UYbaFxFWzF8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UYbaFxFWzF8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UYbaFxFWzF8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-17T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rwphfDQoguX8RUtNNkpNGv6jwzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "QsCRkdtz74w"
    },
    "snippet": {
      "publishedAt": "2023-02-26T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Full Body HIIT Workout with Dumbbells | No Repeat",
      "description": "In this 30 Min Full Body HIIT Workout with Dumbbells we will have 2x 16 movements with the dumbbells targeting the entire body.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QsCRkdtz74w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QsCRkdtz74w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QsCRkdtz74w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-26T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "u2RQVAoW9vJ9-zE7UTK-OfvpzE0",
    "id": {
      "kind": "youtube#video",
      "videoId": "-VfFumuzRxg"
    },
    "snippet": {
      "publishedAt": "2022-08-01T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute FULL BODY HIIT No Equipment No Repeat (Cardio + Endurance + Core\ud83d\udd25Burn Up To 500 Calories\ud83d\udd25)",
      "description": "In this 30 Minute No Equipment No Repeat FULL BODY HIIT workout we have 2x blocks. Each block contains 18x exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-VfFumuzRxg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-VfFumuzRxg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-VfFumuzRxg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-01T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yUzeF1ZI89TKbsmiyOoHPzCpgyk",
    "id": {
      "kind": "youtube#video",
      "videoId": "moddpJqZdrw"
    },
    "snippet": {
      "publishedAt": "2022-11-28T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min FIRE Bodyweight + Kettlebell FULL BODY HIIT Workout (ADVANCED HIIT)",
      "description": "In this 30 Min FIRE Bodyweight + Kettlebell FULL BODY HIIT Workout we will have a combination of Bodyweight and Kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/moddpJqZdrw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/moddpJqZdrw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/moddpJqZdrw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-28T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ume0XN704WGvpWZwURswWJyJJuo",
    "id": {
      "kind": "youtube#video",
      "videoId": "f-_r1pTNlvc"
    },
    "snippet": {
      "publishedAt": "2022-07-01T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min MUSCLE BUILDING Upper Body STRENGTH Workout ( \ud83e\uddbe NO REPEAT + ABS )",
      "description": "In this 40 min MUSCLE BUILDING Upper Body STRENGTH Workout we will split the workout is two parts: Part I : Focusing more ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/f-_r1pTNlvc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/f-_r1pTNlvc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/f-_r1pTNlvc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-01T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lCk0z13YoLXYfCtAgwCnda3y388",
    "id": {
      "kind": "youtube#video",
      "videoId": "WBNsyhO2sGI"
    },
    "snippet": {
      "publishedAt": "2021-01-10T15:00:35Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Kettlebell HIIT Full Workout | Full Body Follow Along Full Workout",
      "description": "30 min Kettlebell HIIT Full Workout | Full Body Follow Along Full Workout This \"30 min Kettlebell HIIT Full Workout | Full Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WBNsyhO2sGI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WBNsyhO2sGI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WBNsyhO2sGI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-10T15:00:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Yh2CEkENrhOjzNDiekm7Hu2zMfY",
    "id": {
      "kind": "youtube#video",
      "videoId": "-_RUkfus4VM"
    },
    "snippet": {
      "publishedAt": "2023-01-13T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min FULL BODY KETTLEBELL + BODYWEIGHT HIIT Workout | No Repeat",
      "description": "In this 30 Min FULL BODY KETTLEBELL + BODYWEIGHT HIIT workout | No Repeat we will have 40x movements, a mixture of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-_RUkfus4VM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-_RUkfus4VM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-_RUkfus4VM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-13T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CJ0M6N3SthGrqTJ5fNwd5iuiUTE",
    "id": {
      "kind": "youtube#video",
      "videoId": "LsiZwWPUJKI"
    },
    "snippet": {
      "publishedAt": "2022-05-09T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min HARDCORE FULL BODY HIIT Workout + ABS ( \ud83d\ude80Intense , No Equipment\ud83d\ude80 )",
      "description": "In this challenging 30 min HARDCORE FULL BODY HIIT Workout we will have 36x movements in total. We will perform each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LsiZwWPUJKI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LsiZwWPUJKI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LsiZwWPUJKI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KcAO5H_qRmC9L-c7nRX7n5FApYo",
    "id": {
      "kind": "youtube#video",
      "videoId": "oJC_Wnw2zlY"
    },
    "snippet": {
      "publishedAt": "2023-03-07T07:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Solid FULL BODY KETTLEBELL Workout | Strength + Explosive + ABS  |  No Repeat",
      "description": "In this 40 min Solid FULL BODY KETTLEBELL Workout | Strength + Explosive + ABS | No Repeat we will have a 10x bundle of 4x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oJC_Wnw2zlY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oJC_Wnw2zlY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oJC_Wnw2zlY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-07T07:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "koIi0JtTkuCv_gk62v6mWntU_r0",
    "id": {
      "kind": "youtube#video",
      "videoId": "QbTl43tbpxI"
    },
    "snippet": {
      "publishedAt": "2022-07-20T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Full Body Dumbbell Superset Strength Workout (\ud83e\uddbemuscle building Routine)",
      "description": "In this 35 min Full Body dumbbell Superset Strength Workout we will have 2x blocks. In the first block we will focus on the major ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QbTl43tbpxI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QbTl43tbpxI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QbTl43tbpxI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-20T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cjz4VVhgfpNKQUsB_NOc_1cTWME",
    "id": {
      "kind": "youtube#video",
      "videoId": "FEHPpCHigIA"
    },
    "snippet": {
      "publishedAt": "2022-09-05T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min INTENSE ALL AROUND Bodyweight HIIT Workout (No REPEAT HIIT -\ud83d\udd25Burn Up to 500 Calories\ud83d\udd25)",
      "description": "In this 30 min INTENSE ALL AROUND Bodyweight HIIT Workout we will challenge the body by splitting the exercises in 3x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FEHPpCHigIA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FEHPpCHigIA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FEHPpCHigIA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-05T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eBseLZ-erIG2qCys6S6ImRGViE8",
    "id": {
      "kind": "youtube#video",
      "videoId": "3CnMMjM8nrc"
    },
    "snippet": {
      "publishedAt": "2023-02-14T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min MIXED Full Body Kettlebell Workout | No Repeat (Intense | Apartment Friendly)",
      "description": "In this 40 Min MIXED Full Body Kettlebell Workout | No Repeat (Intense | Apartment Friendly) we will have a 8x bundle of 5x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3CnMMjM8nrc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3CnMMjM8nrc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3CnMMjM8nrc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-14T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "piRR6Ow30_J_vy3EwHD82Emak0Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "m2DAQn63iOw"
    },
    "snippet": {
      "publishedAt": "2022-12-05T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min SAVAGE Full Body Bodyweight HIIT Workout (No Equipment)",
      "description": "In this 30 Min SAVAGE Full Body Bodyweight HIIT Workout (No Equipment) we will have 2x blocks of 18x movements. Each Block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/m2DAQn63iOw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/m2DAQn63iOw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/m2DAQn63iOw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-05T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZaWKms8QhPOUO5rXmo8EhgoPYZY",
    "id": {
      "kind": "youtube#video",
      "videoId": "SXlnj_I2jHM"
    },
    "snippet": {
      "publishedAt": "2022-09-23T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min FIRE Bodyweight HIIT | No Equipment No Repeat No Joke HIIT (\ud83d\udd25Burn Up to 500 Kcal\ud83d\udd25)",
      "description": "In this 30 min FIRE Bodyweight HIIT | No Equipment No Repeat No Joke HIIT we will have 2x blocks of 20 exercises. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SXlnj_I2jHM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SXlnj_I2jHM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SXlnj_I2jHM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-23T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VjBtNnZmd_YAZCjV7UlB4QNMn5Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "4ioDllEkE90"
    },
    "snippet": {
      "publishedAt": "2022-04-13T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Minute TOTAL BODY Dumbbell Workout ( \ud83e\uddbeStrength Follow Along - NO Repeat )",
      "description": "In this 40 Minute TOTAL BODY Dumbbell Workout we will be having 36x movements in total. There will be 12x Giant Sets in total ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4ioDllEkE90/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4ioDllEkE90/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4ioDllEkE90/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-13T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fDMhkQDtgbCJ6yU_WxRmLIoMHI8",
    "id": {
      "kind": "youtube#video",
      "videoId": "hJ75vC-joH0"
    },
    "snippet": {
      "publishedAt": "2022-10-31T07:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min ABS-DOMINANT Bodyweight HIIT workout | No Repeat",
      "description": "In this 30 min ABS-DOMINANT Bodyweight HIIT workout as the title says we will be putting the focus mainly on the abdominals.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hJ75vC-joH0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hJ75vC-joH0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hJ75vC-joH0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-31T07:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ANcgTgtQ27lEUJ4mn7_012iv3Fc",
    "id": {
      "kind": "youtube#video",
      "videoId": "xE_vgcCqzQs"
    },
    "snippet": {
      "publishedAt": "2022-06-22T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min PUSH PULL FULL BODY DUMBBELL WORKOUT | STRENGTH WORKOUT (BUILD MUSCLE &amp; BURN\ud83d\udd25)",
      "description": "In this 30 Min SOLID PUSH PULL FULL BODY DUMBBELL WORKOUT we will really perform push and pull movements for upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xE_vgcCqzQs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xE_vgcCqzQs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xE_vgcCqzQs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-22T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3F489QTeuSvoipw-AifoqxyFrJU",
    "id": {
      "kind": "youtube#video",
      "videoId": "KH2wkVsveUQ"
    },
    "snippet": {
      "publishedAt": "2022-05-04T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Min Full Body GIANT SET Dumbbell Strength Workout ( BUILD &amp; BURN GIANT - DROP SETS )",
      "description": "In this 35 Min Full Body GIANT SET Dumbbell Strength Workout we will target and challenge the entire body. We will be having ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KH2wkVsveUQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KH2wkVsveUQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KH2wkVsveUQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-04T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HEPZRGUkKQnmRACwkol8fpTFSN0",
    "id": {
      "kind": "youtube#video",
      "videoId": "V-EYOtzngT4"
    },
    "snippet": {
      "publishedAt": "2022-07-15T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 Min LEGS + ABS Kettlebell Workout | No Repeat ( Strength &amp; Muscle Endurance Workout )",
      "description": "In this 25 Min LEGS + ABS Kettlebell Workout we will have 27x exercises in total. We will have 9x bundle of 3x exercises , The ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V-EYOtzngT4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V-EYOtzngT4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V-EYOtzngT4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-15T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dwAIJJAusryhiJFs9nOsVNsi98Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "O-RLuPfiudg"
    },
    "snippet": {
      "publishedAt": "2022-09-02T05:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min FULL BODY STRENGTH Kettlebell Workout | No Jump | No Repeat ( COMPACT + ABS )",
      "description": "In this 30 min FULL BODY STRENGTH Kettlebell Workout | No Jump | No Repeat we will have 8x bundles of 4x Exercises: 1st ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/O-RLuPfiudg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/O-RLuPfiudg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/O-RLuPfiudg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-02T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ctTNoiL_gC-e3b3O-s_yBVli8gA",
    "id": {
      "kind": "youtube#video",
      "videoId": "QAJBJtBtbQM"
    },
    "snippet": {
      "publishedAt": "2022-08-26T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Kettlebell + Bodyweight Full Body HIIT ( Strength + Cardio Combo II \ud83d\udd25Burn Up To 500 Kcal )",
      "description": "In this 30 Min Kettlebell + Bodyweight Full Body HIIT we will have 2x blocks of exercises. Each block contains 18x exercises, each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QAJBJtBtbQM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QAJBJtBtbQM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QAJBJtBtbQM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-26T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5g0ukjatUzRLd_CYE8aH1zJPuJQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "GBnTtbVcsZI"
    },
    "snippet": {
      "publishedAt": "2022-07-13T05:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min MAX OUT HYBRID BODYWEIGHT + DUMBBELL workout | SUPER INTENSE FULL BODY (\ud83d\udd25Up To 500 Calories\ud83d\udd25)",
      "description": "In this 30 min MAX OUT HYBRID BODYWEIGHT + DUMBBELL workout we will challenge the entire body to the edge. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GBnTtbVcsZI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GBnTtbVcsZI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GBnTtbVcsZI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-13T05:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vbRhu7NITTCufrNFZkB4sUAWW-E",
    "id": {
      "kind": "youtube#video",
      "videoId": "8VJS5PV7oAY"
    },
    "snippet": {
      "publishedAt": "2022-05-18T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Full Body KETTLEBELL WORKOUT + BODYWEIGHT  (\ud83d\udd25500+ CALORIE BURN HIIT\ud83d\udd25)",
      "description": "In this 30 Min Full Body KETTLEBELL + BODYWEIGHT HIIT workout we will be having 2x 10 movements which will be performed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8VJS5PV7oAY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8VJS5PV7oAY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8VJS5PV7oAY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-18T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RX_t3jV9MiYWl7oHKsyeM6BkkJk",
    "id": {
      "kind": "youtube#video",
      "videoId": "w7xH7IH6dyY"
    },
    "snippet": {
      "publishedAt": "2022-08-29T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min BALLISTIC Bodyweight HIIT Workout (No REPEAT HIIT \ud83d\udd25Burn Up to 500 Kcal\ud83d\udd25)",
      "description": "In this 30 min BALLISTIC Bodyweight HIIT Workout we will have 2x blocks of workout. Each block contains 18x exercises for 35 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/w7xH7IH6dyY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/w7xH7IH6dyY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/w7xH7IH6dyY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-29T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9onP0ko4fdqAk5CgMjJaDfPfuyg",
    "id": {
      "kind": "youtube#video",
      "videoId": "aMxQVEL4sTA"
    },
    "snippet": {
      "publishedAt": "2022-10-26T06:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Minute BLAST Kettlebell STRENGTH + DYNAMIC Workout | No Repeat",
      "description": "In this 35 Minute BLAST Kettlebell STRENGTH + DYNAMIC Workout We will have a combination of Strength sets of 50 seconds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aMxQVEL4sTA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aMxQVEL4sTA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aMxQVEL4sTA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-26T06:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "t8DOb01DUjBawgsknoDqlQsZnSE",
    "id": {
      "kind": "youtube#video",
      "videoId": "3tcvikEspFw"
    },
    "snippet": {
      "publishedAt": "2022-11-15T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min HARDCORE Bodyweight HIIT ( NO REPEAT | FULL BODY MAX BURN )",
      "description": "In this 30 min HARDCORE Bodyweight HIIT we will have 2x blocks of 19 movements. Each Block contains 9x 40 seconds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3tcvikEspFw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3tcvikEspFw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3tcvikEspFw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-15T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kDcVGFTJhkGGXQRRmpv7_67rJ9Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "C1Mpy30y52E"
    },
    "snippet": {
      "publishedAt": "2022-10-03T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SUPERset HIIT workout | Intense Bodyweight HIIT (\ud83d\udd25DIE HARD LEVEL\ud83d\udd25)",
      "description": "In this 30 minute SUPERset HIIT workout | Intense Bodyweight HIIT we will have 2x blocks of super challenging exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C1Mpy30y52E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C1Mpy30y52E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C1Mpy30y52E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-03T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OAYj_AuyjUCzv11QWRNrz17J1NQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "-nbvWYZHkY0"
    },
    "snippet": {
      "publishedAt": "2023-01-19T07:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Full Body Dumbbell Strength Workout | Lower Body, Upper Body, Combo | No Repeat",
      "description": "In this 35 min Full Body Dumbbell Strength Workout we will have 10x bundles of 3x exercises in total: 1.Lower Body 2.Upper Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-nbvWYZHkY0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-nbvWYZHkY0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-nbvWYZHkY0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-19T07:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JoLRGivlY19aj8dEPGQEBNC3J-E",
    "id": {
      "kind": "youtube#video",
      "videoId": "zzeYCsqCP_c"
    },
    "snippet": {
      "publishedAt": "2022-10-24T06:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Min ESCALATE Bodyweight HIIT Workout | No Repeat ( Intense Full Body Workout )",
      "description": "In this 35 Min ESCALATE Bodyweight HIIT Workout we will have 40x movements in total. We will be performing each movement ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zzeYCsqCP_c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zzeYCsqCP_c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zzeYCsqCP_c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-24T06:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SqNOptkdVa7zw52w5UDLSiA23Tw",
    "id": {
      "kind": "youtube#video",
      "videoId": "W-HSnFka3AA"
    },
    "snippet": {
      "publishedAt": "2022-06-27T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Minute DUMBBELL Strength BODYWEIGHT Cardio HYBRID Workout (BUILD MUSCLE &amp; BURN\ud83d\udd25)",
      "description": "In this 35 minute dumbbell Strength Bodyweight Cardio Hybrid Workout we will be having a combination of dumbbell and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W-HSnFka3AA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W-HSnFka3AA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W-HSnFka3AA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-27T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Tqf--wqtgk02jo5oCzX6TWALha4",
    "id": {
      "kind": "youtube#video",
      "videoId": "P-SwFpWtKIE"
    },
    "snippet": {
      "publishedAt": "2023-03-16T12:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Kettlebell + Bodyweight Workout | Intense Full body Workout",
      "description": "In this 35 min Kettlebell + Bodyweight Workout | Intense Full body Workout We will have 6x bundles of 5x movements for 1x round ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/P-SwFpWtKIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/P-SwFpWtKIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/P-SwFpWtKIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-16T12:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "anW0MR5sKhvvCxvs7AguW5Q2w40",
    "id": {
      "kind": "youtube#video",
      "videoId": "x4eX0y97ick"
    },
    "snippet": {
      "publishedAt": "2023-02-04T10:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min FULL BODY KETTLEBELL Workout | NO Repeat NO Jump ( INTENSE )",
      "description": "In this 40 Min FULL BODY KETTLEBELL Workout | NO Repeat NO Jump we will have a 8x bundle of 5x movements in total ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/x4eX0y97ick/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/x4eX0y97ick/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/x4eX0y97ick/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-04T10:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZXXQA4BdMROpHsTWbKsK4gRgKHI",
    "id": {
      "kind": "youtube#video",
      "videoId": "rya-rAFwMng"
    },
    "snippet": {
      "publishedAt": "2022-10-17T05:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min BODYWEIGHT + DUMBBELL HIIT Workout ( No Repeat | High Impact )",
      "description": "In this 30 Min BODYWEIGHT + DUMBBELL HIIT Workout we will have 2x Block of 18x movements, 9x Exercises With the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rya-rAFwMng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rya-rAFwMng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rya-rAFwMng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-17T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I1VCqJP5p5s3Pwm-qYTsLZY2edo",
    "id": {
      "kind": "youtube#video",
      "videoId": "uLc62C3qTTs"
    },
    "snippet": {
      "publishedAt": "2022-09-07T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Full Body Kettlebell Bodyweight Workout  ( Strength + Cardio + ABS )",
      "description": "In this 40 min Full Body Kettlebell Bodyweight Workout we will have 8x bundles of 5x Exercises: 1st Exercise = Strength 2nd ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uLc62C3qTTs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uLc62C3qTTs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uLc62C3qTTs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-07T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XBfNLanZmKHUKzgK56Lm2GDiJV0",
    "id": {
      "kind": "youtube#video",
      "videoId": "4CTahJF4ajw"
    },
    "snippet": {
      "publishedAt": "2023-01-31T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min JUST BODYWEIGHT HIIT Workout | No Repeat  ( Full Body + ABS )",
      "description": "In this 30 Min JUST BODYWEIGHT HIIT Workout | No Repeat we will have 40x movements in total. We will perform each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4CTahJF4ajw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4CTahJF4ajw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4CTahJF4ajw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-31T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w_y99AtfaASrkpu8z30X7GGX79w",
    "id": {
      "kind": "youtube#video",
      "videoId": "b73bN-RccyM"
    },
    "snippet": {
      "publishedAt": "2022-10-14T05:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min FULL IMPACT Full Body Kettlebell Workout ( No Jump | Power Workout )",
      "description": "In this 40 min FULL IMPACT Full Body Kettlebell Workout we will have 20x Combination exercises in total and we will target the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/b73bN-RccyM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/b73bN-RccyM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/b73bN-RccyM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-14T05:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Xf_hDxcZ2N-rnmdryL0QTdsHbAE",
    "id": {
      "kind": "youtube#video",
      "videoId": "VD6DeG0ldPg"
    },
    "snippet": {
      "publishedAt": "2023-03-11T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min CHEST and BICEPS Dumbbell Workout | No Repeat (Basic Strength)",
      "description": "In this 30 min CHEST and BICEPS Dumbbell Workout | No Repeat We will have 24x exercises for targeting the chest and Biceps ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VD6DeG0ldPg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VD6DeG0ldPg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VD6DeG0ldPg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-11T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Bvb4dR7wobnITMcHJ9rc25CBx0c",
    "id": {
      "kind": "youtube#video",
      "videoId": "QVBPcP6lXrM"
    },
    "snippet": {
      "publishedAt": "2022-12-12T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min FULL BODY Kettlebell STRENGTH + ABS | No Repeat",
      "description": "In this 35 min FULL BODY Kettlebell STRENGTH + ABS Workout We Will focus more on controlled and slower movements rather ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QVBPcP6lXrM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QVBPcP6lXrM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QVBPcP6lXrM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-12T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HkXyALR-pHKGJtVOvpbByzN8Guw",
    "id": {
      "kind": "youtube#video",
      "videoId": "YVuSyMVLO9I"
    },
    "snippet": {
      "publishedAt": "2022-11-11T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min UPPER BODY Workout COMPLEX SETS with dumbbells (Chest, Back, Shoulder, Biceps, Triceps)",
      "description": "In this 30 min UPPER BODY Workout COMPLEX SETS with dumbbells we will bring the basic effective exercises back but will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YVuSyMVLO9I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YVuSyMVLO9I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YVuSyMVLO9I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-11T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cnaQIC9_JreEdd2aj3k0okVpp-w",
    "id": {
      "kind": "youtube#video",
      "videoId": "xLeQIvaKSyA"
    },
    "snippet": {
      "publishedAt": "2022-04-06T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 minute HIGH IMPACT DUMBBELL Workout ( FULL BODY SUPERSET STRENGTH )",
      "description": "In this 40 minute HIGH IMPACT DUMBBELL Workout we will target and challenge the entire body. We will be having 10x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xLeQIvaKSyA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xLeQIvaKSyA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xLeQIvaKSyA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-06T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "apRYrLcgcC59_Pcp36QJq3PpL6I",
    "id": {
      "kind": "youtube#video",
      "videoId": "79CxUKL086Y"
    },
    "snippet": {
      "publishedAt": "2022-09-14T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Full Body BASIC Dumbbell Strength Workout | No Repeat ( Muscle Building Follow Along )",
      "description": "In this 40 min Full Body BASIC Dumbbell Strength Workout | No Repeat we will be having 8x bundle of 5x exercises in total: 1st ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/79CxUKL086Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/79CxUKL086Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/79CxUKL086Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-14T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D50Mtq5Uoz_QdPgwsvsLAvpBNqo",
    "id": {
      "kind": "youtube#video",
      "videoId": "Dt5wZF2WUi0"
    },
    "snippet": {
      "publishedAt": "2022-11-25T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Full Body Dumbbell HYBRID Workout + ABS | HIGH IMPACT",
      "description": "In this 30 Min Full Body Dumbbell HYBRID Workout + ABS we will have a combination of Upper & Lower Body Movements plus ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Dt5wZF2WUi0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Dt5wZF2WUi0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Dt5wZF2WUi0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-25T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9ukTVl7sgT3V4SfySTHwV8PqEhg",
    "id": {
      "kind": "youtube#video",
      "videoId": "oIkzZizSQXQ"
    },
    "snippet": {
      "publishedAt": "2023-02-10T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min HYPER Dumbbell + Bodyweight HIIT workout | No Repeat",
      "description": "In this 30 Min HYPER Dumbbell + Bodyweight HIIT workout | No Repeat we will have a 2x block of 20 movements. A Combination ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oIkzZizSQXQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oIkzZizSQXQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oIkzZizSQXQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-10T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AOX_u-GoPOa9_x2RgA3w1nA00ew",
    "id": {
      "kind": "youtube#video",
      "videoId": "m7Ba22Qxe_E"
    },
    "snippet": {
      "publishedAt": "2022-08-12T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min COMPACT LEG Workout With Dumbbells | No Repeat + ABS",
      "description": "In this 40 Min COMPACT LEG Workout With Dumbbells | No Repeat + ABS we will have 2x blocks of exercises. Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/m7Ba22Qxe_E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/m7Ba22Qxe_E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/m7Ba22Qxe_E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-12T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-i4SFF9JP7AJHml0Vc3T_PPGbTA",
    "id": {
      "kind": "youtube#video",
      "videoId": "t5NpMfkCZ5A"
    },
    "snippet": {
      "publishedAt": "2022-11-09T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min DYNAMIC Explosive Dumbbell HIIT | Full Body Dumbbell HIIT | NO REPEAT",
      "description": "In this 35 min DYNAMIC Explosive Dumbbell HIIT | Full Body Dumbbell HIIT | NO REPEAT we will have a lot of dynamic and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/t5NpMfkCZ5A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/t5NpMfkCZ5A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/t5NpMfkCZ5A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-09T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6lGhyWx-tu322JaT7MpLRYLK6rw",
    "id": {
      "kind": "youtube#video",
      "videoId": "x2mF2Z6ii0o"
    },
    "snippet": {
      "publishedAt": "2023-01-04T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Full Body Kettlebell Workout | No Repeat | No Jump (Strength Workout)",
      "description": "In this 35 Min Full Body Kettlebell Workout We will be focussing on controlled movements and targeting specific areas. There will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/x2mF2Z6ii0o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/x2mF2Z6ii0o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/x2mF2Z6ii0o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-04T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wHqjFFDUmqQABJjs-VjgQPrT2dk",
    "id": {
      "kind": "youtube#video",
      "videoId": "Fym25kYKyxw"
    },
    "snippet": {
      "publishedAt": "2022-12-15T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Min Dumbbell Strength + Bodyweight Dynamic Workout  | No Repeat",
      "description": "In this 35 Min Dumbbell Strength + Bodyweight Dynamic Workout we have a Mixture of Basic Strength Exercises (67%) and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Fym25kYKyxw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Fym25kYKyxw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Fym25kYKyxw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-15T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "k6lhavCpFaDlKwuC8TIoGI-m8Ew",
    "id": {
      "kind": "youtube#video",
      "videoId": "D1N626Ay-J4"
    },
    "snippet": {
      "publishedAt": "2022-10-07T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min ADVANCED kettlebell HIIT workout ( BEASTMODE HIIT WORKOUT \ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 Min ADVANCED kettlebell HIIT workout we will bring my 'old skool' style of HIIT workout with my favorite movements ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D1N626Ay-J4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D1N626Ay-J4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D1N626Ay-J4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-07T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1fSPOw95PpkL8nBfaWjksccv0XY",
    "id": {
      "kind": "youtube#video",
      "videoId": "T3CbioK6KEE"
    },
    "snippet": {
      "publishedAt": "2023-02-28T07:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Kettlebell LEG Workout ( No Repeat | Intense | Strength Focused +ABS )",
      "description": "In this 40 min Kettlebell LEG Workout we will have a 8x bundle of 5x movements in total targeting the legs + abdominals . We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T3CbioK6KEE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T3CbioK6KEE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T3CbioK6KEE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-28T07:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ck2X-XdSvFcXnUbhBF5C1NuoBxM",
    "id": {
      "kind": "youtube#video",
      "videoId": "zbJZwRk6rhg"
    },
    "snippet": {
      "publishedAt": "2022-08-05T07:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min EFFECTIVE Full Body DUMBBELL Workout (\ud83e\uddbeBuild Muscle &amp; BURN Up to 500 Calories\ud83d\udd25)",
      "description": "In this 40 min EFFECTIVE Full Body DUMBBELL Workout we will have 2x blocks of compound exercises. Each block contains 5x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zbJZwRk6rhg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zbJZwRk6rhg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zbJZwRk6rhg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-05T07:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KnneSPIQ064zUOetP_0MMT0zgus",
    "id": {
      "kind": "youtube#video",
      "videoId": "QELg8VtnDqo"
    },
    "snippet": {
      "publishedAt": "2023-01-24T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min DYNAMIC Full Body Dumbbell Workout ( Combination Moves + ABS )",
      "description": "In this 40 min DYNAMIC Full Body Dumbbell Workout We will have a 4x bundle of 5x movements for 2x rounds in a row. There are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QELg8VtnDqo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QELg8VtnDqo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QELg8VtnDqo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-24T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "l404xXnMPQ2s-JBBcyfaVw13mNQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZrY_6l1wnU0"
    },
    "snippet": {
      "publishedAt": "2022-05-06T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min GIANT SET BACK &amp; TRICEP Workout (\ud83d\udd25DUMBBELLS + LONG BANDS STRENGTH WORKOUT\ud83d\udd25)",
      "description": "In this 30 Min GIANT SET BACK & TRICEP Workout we will be having 36x Movements in total. We will be having 6x Giant Sets in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZrY_6l1wnU0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZrY_6l1wnU0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZrY_6l1wnU0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-06T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "85fBh9vE6_JCh_MhWgBVcvGcGUk",
    "id": {
      "kind": "youtube#video",
      "videoId": "lrt-h0dWxBI"
    },
    "snippet": {
      "publishedAt": "2022-07-27T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body DUMBBELL HYBRID HIIT Workout ( Intense Compact Workout )",
      "description": "In this challenging 30 minute Full Body DUMBBELL HYBRID HIIT Workout we will be having 2x blocks. Each Block contains 3x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lrt-h0dWxBI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lrt-h0dWxBI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lrt-h0dWxBI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-27T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VhmebvsYrQG7rdPI0sCr_ixlj70",
    "id": {
      "kind": "youtube#video",
      "videoId": "Cc-iJ91n3XI"
    },
    "snippet": {
      "publishedAt": "2022-03-25T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "BACK BICEPS Resistance Band Workout ( 20 minute Follow Along Workout )",
      "description": "In this 20 minute Back & Biceps Resistance Band Workout We will be having 20 movements in total. We will have 12x movements ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Cc-iJ91n3XI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Cc-iJ91n3XI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Cc-iJ91n3XI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-25T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IQdW6qdajnXgXoTlcU4m3Gw1-QE",
    "id": {
      "kind": "youtube#video",
      "videoId": "JDxoydrS3gk"
    },
    "snippet": {
      "publishedAt": "2022-08-17T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min DYNAMIC Dumbbell Workout (Full Body COMPACT Workout\ud83d\udd25)",
      "description": "In this 30 min DYNAMIC Dumbbell Workout we will be having 2x blocks of exercises. Each block contains 3x bundles of 5x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JDxoydrS3gk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JDxoydrS3gk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JDxoydrS3gk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-17T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nJ9lqcdWC7VJCGMD4LfkBZudEtU",
    "id": {
      "kind": "youtube#video",
      "videoId": "c5n99kaWO84"
    },
    "snippet": {
      "publishedAt": "2022-09-28T05:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min KETTLEBELL STRENGTH FULL BODY Workout | No Jump | No Repeat ( COMPACT + ABS )",
      "description": "In this 30 min KETTLEBELL STRENGTH FULL BODY Workout | No Jump | No Repeat we will have 8x bundles of 4x Exercises: 1st ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c5n99kaWO84/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c5n99kaWO84/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c5n99kaWO84/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-28T05:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8JyzoHG2T5GU07SBdERJpmJnOvI",
    "id": {
      "kind": "youtube#video",
      "videoId": "q0TseML2grE"
    },
    "snippet": {
      "publishedAt": "2022-09-30T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min GIANT SET Lower Body Dumbbell Strength workout ( No Repeat | No Jump + ABS )",
      "description": "In this 40 min GIANT SET Lower Body Dumbbell Strength workout we will be having 8x giant sets with 5x exercises each. This first ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/q0TseML2grE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/q0TseML2grE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/q0TseML2grE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-30T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1a0nz_YlZuCz0-zvQR_szB9w-_E",
    "id": {
      "kind": "youtube#video",
      "videoId": "GA8GaRwbhOE"
    },
    "snippet": {
      "publishedAt": "2022-11-04T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Min EXPLOSIVE + STRENGTH Full Body Kettlebell Workout (\ud83d\udd25Full Body COMPACT Workout\ud83d\udd25)",
      "description": "In this 35 Min EXPLOSIVE + STRENGTH Full Body Kettlebell Workout we will have a combination of Upper & Lower Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GA8GaRwbhOE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GA8GaRwbhOE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GA8GaRwbhOE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-04T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "E5P9R5uE_k2ZxQHKbkSTCmoRwGU",
    "id": {
      "kind": "youtube#video",
      "videoId": "o_hf6aLSsRM"
    },
    "snippet": {
      "publishedAt": "2022-12-29T07:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Bodyweight CARDIO Kettlebell STRENGTH HIIT  ( No Repeat | Full Body Workout )",
      "description": "In this 30 Min Bodyweight CARDIO Kettlebell STRENGTH HIIT We will have 20x Bodyweight and 20x Kettlebell movements.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/o_hf6aLSsRM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/o_hf6aLSsRM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/o_hf6aLSsRM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-29T07:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1Zc8si9HO3wGCsvuyao4k5PW30c",
    "id": {
      "kind": "youtube#video",
      "videoId": "_kBVbm9vV2U"
    },
    "snippet": {
      "publishedAt": "2022-10-19T06:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min UPPER BODY DROP SET Dumbbell Workout (Chest, Back, Shoulder, Biceps, Triceps + ABS)",
      "description": "In this 40 min UPPER BODY DROP SET Dumbbell Workout we will have 20x Drop sets in total. We will simply Perform each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_kBVbm9vV2U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_kBVbm9vV2U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_kBVbm9vV2U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-19T06:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aycPXLNfV8Igc4-s3UShCotPR6A",
    "id": {
      "kind": "youtube#video",
      "videoId": "SRFOIEkzgtg"
    },
    "snippet": {
      "publishedAt": "2023-02-18T07:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Dumbbell Strength + Bodyweight Cardio Full Body Workout (No Repeat | Intense Burn )",
      "description": "In this 40 min Dumbbell Strength + Bodyweight Cardio Full Body Workout (No Repeat | Intense Burn ) we will have a mixture of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SRFOIEkzgtg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SRFOIEkzgtg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SRFOIEkzgtg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-18T07:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iokPQJHhA0Z7KD9RaQZE7lVNTaE",
    "id": {
      "kind": "youtube#video",
      "videoId": "_aIHzK8xcF8"
    },
    "snippet": {
      "publishedAt": "2023-01-06T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min Hardcore Dumbbell + Bodyweight HIIT Workout ( FULL BODY MAX BURN\ud83d\udd25)",
      "description": "In this 30 Min Hardcore Dumbbell + Bodyweight HIIT Workout we will have 10x dynamic dumbbell movements and 10x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_aIHzK8xcF8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_aIHzK8xcF8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_aIHzK8xcF8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-06T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DxC7I4BVGgWLqIyh6z2f5QY4n1o",
    "id": {
      "kind": "youtube#video",
      "videoId": "SpWqy0clJP4"
    },
    "snippet": {
      "publishedAt": "2022-11-30T07:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Full Body Resistance Band workout | No Repeat | Follow Along Strength Workout",
      "description": "In this 35 min Full Body Resistance Band strength workout we will be targeting the Chest, Back, Shoulder, Leg, Arms + the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SpWqy0clJP4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SpWqy0clJP4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SpWqy0clJP4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-30T07:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Uf3Yw-H2cguKe6g_HNOZEov2wwY",
    "id": {
      "kind": "youtube#video",
      "videoId": "dzsAowGhofg"
    },
    "snippet": {
      "publishedAt": "2022-04-18T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body Bodyweight + Kettlebell Workout (\ud83d\udd2550% KETTLEBELL 50% BODYWEIGHT CARDIO BURNER\ud83d\udca6)",
      "description": "In this 30 minute Full Body Bodyweight + Kettlebell Workout we are going to split the workout in two sections. We will start the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dzsAowGhofg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dzsAowGhofg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dzsAowGhofg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-18T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "w3uboCx6TXbDxhrtAbs0fCg_pOU",
    "id": {
      "kind": "youtube#video",
      "videoId": "K9NsdSuQVW4"
    },
    "snippet": {
      "publishedAt": "2022-10-12T05:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min Challenging Combination STRENGTH Workout with Weights",
      "description": "In this40 Min Challenging Combination STRENGTH Workout with Weights we will have 20x Combination exercises in total and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/K9NsdSuQVW4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/K9NsdSuQVW4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/K9NsdSuQVW4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-12T05:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h_C_tsvoAwyF9sonJWUQd5FS3LU",
    "id": {
      "kind": "youtube#video",
      "videoId": "h66-PQg4V58"
    },
    "snippet": {
      "publishedAt": "2022-12-08T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min COMBINATION Full Body Dumbbell Strength Workout",
      "description": "In this 40 Min COMBINATION Full Body Dumbbell Strength Workout we will have 16x Combination Exercises for 40 seconds long ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/h66-PQg4V58/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/h66-PQg4V58/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/h66-PQg4V58/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-08T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aFXzVP1H9eVOE69F6LT4ZLtUPas",
    "id": {
      "kind": "youtube#video",
      "videoId": "kS287wkxqlA"
    },
    "snippet": {
      "publishedAt": "2022-09-19T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min HYBRID Bodyweight + Dumbbell Workout ( FULL BODY + ABS | NO REPEAT)",
      "description": "In This 35 min HYBRID Bodyweight + Dumbbell Workout we will have two blocks of 18x exercises. A combination of Dynamic ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kS287wkxqlA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kS287wkxqlA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kS287wkxqlA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-19T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "O08C_-GxmVho-Xitxe-brbdK250",
    "id": {
      "kind": "youtube#video",
      "videoId": "L-_mCAaSJOY"
    },
    "snippet": {
      "publishedAt": "2022-09-09T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min Full Body Strength + HYBRID Workout (Upper Pull, Upper Push, Legs, Explosive Combo\ud83d\udd25+ ABS)",
      "description": "In this 40 Min Full Body Strength + HYBRID Workout we will have 8x bundles of 5x Exercises: 1st Exercise = Upper Body Pull 2nd ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/L-_mCAaSJOY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/L-_mCAaSJOY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/L-_mCAaSJOY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-09T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pQuGQJCMUYRBZHmiazVWnb2cELk",
    "id": {
      "kind": "youtube#video",
      "videoId": "Td5F7MRqm1c"
    },
    "snippet": {
      "publishedAt": "2023-01-21T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min COMPACT FULL BODY KETTLEBELL Workout | No Jump | No Repeat",
      "description": "In this 30 Min COMPACT FULL BODY KETTLEBELL Workout | No Jump | No Repeat we will have a 6x bundle of 5x movements in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Td5F7MRqm1c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Td5F7MRqm1c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Td5F7MRqm1c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-21T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HS5HMmocAdgaSrDvIUy5mPOUEpk",
    "id": {
      "kind": "youtube#video",
      "videoId": "kVmHcKFsd4w"
    },
    "snippet": {
      "publishedAt": "2023-01-17T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min HIGH IMPACT Bodyweight HIIT Workout (ADVANCED)",
      "description": "In this 30 Min HIGH IMPACT Bodyweight HIIT Workout (ADVANCED) we will have a 4x bundle of 5x exercises for 2x Rounds (20x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kVmHcKFsd4w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kVmHcKFsd4w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kVmHcKFsd4w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-17T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CWN4zGTWX7SCMAL-NigqHGIOFvQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "gAOb86V0yAQ"
    },
    "snippet": {
      "publishedAt": "2022-10-28T06:00:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body Dumbbell COMPOUND + DYNAMIC Workout | No Repeat ( BUILD &amp; BURN )",
      "description": "In this 30 min Full Body Dumbbell COMPOUND + DYNAMIC Workout we will have a combination of 50 seconds Compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gAOb86V0yAQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gAOb86V0yAQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gAOb86V0yAQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-28T06:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mYtEUCnke0xUn8uLk5g4Qho42pc",
    "id": {
      "kind": "youtube#video",
      "videoId": "3kkJ__jCql8"
    },
    "snippet": {
      "publishedAt": "2022-11-21T07:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Min Compact GIANT SET Full Body DUMBBELL Workout (Chest, Back, Shoulder, Leg &amp; ABS)",
      "description": "In this 40 Min Compact GIANT SET Full Body DUMBBELL Workout we will have 10x Giant-set of 4x movements in total and will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3kkJ__jCql8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3kkJ__jCql8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3kkJ__jCql8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-21T07:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SPnhut3D9_hxEmmQuSXN4CI2D5U",
    "id": {
      "kind": "youtube#video",
      "videoId": "hyQs4tRhpkg"
    },
    "snippet": {
      "publishedAt": "2023-01-02T07:00:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min UPPER BODY DUMBBELL workout Build Muscle | Chest , Back , Shoulder, Biceps , Triceps + ABS",
      "description": "In this 40 min UPPER BODY DUMBBELL workout Build Muscle | Chest , Back , Shoulder, Biceps , Triceps + ABS | No Repeat we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hyQs4tRhpkg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hyQs4tRhpkg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hyQs4tRhpkg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-02T07:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iLXYzGOB39zte1Ha5zNpVZQIcPA",
    "id": {
      "kind": "youtube#video",
      "videoId": "gUtm4oHYUSM"
    },
    "snippet": {
      "publishedAt": "2023-03-02T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 min UPPER BODY PUSH &amp; PULL Workout with Dumbbells | SuperSet (Chest, Back, Shoulder, Arms + ABS)",
      "description": "In this 45 min UPPER BODY PUSH & PULL Workout with Dumbbells | Superset We will have 5x bundle of Push & Pull Exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gUtm4oHYUSM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gUtm4oHYUSM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gUtm4oHYUSM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-02T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ShWQgrmkOECCBlWxkz16STgDCTU",
    "id": {
      "kind": "youtube#video",
      "videoId": "TuzbWuvvqfo"
    },
    "snippet": {
      "publishedAt": "2023-01-10T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min LOWER BODY DROP SET Dumbbell workout + ABS",
      "description": "In this 40 min LOWER BODY DROP SET Dumbbell workout + ABS we will have 20x Drop sets in total. We will simply Perform ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TuzbWuvvqfo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TuzbWuvvqfo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TuzbWuvvqfo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-10T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IdvlfwvcSQekp8nO2wT4jcRT6Jk",
    "id": {
      "kind": "youtube#video",
      "videoId": "xa7-2vwJelo"
    },
    "snippet": {
      "publishedAt": "2022-06-24T05:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min STAMINA Bodyweight HIIT (HARDCORE | \ud83d\udd25Burn Up to 500 Calories )",
      "description": "In this 30 Min STAMINA Bodyweight HIIT we will push the heart rate to the edge while we are challenging the different muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xa7-2vwJelo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xa7-2vwJelo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xa7-2vwJelo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-24T05:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bT40Pm9lNMcLQTMwFaADmTqfgVA",
    "id": {
      "kind": "youtube#video",
      "videoId": "2H1v960LUmo"
    },
    "snippet": {
      "publishedAt": "2022-11-07T07:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 Minute QUADZILLA Dumbbell LOWER BODY STRENGTH Workout (High Impact High Reps)",
      "description": "In this 45 Minute QUADZILLA Dumbbell LOWER BODY STRENGTH Workout we will be focusing on quad-dominant movements ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2H1v960LUmo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2H1v960LUmo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2H1v960LUmo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-07T07:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dkXtSab3qbGWHfJuFjDClf4ufZM",
    "id": {
      "kind": "youtube#video",
      "videoId": "QF_y7gmLm2E"
    },
    "snippet": {
      "publishedAt": "2022-09-26T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min FUSION Dumbbell POWER + Bodyweight CARDIO Workout (NO REPEAT | INTENSE  FULL BODY)",
      "description": "In this 35 min FUSION Dumbbell Power + Bodyweight Cardio Workout we will have 2x Block of 18x movements. After each 2x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QF_y7gmLm2E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QF_y7gmLm2E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QF_y7gmLm2E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-26T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lOPIiCWV70J20Q21DpQGgB2UA98",
    "id": {
      "kind": "youtube#video",
      "videoId": "T4xNk1TxcSE"
    },
    "snippet": {
      "publishedAt": "2023-01-26T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min COMPACT UPPER BODY DUMBBELL Workout ( Chest, Back, Shoulder, Biceps &amp; Triceps | No Repeat )",
      "description": "In this 40 min COMPACT UPPER BODY DUMBBELL Workout we will be targeting The ches-t, Back-, Shoulders-, Biceps- & triceps ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T4xNk1TxcSE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T4xNk1TxcSE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T4xNk1TxcSE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-26T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "f3KU0OVfvY2Pz5NujZ6Dso7mmJ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "VsruY5QreiQ"
    },
    "snippet": {
      "publishedAt": "2022-06-29T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min DRIPPING Bodyweight Kettlebell HIIT ( Burn Up To 500 Calories\ud83d\udd25)",
      "description": "In this 30 Min DRIPPING Bodyweight Kettlebell HIIT workout we will have 66% kettlebell movements and 34% bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VsruY5QreiQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VsruY5QreiQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VsruY5QreiQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-29T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gsWYVl2lMCVAx2rQHasF4pV-A58",
    "id": {
      "kind": "youtube#video",
      "videoId": "k3txBLeQU28"
    },
    "snippet": {
      "publishedAt": "2022-12-22T07:00:07Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min DYNAMIC Full Body Dumbbell Workout | Combination Moves + ABS",
      "description": "In this 40 min DYNAMIC Full Body Dumbbell Workout | Combination Moves + ABS We will have a 4x bundle of 5x movements for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/k3txBLeQU28/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/k3txBLeQU28/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/k3txBLeQU28/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-22T07:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1mvBPyg51iltgMUvvMkyXLuSMyk",
    "id": {
      "kind": "youtube#video",
      "videoId": "CwwNVQIirOM"
    },
    "snippet": {
      "publishedAt": "2022-08-24T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Muscle Building Full Body Dumbbell Workout ( No Repeat - Superset Workout )",
      "description": "In this 40 min Muscle Building Full Body Dumbbell Workout we will have 2x blocks of exercises. Each block contains 10x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CwwNVQIirOM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CwwNVQIirOM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CwwNVQIirOM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-24T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DfMnrQPacmiLqi7lXQXYAEVNaU8",
    "id": {
      "kind": "youtube#video",
      "videoId": "7aQBVhfuJg8"
    },
    "snippet": {
      "publishedAt": "2022-03-21T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Dumbbell COMPOUND and Bodyweight Workout ( INTENSE FULL BODY Workout \ud83e\uddbe)",
      "description": "In this 30 minute Dumbbell COMPOUND and Bodyweight Workout we will have 18x Dumbbell and Bodyweight Supersets.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7aQBVhfuJg8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7aQBVhfuJg8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7aQBVhfuJg8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-21T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x2-MTkV8dG20HSVycbZe3M_MiPI",
    "id": {
      "kind": "youtube#video",
      "videoId": "3uCykvdCqo8"
    },
    "snippet": {
      "publishedAt": "2023-02-07T07:15:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 Min Full Body BASIC COMPOUND Dumbbell Workout | No Repeat No Jump",
      "description": "In this 45 Min Full Body BASIC COMPOUND Dumbbell Workout | No Repeat No Jump we will be targeting mainly the major ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3uCykvdCqo8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3uCykvdCqo8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3uCykvdCqo8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-07T07:15:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tSTAJDfv5rQra-hQ2lxHcIEnY38",
    "id": {
      "kind": "youtube#video",
      "videoId": "E-ZPksWJR3M"
    },
    "snippet": {
      "publishedAt": "2022-09-16T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min EXPLOSIVE Bodyweight + POWER kettlebell HIIT | No Repeat",
      "description": "In this 30 min EXPLOSIVE Bodyweight + POWER kettlebell HIIT we will have a 50/50 combination of Kettlebell and bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E-ZPksWJR3M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E-ZPksWJR3M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E-ZPksWJR3M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-16T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zHjWKU2oXkymVdYN-TBXqqSQlfo",
    "id": {
      "kind": "youtube#video",
      "videoId": "8AFE5JaZXng"
    },
    "snippet": {
      "publishedAt": "2022-10-05T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min FULL BODY STRENGTH Dumbbell Workout | PUSH PULL Workout",
      "description": "In this 40 min FULL BODY STRENGTH Dumbbell Workout | PUSH PULL Workout we will bring the basic effective exercises back ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8AFE5JaZXng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8AFE5JaZXng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8AFE5JaZXng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-05T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TFH2RFKp3SO4tNkWUznMBZHrFbs",
    "id": {
      "kind": "youtube#video",
      "videoId": "HYgTD4CC_PM"
    },
    "snippet": {
      "publishedAt": "2022-08-31T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min Muscle Building Dumbbell Workout ( NO Repeat | COMPOUND GIANT SET + ABS )",
      "description": "In this 40 min Muscle Building Dumbbell Workout we will have 2x blocks of compound + Abdominals exercises. Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HYgTD4CC_PM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HYgTD4CC_PM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HYgTD4CC_PM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-31T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mGV809o2ljNVoNOj-FGHNA4KFnM",
    "id": {
      "kind": "youtube#video",
      "videoId": "FL8gUjmk8GY"
    },
    "snippet": {
      "publishedAt": "2021-08-29T19:15:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Cool Down and Stretching DanielPT",
      "description": "Cool Down and Stretching DanielPT This Cool down and Stretching is including follow along at home cooldown and stretch ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FL8gUjmk8GY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FL8gUjmk8GY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FL8gUjmk8GY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-29T19:15:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "58w2gRvWLXagtnYEh5ceom0__0g",
    "id": {
      "kind": "youtube#video",
      "videoId": "M5vmMdC3Jnk"
    },
    "snippet": {
      "publishedAt": "2021-08-26T17:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Dynamic Stretch Full Body Warm Up | Warming Up Follow Along Full Body",
      "description": "Dynamic Stretch Full Body Warm Up | Warming Up Follow Along Full Body This dynamic full warm up workout is including follow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M5vmMdC3Jnk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M5vmMdC3Jnk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M5vmMdC3Jnk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-26T17:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4hms4MaDIM-INXhk3TCqPwAWQKs",
    "id": {
      "kind": "youtube#video",
      "videoId": "VOaQSiJdCm0"
    },
    "snippet": {
      "publishedAt": "2020-09-13T14:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body Follow Along Home Workout | Kettlebell Bodyweight Exercises | HIIT Home Routine",
      "description": "30 minute Full Body Follow Along Home Workout | Kettlebell Bodyweight Exercises | HIIT Home Routine This 30 minute Full Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VOaQSiJdCm0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VOaQSiJdCm0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VOaQSiJdCm0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-13T14:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "luHA6sBJ8c7o1i6RFM88yv8WjRE",
    "id": {
      "kind": "youtube#video",
      "videoId": "E6-YOsRAQ68"
    },
    "snippet": {
      "publishedAt": "2021-03-14T14:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Foam Rolling Full Body Routine |\u00a0Follow Along Stretching After Workout",
      "description": "Foam Rolling Full Body Routine | Follow Along Stretching After Workout Disclaimer: The foam roller used in this workout is gifted ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/E6-YOsRAQ68/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/E6-YOsRAQ68/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/E6-YOsRAQ68/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-14T14:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "td2-cegfsr12irh-l5PX14fiGys",
    "id": {
      "kind": "youtube#video",
      "videoId": "vx5E9pKyNl8"
    },
    "snippet": {
      "publishedAt": "2021-02-03T15:15:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Dynamic Full Warm Up | Warming Up Follow Along Full Body | Warm Up DanielPT",
      "description": "Dynamic Full Warm Up | Warming Up Follow Along Full Body This dynamic full warm up workout is including follow along at home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vx5E9pKyNl8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vx5E9pKyNl8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vx5E9pKyNl8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-03T15:15:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EmM1rz1Wkr0II4xHSAqfpKyuFyg",
    "id": {
      "kind": "youtube#video",
      "videoId": "ELj_1LZFDGQ"
    },
    "snippet": {
      "publishedAt": "2021-05-19T15:00:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Build Big Shoulders with Bands | Follow Along",
      "description": "Build Big Shoulders with Bands | Follow Along | SHOULDER Workout with Long Band feat. Long Resistance Bands from ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ELj_1LZFDGQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ELj_1LZFDGQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ELj_1LZFDGQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-19T15:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ueYcw-Xwj1RIL-a6-vMZwzGAntc",
    "id": {
      "kind": "youtube#video",
      "videoId": "ewbiI5WR76s"
    },
    "snippet": {
      "publishedAt": "2020-07-15T09:30:28Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute INTENSE KETTLEBELL HIIT WORKOUT // Follow Along // DanielPTFitness",
      "description": "This 30 minute INTENSE KETTLEBELL HIIT WORKOUT is made up of 3 x blocks of exercises . Each block contains 6 x exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ewbiI5WR76s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ewbiI5WR76s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ewbiI5WR76s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-15T09:30:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lvhr4O7MXcFz8Xi5kLRUXYGEQ14",
    "id": {
      "kind": "youtube#video",
      "videoId": "BzlhyfDgu0c"
    },
    "snippet": {
      "publishedAt": "2021-01-17T14:30:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Bodyweight Full Workout | Serious Follow Along Full Body Workout",
      "description": "30 min Bodyweight Full Workout | Follow Along Full Body Workout This 30 min bodyweight full workout is a follow along full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BzlhyfDgu0c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BzlhyfDgu0c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BzlhyfDgu0c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-17T14:30:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "a0h8loIthW7GwnMhH5INxFkEAV0",
    "id": {
      "kind": "youtube#video",
      "videoId": "Mu2ZKhbrcOU"
    },
    "snippet": {
      "publishedAt": "2021-10-07T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "No Repeat Core Stability Workout with Physioball",
      "description": "No Repeat Core Stability Workout with Physioball This workout is a no repeat abs and core workout using a physioball, also ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Mu2ZKhbrcOU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Mu2ZKhbrcOU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Mu2ZKhbrcOU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-07T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mQBRFMBPQt6HT53j0iNPAeiE280",
    "id": {
      "kind": "youtube#video",
      "videoId": "_HtLveTte8A"
    },
    "snippet": {
      "publishedAt": "2021-01-20T18:15:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 Min Serious ARMS Follow Along Workout | BICEPS and TRICEPS Workout Routine at Home",
      "description": "25 Min Serious ARMS Follow Along Workout | BICEPS and TRICEPS Workout Routine at Home This 25 min serious arms follow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_HtLveTte8A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_HtLveTte8A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_HtLveTte8A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-20T18:15:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bTWODKjrBkE8tl9RmbB7UbAdzp8",
    "id": {
      "kind": "youtube#video",
      "videoId": "_GKEPOLR2fc"
    },
    "snippet": {
      "publishedAt": "2021-04-18T16:30:17Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute ADVANCED Kettlebell and Bodyweight HIIT workout | Cardio Strength Workout",
      "description": "This ADVANCED Kettlebell and Bodyweight HIIT workout contains 2x block of exercises and an afterburner. Each block contains ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_GKEPOLR2fc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_GKEPOLR2fc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_GKEPOLR2fc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-18T16:30:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TaS_5pMaFRpXwZcQ-6s24eG_etg",
    "id": {
      "kind": "youtube#video",
      "videoId": "Bc6xSVKKg4o"
    },
    "snippet": {
      "publishedAt": "2021-04-25T16:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes CHALLENGING Kettlebell HIIT Workout",
      "description": "This kettlebell workout is a full body HIIT workout. We will have 3 blocks of exercises, each block contains 4 movements, each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Bc6xSVKKg4o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Bc6xSVKKg4o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Bc6xSVKKg4o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-25T16:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KrD_Bn5m99_yFHorohnc3GSiEos",
    "id": {
      "kind": "youtube#video",
      "videoId": "AS1ejRUkzL4"
    },
    "snippet": {
      "publishedAt": "2019-06-16T18:30:21Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "BICEPS WORKOUT | 7 biceps exercises With the EZ-BAR | Biceps workout at home | DANIELPT",
      "description": "Biceps workout at home! In this video I demonstrate 7 Great Biceps exercises with the EZ-bar for training the biceps and he ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AS1ejRUkzL4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AS1ejRUkzL4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AS1ejRUkzL4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-06-16T18:30:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rbbZ5eAcYhxAlIJ-MClTC-x514Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "9KgS6WFhtbg"
    },
    "snippet": {
      "publishedAt": "2019-11-13T19:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Dumbbell Workout Strength training at home | Dumbbell strength workout | Full body strength workout",
      "description": "This 20 minute Dumbbell Workout Strength training at home | Dumbbell strength workout | Full body strength workout is made up ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9KgS6WFhtbg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9KgS6WFhtbg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9KgS6WFhtbg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-13T19:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tb1dYoUVs2UfehtRdqjP0Xw6Rug",
    "id": {
      "kind": "youtube#video",
      "videoId": "CBi_PXlAI38"
    },
    "snippet": {
      "publishedAt": "2020-08-23T11:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body HIIT Home Workout | Kettlebell Workout | Full Length Follow Along Workout",
      "description": "30 minute Full Body HIIT Home Workout | 30 minute Full Body HIIT Home Workout | Full Length Follow Along Workout This full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CBi_PXlAI38/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CBi_PXlAI38/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CBi_PXlAI38/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-23T11:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Se76RpyUvvQZf2HAMfUgX-TF5BQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "uO-lirWZv2Y"
    },
    "snippet": {
      "publishedAt": "2017-07-07T21:43:04Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 minute INTENSE Home Workout without equipment |Home Cardio Workout | Body weight workout at home",
      "description": "This 20 minute INTENSE Home Workout without equipment |Home Cardio Workout | Body weight workout at home is made up of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uO-lirWZv2Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uO-lirWZv2Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uO-lirWZv2Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-07T21:43:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MZ6j1Ug4nRoJax1Oot_G_uWf4Kg",
    "id": {
      "kind": "youtube#video",
      "videoId": "gNAUDiKMu08"
    },
    "snippet": {
      "publishedAt": "2020-08-19T11:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Shoulder Follow Along Home Workout | Upper Body Long Resistance Band Workout | No noise",
      "description": "Shoulder Follow Along Home Workout | Upper Body Long Resistance Band Workout | No noise This shoulder follow along home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gNAUDiKMu08/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gNAUDiKMu08/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gNAUDiKMu08/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-19T11:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PYamOOdJLlEfB4zTz4MluMb0DUk",
    "id": {
      "kind": "youtube#video",
      "videoId": "XWomvcYqBjg"
    },
    "snippet": {
      "publishedAt": "2019-10-26T11:57:38Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Full body kettlebell workout | Full body workout at home | HIIT workout at home | DANIELPT",
      "description": "This 20 minute Full body kettlebell workout | Full body workout at home | HIIT workout at home is a HIIT workout that is made up of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XWomvcYqBjg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XWomvcYqBjg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XWomvcYqBjg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-26T11:57:38Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "McGHyf5k94e4qHoKt2YD_Y1f_os",
    "id": {
      "kind": "youtube#video",
      "videoId": "wia1M6ryVe8"
    },
    "snippet": {
      "publishedAt": "2021-02-17T14:30:28Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Long Band Chest Workout At Home | Chest Full Strength At Home | Follow Along Home Long Band",
      "description": "Long Band Chest Workout At Home| Chest Full Strength At Home | Follow Along Home Long Band This 20 min Long Band Chest ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wia1M6ryVe8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wia1M6ryVe8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wia1M6ryVe8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-17T14:30:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7sKiM-A3A7RLoDRTJkyEUCWxoNA",
    "id": {
      "kind": "youtube#video",
      "videoId": "C54o47qwIIA"
    },
    "snippet": {
      "publishedAt": "2022-05-03T10:47:45Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Exercises with CABLE PULLEY SYSTEM (Affordable Option For HOME GYM)",
      "description": "In this video I will be showing my favorite 27x exercises with the Cable Pulley System of the brand \u201cFITBEAST\u201d. This is such a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C54o47qwIIA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C54o47qwIIA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C54o47qwIIA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-03T10:47:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RnlnBYj7wAiuRZXNKHbI944RJ8Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "LmrnVBOKLmw"
    },
    "snippet": {
      "publishedAt": "2021-07-08T18:00:25Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Minute SIX PACK Workout | Kettlebell ABS Workout",
      "description": "This 15 Minute SIX PACK Workout | Kettlebell ABS Workout contains 10 different exercises for targeting the lower abs, upper abs ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LmrnVBOKLmw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LmrnVBOKLmw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LmrnVBOKLmw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-08T18:00:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UHON9yqxDKP6wrCeT6wnaTISCe4",
    "id": {
      "kind": "youtube#video",
      "videoId": "kDpyDQJqG1Y"
    },
    "snippet": {
      "publishedAt": "2020-12-06T15:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Serious Long Band Back Workout At Home | 40 min At Home Workout",
      "description": "Serious Long Band Back Workout At Home | 40 min At Home Workout Disclaimer: The long resistance bands used in this workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kDpyDQJqG1Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kDpyDQJqG1Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kDpyDQJqG1Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-06T15:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "b9-GlI6CHGgKSgajrLzkdxyhyac",
    "id": {
      "kind": "youtube#video",
      "videoId": "y21t_cBgy3o"
    },
    "snippet": {
      "publishedAt": "2021-07-12T04:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min SERIOUS HIIT KETTLEBELL Workout",
      "description": "This 30 min SERIOUS HIIT KETTLEBELL Workout is a mixture of strength movements in a short amount of time. We will have 3 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y21t_cBgy3o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y21t_cBgy3o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y21t_cBgy3o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-12T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WNr6O2Y2gxv1V7g2GYbm0cQq1aQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "7VLqtRJYQSk"
    },
    "snippet": {
      "publishedAt": "2021-02-10T18:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Strong Back Full Workout With Weights | Long Resistance Band + Kettlebell Workout",
      "description": "PERFECT BACK Workout With Resistance Band and Kettlebell | Resistance Band and Kettlebell Workout This 25 min Back ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7VLqtRJYQSk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7VLqtRJYQSk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7VLqtRJYQSk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-10T18:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "abfDNwolP1IkB5H8oI75ojxxjyk",
    "id": {
      "kind": "youtube#video",
      "videoId": "5WkW5DQUdEM"
    },
    "snippet": {
      "publishedAt": "2019-08-07T21:59:54Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "SHORT HIIT WORKOUT| No Equipment challenging 3 minute Workout | HIIT Warming up | DanielPT",
      "description": "No Equipment challenging 3 minute VERY SHORT FUN HIIT Workout | HIIT Warming up | DanielPT. Hey guys, here you have a 3 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5WkW5DQUdEM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5WkW5DQUdEM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5WkW5DQUdEM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-07T21:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3M32Tj4A1FV9_bw4Xj6Wh2MPVr8",
    "id": {
      "kind": "youtube#video",
      "videoId": "AZV5bDliD_Q"
    },
    "snippet": {
      "publishedAt": "2021-07-04T19:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min SWEATY Full Body Kettlebell HIIT",
      "description": "This 30 min SWEATY full body kettlebell HIIT is a mixture of strength movements in a short amount of time. We will have 2 blocks ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AZV5bDliD_Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AZV5bDliD_Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AZV5bDliD_Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-04T19:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TeELsa5NwusQZqDbufQMvbI0t9k",
    "id": {
      "kind": "youtube#video",
      "videoId": "mik0oAojBv0"
    },
    "snippet": {
      "publishedAt": "2020-08-12T11:45:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 minute ABS/CORE Workout // BODY WEIGHT HOME WORKOUT //DANIELPT",
      "description": "This 20 minute ABS/CORE Workout | BODYWEIGHT HOME WORKOUT consists of 2 x blocks of exercises. Each block contains 4 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mik0oAojBv0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mik0oAojBv0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mik0oAojBv0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-12T11:45:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NV9aRP2NfbmIAIWs-jPL2OI7oI0",
    "id": {
      "kind": "youtube#video",
      "videoId": "1LV0R928qF0"
    },
    "snippet": {
      "publishedAt": "2020-11-22T14:30:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body Kettlebell Workout - Serious Follow Along Home Workout",
      "description": "30 min Full Body Kettlebell Workout - Serious Follow Along Home Workout This 30 min Full Body Kettlebell Workout is a serious ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1LV0R928qF0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1LV0R928qF0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1LV0R928qF0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-22T14:30:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "766h0JH9bi9CiATdig732N1ZFow",
    "id": {
      "kind": "youtube#video",
      "videoId": "IoiO27mG1D8"
    },
    "snippet": {
      "publishedAt": "2021-01-27T19:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min No Jumping Full Body Workout - No Repeat | Serious Follow Along Home Workout",
      "description": "20 min No Jumping Full Body Workout - No Repeat | Serious Follow Along Home Workout This 20 min No Jumping Full Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IoiO27mG1D8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IoiO27mG1D8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IoiO27mG1D8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-27T19:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Qyj3r_qgotwtT9tNi9qYdR-B_MY",
    "id": {
      "kind": "youtube#video",
      "videoId": "VK_C19Nu2ng"
    },
    "snippet": {
      "publishedAt": "2019-10-09T14:17:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min. HARD CORE HIIT workout | No Equipment Full Body Workout | Cardio HIIT workout | DANIELPT",
      "description": "This 20 min. HIIT workout | No Equipment Full Body Workout | Cardio HIIT workout is made up of 2 blocks of 4 different exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VK_C19Nu2ng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VK_C19Nu2ng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VK_C19Nu2ng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-09T14:17:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4E2WQuzcReD77qn4KONs2S_5ue0",
    "id": {
      "kind": "youtube#video",
      "videoId": "xBDEHDVcYu0"
    },
    "snippet": {
      "publishedAt": "2021-06-09T14:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min CLIMAX  Bodyweight HIIT Workout",
      "description": "This 20 minute CLIMAX bodyweight HIIT workout contains 20x bodyweight exercises in a row. The workout starts with the low ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xBDEHDVcYu0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xBDEHDVcYu0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xBDEHDVcYu0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-09T14:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SscBp-JqwRkIPci8jCPGp3lqYf8",
    "id": {
      "kind": "youtube#video",
      "videoId": "dxqZzdKo3M8"
    },
    "snippet": {
      "publishedAt": "2021-06-24T14:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Minute NO JUMPING HIIT Workout",
      "description": "This workout is one of the workouts from my platform \u201cHomeGym\u201d where I provide 3x workout videos each week. With these ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dxqZzdKo3M8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dxqZzdKo3M8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dxqZzdKo3M8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-24T14:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Nr7itF_ZxQaWQ_WzXuav5MRy7gQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "hQgcyn-wCt8"
    },
    "snippet": {
      "publishedAt": "2020-03-15T17:30:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min. Hiit Workout | Kettlebell Full Body Follow Along Home Workout | Beginner Kettlebell Workout",
      "description": "20 min. Hiit Workout | Kettlebell Full Body Follow Along Home Workout | Beginner Kettlebell Workout In this 20 minute Hiit Workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hQgcyn-wCt8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hQgcyn-wCt8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hQgcyn-wCt8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-15T17:30:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BzNj46CH1CWiCulJb483BZD9jC4",
    "id": {
      "kind": "youtube#video",
      "videoId": "8BXV4ylGdcM"
    },
    "snippet": {
      "publishedAt": "2021-08-01T19:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 min HARDCORE Bodyweight HIIT for DIEHARDS",
      "description": "This 25 HARDCORE Bodyweight HIIT for DIEHARDS is a mixture of strength and cardio movements in a short amount of time.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8BXV4ylGdcM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8BXV4ylGdcM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8BXV4ylGdcM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-01T19:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D7vEUhHog8_Vyh15M5cDJCHaQwA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qq8F6nNnoBM"
    },
    "snippet": {
      "publishedAt": "2021-05-26T15:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Build Big Biceps &amp; Triceps with Bands | Follow Along",
      "description": "Build Big Biceps & Triceps with Bands | Follow Along Workout with Long Band feat. Long Resistance Bands from FITBEAST.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qq8F6nNnoBM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qq8F6nNnoBM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qq8F6nNnoBM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-26T15:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "S3s5Jz8GwHvyTb2LcKoS9WFyMRw",
    "id": {
      "kind": "youtube#video",
      "videoId": "EU_OiLfw2gs"
    },
    "snippet": {
      "publishedAt": "2021-03-07T17:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body Bodyweight HIIT At Home | No repeat + No equipment Full Workout",
      "description": "30 min Full Body Bodyweight HIIT At Home | No repeat + No equipment Full Workout This full body HIIT workout at home is a no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EU_OiLfw2gs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EU_OiLfw2gs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EU_OiLfw2gs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-07T17:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wa3XIJknYlDeHnKtFejuj2-dNwI",
    "id": {
      "kind": "youtube#video",
      "videoId": "JGE_mLzuO0w"
    },
    "snippet": {
      "publishedAt": "2021-01-31T15:45:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Serious Kettlebell HIIT | Follow Along At Home Workout",
      "description": "30 min Serious Kettlebell HIIT | Follow Along At Home Workout This 30 min kettlebell HIIT workout is a serious advanced full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JGE_mLzuO0w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JGE_mLzuO0w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JGE_mLzuO0w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-31T15:45:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3_6Dyi8OscAn0j6IgM3oesSTcTE",
    "id": {
      "kind": "youtube#video",
      "videoId": "zmxAfzyHVeo"
    },
    "snippet": {
      "publishedAt": "2022-01-26T06:00:23Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KILLER Kettlebell HIIT workout | Force Series DanielPT",
      "description": "In this 15 minute Dynamic Killer Kettlebell HIIT workout we will mix up some of the great and challenging kettlebell + bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zmxAfzyHVeo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zmxAfzyHVeo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zmxAfzyHVeo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-26T06:00:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "idQ4VPqel1lAjpxM2qELhNJeFNw",
    "id": {
      "kind": "youtube#video",
      "videoId": "gxSu-LeyLrc"
    },
    "snippet": {
      "publishedAt": "2021-05-16T15:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes ENERGETIC Kettlebell Bodyweight HIIT Workout",
      "description": "30 minutes ENERGETIC Kettlebell Bodyweight HIIT Workout This Kettlebell Bodyweight workout is a mixture of strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gxSu-LeyLrc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gxSu-LeyLrc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gxSu-LeyLrc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-16T15:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ow707luCUB4leFe3CvzAF4l2SiY",
    "id": {
      "kind": "youtube#video",
      "videoId": "UQEIIYNaSJA"
    },
    "snippet": {
      "publishedAt": "2021-07-23T05:15:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "PERFECT FULL BODY KETTLEBELL WORKOUT",
      "description": "This Full Body Kettlebell Workout is a full body strength and a bit of cardio workout. We will have 2 blocks of exercises, each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UQEIIYNaSJA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UQEIIYNaSJA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UQEIIYNaSJA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-23T05:15:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NP_GIawJvvh2hk8hpMUG0v46ZBw",
    "id": {
      "kind": "youtube#video",
      "videoId": "wvAd_qL6WXk"
    },
    "snippet": {
      "publishedAt": "2021-09-30T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "&quot;ROUND TRIP&quot; Full Body 30 minute Workout | Bodyweight HIIT",
      "description": "In this \u201cRound Trip\u201d Full Body 30 minute Workout we will be having strength, cardio and core exercises all together! For some of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wvAd_qL6WXk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wvAd_qL6WXk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wvAd_qL6WXk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-30T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bAsEFH8pzArYEw_mOJH3h-FNhVQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "4ldV7VRysN4"
    },
    "snippet": {
      "publishedAt": "2021-09-27T05:00:28Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Upper Body Lower Body Workout",
      "description": "In this 30 minutes Kettlebell Upper Body Lower Body Workout we will be having 30 movements in total. We will perform each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4ldV7VRysN4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4ldV7VRysN4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4ldV7VRysN4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-27T05:00:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vui45SLCuJnBjsKtPGnUJyQKE3o",
    "id": {
      "kind": "youtube#video",
      "videoId": "1UjTpzB9fLs"
    },
    "snippet": {
      "publishedAt": "2022-02-18T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "SHOULDER Workout with Dumbbells | 15 minute Strength Follow along Workout",
      "description": "In this 15 minute shoulder workout with dumbbells we will be targeting all three heads of the shoulder multiple times. We will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1UjTpzB9fLs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1UjTpzB9fLs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1UjTpzB9fLs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-18T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z_G6nVz77CGdfpnvxs09SnDCKo4",
    "id": {
      "kind": "youtube#video",
      "videoId": "oIeNyS_t7mk"
    },
    "snippet": {
      "publishedAt": "2021-04-14T13:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 MIN SIXPACK WORKOUT INTENSE AB workout | NO REPEAT",
      "description": "This Challenging 15 MIN SIXPACK WORKOUT INTENSE AB workout contains 15 different exercises. We will do each exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oIeNyS_t7mk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oIeNyS_t7mk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oIeNyS_t7mk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-14T13:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jkCh1QgjIXV5ScQmzFdpi_WyEew",
    "id": {
      "kind": "youtube#video",
      "videoId": "LIJGjcRkdHo"
    },
    "snippet": {
      "publishedAt": "2021-11-04T08:15:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "FULL CONTROL KETTLEBELL STRENGTH WORKOUT | 35 MIN FOLLOW ALONG WORKOUT",
      "description": "In this FULL CONTROL KETTLEBELL STRENGTH WORKOUT we will have 30 controlled and challenging movements to train ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LIJGjcRkdHo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LIJGjcRkdHo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LIJGjcRkdHo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-04T08:15:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DS4q1k2-7pyvoOu5C27NFwp7f1c",
    "id": {
      "kind": "youtube#video",
      "videoId": "pwb4BGpEPuU"
    },
    "snippet": {
      "publishedAt": "2021-10-18T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KETTLEBELL FULL BODY STRENGTH Workout / Follow along No Repeat",
      "description": "In this kettlebell full body strength + Bodyweight workout we will be challenging each muscle group at the time. At first we will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pwb4BGpEPuU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pwb4BGpEPuU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pwb4BGpEPuU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-18T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HDYmk__TIR1lhklM018WMWgW4hY",
    "id": {
      "kind": "youtube#video",
      "videoId": "nIFbeAvQvws"
    },
    "snippet": {
      "publishedAt": "2020-05-17T13:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. STRENGTH Kettlebell Upper Body Workout | Target Chest, Back, Shoulder, Arms | FOLLOW ALONG",
      "description": "30 min. STRENGTH Kettlebell Upper Body Workout | Target Chest, Back, Shoulder, Arms This FOLLOW ALONG KETTLEBELL ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nIFbeAvQvws/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nIFbeAvQvws/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nIFbeAvQvws/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-17T13:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3XlzbRBeXRRhK3IQrbuXNbejkuY",
    "id": {
      "kind": "youtube#video",
      "videoId": "1IWox7ivEX8"
    },
    "snippet": {
      "publishedAt": "2021-05-05T16:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Build a Big Chest with Bands | Follow Along",
      "description": "Build a Big Chest with Bands | Follow Along | CHEST Workout with Long Band feat. Long Resistance Bands from FITBEAST.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1IWox7ivEX8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1IWox7ivEX8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1IWox7ivEX8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-05T16:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3zCPR2EuSY0TYLvi_adcgbLrBus",
    "id": {
      "kind": "youtube#video",
      "videoId": "fzxvX1zw6TQ"
    },
    "snippet": {
      "publishedAt": "2021-02-03T18:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 min Ab Full Workout | Serious Follow Along Six Pack Workout | DanielPT Abs",
      "description": "15 min Ab Full Workout | Serious Follow Along Six Pack Workout | DanielPT Abs This 15 min ab full workout is a bodyweight core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fzxvX1zw6TQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fzxvX1zw6TQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fzxvX1zw6TQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-03T18:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Y4-vKqgaiz3r5LmukPYyUsY1NAY",
    "id": {
      "kind": "youtube#video",
      "videoId": "SYt1B_nxdL0"
    },
    "snippet": {
      "publishedAt": "2022-03-11T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Minute BODYWEIGHT HIIT ( SPEEDY\ud83d\udca8 &amp; SWEATY \ud83d\udca6CARDIO HIIT )",
      "description": "In this 15 minute Bodyweight HIIT Workout we will go FULL THROTTLE! We will be having only 10 movements in total and we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SYt1B_nxdL0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SYt1B_nxdL0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SYt1B_nxdL0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-11T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3qdWkbkhWQBgTtuVpQDxrvPPxEE",
    "id": {
      "kind": "youtube#video",
      "videoId": "bk0sTqePZg4"
    },
    "snippet": {
      "publishedAt": "2017-04-20T22:32:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute HARDCORE Total Body HIIT Workout | Home Bodyweight Workout",
      "description": "The good thing about HIIT training is that you can do it ANYWHERE! All you need is a little bit of space and a proper mindset.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bk0sTqePZg4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bk0sTqePZg4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bk0sTqePZg4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-04-20T22:32:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OWA8yAqua0eO6ypaU3FOBnM3_JU",
    "id": {
      "kind": "youtube#video",
      "videoId": "8tGIAKhUnHs"
    },
    "snippet": {
      "publishedAt": "2021-06-27T17:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 Min Calorie Killer Kettlebell Workout | Full Body HIIT",
      "description": "This Calorie Killer Kettlebell Workout | Full Body HIIT is a mixture of strength movements in a short amount of time. We will have 2 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8tGIAKhUnHs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8tGIAKhUnHs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8tGIAKhUnHs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-27T17:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ab2M-zwOGDSX5t2IpdFY45DORJw",
    "id": {
      "kind": "youtube#video",
      "videoId": "8Uzo3-7QKTU"
    },
    "snippet": {
      "publishedAt": "2020-04-03T19:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 min Glute and legs workout HIIT workout | Kettlebell Leg Workout | Leg and Butt Workout |DANIELPT",
      "description": "In this 25 min Glute and legs workout HIIT workout | Kettlebell Leg Workout | Leg and Butt Workout we have 2 blocks of exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8Uzo3-7QKTU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8Uzo3-7QKTU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8Uzo3-7QKTU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-03T19:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WPItgHnhLR-ui3eM5lrXlx6XlcI",
    "id": {
      "kind": "youtube#video",
      "videoId": "FP67F3pCMzk"
    },
    "snippet": {
      "publishedAt": "2020-04-01T05:18:55Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Upper body resistance band workout | Upper body workout at home | Upper body strength workout",
      "description": "This 35 minute Upper body resistance band workout | Upper body workout at home | Upper body strength workout is made up of 3 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FP67F3pCMzk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FP67F3pCMzk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FP67F3pCMzk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-01T05:18:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "27967eKyLIExPLPBQg-cSV8cpdM",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZQ6P0JQwC1A"
    },
    "snippet": {
      "publishedAt": "2021-08-05T21:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Low Impact FULL BODY Workout\ud83d\udc4a",
      "description": "This 30 min Low Impact FULL BODY Workout contains 30 different Exercises. Exercises which focuses on the upper body, lower ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZQ6P0JQwC1A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZQ6P0JQwC1A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZQ6P0JQwC1A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-05T21:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vy3gDey65ZDb0k6M-udDsrcJSBA",
    "id": {
      "kind": "youtube#video",
      "videoId": "fj_0yU2m62M"
    },
    "snippet": {
      "publishedAt": "2021-08-12T20:45:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 minute ARMS ABS kettlebell home workout",
      "description": "In this 20 minute Arms & Abs kettlebell workout we will go trough 20x movements for biceps , triceps and the abdominals. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fj_0yU2m62M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fj_0yU2m62M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fj_0yU2m62M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-12T20:45:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qqAXG4DLlaqHuMrQhxQB2dz_CHg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WyweevfHG9Y"
    },
    "snippet": {
      "publishedAt": "2021-09-02T23:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute INTENSE Bodyweight HIIT Workout",
      "description": "This 30 minute INTENSE Bodyweight HIIT Workout is a mixture of strength, cardio and core movements in a short amount of time.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WyweevfHG9Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WyweevfHG9Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WyweevfHG9Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-02T23:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W8ibRdg0EU2oRYDZTjjXU2xUqIU",
    "id": {
      "kind": "youtube#video",
      "videoId": "OFdW39gdLTU"
    },
    "snippet": {
      "publishedAt": "2021-06-30T23:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INSANE CHEST Workout With DUMBBELLS | Follow Along",
      "description": "This INSANE CHEST Workout with dumbbells contains 8x exercises to challenge the chest, shoulders, triceps and the lat muscles.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OFdW39gdLTU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OFdW39gdLTU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OFdW39gdLTU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-30T23:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lEFhNKI35ykzm8ihQNk51etwNH4",
    "id": {
      "kind": "youtube#video",
      "videoId": "EA_auT8Eknc"
    },
    "snippet": {
      "publishedAt": "2019-11-05T16:54:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Leg workout kettlebell | Glutes workout | Butt workout for women and men 20 min.",
      "description": "This Leg workout kettlebell | Glutes workout | Butt workout for women and men 20 min. is made up of 2 x 4 different exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EA_auT8Eknc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EA_auT8Eknc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EA_auT8Eknc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-05T16:54:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3WS2EiV7l86Uea1BGeyse7Y6LVw",
    "id": {
      "kind": "youtube#video",
      "videoId": "N5Dumo2xKTA"
    },
    "snippet": {
      "publishedAt": "2020-09-28T17:12:01Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "DanielPT&#39;s HomeGym | Follow Along Home Workouts",
      "description": "Hi, it's Daniel from DanielPT and this is \"DanielPT's HomeGym\". For more information, please click on: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/N5Dumo2xKTA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/N5Dumo2xKTA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/N5Dumo2xKTA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-28T17:12:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cdjA1FQX5qZlCp2i2k7Yfj2eQWw",
    "id": {
      "kind": "youtube#video",
      "videoId": "jfEOqm6ZDgA"
    },
    "snippet": {
      "publishedAt": "2021-11-29T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "FULL CONTROL KETTLEBELL STRENGTH WORKOUT | 30 MIN GIANT SETS",
      "description": "In this FULL CONTROL KETTLEBELL STRENGTH WORKOUT we will have 10x Giant sets. Each giant set contains 3x movements ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jfEOqm6ZDgA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jfEOqm6ZDgA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jfEOqm6ZDgA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-29T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8bdqIMQbzBjVc2w8kh0RrUacNrQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "1w_HAnNfvnE"
    },
    "snippet": {
      "publishedAt": "2021-02-21T16:45:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Cardio Bodyweight HIIT | Full Body No Equipment At Home",
      "description": "30 min Cardio Bodyweight HIIT | Full Body No Equipment At Home This 30 min Cardio Bodyweight HIIT is a full body no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1w_HAnNfvnE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1w_HAnNfvnE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1w_HAnNfvnE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-21T16:45:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PaHPzDUd-lx7E9PNSBjWAN6Pqqc",
    "id": {
      "kind": "youtube#video",
      "videoId": "L8bA5_1XHIk"
    },
    "snippet": {
      "publishedAt": "2020-06-24T10:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 min. GREAT FULL BODY Resistance Band Workout | At Home Strength Workout",
      "description": "This 25. Min. 25 min. GREAT FULL BODY Resistance Band combination Workout | FULL BODY STRENGTH Workout is made up ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/L8bA5_1XHIk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/L8bA5_1XHIk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/L8bA5_1XHIk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-24T10:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "k37L1YPrAoOqHxPDkoNrRnbzuVA",
    "id": {
      "kind": "youtube#video",
      "videoId": "9iqMkgI1igM"
    },
    "snippet": {
      "publishedAt": "2020-08-05T10:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Long Resistance Band Arm Workout | Massive Arm Workout For Mass Follow Along",
      "description": "30 minute Long Resistance Band Arm Workout | Massive Arm Workout For Mass Follow Along This 30 minute Long Resistance ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9iqMkgI1igM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9iqMkgI1igM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9iqMkgI1igM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-05T10:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Bm8pLqMS7FXoHKHAMTfQcoO9muc",
    "id": {
      "kind": "youtube#video",
      "videoId": "EtxH6L2-Ymc"
    },
    "snippet": {
      "publishedAt": "2021-01-24T17:35:02Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Serious Kettlebell HIIT | Serious Advanced Full Body Workout",
      "description": "This 30 min serious kettlebell HIIT is a serious advanced full body workout for everyone looking for an intense and sweaty home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EtxH6L2-Ymc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EtxH6L2-Ymc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EtxH6L2-Ymc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-24T17:35:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BGXvYaPdQxl0WuCiIWBDB4PbeSk",
    "id": {
      "kind": "youtube#video",
      "videoId": "eS1Lgz1-deg"
    },
    "snippet": {
      "publishedAt": "2021-11-08T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Serious Kettlebell Hiit Follow Along",
      "description": "In this Serious Kettlebell Hiit workout we will have 30x movements with kettlebell and a few bodyweight movements. We are going ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eS1Lgz1-deg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eS1Lgz1-deg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eS1Lgz1-deg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-08T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "U_DEyKLncBfCJnIMLO3fK1V2HEE",
    "id": {
      "kind": "youtube#video",
      "videoId": "vvgcsxGGqzA"
    },
    "snippet": {
      "publishedAt": "2022-03-18T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Chest Workout with Dumbbells (15 minute Follow A long + Push Ups)",
      "description": "In this 15 minute Chest Workout with Dumbbells we will be having 3x bundles of 5x exercises. Some of the exercises with the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vvgcsxGGqzA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vvgcsxGGqzA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vvgcsxGGqzA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-18T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "B6KL1q7YmjTGBURVbEHjJINnckk",
    "id": {
      "kind": "youtube#video",
      "videoId": "rOf9a2szuFk"
    },
    "snippet": {
      "publishedAt": "2020-04-10T11:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT Full Body Kettlebell Workout at Home| Body weight Workout Kettlebell Cardio Strength Workout",
      "description": "This 30 min. HIIT Full Body Kettlebell Workout at Home | Bodyweight Workout + Kettlebell Workout | Cardio + Strength Workout at ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rOf9a2szuFk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rOf9a2szuFk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rOf9a2szuFk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-10T11:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0uIUEQadzqx7cc2g0o1R8nrMWUg",
    "id": {
      "kind": "youtube#video",
      "videoId": "EflT1kAmZ2Q"
    },
    "snippet": {
      "publishedAt": "2021-08-08T15:00:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min Booty Band Workout at Home - Grow Your Glutes",
      "description": "In this 20 minute Boody Band Workout we will have 2x blocks of workout. Each Block contains 5x movements and we will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EflT1kAmZ2Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EflT1kAmZ2Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EflT1kAmZ2Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-08T15:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AkuaCLOq3WkgyqjSZYm1pzjKzSs",
    "id": {
      "kind": "youtube#video",
      "videoId": "BPQLXQvbPbI"
    },
    "snippet": {
      "publishedAt": "2021-03-03T15:00:20Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min Low Impact Full Body Workout at home | No Repeat Full Workout",
      "description": "20 min Low Impact Full Body Workout at home | No Repeat Full Workout This 20 min low impact full body workout entails two ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BPQLXQvbPbI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BPQLXQvbPbI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BPQLXQvbPbI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-03T15:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PC-bGNQjuXPOP6yJU-_D5tqf1-o",
    "id": {
      "kind": "youtube#video",
      "videoId": "RC2q8mQpsy0"
    },
    "snippet": {
      "publishedAt": "2021-10-28T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INSANE KETTLEBELL PUSH WORKOUT",
      "description": "In this Insane Kettlebell push workout we will have 21 minutes of kettlebell and bodyweight upper body PUSH- exercises! We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RC2q8mQpsy0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RC2q8mQpsy0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RC2q8mQpsy0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-28T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DcOz-Jyp4Ek6PN3uVlRMNdd2URM",
    "id": {
      "kind": "youtube#video",
      "videoId": "gDIWiOMTK48"
    },
    "snippet": {
      "publishedAt": "2019-05-03T05:18:00Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series | Part 4",
      "description": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series 2 x Blocks of 4 exercises | Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gDIWiOMTK48/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gDIWiOMTK48/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gDIWiOMTK48/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-05-03T05:18:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vXTdIvOBiUwFebEW-DBxJt17XMQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "74PVkv4RLHY"
    },
    "snippet": {
      "publishedAt": "2020-10-04T14:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min At Home Kettlebell Workout | Kettlebell Bodyweight Exercises | Fit From Home Follow Along",
      "description": "30 min At Home Kettlebell Workout | Kettlebell Bodyweight Exercises | Fit From Home Follow Along This 30 min at home kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/74PVkv4RLHY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/74PVkv4RLHY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/74PVkv4RLHY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-04T14:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4l-2IzCwnlxm95WsfmtJhpv11yQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "ili6CZ-377g"
    },
    "snippet": {
      "publishedAt": "2021-02-14T14:45:17Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body HIIT At Home Dumbbells - Advanced Full At Home Workout",
      "description": "30 min Full Body HIIT At Home Dumbbells - Advanced Full At Home Workout This full body hiit workout is an advanced at home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ili6CZ-377g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ili6CZ-377g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ili6CZ-377g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-14T14:45:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GkoEwhbnGCj69_C1Re198ia-AMo",
    "id": {
      "kind": "youtube#video",
      "videoId": "JBA9XFvH_88"
    },
    "snippet": {
      "publishedAt": "2021-10-11T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT FULL BODY KETTLEBELL BODYWEIGHT | NO REPEAT",
      "description": "In this HIIT FULL BODY KETTLEBELL BODYWEIGHT Workout we will be Combining strength movements with cardio and core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JBA9XFvH_88/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JBA9XFvH_88/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JBA9XFvH_88/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-11T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "poQiITOGnD9jpvQYEXOfVdHcLB4",
    "id": {
      "kind": "youtube#video",
      "videoId": "CDfNsKQlUpg"
    },
    "snippet": {
      "publishedAt": "2021-08-19T20:15:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KETTLEBELL BACK follow along Workout",
      "description": "This 20 minute kettlebell back workout contains 20 movements. We will be doing each movement for 40 seconds long and with 20 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CDfNsKQlUpg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CDfNsKQlUpg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CDfNsKQlUpg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-19T20:15:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lQoYOUPxSk5EL8Gx9MM6NNi5pMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ah-pYeZI7Uc"
    },
    "snippet": {
      "publishedAt": "2021-02-24T14:30:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min Full Body Workout At Home | Low Impact No Repeat Workout",
      "description": "20 min Full Body Workout At Home | Low Impact No Repeat Workout This 20 min Full Body Workout at Home is a low impact no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ah-pYeZI7Uc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ah-pYeZI7Uc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ah-pYeZI7Uc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-24T14:30:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "U3vCYVamIIQj5VYkE94U8SPWWDE",
    "id": {
      "kind": "youtube#video",
      "videoId": "Mx24QWBlQDo"
    },
    "snippet": {
      "publishedAt": "2022-01-12T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 min HARDCORE Dynamic ABS workout | Force Series DanielPT",
      "description": "In this 15 minute Dynamic ABS workout we will hit the abdominal from all the angle possible. We will be having 18x different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Mx24QWBlQDo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Mx24QWBlQDo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Mx24QWBlQDo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-12T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ja4FdH2EzowzGLJc8wcooIk2IcQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "okyeTR2rDG8"
    },
    "snippet": {
      "publishedAt": "2020-04-13T20:00:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Mini band Glute workout | Resistance band Glute workout | Leg resistance band workout | HIIT",
      "description": "This 30 min Mini band Glute workout | Resistance band Glute workout | Leg resistance band workout | HIIT is made up of 2 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/okyeTR2rDG8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/okyeTR2rDG8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/okyeTR2rDG8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-13T20:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HZdIdkbBJ68tqRQEFJ0PcsrX8Ew",
    "id": {
      "kind": "youtube#video",
      "videoId": "QGeMP-lfQzg"
    },
    "snippet": {
      "publishedAt": "2021-02-07T18:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 minute Full Body HIIT Home Workout | Kettlebell Workout | Serious Full Body Home Workout",
      "description": "35 minute Full Body HIIT Home Workout | Kettlebell Workout | Serious Full Body Home Workout This 35 min Full Body HIIT Home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QGeMP-lfQzg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QGeMP-lfQzg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QGeMP-lfQzg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-07T18:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AZ1qdJrVcy7l7wU_Nac2Nvjdf3k",
    "id": {
      "kind": "youtube#video",
      "videoId": "epQoLTSACA0"
    },
    "snippet": {
      "publishedAt": "2019-01-14T12:30:27Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Chest Back Shoulder Arms \u201cTORTURE\u201d Workout | Kettlebell HIIT Upper Body WORKOUT | FOLLOW ALONG",
      "description": "This 25-minute Kettlebell-upper body HIIT workout is made up of 2x blocks of 4 exercises, each exercise for 45 seconds followed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/epQoLTSACA0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/epQoLTSACA0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/epQoLTSACA0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-01-14T12:30:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VnK_KzFPPCASnQcacJsJId059zY",
    "id": {
      "kind": "youtube#video",
      "videoId": "c1rn8BLAokI"
    },
    "snippet": {
      "publishedAt": "2021-09-23T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 minute No Repeat Dumbbell Strength Workout",
      "description": "This 20 minute No Repeat Dumbbell Strength Workout is a full body workout that contains 20 different exercises. We will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c1rn8BLAokI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c1rn8BLAokI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c1rn8BLAokI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-23T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tAs9R01CxSMjtWn8WSrnwftOXzE",
    "id": {
      "kind": "youtube#video",
      "videoId": "5y0uV1DHXKg"
    },
    "snippet": {
      "publishedAt": "2020-05-29T10:36:03Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Resistance Band Upper Body Workout At Home | Chest Back Shoulder Biceps Triceps strength workout",
      "description": "This Resistance Band Upper Body Workout At Home | Chest Back Shoulder Biceps Triceps strength workout is made up of 2 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5y0uV1DHXKg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5y0uV1DHXKg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5y0uV1DHXKg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-29T10:36:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JUtsC4UVSGz2GLPkmsKDEqWOg6w",
    "id": {
      "kind": "youtube#video",
      "videoId": "FMkGxOTEV1c"
    },
    "snippet": {
      "publishedAt": "2020-11-08T14:45:22Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute No Equipment Workout",
      "description": "30 Minute No Equipment Workout | This 30 Minute At Home No Equipment Workout is a Full Body Follow Along Home Workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FMkGxOTEV1c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FMkGxOTEV1c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FMkGxOTEV1c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-08T14:45:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FXu4Wa1OkNYnOwdP3zSXCda5c_4",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZMOgAcOfgPQ"
    },
    "snippet": {
      "publishedAt": "2021-10-14T05:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute UPPER BODY STRENGTH Workout With Dumbbells",
      "description": "In this 30 Minute Upper Body Strength Workout With Dumbbells we will be targeting chest, back, shoulder, biceps and triceps.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZMOgAcOfgPQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZMOgAcOfgPQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZMOgAcOfgPQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-14T05:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Y_wr8m7P1fNUyFwCQEwzQCoKsCU",
    "id": {
      "kind": "youtube#video",
      "videoId": "t8mjaNgwATY"
    },
    "snippet": {
      "publishedAt": "2021-05-02T18:15:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "ADVANCED Full Body HIIT Workout | No Equipment",
      "description": "ADVANCED Full Body HIIT Workout | No Equipment This advanced full body bodyweight HIIT workout contains 2x blocks of HIIT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/t8mjaNgwATY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/t8mjaNgwATY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/t8mjaNgwATY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-02T18:15:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I5zST5ewBP48nlhIiaICO9TjFFE",
    "id": {
      "kind": "youtube#video",
      "videoId": "XqYHfm9f8Ho"
    },
    "snippet": {
      "publishedAt": "2021-04-21T16:30:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "SOLID &amp; COMPACT Full Body Low Impact Dumbbell Workout | Follow Along Dumbbell Workout",
      "description": "This Full Body Low Impact Dumbbell Workout | follow along Dumbbell workout contains 2x block of workout. Each block contains ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XqYHfm9f8Ho/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XqYHfm9f8Ho/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XqYHfm9f8Ho/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-21T16:30:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dPmIrQrPpzAdC2Q7HtDlhu187DE",
    "id": {
      "kind": "youtube#video",
      "videoId": "GKErQEKMbZw"
    },
    "snippet": {
      "publishedAt": "2021-06-20T16:30:19Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute KILLER KETTLEBELL HIIT Workout",
      "description": "This 30 Minute KILLER KETTLEBELL HIIT Workout is a mixture of strength movements in a short amount of time. We will have 3 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GKErQEKMbZw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GKErQEKMbZw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GKErQEKMbZw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-20T16:30:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "D8St5ElgnGRHsv4uYuaYN1NSuMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "jVvEpLtDMBc"
    },
    "snippet": {
      "publishedAt": "2021-05-09T13:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min Full Body Kettlebell Strength Workout",
      "description": "Kettlebell Full Body Workout At Home - Full Body Strength Training This 35 minute kettlebell full body workout is a kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jVvEpLtDMBc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jVvEpLtDMBc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jVvEpLtDMBc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-09T13:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M1vjwVbrg4xE4Lmw7d7seRjN_9U",
    "id": {
      "kind": "youtube#video",
      "videoId": "VkHLTXfq0m0"
    },
    "snippet": {
      "publishedAt": "2017-06-02T20:22:21Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Minute No equipment workout | Body Weight HIIT Workout | Advanced HIIT Workout | DANIELPT",
      "description": "This 15 Minute No equipment workout | Body Weight HIIT Workout | Advanced HIIT Workout is made up of 2 blocks of 4 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VkHLTXfq0m0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VkHLTXfq0m0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VkHLTXfq0m0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-06-02T20:22:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QEeKpuZtbaz7U-1u3-zSNii5xcc",
    "id": {
      "kind": "youtube#video",
      "videoId": "xWzaIPJkhxo"
    },
    "snippet": {
      "publishedAt": "2020-06-21T19:00:34Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 MIN. DUMBBELL FULL BODY HIIT WORKOUT | INTENSE HOME WORKOUT | Follow Along Workout",
      "description": "This 25 MIN. DUMBBELL FULL BODY HIIT WORKOUT | INTENSE HOME WORKOUT | Follow Along Workout is made up of 2 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xWzaIPJkhxo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xWzaIPJkhxo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xWzaIPJkhxo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-21T19:00:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o13D87it2vZte76DqkwT-0Qi03c",
    "id": {
      "kind": "youtube#video",
      "videoId": "i3mCmPZqLRE"
    },
    "snippet": {
      "publishedAt": "2021-03-17T16:30:17Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Workout | Full Body No Jumping Full Workout At Home",
      "description": "Kettlebell Workout | Full Body No Jumping Full Workout At Home This kettlebell workout is a full body no jumping routine. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/i3mCmPZqLRE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/i3mCmPZqLRE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/i3mCmPZqLRE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-17T16:30:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LbKx-lNUM_zY2ubQCWIh7sw5CvA",
    "id": {
      "kind": "youtube#video",
      "videoId": "hQYR-9lGlhI"
    },
    "snippet": {
      "publishedAt": "2020-06-30T10:00:17Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 MINUTE FULL BODY WORKOUT NO EQUIPMENT // ADVANCED HIIT WORKOUT // HIGH INTENSITY WORKOUT AT HOME",
      "description": "This 30 minute FULL BODY Workout // ADVANCED HIIT WORKOUT // HIGH INTENSITY WORKOUT AT HOME is made up of 2 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hQYR-9lGlhI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hQYR-9lGlhI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hQYR-9lGlhI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-30T10:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_VZaVrERjh1D21j3zTG-PglHZdc",
    "id": {
      "kind": "youtube#video",
      "videoId": "fSBBSWN1XKE"
    },
    "snippet": {
      "publishedAt": "2021-05-12T15:30:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Build a Big Back with Bands | Follow Along",
      "description": "Build a Big Back with Bands | Follow Along | BACK Workout with Long Band feat. Long Resistance Bands from FITBEAST.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fSBBSWN1XKE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fSBBSWN1XKE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fSBBSWN1XKE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-12T15:30:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "40LCYWCVUOh03sH31UNcGVFZVwc",
    "id": {
      "kind": "youtube#video",
      "videoId": "CBJ69rz-AJU"
    },
    "snippet": {
      "publishedAt": "2022-03-07T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes ADVANCED Bodyweight + Kettlebell HIIT | No Repeat",
      "description": "In This 30 minutes ADVANCED Bodyweight + Kettlebell HIIT we will have a mixture of kettlebell, bodyweight and core exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CBJ69rz-AJU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CBJ69rz-AJU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CBJ69rz-AJU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-07T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7Rcj_6355wSHmkrBiUY20EPppvU",
    "id": {
      "kind": "youtube#video",
      "videoId": "YY-gi_N6wcQ"
    },
    "snippet": {
      "publishedAt": "2021-08-15T13:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "PYRAMID FULL BODY WORKOUT - Power Kettlebell Workout",
      "description": "30 minutes PYRAMID FULL BODY Power! This Full Body workout combines high intensity bodyweight and kettlebell exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YY-gi_N6wcQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YY-gi_N6wcQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YY-gi_N6wcQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-15T13:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WelqXTWOzveOQpzTjLnWuAM7IMw",
    "id": {
      "kind": "youtube#video",
      "videoId": "_eBhRQMqyNQ"
    },
    "snippet": {
      "publishedAt": "2020-12-27T14:30:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute Leg Workout At Home | Serious Follow Along At Home Workout",
      "description": "30 Minute Leg Workout At Home | Serious Follow Along At Home Workout This 30 minute leg workout at home is a serious follow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_eBhRQMqyNQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_eBhRQMqyNQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_eBhRQMqyNQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-27T14:30:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "abL5pE7CvSpbzL7ZGucBBwZL9so",
    "id": {
      "kind": "youtube#video",
      "videoId": "fJCzZqWIf2U"
    },
    "snippet": {
      "publishedAt": "2021-10-04T05:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KETTLEBELL LEG WORKOUT | PERFECT STRENGTH WORKOUT",
      "description": "In this Kettlebell Leg Workout Strength Workout we will be challenging the leg muscles within 30 minutes. This is a great workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fJCzZqWIf2U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fJCzZqWIf2U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fJCzZqWIf2U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-04T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mRg11574D6a4lfWPHPB3aoAmITw",
    "id": {
      "kind": "youtube#video",
      "videoId": "a-Rn-0DbItg"
    },
    "snippet": {
      "publishedAt": "2021-03-31T13:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Chest and Biceps Dumbbell Workout | Super Set Upper Body Workout At Home",
      "description": "Chest and Biceps TORTURE Dumbbell Workout | 30 minutes Superset Workout This Chest and Biceps TORTURE Dumbbell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a-Rn-0DbItg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a-Rn-0DbItg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a-Rn-0DbItg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-31T13:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Th-chfmwn2xJEK31QOkm4bjE29w",
    "id": {
      "kind": "youtube#video",
      "videoId": "NMEGz-BijnA"
    },
    "snippet": {
      "publishedAt": "2022-01-05T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "No Equipment Full Body BOOSTER HIIT - FORCE No. 02 | DanielPT",
      "description": "No Equipment Full Body BOOSTER HIIT - FORCE No. 02 | DanielPT In this fast and explosive workout we try to keep the heart ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NMEGz-BijnA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NMEGz-BijnA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NMEGz-BijnA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-05T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-W4YqALeWPdamrrI4Uewf0MStbs",
    "id": {
      "kind": "youtube#video",
      "videoId": "U9iNO_UeDCA"
    },
    "snippet": {
      "publishedAt": "2017-05-11T06:44:43Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "ABS workout for men at home | ABS workout for women | Advanced ABS workout | DANIELPT",
      "description": "This challenging ABS workout for men at home | ABS workout for women | Advanced ABS workout is made up of 4 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U9iNO_UeDCA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U9iNO_UeDCA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U9iNO_UeDCA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-05-11T06:44:43Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x1eIgDxeHQtQ3OzbyizXmg7Vusc",
    "id": {
      "kind": "youtube#video",
      "videoId": "fph84Yh2zkA"
    },
    "snippet": {
      "publishedAt": "2021-10-25T05:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "DIE HARD FULL BODY KETTLEBELL HIIT",
      "description": "In this Die Hard Full Body Kettlebell + Bodyweight HIIT we are having 20x movements. We are going to perform each 5x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fph84Yh2zkA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fph84Yh2zkA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fph84Yh2zkA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-25T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nZIYSfL7gCdlBbtPAHr12KfRTeY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Esv1zq6_DIE"
    },
    "snippet": {
      "publishedAt": "2017-07-29T08:21:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "16 Minute HIIT Workout | Body Weight Workout | Full Body Weight Training Workout | No Equipment",
      "description": "This 16 Minute HIIT Workout | Body Weight Workout | Full Body Weight Training Workout | No Equipment is made up of 2 x 4 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Esv1zq6_DIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Esv1zq6_DIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Esv1zq6_DIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-29T08:21:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c42cGeqoQvz0tqxb4FzPj8-Vm-Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "EWDssa13jLM"
    },
    "snippet": {
      "publishedAt": "2022-03-16T06:00:21Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes Bodyweight Kettlebell HIIT Workout (FULL BLAST\ud83d\udd25\ud83d\udd25\ud83d\udd25)",
      "description": "In this 30 minutes Bodyweight Kettlebell HIIT Workout we will have 40x movements in total, 20 with the kettlebell and 20 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EWDssa13jLM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EWDssa13jLM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EWDssa13jLM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-16T06:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Y06v-5uu7soJHRvtfvJvy7H-bpE",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZeoiQOmAMs4"
    },
    "snippet": {
      "publishedAt": "2021-11-11T06:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Dumbbell + Bodyweight STRENGTH Upper Body Workout for Diehard",
      "description": "In This Dumbbell + Bodyweight Strength Upper Body Workout we will do 30 movements in total. In the first part of the workout we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZeoiQOmAMs4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZeoiQOmAMs4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZeoiQOmAMs4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-11T06:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GbDFxXy85gtbTgRSYr0qStsQRbg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WrQLAUjuq4Q"
    },
    "snippet": {
      "publishedAt": "2017-06-30T19:02:05Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 Minute HARD Full Body Workout | HIIT Workout at Home| Home Workout Without Equipment | DANIELPT",
      "description": "This 25 Minute HARD Full Body Workout | HIIT Workout at Home| Home Workout Without Equipment is made up of 2 x 5 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WrQLAUjuq4Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WrQLAUjuq4Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WrQLAUjuq4Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-06-30T19:02:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xH7DCO1o1bussOlKRTJrpEscnko",
    "id": {
      "kind": "youtube#video",
      "videoId": "jesenPIiNPY"
    },
    "snippet": {
      "publishedAt": "2021-09-09T20:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Upper Body Kettlebell Workout",
      "description": "In this 30 minute Upper Body Kettlebell Workout we will go through each muscle group of the upper body such as chest, back, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jesenPIiNPY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jesenPIiNPY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jesenPIiNPY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-09T20:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tYldSmjMtCqBuzXUwBnI-EeicTY",
    "id": {
      "kind": "youtube#video",
      "videoId": "u3Pb7Y2Ubls"
    },
    "snippet": {
      "publishedAt": "2021-11-22T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Serious HIIT Kettlebell Bodyweight Follow Along",
      "description": "In this Serious HIIT Kettlebell Bodyweight workout we will be working with 4x blocks of movements, 2x blocks with kettlebell and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/u3Pb7Y2Ubls/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/u3Pb7Y2Ubls/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/u3Pb7Y2Ubls/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-22T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qW8L1U_asfFFeGDi9_eLstcgTxE",
    "id": {
      "kind": "youtube#video",
      "videoId": "nDpZVjE2UEQ"
    },
    "snippet": {
      "publishedAt": "2021-11-15T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "No repeat No Equipment HIIT workout // DIEHARD 30 minute follow Along",
      "description": "In this Challenging \u201cNo repeat No Equipment HIIT workout\u201d we will push it to the LIMITS! We will have 36x movements in total.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nDpZVjE2UEQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nDpZVjE2UEQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nDpZVjE2UEQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-15T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "93mCqbvI9FAXE03Sa1Up1j9H080",
    "id": {
      "kind": "youtube#video",
      "videoId": "SQDh_yADb9s"
    },
    "snippet": {
      "publishedAt": "2022-01-19T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 minute Light Dumbbell HIIT | FORCE Series DanielPT",
      "description": "In this 15 minute Light Dumbbell HIIT we will have 18x movements. We will perform each movement for 35 seconds long and with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SQDh_yADb9s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SQDh_yADb9s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SQDh_yADb9s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-19T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "B-VOOdvYPgIwb8GRgsEwV1NiWew",
    "id": {
      "kind": "youtube#video",
      "videoId": "cjfev0aUhWY"
    },
    "snippet": {
      "publishedAt": "2022-02-09T06:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell ABS Workout | 15 minute Follow along",
      "description": "In this 15 minute kettlebell ABS workout we will keep simple, effective and challenging as always. We Will be having 12x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cjfev0aUhWY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cjfev0aUhWY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cjfev0aUhWY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-09T06:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-cr475RLZXfb09RcyaauQiWf1WM",
    "id": {
      "kind": "youtube#video",
      "videoId": "zGQiX1zL-2c"
    },
    "snippet": {
      "publishedAt": "2021-11-18T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 minute TOTAL BODY Kettlebell STRENGTH Home Workout 6x6",
      "description": "In this Total Body kettlebell strength workout we will go through each body part step by step. For each body part we will do 6x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zGQiX1zL-2c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zGQiX1zL-2c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zGQiX1zL-2c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-18T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EvXvIw5KrWjjgDPLizX6SKojd5s",
    "id": {
      "kind": "youtube#video",
      "videoId": "MylkiEMMAbc"
    },
    "snippet": {
      "publishedAt": "2020-09-27T15:13:56Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min. Long Band Chest Workout | Upper Body Resistance Band | Follow Along Home Workout",
      "description": "35 min. Long Band Chest Workout | Upper Body Resistance Band | Follow Along Home Workout This long band chest workout is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MylkiEMMAbc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MylkiEMMAbc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MylkiEMMAbc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-27T15:13:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Yeo2Xd1vqWxR0flmJ8VsdGIXqms",
    "id": {
      "kind": "youtube#video",
      "videoId": "eZ4-7WBfpP4"
    },
    "snippet": {
      "publishedAt": "2021-06-13T16:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 Minute CHALLENGING FULL BODY KETTLEBELL HIIT Workout",
      "description": "This 35 Minute CHALLENGING FULL BODY KETTLEBELL HIIT Workout is a mixture of strength movements in a short amount of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eZ4-7WBfpP4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eZ4-7WBfpP4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eZ4-7WBfpP4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-13T16:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LadYVVMbE4b1ytArZIw8sDX3jS0",
    "id": {
      "kind": "youtube#video",
      "videoId": "T-6722vz430"
    },
    "snippet": {
      "publishedAt": "2020-11-29T14:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Chest Workout With Dumbbells - At Home No Bench | Serious Follow Along Home Full Workout",
      "description": "Chest Workout With Dumbbells - At Home No Bench | Serious Follow Along Home Full Workout This 45 minute chest follow along ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T-6722vz430/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T-6722vz430/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T-6722vz430/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-29T14:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hPOgzrVd-7ig6pZj1ZugGxT8Ycs",
    "id": {
      "kind": "youtube#video",
      "videoId": "-QiuszsdmwM"
    },
    "snippet": {
      "publishedAt": "2020-03-01T21:11:54Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. HIIT Workout | Kettlebell workout and body weight Full body Training | DANIELPT",
      "description": "In this 30 minute Kettlebell fullbody and bodyweight HIIT workout we have 3 blocks of exercises in total. My new butt and leg ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-QiuszsdmwM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-QiuszsdmwM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-QiuszsdmwM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-01T21:11:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "i23qmDAwFP5ciTgpoqQ__5IfbU4",
    "id": {
      "kind": "youtube#video",
      "videoId": "l5jUZLVZfrU"
    },
    "snippet": {
      "publishedAt": "2021-11-01T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Leg Workout Follow Along",
      "description": "In this challenging Kettlebell Leg Workout we will be focusing on hamstrings, glutes and quadriceps. We will be having 17x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/l5jUZLVZfrU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/l5jUZLVZfrU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/l5jUZLVZfrU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-01T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wuzJljBxIS03Z1YlYrC0_6ulJjA",
    "id": {
      "kind": "youtube#video",
      "videoId": "FXZ73qtFA5U"
    },
    "snippet": {
      "publishedAt": "2020-03-25T22:09:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 MIN. BEST KETTLEBELL EXERCISES | UPPER BODY KETTLEBELL WORKOUT | Mass BUILDING WORKOUT AT HOME",
      "description": "This UPPER BODY KETTLEBELL WORKOUT includes 30 min. best kettlebell exercises for mass building at home and is made up ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FXZ73qtFA5U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FXZ73qtFA5U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FXZ73qtFA5U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-25T22:09:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0KmVY4Sg5hKnsDrKwhzenUpfxJE",
    "id": {
      "kind": "youtube#video",
      "videoId": "QFFaHaGJRxE"
    },
    "snippet": {
      "publishedAt": "2021-07-30T04:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Upperbody Lowerbody Kettlebell Workout",
      "description": "This full body workout is a mixture of Upper body , Lower body and Core exercises. We will have 15 movements, each movement ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QFFaHaGJRxE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QFFaHaGJRxE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QFFaHaGJRxE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-30T04:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-1bs4rtyYMKAigZnFrhJWax6NaY",
    "id": {
      "kind": "youtube#video",
      "videoId": "WrM-FISIwlM"
    },
    "snippet": {
      "publishedAt": "2021-01-06T15:45:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Kettlebell HIIT Workout | Full Body Follow Along Home Workout",
      "description": "30 min Kettlebell HIIT Workout | Full Body Follow Along Home Workout This 30 minute 30 min Kettlebell HIIT Full Body Follow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WrM-FISIwlM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WrM-FISIwlM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WrM-FISIwlM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-06T15:45:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h-p2sO_ZvOrDcF8nE_GscRP8_UA",
    "id": {
      "kind": "youtube#video",
      "videoId": "pX3bztX2pdM"
    },
    "snippet": {
      "publishedAt": "2022-01-17T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute Kettlebell Full Body HIIT | FORCE Series DanielPT",
      "description": "In this Kettlebell Full Body HIIT workout we will have 12x Giant Sets and each Giant Set Contains 3x Movements. We will perform ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pX3bztX2pdM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pX3bztX2pdM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pX3bztX2pdM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-17T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "d7qrVQ0D2Pyys7P570tYvETHcJE",
    "id": {
      "kind": "youtube#video",
      "videoId": "LDW26F4g_Xo"
    },
    "snippet": {
      "publishedAt": "2022-01-03T06:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Full Body Kettlebell GIANT SETS - FORCE SERIES 01 | DanielPT",
      "description": "Full Body Kettlebell GIANT SETS - FORCE SERIES 01 | DanielPT We are going to kick off the new year with a full body kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LDW26F4g_Xo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LDW26F4g_Xo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LDW26F4g_Xo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-03T06:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mr9w-gSELqTLCEJ0E5ewk7pWp4Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "3nQQsEz65YM"
    },
    "snippet": {
      "publishedAt": "2017-06-16T22:08:34Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 Minute Extreme HIIT WORKOUT | No Equipment HIIT Workout | Full Body Workout at Home |DANIELPT",
      "description": "This 20 Minute Extreme HIIT WORKOUT | No Equipment HIIT Workout | Full Body Workout at Home is made up of 4 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3nQQsEz65YM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3nQQsEz65YM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3nQQsEz65YM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-06-16T22:08:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PmtLf9wVN0SIYh9jnMTsBdCWbsI",
    "id": {
      "kind": "youtube#video",
      "videoId": "97vWXQukxtE"
    },
    "snippet": {
      "publishedAt": "2020-10-25T14:37:26Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 minute Long Band Full Body | Follow Along At Home Long Band",
      "description": "40 minute Long Band Full Body | Follow Along At Home Long Band This 40 minute long band full body workout is a follow along ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/97vWXQukxtE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/97vWXQukxtE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/97vWXQukxtE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-25T14:37:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gMMgyfLio4xZUryANKwOo13pNLw",
    "id": {
      "kind": "youtube#video",
      "videoId": "FAm8D3a5udE"
    },
    "snippet": {
      "publishedAt": "2020-07-12T11:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Full Body Resistance Band Exercises // FULL BODY Routine At Home // Follow Along Workout // DANIELPT",
      "description": "This FULL BODY routine with the full body resistance band exercises is made up of 4 x blocks of exercises . Each block contains 3 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FAm8D3a5udE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FAm8D3a5udE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FAm8D3a5udE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-12T11:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rcfh9zcItU_OVTg3CnJhn3tk4_s",
    "id": {
      "kind": "youtube#video",
      "videoId": "s3u0fWwiZGI"
    },
    "snippet": {
      "publishedAt": "2021-07-15T20:30:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute FULL BODY Dumbbell Strength Cardio workout",
      "description": "This 30 Minute FULL BODY Dumbbell Strength Cardio workout is a mixture of strength movements with the dumbbells and the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s3u0fWwiZGI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s3u0fWwiZGI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s3u0fWwiZGI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-15T20:30:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o4UeHL-Gt1ZKD12zekWs89uPsH4",
    "id": {
      "kind": "youtube#video",
      "videoId": "sTuf9AlmWZk"
    },
    "snippet": {
      "publishedAt": "2021-04-07T17:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Advanced UPPER BODY Workout with Long Resistance Band | Strength Band Workout",
      "description": "Advanced UPPER BODY Workout with Long Resistance Band | Strength Band Workout This 25 min upper body resistance band ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sTuf9AlmWZk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sTuf9AlmWZk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sTuf9AlmWZk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-07T17:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ad2fR6i9QqCFdgp2QCIJ1swWUKs",
    "id": {
      "kind": "youtube#video",
      "videoId": "JBTNLAXAgT4"
    },
    "snippet": {
      "publishedAt": "2022-02-16T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute CHALLENGING Kettlebell HIIT",
      "description": "In this 30 minute challenging kettlebell full body HIIT Workout we are trying to get the heart rate as high as we can and push it to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JBTNLAXAgT4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JBTNLAXAgT4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JBTNLAXAgT4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-16T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5p5pdt9lUHuvejaiAVql1bK7FoI",
    "id": {
      "kind": "youtube#video",
      "videoId": "q1_yGuUKe4o"
    },
    "snippet": {
      "publishedAt": "2020-07-22T10:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HEAVY DUMBBELL HOME LEG WORKOUT | FULL LENGTH FOLLOW ALONG WORKOUT | HIIT LEG EXERCISE ROUTINE",
      "description": "This 30 min Heavy Dumbbell Home Leg Workout consists out of 3 x blocks of hiit leg exercises . Each block contains 3 x giant sets ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/q1_yGuUKe4o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/q1_yGuUKe4o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/q1_yGuUKe4o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-22T10:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tbi4hB8UfiO_yeyb833AUpjm6I0",
    "id": {
      "kind": "youtube#video",
      "videoId": "oIpOZ-6wuzM"
    },
    "snippet": {
      "publishedAt": "2021-08-22T20:30:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INTENSE Kettlebell HIIT FullBody Follow Along Workout",
      "description": "In this 30 minute Intense Kettlebell Hiit we will have 2 blocks of workout. Each Block contains 3 giant sets and we will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oIpOZ-6wuzM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oIpOZ-6wuzM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oIpOZ-6wuzM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-22T20:30:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x_vr75z27ntezWL9APscLzweAWs",
    "id": {
      "kind": "youtube#video",
      "videoId": "H0nhRUD1skg"
    },
    "snippet": {
      "publishedAt": "2021-06-17T14:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "35 min SAVAGE UPPER BODY DUMBBELL Workout",
      "description": "This 35 minute SAVAGE Upper Body Dumbbell Workout Covers all the muscles groups of the upper body. The workout contains ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H0nhRUD1skg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H0nhRUD1skg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H0nhRUD1skg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-17T14:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NEowc7gCbGmhjTx0eu160k7WAzs",
    "id": {
      "kind": "youtube#video",
      "videoId": "i0PIoxx7AVM"
    },
    "snippet": {
      "publishedAt": "2021-05-23T18:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INTENSE FULL BODY DUMBBELL Workout | Muscle Building",
      "description": "This Intense Full Body Strength Workout with dumbbells includes a mix of upper body and lower body strength exercises. We will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/i0PIoxx7AVM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/i0PIoxx7AVM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/i0PIoxx7AVM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-23T18:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Jaj2BHOJ3kf3wQlZjZCExrFmQss",
    "id": {
      "kind": "youtube#video",
      "videoId": "G7Ud1ihnSBM"
    },
    "snippet": {
      "publishedAt": "2018-09-03T09:36:46Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Chest Workout With Dumbbells | Torture And Pump Routine with new exercises For a FULL CHEST",
      "description": "This Chest workout routine is for shocking and torturing the chest muscle in a right way with a different dumbbell exercises. Try this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/G7Ud1ihnSBM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/G7Ud1ihnSBM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/G7Ud1ihnSBM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2018-09-03T09:36:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6q0uEOR7T-8hZNlnfCqfHPaSdNk",
    "id": {
      "kind": "youtube#video",
      "videoId": "zGu4LBv8Q6c"
    },
    "snippet": {
      "publishedAt": "2020-06-12T10:00:29Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 min. Kettlebell Full Body \u201cTORTURE\u201d Workout | SUPER INTENSE HIIT Kettlebell Workout | DANIELPT",
      "description": "This 25 min. Kettlebell Full Body \u201cTORTURE\u201d Workout | SUPER INTENSE HIIT Kettlebell Workout made up of 2 x blocks of 4 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zGu4LBv8Q6c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zGu4LBv8Q6c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zGu4LBv8Q6c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-12T10:00:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IaiayyXW2aXc8RUfFxPFLDPT9hk",
    "id": {
      "kind": "youtube#video",
      "videoId": "vzWojqR1x7s"
    },
    "snippet": {
      "publishedAt": "2021-03-24T15:30:29Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Workout | Upper Body Full Workout At Home - Strength",
      "description": "Kettlebell Workout | Upper Body Full Workout At Home - Strength This kettlebell workout is an upper body full workout at home.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vzWojqR1x7s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vzWojqR1x7s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vzWojqR1x7s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-24T15:30:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cf6n3dzE_JBbOuKZDW1U-VFKYc0",
    "id": {
      "kind": "youtube#video",
      "videoId": "BqDZ7eETEUc"
    },
    "snippet": {
      "publishedAt": "2020-04-29T11:20:34Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "21 min. SUPER INTENSE Lower Body Upper Body CORE | NO EQUIPMENT HORROR HIIT from ATHLEAN-X Workout",
      "description": "This 21 min. SUPER INTENSE Lower Body Upper Body CORE | NO EQUIPMENT HORROR HIIT from ATHLEAN-X Workout is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BqDZ7eETEUc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BqDZ7eETEUc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BqDZ7eETEUc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-29T11:20:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "p2x_iGvdMNsPNHMO1Pf0-6OduwA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Pc9L2rh4-9Q"
    },
    "snippet": {
      "publishedAt": "2022-01-10T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Lower Body Kettlebell Workout at Home FORCE Series DanielPT",
      "description": "In this Lower Body Kettlebell Workout we will have 30 movements in total. We Will Perform each Movement for 45 seconds and we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Pc9L2rh4-9Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Pc9L2rh4-9Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Pc9L2rh4-9Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-10T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qiDChD6_mDV9n2YTvQ2DQcFCV3k",
    "id": {
      "kind": "youtube#video",
      "videoId": "h4kCHAY1CIk"
    },
    "snippet": {
      "publishedAt": "2020-04-06T19:00:10Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "25 min. FULL BODY workout HIIT | No equipment workout | No jumping full body workout | DANIELPT",
      "description": "This 25 minute follow along FULL BODY workout | No equipment workout | No jumping full body workout is made up of 2 x blocks ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/h4kCHAY1CIk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/h4kCHAY1CIk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/h4kCHAY1CIk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-06T19:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2eN-_LNKgfNqTaNGZJXYyvjV1sU",
    "id": {
      "kind": "youtube#video",
      "videoId": "s-uCG0JSXhE"
    },
    "snippet": {
      "publishedAt": "2022-01-31T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes Bodyweight Kettlebell HIIT | No Repeat",
      "description": "In this 30 minutes Bodyweight Kettlebell HIIT we will be working with a bundle of 3x movements. The first movement with the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s-uCG0JSXhE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s-uCG0JSXhE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s-uCG0JSXhE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-31T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "55MoFc7VbaIvz2rdcIHQv52Xjc8",
    "id": {
      "kind": "youtube#video",
      "videoId": "w2l2sclUP-U"
    },
    "snippet": {
      "publishedAt": "2022-02-28T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes Bodyweight + Kettlebell HIIT | No Repeat No Regret",
      "description": "In this 30 minutes Bodyweight + Kettlebell HIIT Workout we will have a mixture of kettlebell, bodyweight and core exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/w2l2sclUP-U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/w2l2sclUP-U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/w2l2sclUP-U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-28T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IIAPTXnJwS-gE68mvi8Hevgd-iM",
    "id": {
      "kind": "youtube#video",
      "videoId": "pF57sYe8riM"
    },
    "snippet": {
      "publishedAt": "2020-05-11T10:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. CHALLENGING FULL BODY Kettlebell Workout |  FOLLOW ALONG Workout Advanced",
      "description": "This 30 min. CHALLENGING FULL BODY Kettlebell Workout | FOLLOW ALONG Workout is made up of 2 x blocks of 4 exercises, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pF57sYe8riM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pF57sYe8riM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pF57sYe8riM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-11T10:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PznMKthslDzIJo1nreyNxBWSNIw",
    "id": {
      "kind": "youtube#video",
      "videoId": "XBkid_LGg4Q"
    },
    "snippet": {
      "publishedAt": "2020-05-23T09:25:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "EXTREMELY SWEATY HIIT Workout | No Equipment Workout | Intense Full Body HIIT | Follow Along Workout",
      "description": "This is EXTREMELY SWEATY HIIT Workout | No Equipment Workout | Intense Full Body HIIT made up of 2 x blocks of 4 exercises, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XBkid_LGg4Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XBkid_LGg4Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XBkid_LGg4Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-23T09:25:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XjVv2HhHl9IwDgweRvA22EJJaho",
    "id": {
      "kind": "youtube#video",
      "videoId": "Hi6JOLPDe5A"
    },
    "snippet": {
      "publishedAt": "2021-03-28T14:30:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Leg Workout At Home | Legs and Glutes Follow along",
      "description": "Kettlebell Leg Workout At Home | Legs and Glutes Follow along This kettlebell leg workout is targeting your legs and glutes.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Hi6JOLPDe5A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Hi6JOLPDe5A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Hi6JOLPDe5A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-28T14:30:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z9Pb3pzXCr4abQ2uemnXhiu72wU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ibpc7idj0gM"
    },
    "snippet": {
      "publishedAt": "2021-09-05T21:15:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell LEG Workout + Core",
      "description": "Welcome to today`s 40 minute challenging \"Kettlebell LEG Workout + Core\". Today's workout contains 3 blocks of exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ibpc7idj0gM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ibpc7idj0gM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ibpc7idj0gM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-05T21:15:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "B18tzcDH-gpYuDbo5RZBhVpF1v0",
    "id": {
      "kind": "youtube#video",
      "videoId": "O1J3LiFYkdc"
    },
    "snippet": {
      "publishedAt": "2020-06-17T12:01:33Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INTENSE Chest Exercises At Home | Kettlebell Chest Workout | Follow Along Workout",
      "description": "This 30. Min. INTENSE Chest Exercises At Home | Kettlebell Dumbbell Chest Workout | Follow Along Workout is made up of 3 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/O1J3LiFYkdc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/O1J3LiFYkdc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/O1J3LiFYkdc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-17T12:01:33Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lLk8DvP_FGaP8Zyxcn0hfDgIWhU",
    "id": {
      "kind": "youtube#video",
      "videoId": "1GBLb4FyZLQ"
    },
    "snippet": {
      "publishedAt": "2021-06-06T14:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "All Around FULL BODY Kettlebell Workout",
      "description": "This All Around FULL BODY Kettlebell Workout is a mixture of strength movements in a short amount of time. We will have 2 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1GBLb4FyZLQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1GBLb4FyZLQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1GBLb4FyZLQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-06T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DZAEWHS-d-S1y5tAjHl52l89ZDM",
    "id": {
      "kind": "youtube#video",
      "videoId": "j8SM0HMx3nc"
    },
    "snippet": {
      "publishedAt": "2021-12-06T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KETTLEBELL+BODYWEIGHT+ABS FULL BODY HIIT WORKOUT // DANIELPT",
      "description": "In this Kettlebell + Bodyweight + Abs Full body Hiit Workout we will work with Giant-Sets. We will have 12x giant-sets in total.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j8SM0HMx3nc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j8SM0HMx3nc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j8SM0HMx3nc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-06T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tAQgy-rN7TNlcwnqx944xqdth3c",
    "id": {
      "kind": "youtube#video",
      "videoId": "NQ23ZFiaze0"
    },
    "snippet": {
      "publishedAt": "2020-08-30T12:00:37Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Strong Upper Body Follow Along Workout | Back And Biceps Routine At Home | Workout for Mass",
      "description": "Strong Upper Body Follow Along Workout | Back And Biceps Routine At Home | Workout for Mass This strong upper body follow ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NQ23ZFiaze0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NQ23ZFiaze0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NQ23ZFiaze0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-30T12:00:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "j-Hg2xN7LA5wbp3iK9M3443gRf4",
    "id": {
      "kind": "youtube#video",
      "videoId": "clY_kiXI6HI"
    },
    "snippet": {
      "publishedAt": "2022-01-14T06:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "MUSCLE BUILDING + STRENGTH  Upper Body Dumbbell Workout | FORCE Series DanielPT",
      "description": "In This MUSCLE BUILDING + STRENGTH Upper Body Dumbbell Workout we will target the Chest , Back, Shoulder, Biceps and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/clY_kiXI6HI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/clY_kiXI6HI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/clY_kiXI6HI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-14T06:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jn3WqYuj-HKCjyjulxrH7TvywNI",
    "id": {
      "kind": "youtube#video",
      "videoId": "Amj0PAfhGIk"
    },
    "snippet": {
      "publishedAt": "2021-04-11T17:00:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Super INTENSE Bodyweight HIIT Workout | No Equipment Full Body Advanced HIIT",
      "description": "This INTENSE Bodyweight HIIT Workout contains 3x blocks of exercises. Each Block contains 4x exercises, each exercise for 35 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Amj0PAfhGIk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Amj0PAfhGIk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Amj0PAfhGIk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-11T17:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bqRW-vvVCGOgj6sgEiBXY7FZZTg",
    "id": {
      "kind": "youtube#video",
      "videoId": "xXD1IbwO5j0"
    },
    "snippet": {
      "publishedAt": "2021-06-02T13:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 minute COMPOUND LEG WORKOUT with Dumbbells | Follow Along",
      "description": "This 45 minute COMPOUND LEG WORKOUT with Dumbbells at Home | Follow Along workout is one of the workouts from my ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xXD1IbwO5j0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xXD1IbwO5j0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xXD1IbwO5j0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-02T13:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GH9LWBU-gzSszh3m-BvB309AZOE",
    "id": {
      "kind": "youtube#video",
      "videoId": "fq5xMpAk60E"
    },
    "snippet": {
      "publishedAt": "2021-11-25T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "STRONG ABS &amp; CORE with Kettlebell or Dumbbell",
      "description": "In this strong abs & core kettlebell or dumbbell workout we will have a combination of bodyweight and weight exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fq5xMpAk60E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fq5xMpAk60E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fq5xMpAk60E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-25T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RKEtskjbyfJm5UjMiM1LByxacIs",
    "id": {
      "kind": "youtube#video",
      "videoId": "AZvO5GIrP4s"
    },
    "snippet": {
      "publishedAt": "2020-12-20T14:30:25Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body Killer Workout At Home | Serious Kettlebell HIIT Workout",
      "description": "30 min Full Body Killer Workout At Home | Serious Kettlebell HIIT Workout This 30 min Full Body Killer Workout At Home is a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AZvO5GIrP4s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AZvO5GIrP4s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AZvO5GIrP4s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-20T14:30:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ndu9DTH3TXNPi79qTMU6yIA6_zc",
    "id": {
      "kind": "youtube#video",
      "videoId": "UU9YlTuhcww"
    },
    "snippet": {
      "publishedAt": "2022-02-02T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 minute BICEPS TRICEPS Superset Workout Dumbbells",
      "description": "In this 15 minute biceps triceps Superset workout with dumbbells we will be having 16 different movements. Seven exercises for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UU9YlTuhcww/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UU9YlTuhcww/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UU9YlTuhcww/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-02T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "e0eTDDaxy0U5Pp6oEa3cnbDT-bE",
    "id": {
      "kind": "youtube#video",
      "videoId": "JgLwGJHLp8Y"
    },
    "snippet": {
      "publishedAt": "2020-12-13T14:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute At Home No Equipment Workout | Serious Follow Along Home Workout",
      "description": "30 Minute At Home No Equipment Workout | Serious Follow Along Home Workout This 30 Minute At Home No Equipment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JgLwGJHLp8Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JgLwGJHLp8Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JgLwGJHLp8Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-13T14:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OQZf5DELDSRZtRO1fpeOv5zBBDA",
    "id": {
      "kind": "youtube#video",
      "videoId": "TUM_lofMmQk"
    },
    "snippet": {
      "publishedAt": "2020-06-08T10:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. Leg Workout with Resistance Bands (Part 2) | Resistance Band Workout Legs | Band Leg Workout",
      "description": "30 min. Leg Workout with Resistance Bands (Part 2) | Resistance Band Workout Legs | Band Leg Workout This 30 min.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TUM_lofMmQk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TUM_lofMmQk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TUM_lofMmQk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-08T10:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "q2cfb7gYZeC29qix_93m_ijxM9M",
    "id": {
      "kind": "youtube#video",
      "videoId": "X-IGUHHGVP0"
    },
    "snippet": {
      "publishedAt": "2021-08-29T18:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Full Body LONG BAND Workout Follow Along",
      "description": "This 45 minute Full Body LONG BAND Workout Follow Along is one of the workouts from my platform \u201cHomeGym\u201d where I provide ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/X-IGUHHGVP0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/X-IGUHHGVP0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/X-IGUHHGVP0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-29T18:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "f-W8IUNg9WVgdOSQSTkcmdBc90o",
    "id": {
      "kind": "youtube#video",
      "videoId": "yQWMuUPOMUw"
    },
    "snippet": {
      "publishedAt": "2020-08-16T12:06:32Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Intense Bodyweight HIIT Workout // Full Body Home Workout Follow Along // No equipment",
      "description": "This 30 minute Intense Bodyweight HIIT Workout is a full body home workout follow along. It consists out of 2 x blocks of exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yQWMuUPOMUw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yQWMuUPOMUw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yQWMuUPOMUw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-16T12:06:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_sK9q8KzlpH-8z1t8Xgo4rSyJTk",
    "id": {
      "kind": "youtube#video",
      "videoId": "wNY6BIB1Bzc"
    },
    "snippet": {
      "publishedAt": "2021-03-10T16:30:21Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Back and Tricep Workout With Dumbbells - Upper Body Workout At Home",
      "description": "Back and Tricep Workout With Dumbbells - Upper Body Workout At Home DanielPT's HomeGym Workout - this 40 min back and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wNY6BIB1Bzc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wNY6BIB1Bzc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wNY6BIB1Bzc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-10T16:30:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UCDcww2Bki2Lz3X0tjyqsHHDTz4",
    "id": {
      "kind": "youtube#video",
      "videoId": "bSZKRiCbUYk"
    },
    "snippet": {
      "publishedAt": "2019-04-15T23:01:08Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series | Part 2",
      "description": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series 2 x Blocks of 4 exercises | Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bSZKRiCbUYk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bSZKRiCbUYk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bSZKRiCbUYk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-15T23:01:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FWSHQAgyYRbooFAFD5x3FHFCgyU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hpmbRsK-dUo"
    },
    "snippet": {
      "publishedAt": "2022-02-25T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 minute Glute &amp; LEG workout with Dumbbell + Band",
      "description": "In this short 15 minute Glute & LEG workout with Dumbbell + Band we will combine the mini-bad with the dumbbell exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hpmbRsK-dUo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hpmbRsK-dUo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hpmbRsK-dUo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-25T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7IUwjrmiG8fTthicTYy-HluFzjQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "HCnr5P5OPSI"
    },
    "snippet": {
      "publishedAt": "2020-07-26T13:45:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min INTENSE HIIT HOME WORKOUT | FULL BODY KETTLEBELL HOME WORKOUT | FOLLOW ALONG FULL LENGTH",
      "description": "This 30 min INTENSE HIIT HOME WORKOUT is a FULL BODY KETTLEBELL HOME WORKOUT. The follow along full length ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HCnr5P5OPSI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HCnr5P5OPSI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HCnr5P5OPSI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-26T13:45:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7tVTWTEDxGHw7v93mNcefi4D4FM",
    "id": {
      "kind": "youtube#video",
      "videoId": "yQYYezQdh1g"
    },
    "snippet": {
      "publishedAt": "2022-02-21T06:00:24Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 minute FULL BODY STRENGTH Kettlebell Workout | Supersets",
      "description": "In this 40 min full body strength kettlebell Workout we will be having 10 supersets targeting the entire body. We will be doing each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yQYYezQdh1g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yQYYezQdh1g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yQYYezQdh1g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-21T06:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eOq2OHh6m4ykQLRD88KArMnhPag",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qtn4f31dTpw"
    },
    "snippet": {
      "publishedAt": "2021-12-16T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute FULL BODY kettlebell STRENGTH Workout // NO JUMP NO REPEAT",
      "description": "In This 30 minute FULL BODY Kettlebell STRENGTH Workout we will be having a combination of upper-and the Lower Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qtn4f31dTpw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qtn4f31dTpw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qtn4f31dTpw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-16T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cTfjBJ6gwEzJ7Ob2xNVNtdZ_P-Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "fi-nxbzM3yo"
    },
    "snippet": {
      "publishedAt": "2020-08-02T20:09:20Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Leg &amp; Glutes No Noise Home Workout | Rhythmic Dance Leg Workout | No noise Exercises",
      "description": "30 minute Leg & Glutes No Noise Home Workout | Rhythmic Dance Leg Workout | No noise Exercises This 30 min no noise legs ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fi-nxbzM3yo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fi-nxbzM3yo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fi-nxbzM3yo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-02T20:09:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DKEHmI9hBZYH5k1UZcNvpNEWP0M",
    "id": {
      "kind": "youtube#video",
      "videoId": "MWNse8hD6Po"
    },
    "snippet": {
      "publishedAt": "2022-02-14T06:00:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SUPREME Bodyweight HIIT Workout",
      "description": "In this 30 minute Bodyweight HIIT Workout we are trying to get the heart rate as high as we can and push it to the limits. We are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MWNse8hD6Po/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MWNse8hD6Po/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MWNse8hD6Po/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-14T06:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AeOT34KXS9TDI6frbty68PAvTDU",
    "id": {
      "kind": "youtube#video",
      "videoId": "TbrQtf4nlmY"
    },
    "snippet": {
      "publishedAt": "2017-07-15T08:24:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Legs and Glutes \u201cTORTURE\u201d Workout | Kettlebell HIIT WORKOUT",
      "description": "This 25 minute Kettlebell-HIIT workout is made up of 3x4 different exercises and each 4 exercise for 2 rounds. We do the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TbrQtf4nlmY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TbrQtf4nlmY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TbrQtf4nlmY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-15T08:24:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CLb8HvRA2yP6KigMbU94P6OvUPM",
    "id": {
      "kind": "youtube#video",
      "videoId": "sewaX21jfoU"
    },
    "snippet": {
      "publishedAt": "2021-10-21T05:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell ABS Workout | 15 minute No Repeat Sixpack",
      "description": "In this kettlebell ABS Workout we will be having 15x challenging movements with and without the kettlebell for targeting the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sewaX21jfoU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sewaX21jfoU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sewaX21jfoU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-21T05:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GI3aXIf7notmK1Knmo2GJeOsxlM",
    "id": {
      "kind": "youtube#video",
      "videoId": "BayV9q8J_Dg"
    },
    "snippet": {
      "publishedAt": "2021-04-04T13:30:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minutes Intense Kettlebell HIIT Workout | Strength &amp; Cardio HIIT Workout",
      "description": "30 minutes Intense Full Body Kettlebell Workout | Strength & Cardio HIIT Workout This kettlebell workout is a full body HIIT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BayV9q8J_Dg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BayV9q8J_Dg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BayV9q8J_Dg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-04T13:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "asxsn8S-11Rd0UObPLGX6ZmgqhM",
    "id": {
      "kind": "youtube#video",
      "videoId": "Y0OrhR1xr7k"
    },
    "snippet": {
      "publishedAt": "2022-02-07T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute SAVAGE Bodyweight HIIT Workout",
      "description": "In this 30 minute SAVAGE Bodyweight HIIT workout we are trying to get the heart rate as high as we can and push it to the limits.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Y0OrhR1xr7k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Y0OrhR1xr7k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Y0OrhR1xr7k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-07T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WHmlLmHWNUrLDp2I9x-tVZ63hlI",
    "id": {
      "kind": "youtube#video",
      "videoId": "PKzjvpO2KHA"
    },
    "snippet": {
      "publishedAt": "2022-01-28T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Dumbbell LEG workout | Quads, Hamstrings &amp; Glutes Workout | Force Series DanielPT",
      "description": "In this challenging Dumbbell LEG workout we will be working with Giant-sets. Each giant set contains 3x movements. We will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PKzjvpO2KHA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PKzjvpO2KHA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PKzjvpO2KHA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-28T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CU-0IVpGKggCwoNlcSTRSU8rqqs",
    "id": {
      "kind": "youtube#video",
      "videoId": "H5H0TBtpZEA"
    },
    "snippet": {
      "publishedAt": "2022-01-24T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell UPPER BODY STRENGTH Workout | Muscle Building | Force Series DanielPT",
      "description": "In this Kettlebell UPPER BODY STRENGTH Workout we will have 30 movements in total. We will be switching between Push ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H5H0TBtpZEA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H5H0TBtpZEA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H5H0TBtpZEA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-24T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4mbi8ELR-dk8C0YWbxqTSYrzdcM",
    "id": {
      "kind": "youtube#video",
      "videoId": "6YaAZkZOPf4"
    },
    "snippet": {
      "publishedAt": "2021-09-19T21:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Pyramid No Equipment Full Body Workout",
      "description": "This 30 minute Pyramid No Equipment Full Body Workout is a mixture of strength and cardio exercises. We will be having 20 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6YaAZkZOPf4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6YaAZkZOPf4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6YaAZkZOPf4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-19T21:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-a7cO98RlidmDGqOe8qmxBLQlGA",
    "id": {
      "kind": "youtube#video",
      "videoId": "LMhsF0X29BQ"
    },
    "snippet": {
      "publishedAt": "2021-12-02T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "LEG KETTLEBELL WORKOUT | KILLER  SUPERSETS",
      "description": "In this LEG KETTLEBELL WORKOUT we will focus on strengthening the lower body. We will have 16x super sets which means ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LMhsF0X29BQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LMhsF0X29BQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LMhsF0X29BQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-02T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GC60T7QtAJZsC_V6SMrT1HkKQOQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "4jfeoOhjhfg"
    },
    "snippet": {
      "publishedAt": "2022-02-23T06:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute HARDCORE Bodyweight HIIT Workout",
      "description": "In this 30 minute Bodyweight HIIT Workout we are trying to get the heart rate as high as we can and push it to the limits. We are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4jfeoOhjhfg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4jfeoOhjhfg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4jfeoOhjhfg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-23T06:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ywqu17EQ_K8T6fNxQU1yO2Va9oc",
    "id": {
      "kind": "youtube#video",
      "videoId": "rDkCsRidZtg"
    },
    "snippet": {
      "publishedAt": "2022-02-11T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 Minute Full Body STRENGTH Dumbbell Workout",
      "description": "In this intense 40 minute Full Body Strength workout we will be focusing on the major muscle groups chest, back and the leg ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rDkCsRidZtg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rDkCsRidZtg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rDkCsRidZtg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-11T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YGowhRBR2lmqV1t4pkzfvdEQvnE",
    "id": {
      "kind": "youtube#video",
      "videoId": "CuStVqnrRX0"
    },
    "snippet": {
      "publishedAt": "2020-04-19T10:00:46Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. GREAT Leg Workout with Resistance Bands | Resistance Band Workout Legs | Band Leg Workout",
      "description": "This 30 min. GREAT Leg Workout with Resistance Bands | Resistance Band Workout Legs | Band Leg Workout is made up of 3 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CuStVqnrRX0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CuStVqnrRX0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CuStVqnrRX0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-19T10:00:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oCWSd8X25s73Zl1TGxim5nk43sg",
    "id": {
      "kind": "youtube#video",
      "videoId": "QuorKJtOFug"
    },
    "snippet": {
      "publishedAt": "2021-12-09T06:00:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "STRENGTH + MUSCLE BUILDING Full Body Dumbbell Workout // 30 minute DANIELPT",
      "description": "In this STRENGTH + MUSCLE BUILDING Full Body Dumbbell Workout we will switch back to the best basic movements for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QuorKJtOFug/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QuorKJtOFug/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QuorKJtOFug/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-09T06:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NVHw_j1V8E61qQRi34CE0Xs0Kvo",
    "id": {
      "kind": "youtube#video",
      "videoId": "sgBBcz-V-Kw"
    },
    "snippet": {
      "publishedAt": "2018-05-17T21:23:52Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Legs and Glutes weight training Workout | SUPERSET WORKOUT",
      "description": "3x Superset routines for the QUADS, HAMSTRING and the GLUTE muscles! LET`s GO!!! \u25bbInstagram: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sgBBcz-V-Kw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sgBBcz-V-Kw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sgBBcz-V-Kw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2018-05-17T21:23:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x32ZvaGBtsA5yojebfWaHS4gDns",
    "id": {
      "kind": "youtube#video",
      "videoId": "KM9IrjYIPUU"
    },
    "snippet": {
      "publishedAt": "2020-06-26T10:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Legs and Butt workout with MiniBand | Mini Band Leg Workout at Home | Follow Along Workout",
      "description": "This 20. Min. Legs and Butt workout with MiniBand | Mini Band Leg Workout at Home | Follow Along Workout is made up of 3 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KM9IrjYIPUU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KM9IrjYIPUU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KM9IrjYIPUU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-26T10:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "l2-K0b2gjmmgsEY1vd7Dq1w6lb4",
    "id": {
      "kind": "youtube#video",
      "videoId": "jjzWzK3a334"
    },
    "snippet": {
      "publishedAt": "2021-07-18T19:31:00Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full body REAL HIIT workout for DIEHARDS",
      "description": "This 30 minute Full body REAL HIIT workout for DIEHARDS is a mixture of strength and cardio movements in a short amount of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jjzWzK3a334/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jjzWzK3a334/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jjzWzK3a334/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-18T19:31:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n1WcNsG-yTQHAcCRbqr3FHVjRHc",
    "id": {
      "kind": "youtube#video",
      "videoId": "-vvJ7SwZDSY"
    },
    "snippet": {
      "publishedAt": "2022-02-04T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 min FULL BODY STRENGTH Kettlebell Workout | Superset",
      "description": "In this 40 min full body strength kettlebell Workout we will be having 10 supersets targeting the entire body. We will be doing each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-vvJ7SwZDSY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-vvJ7SwZDSY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-vvJ7SwZDSY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-04T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ChQgSz-9yvOvBM1rGXWWERTwKX4",
    "id": {
      "kind": "youtube#video",
      "videoId": "W3jBPMswN-c"
    },
    "snippet": {
      "publishedAt": "2022-03-04T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "15 Minute Glorious DYNAMIC ABS  Workout  | Bodyweight Workout",
      "description": "In this 15 Minute Glorious DYNAMIC ABS Workout | Bodyweight Workout we will be having 17x different movements to target the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W3jBPMswN-c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W3jBPMswN-c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W3jBPMswN-c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-04T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YBnBs9cxYJLezAGlh2YJYWWEw7I",
    "id": {
      "kind": "youtube#video",
      "videoId": "D33bawan28A"
    },
    "snippet": {
      "publishedAt": "2022-03-09T06:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute INTENSE Dumbbell + Bodyweight LEG Workout | No Repeat",
      "description": "In this 30 minute INTENSE Dumbbell + Bodyweight LEG Workout we will be targeting the entire Lower Body with a high volume ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D33bawan28A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D33bawan28A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D33bawan28A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-09T06:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VYhPJeMsFcb0Fw9lMLdwc6OP1uw",
    "id": {
      "kind": "youtube#video",
      "videoId": "RP_0tVXgjbQ"
    },
    "snippet": {
      "publishedAt": "2017-08-05T22:05:34Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Shoulder Workout With Dumbbells | Torture And Pump Routine For Round Shoulders",
      "description": "This Shoulder workout routine is for shocking and torturing the shoulder muscle in a right way with a different dumbbell exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RP_0tVXgjbQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RP_0tVXgjbQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RP_0tVXgjbQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-08-05T22:05:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vXtxLS3DEOXrayelSnxR0o-wFso",
    "id": {
      "kind": "youtube#video",
      "videoId": "6FDc5-ueR1U"
    },
    "snippet": {
      "publishedAt": "2020-07-19T10:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Challenging FULL BODY HOME HIIT WORKOUT |  NO EQUIPMENT",
      "description": "30 min Challenging FULL BODY HOME HIIT WORKOUT | NO EQUIPMENT This 30 min Challenging FULL BODY HOME HIIT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6FDc5-ueR1U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6FDc5-ueR1U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6FDc5-ueR1U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-19T10:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9IVMNIpZznLKX5lS8lxNa9Tid8s",
    "id": {
      "kind": "youtube#video",
      "videoId": "xqLLzFBFj2w"
    },
    "snippet": {
      "publishedAt": "2022-01-07T06:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "DIE HARD FULL BODY Dumbbell Workout - FORCE No. 03 | DanielPT",
      "description": "In This KILLING FULL BODY Dumbbell Workout I will be using the same challenging weights that I normally use for the strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xqLLzFBFj2w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xqLLzFBFj2w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xqLLzFBFj2w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-07T06:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "k6jIiU3FWBnzS8UZir3xjxp4J20",
    "id": {
      "kind": "youtube#video",
      "videoId": "tO94EOOniAw"
    },
    "snippet": {
      "publishedAt": "2017-04-15T09:47:52Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute Intense Full Body HIIT Workout Without Equipment |&quot;SAVAGE&quot; Home workout",
      "description": "The good thing about HIIT training is that you can do it ANYWHERE! All you need is a little bit of space and a proper mindset.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tO94EOOniAw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tO94EOOniAw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tO94EOOniAw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-04-15T09:47:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QzB-WGdpJdQTpdKMCZGUEEhOEKg",
    "id": {
      "kind": "youtube#video",
      "videoId": "iqm8JK69JW8"
    },
    "snippet": {
      "publishedAt": "2017-07-16T09:40:49Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Biceps Workout With Dumbbells | Torture And Pump Routine For Bigger Arms",
      "description": "This Biceps workout routine is for shocking and torturing the bicep muscle in a right way with a different dumbbell exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iqm8JK69JW8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iqm8JK69JW8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iqm8JK69JW8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-16T09:40:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eNnIg8lgf_byvuylAwkBxJhVSuo",
    "id": {
      "kind": "youtube#video",
      "videoId": "nGWlPR7rVo8"
    },
    "snippet": {
      "publishedAt": "2019-03-12T13:10:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "BUTT AND LEGS WORKOUT  | Kettle bell and Band HIIT WORKOUT part 3 |DANIELPT",
      "description": "This 32 minute butt and legs workout is made up of 3 blocks of exercises; The 1st block of butt and legs workout is with Kettle bell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nGWlPR7rVo8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nGWlPR7rVo8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nGWlPR7rVo8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-03-12T13:10:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-OrnIQI9YV4uPBzzH-GJLw0M3EA",
    "id": {
      "kind": "youtube#video",
      "videoId": "CH6xEuBTIkM"
    },
    "snippet": {
      "publishedAt": "2021-05-30T16:30:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute CALORIE KILLER KETTLEBELL and BODYWEIGHT HIIT",
      "description": "This Kettlebell Bodyweight workout is a mixture of strength and cardio in a short amount of time. We will have 3 blocks of exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CH6xEuBTIkM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CH6xEuBTIkM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CH6xEuBTIkM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-30T16:30:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SljP6MlWnYxJ2kO0AmWF_WOea94",
    "id": {
      "kind": "youtube#video",
      "videoId": "e_cTKWF0M_Y"
    },
    "snippet": {
      "publishedAt": "2019-03-05T12:49:53Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "BUTT AND LEGS WORKOUT  | Kettle bell and Band HIIT WORKOUT part 2 |DANIELPT",
      "description": "This 35 minute butt and legs workout is made up of 3 blocks of exercises; The 1st block of butt and legs workout is with Kettle bell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/e_cTKWF0M_Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/e_cTKWF0M_Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/e_cTKWF0M_Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-03-05T12:49:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "s_zeDvBKGiIwrOsRI8KcTO_2nG4",
    "id": {
      "kind": "youtube#video",
      "videoId": "rHjlbAHAPiU"
    },
    "snippet": {
      "publishedAt": "2021-12-20T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "SUPREME FULL BODY Strength Workout with Dumbbell [Metabolic Strength Training]",
      "description": "In this FULL BODY Strength Workout with Dumbbell we will be having 6x challenging blocks of workout. Each block contains 4x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/rHjlbAHAPiU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/rHjlbAHAPiU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/rHjlbAHAPiU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-20T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DrsQf-sUw_3Oy0BZhFaTm6psLYw",
    "id": {
      "kind": "youtube#video",
      "videoId": "UyLmACEtk_8"
    },
    "snippet": {
      "publishedAt": "2022-03-14T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Minute UPPER BODY Resistance Band Workout",
      "description": "In this 30 Minute UPPER BODY Resistance Band Workout we will be targeting the chest, Back, Shoulder, Biceps and Triceps.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UyLmACEtk_8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UyLmACEtk_8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UyLmACEtk_8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-14T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o9N9GsFZiBGTf4hpIvGHH7wAZ7M",
    "id": {
      "kind": "youtube#video",
      "videoId": "ITwNKMFc8JA"
    },
    "snippet": {
      "publishedAt": "2019-04-09T11:41:21Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series | Part 1",
      "description": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series 2 x Blocks of 4 exercises | Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ITwNKMFc8JA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ITwNKMFc8JA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ITwNKMFc8JA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-09T11:41:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JP_qQ4BpgWPtzgsmyFl7BSAG-3I",
    "id": {
      "kind": "youtube#video",
      "videoId": "5u0FHcrAFzQ"
    },
    "snippet": {
      "publishedAt": "2019-09-26T16:00:29Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Mini band workout | Butt workout for women and men | Leg HIIT workout | Leg workout at home",
      "description": "This 20 minute Mini band workout | Butt workout for women and men | Leg HIIT workout | Leg workout at home is made up of 2 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5u0FHcrAFzQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5u0FHcrAFzQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5u0FHcrAFzQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-09-26T16:00:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vmbEZb8zXJJuo2QEFMwYzT30bUs",
    "id": {
      "kind": "youtube#video",
      "videoId": "EG4GjRl6nnE"
    },
    "snippet": {
      "publishedAt": "2022-01-21T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Low Impact Bodyweight FULL BODY Workout / No Jump - Force Series DanielPT",
      "description": "In this low impact yet Super Challenging Slow-Paced Bodyweight workout we will have 30 movements in total. We will be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EG4GjRl6nnE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EG4GjRl6nnE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EG4GjRl6nnE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-21T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "omyvv0ivQonlQd54q6TMK013cwM",
    "id": {
      "kind": "youtube#video",
      "videoId": "9GsmXEheI_Q"
    },
    "snippet": {
      "publishedAt": "2021-07-26T04:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 minute Upperbody Workout | Band + Dumbbell",
      "description": "This 45 minute Upperbody Workout | Band + Dumbbell workout is one of the workouts from my platform \u201cHomeGym\u201d where I ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9GsmXEheI_Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9GsmXEheI_Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9GsmXEheI_Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-26T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ECw7W62wA_jknHfhwjSOBjjvjMs",
    "id": {
      "kind": "youtube#video",
      "videoId": "lD_-V4c1a7E"
    },
    "snippet": {
      "publishedAt": "2021-12-13T06:00:14Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 Min INTENSE Bodyweight SUPERSET HIIT Workout // DANIELPT",
      "description": "In This 30 Min INTENSE Bodyweight SUPERSET HIIT Workout we will have 16x supersets in total. We will perform the first ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lD_-V4c1a7E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lD_-V4c1a7E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lD_-V4c1a7E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-13T06:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XWqSzWkX-TQ31KNlIp77owa95T4",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZmJgJ6ycK68"
    },
    "snippet": {
      "publishedAt": "2021-03-21T14:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Kettlebell Workout | Full Body Workout At Home |\u00a0BenQ Portable Projector GS2",
      "description": "Kettlebell Workout | Full Body Workout At Home | BenQ Portable Projector GS2 Today's kettlebell workout is a full body workout at ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZmJgJ6ycK68/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZmJgJ6ycK68/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZmJgJ6ycK68/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-21T14:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7AgIqz5jkioX2y6NvpY8rfXvLrg",
    "id": {
      "kind": "youtube#video",
      "videoId": "HCBC4X-4Vxg"
    },
    "snippet": {
      "publishedAt": "2021-01-13T16:15:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 min Sweaty MINI BAND GLUTE Workout at Home | DANIELPT",
      "description": "20 min Sweaty MINI BAND GLUTE Workout at Home | DANIELPT This \" 20 min Sweaty MINI BAND GLUTE Workout at Home \" is a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HCBC4X-4Vxg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HCBC4X-4Vxg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HCBC4X-4Vxg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-13T16:15:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YJuBGZflukzJYTPrB9cE0039w40",
    "id": {
      "kind": "youtube#video",
      "videoId": "gCS3YIX3G2E"
    },
    "snippet": {
      "publishedAt": "2022-03-02T06:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Upper Body PUSH PULL Dumbbell STRENGTH Workout",
      "description": "In this 30 minute Upper Body PUSH PULL Dumbbell STRENGTH Workout we will be targeting mainly the Chest and Back ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gCS3YIX3G2E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gCS3YIX3G2E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gCS3YIX3G2E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-02T06:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7R7padwG_k6Qax7npFOuR4Eqj1U",
    "id": {
      "kind": "youtube#video",
      "videoId": "qLGzC92pnDQ"
    },
    "snippet": {
      "publishedAt": "2020-07-30T10:30:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "40 minute No Jumping Full Body Workout // No Equipment Hiit Home Workout // DanielPT",
      "description": "This 40 min No Equipment Hiit Home Workout is a full body workout with no jumping included. The follow along full length workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qLGzC92pnDQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qLGzC92pnDQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qLGzC92pnDQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-30T10:30:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GnrtkzdoOyrX0Aqds4laNLzUobo",
    "id": {
      "kind": "youtube#video",
      "videoId": "OLZIR4OyEXE"
    },
    "snippet": {
      "publishedAt": "2021-02-28T18:30:17Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min Full Body Kettlebell Workout At Home | Full Hiit Workout",
      "description": "30 min Full Body Kettlebell Workout At Home | Full Hiit Workout This kettlebell hiit workout entails two blocks of exercises and one ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OLZIR4OyEXE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OLZIR4OyEXE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OLZIR4OyEXE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-28T18:30:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dVABRmCXAl3z04Gh41SOcHav5jY",
    "id": {
      "kind": "youtube#video",
      "videoId": "RKqybKM1lT0"
    },
    "snippet": {
      "publishedAt": "2021-09-16T21:15:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "KETTLEBELL WORKOUT FOR DIEHARD",
      "description": "In this 30 minute kettlebell Workout is a mixture of kettlebell and bodyweight exercises. We will be having 30 different exercises, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RKqybKM1lT0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RKqybKM1lT0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RKqybKM1lT0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-16T21:15:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7zI3QyT0O98meyxg---FHhhb4fk",
    "id": {
      "kind": "youtube#video",
      "videoId": "OGrD3p3enCU"
    },
    "snippet": {
      "publishedAt": "2020-04-23T09:45:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INTENSE TORTURE HIIT kettlebell workout | Full Body HIIT workout | KB and Body weight FOLLOW ALONG",
      "description": "This 25 min. INTENSE TORTURE HIIT kettlebell workout | Full Body HIIT workout | KB and Body weight FOLLOW ALONG workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/OGrD3p3enCU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/OGrD3p3enCU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/OGrD3p3enCU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-23T09:45:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Uk5n5CHBm5WOmCgifu_lHVD17Fs",
    "id": {
      "kind": "youtube#video",
      "videoId": "YKWvci-ylvk"
    },
    "snippet": {
      "publishedAt": "2020-11-15T14:30:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body Home Workout | Follow Along Dumbbell Workout",
      "description": "30 minute Full Body Home Workout | Follow Along Dumbbell Workout This 30 minute Full Body Home Workout is a follow along ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YKWvci-ylvk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YKWvci-ylvk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YKWvci-ylvk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-15T14:30:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jpdcqc2h4thfAl5CXMIpHR4fCmk",
    "id": {
      "kind": "youtube#video",
      "videoId": "z010mZW534Y"
    },
    "snippet": {
      "publishedAt": "2020-07-07T11:00:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body Dumbbell Workout // Dumbbell Workout At Home // Follow Along No Repeat Workout",
      "description": "This 30 minute Full Body Dumbbell Workout // Dumbbell workout at home // Follow Along No Repeat Workout is made up of 3 x ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/z010mZW534Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/z010mZW534Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/z010mZW534Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-07T11:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iTecvsDFZr_Om0ED9biIobMrd-c",
    "id": {
      "kind": "youtube#video",
      "videoId": "bXvMfEiGp_0"
    },
    "snippet": {
      "publishedAt": "2020-05-05T09:00:16Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min. Mini Band Full Body Workout | Resistance Band Workout | FOLLOW ALONG  HOME Workout",
      "description": "30 min. Mini Band Full Body Workout | Resistance Band Workout | FOLLOW ALONG HOME Workout This 30 min. Mini Band Full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bXvMfEiGp_0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bXvMfEiGp_0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bXvMfEiGp_0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-05T09:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "97d7rgJ4aGv7Jrogi1k6LSGcXSU",
    "id": {
      "kind": "youtube#video",
      "videoId": "agpgBb_iiG0"
    },
    "snippet": {
      "publishedAt": "2017-04-27T17:32:52Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 Minutes Full Body Low Impact HIIT Workout | Bodyweight Home Workout | DANIELPT",
      "description": "The good thing about HIIT training is that you can do it ANYWHERE! All you need is a little bit of space and a proper mindset.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/agpgBb_iiG0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/agpgBb_iiG0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/agpgBb_iiG0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-04-27T17:32:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AcSysfqLBLw86xswp4yFnXMdxZ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "kCcBh0A064U"
    },
    "snippet": {
      "publishedAt": "2019-03-10T09:49:06Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "DanielPT WEEKLY WORKOUTS Trailer",
      "description": "Welcome to DanielPT lifestylcoaching channel where I post different kind of workouts to help you get fitter, healthier and stronger.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kCcBh0A064U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kCcBh0A064U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kCcBh0A064U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-03-10T09:49:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zMuCzpxPmlkNRllSDWDTz5VMG_g",
    "id": {
      "kind": "youtube#video",
      "videoId": "7Rk5_-y1r_w"
    },
    "snippet": {
      "publishedAt": "2021-01-03T14:30:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "45 Min Long Band Full Body Workout | Follow Along Workout",
      "description": "45 Min Long Band Full Body Workout | Follow Along Workout Disclaimer: The long resistance bands used in this workout are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7Rk5_-y1r_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7Rk5_-y1r_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7Rk5_-y1r_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-03T14:30:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8EgnKYFilDduqStry6oeVuHsQuw",
    "id": {
      "kind": "youtube#video",
      "videoId": "BJxNPVx98Yg"
    },
    "snippet": {
      "publishedAt": "2021-04-28T16:30:29Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Most Effective LEG &amp; GLUTE Workout with Resistance Bands",
      "description": "Most Effective LEG & GLUTE Workout with Resistance Bands | Leg & glute Workout with Long Band feat. Long Resistance Bands ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BJxNPVx98Yg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BJxNPVx98Yg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BJxNPVx98Yg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-28T16:30:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nMPffB0hEdXcFIdrLHtQNln2xAE",
    "id": {
      "kind": "youtube#video",
      "videoId": "mBiZsbFCg1s"
    },
    "snippet": {
      "publishedAt": "2017-06-23T20:14:40Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 Minute Step HIIT Workout | HIIT Home Workout | Full Body Workout at Home | DANIELPT",
      "description": "This 20 Minute Step HIIT Workout | HIIT Home Workout | Full Body Workout at Home is made up of 2 x 4 different exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mBiZsbFCg1s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mBiZsbFCg1s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mBiZsbFCg1s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-06-23T20:14:40Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZBT-ne19y0w0h3hpHLKc-b9IjqE",
    "id": {
      "kind": "youtube#video",
      "videoId": "nVZJOtduzOc"
    },
    "snippet": {
      "publishedAt": "2020-09-06T11:00:15Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Full Body HIIT Home Workout | Follow Along No Equipment Workout",
      "description": "30 minute Full Body HIIT Home Workout | Follow Along No Equipment Workout This follow along no equipment workout is a full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nVZJOtduzOc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nVZJOtduzOc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nVZJOtduzOc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-06T11:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kW98H2fi6s5Y70kqtTZRGT6jXpY",
    "id": {
      "kind": "youtube#video",
      "videoId": "iBOWfHiiJ30"
    },
    "snippet": {
      "publishedAt": "2017-05-27T11:40:09Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 minute Intense Full Body Workout | No Equipment Workout | HIIT at HOME | DANIELPT",
      "description": "This Intense Full Body Workout | No Equipment Workout | HIIT at HOME is made up of 2 blocks of 5 different exercises and each 5 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iBOWfHiiJ30/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iBOWfHiiJ30/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iBOWfHiiJ30/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-05-27T11:40:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "juL3x8n-_qc4Q0Lm0q8e08cuas4",
    "id": {
      "kind": "youtube#video",
      "videoId": "bCtAmG2V71U"
    },
    "snippet": {
      "publishedAt": "2020-08-09T11:00:11Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Follow Along Home Workout // Full Body Kettlebell Home Workout",
      "description": "This 30 minute Follow Along Home Workout is a full body kettlebell home workout. The full length follow along workout consists of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bCtAmG2V71U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bCtAmG2V71U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bCtAmG2V71U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-09T11:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iFxW5Xdx-3AIh5rHf3q_qO1b0qU",
    "id": {
      "kind": "youtube#video",
      "videoId": "trIYd9z1fQA"
    },
    "snippet": {
      "publishedAt": "2017-05-04T22:30:18Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "20 Minute Intense LEGS and GLUTES + CORE HIIT Workout | HOME WORKOUT | DANIELPT",
      "description": "The good thing about HIIT training is that you can do it ANYWHERE! All you need is a little bit of space and a proper mindset.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/trIYd9z1fQA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/trIYd9z1fQA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/trIYd9z1fQA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-05-04T22:30:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RQEtyUhU2rXsO2nXwuryF3zl4Nk",
    "id": {
      "kind": "youtube#video",
      "videoId": "uORsFi76OOQ"
    },
    "snippet": {
      "publishedAt": "2020-07-03T10:00:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 min FULL BODY WORKOUT with WEIGHTS (Kettlebell / Dumbbell) + Bodyweight exercises | Follow Along",
      "description": "This 30 min. FULL BODY WORKOUT with WEIGHTS (Kettlebell or Dumbbell) + Body weight exercises // Follow Along Workout is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uORsFi76OOQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uORsFi76OOQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uORsFi76OOQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-03T10:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "R_0cdQYdFpUhfEYZ6IjQmk2Y5Ls",
    "id": {
      "kind": "youtube#video",
      "videoId": "pi8_jg6mY0w"
    },
    "snippet": {
      "publishedAt": "2018-08-03T21:08:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "FULLBODY &amp; ABS HIIT Workout | DUMBBELL HIIT WORKOUT",
      "description": "This 25 minute FULLBODY DUMBBELL-HIIT workout is made up of 3x4 different exercises and each 4 exercise for 3 rounds.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pi8_jg6mY0w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pi8_jg6mY0w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pi8_jg6mY0w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2018-08-03T21:08:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9NjjD798ukx54D9Lf31ZRhXzUB8",
    "id": {
      "kind": "youtube#video",
      "videoId": "mxJznW0WLtA"
    },
    "snippet": {
      "publishedAt": "2020-04-03T09:53:01Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "My first Men&#39;s Physique competition in 2016 | After movie | Throwback | DANIELPT",
      "description": "Hey guys, How is it going? I KNOW...this is not a workout video! This is an aftermovie of my first Men's Physique competition from ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mxJznW0WLtA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mxJznW0WLtA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mxJznW0WLtA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-03T09:53:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9N9Bk0E_9ZqH1J6XcrqwBDaFXwU",
    "id": {
      "kind": "youtube#video",
      "videoId": "mmAa9lkxPo0"
    },
    "snippet": {
      "publishedAt": "2020-11-01T14:45:13Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute At Home Full Body Workout | Intense Full Body Follow Along Workout | Kettlebell Workout",
      "description": "30 minute At Home Full Body Workout | Intense Full Body Follow Along Workout | Kettlebell Workout This 30 minute at home full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mmAa9lkxPo0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mmAa9lkxPo0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mmAa9lkxPo0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-01T14:45:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wfIxSMdDKnfCjbJG4z195wspXFQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "gGpRnhoaeMQ"
    },
    "snippet": {
      "publishedAt": "2019-05-20T21:39:54Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series | Part 5",
      "description": "HIIT WORKOUT body weight + Kettle bell | 20 Minute HIIT Cardio Kettle bell Workout Series 2 x Blocks of 4 exercises | Each block ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gGpRnhoaeMQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gGpRnhoaeMQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gGpRnhoaeMQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-05-20T21:39:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qkj04pchSLDtKv32gXgTThKgPVo",
    "id": {
      "kind": "youtube#video",
      "videoId": "hLr_OlrSUus"
    },
    "snippet": {
      "publishedAt": "2020-06-03T12:01:04Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "INTENSE FULL BODY KETTLEBELL Workout | Kettlebell HIIT workout | Challenging Kettlebell workout",
      "description": "This Intense FULL BODY KETTLEBELL Workout | Kettlebell HIIT workout | Challenging Kettlebell workout is made up of 2 x blocks ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hLr_OlrSUus/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hLr_OlrSUus/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hLr_OlrSUus/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-06-03T12:01:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7JjP3ecbr95rpOl3ywZwtr2kJHQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "j6i-tLAbLgE"
    },
    "snippet": {
      "publishedAt": "2019-06-24T10:48:29Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 MIN FULL BODY CRUSHER - HIIT WORKOUT with light dumbbell",
      "description": "This full 30 MIN FULL BODY CRUSHER - HIIT WORKOUT with light dumbbell is made of two blocks: 1st block of the strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j6i-tLAbLgE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j6i-tLAbLgE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j6i-tLAbLgE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2019-06-24T10:48:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1v5RM2svm04NfZ7KFLzlRzMBqOE",
    "id": {
      "kind": "youtube#video",
      "videoId": "ujRP7yhOO34"
    },
    "snippet": {
      "publishedAt": "2020-10-11T13:30:12Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "30 minute Follow Along Home Workout | Fit From Home Full Body Workout | Intense HIIT Workout",
      "description": "30 minute Follow Along Home Workout | Fit From Home Full Body Workout | Intense HIIT Workout This 30 minute Follow Along ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ujRP7yhOO34/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ujRP7yhOO34/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ujRP7yhOO34/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-11T13:30:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "75voPKJqxgLlOpmK2_q-n1VW8NE",
    "id": {
      "kind": "youtube#video",
      "videoId": "SgT3kirq59Q"
    },
    "snippet": {
      "publishedAt": "2017-05-19T22:05:26Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Bodyweight HIIT workout | Full body Workout at Home | HIIT training | Insane workout | DANIELPT",
      "description": "This Bodyweight HIIT workout | Full body Workout at Home | HIIT training | Insane workout is made up of 4 different exercises and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SgT3kirq59Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SgT3kirq59Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SgT3kirq59Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-05-19T22:05:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "esLpEhibeIgo455Bc56px3iwAZs",
    "id": {
      "kind": "youtube#video",
      "videoId": "dA7qSfn6mbg"
    },
    "snippet": {
      "publishedAt": "2017-12-30T00:14:47Z",
      "channelId": "UCE3qIUaYYSQfgZZZlFIenZw",
      "title": "Triceps Workout With Barbell | Torture And Pump Routine For Bigger Arms",
      "description": "This Triceps workout routine is for shocking and torturing the Tricep muscle with only a barbell and a Bench! Try this workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dA7qSfn6mbg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dA7qSfn6mbg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dA7qSfn6mbg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "DANIELPT FITNESS",
      "liveBroadcastContent": "none",
      "publishTime": "2017-12-30T00:14:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "U3ws5hGgj4Hkh3XrzV6L_QXByC4",
    "id": {
      "kind": "youtube#video",
      "videoId": "ECQKPqGBmP4"
    },
    "snippet": {
      "publishedAt": "2023-01-25T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "When I\u2019m craving something sweet\u2026.#shorts",
      "description": "High #protein, easy to make, chocolate chia pudding? Yes please. This is one of my favorite #healthyrecipes to whip up during the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ECQKPqGBmP4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ECQKPqGBmP4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ECQKPqGBmP4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-25T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4OkgjmGCQE2SQveFDeaPjFk1VUE",
    "id": {
      "kind": "youtube#video",
      "videoId": "CRHYu8t-PIw"
    },
    "snippet": {
      "publishedAt": "2023-01-18T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Healthy Breakfast // Egg Cup Recipe #shorts",
      "description": "A #mealprep breakfast classic - Veggie Egg Muffins! Makes 6 servings 1 med sweet potato, grated 1 red bell pepper, diced 1 cup ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CRHYu8t-PIw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CRHYu8t-PIw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CRHYu8t-PIw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-18T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iuRybIUFhslYt7vMh9hY20FhF_k",
    "id": {
      "kind": "youtube#video",
      "videoId": "G0apnmeoP_k"
    },
    "snippet": {
      "publishedAt": "2023-02-04T14:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Easy Frozen Yogurt Parfait Cups \ud83e\udd24",
      "description": "These healthy, easy to make, Frozen Yogurt Parfait Cups are a must try! #shorts #frozenyogurt #healthysnacks #recipe (Makes 12 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/G0apnmeoP_k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/G0apnmeoP_k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/G0apnmeoP_k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-04T14:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LRCgqBN496oRywPM_qdBPdTnIXY",
    "id": {
      "kind": "youtube#video",
      "videoId": "hG16cgzFSQk"
    },
    "snippet": {
      "publishedAt": "2023-01-21T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Knee Pain? Try these 5 Stretches!",
      "description": "Knee Pain? Sometimes knee pain can be aggravated by tight muscles that surround and support the knee joint. Here are 5 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hG16cgzFSQk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hG16cgzFSQk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hG16cgzFSQk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-21T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "teUleGXgU0adcUSAhDd1Sl_qJmU",
    "id": {
      "kind": "youtube#video",
      "videoId": "PhN5lr0kBxE"
    },
    "snippet": {
      "publishedAt": "2023-01-14T14:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "High Protein Snack Ideas #shorts",
      "description": "Meet your daily protein requirements with these easy high protein snack ideas! 1. Protein Stacker (17g protein) \u2043 rice cakes ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PhN5lr0kBxE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PhN5lr0kBxE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PhN5lr0kBxE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-14T14:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fDz0gUwjZ55SBTuFq_PRo3ebuY4",
    "id": {
      "kind": "youtube#video",
      "videoId": "_jQbpSL1fgk"
    },
    "snippet": {
      "publishedAt": "2023-03-08T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Sore wrists during push ups or planks? #shorts",
      "description": "Do you have sore wrists during push ups or planks? Here are a few tips on how to reduce wrist pain during you workouts! Tension ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-08T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zetWq_ucWfQ0EUVBA1m98VTgoQI",
    "id": {
      "kind": "youtube#video",
      "videoId": "htqqk_uojIs"
    },
    "snippet": {
      "publishedAt": "2022-11-08T14:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Plank #shorts",
      "description": "Let's learn how to do a plank! #plank #workouttips #form #exercisetips #howto Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-08T14:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "81yfqVgSDGwMbbxe5xydiHTEVq0",
    "id": {
      "kind": "youtube#video",
      "videoId": "ljZA17b52FE"
    },
    "snippet": {
      "publishedAt": "2022-11-04T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do Reverse Lunges #shorts",
      "description": "Let's learn how to do Reverse Lunges with dumbbells! #workouttips #form #lunges #reverselunges #exercisetips #howto #shorts ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-04T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z3sZ8KARFFQOo2EYObctKxTqGLU",
    "id": {
      "kind": "youtube#video",
      "videoId": "YvfvKbUHZ2Q"
    },
    "snippet": {
      "publishedAt": "2022-10-26T15:49:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do T-Push Ups #shorts",
      "description": "Let's learn how to do T-Push Ups! #shorts #form #workouttips #howto #exercise #fitness Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-26T15:49:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "i54h1bQ19KC4c26lC5W0ZDb6sPw",
    "id": {
      "kind": "youtube#video",
      "videoId": "JxBevX7raj0"
    },
    "snippet": {
      "publishedAt": "2022-05-11T13:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "12 MIN Total Body TABATA Workout",
      "description": "Today's quick workout is a 12 minute Tabata with weights. We are making the most of our time and getting in a total body workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JxBevX7raj0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JxBevX7raj0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JxBevX7raj0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-11T13:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AbTEM5x9iuaw-ZCPa1IpHwxoBkM",
    "id": {
      "kind": "youtube#video",
      "videoId": "Lh9u4X66lOk"
    },
    "snippet": {
      "publishedAt": "2022-06-22T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Squat Challenge \ud83c\udf51",
      "description": "Who's up for a new squat challenge!!? This quick 10min workout is a fun way to challenge yourself and get in a serious leg and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Lh9u4X66lOk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Lh9u4X66lOk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Lh9u4X66lOk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-22T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ViY1AR79CJ5oOuCPyU8hYtYi6Lw",
    "id": {
      "kind": "youtube#video",
      "videoId": "RAsoTHpsKuQ"
    },
    "snippet": {
      "publishedAt": "2022-10-28T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Glute Bridge #shorts",
      "description": "Let's learn how to do a Glute Bridge! #shorts #glutebridge #workouttips #exercise #form Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-28T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0zvIe1OTBp4dI8upEHFcO391Vgw",
    "id": {
      "kind": "youtube#video",
      "videoId": "SzzY6gH5kLs"
    },
    "snippet": {
      "publishedAt": "2022-07-27T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "7MIN Plank Challenge",
      "description": "Ok team it's time for another Plank Challenge!! This one is only 7 minutes but it is a serious burner and was a challenge for me!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SzzY6gH5kLs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SzzY6gH5kLs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SzzY6gH5kLs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-27T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wjC8m1yJbGiMSrJAtML3msq4vHQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "jRcFX-d-dPw"
    },
    "snippet": {
      "publishedAt": "2022-11-01T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do The Crab Dance #shorts",
      "description": "Let's lean how to do the Crab Dance Exercise! #workouttips #exercise #form #crabdance #howto Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-01T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "T9gSzoI6QuAaTTr3Yhg2oqEiAGI",
    "id": {
      "kind": "youtube#video",
      "videoId": "XdaECjKa7ZE"
    },
    "snippet": {
      "publishedAt": "2022-06-08T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10MIN Abs Workout // AMRAP",
      "description": "Abs on Fire! This quick AMRAP style Abs Workout include two 5 minute circuits that will challenge you and help to build core ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XdaECjKa7ZE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XdaECjKa7ZE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XdaECjKa7ZE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-08T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WRKHA4-_q_AJX4owCOhI1Ep9Svw",
    "id": {
      "kind": "youtube#video",
      "videoId": "zoGkKqhdBOI"
    },
    "snippet": {
      "publishedAt": "2022-12-28T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HR12WEEK 3.0 / Free 12 Week Workout Series",
      "description": "The HR12WEEK 3.0 Begins January 2nd 2023!! Download your Calendar & Free Ultimate Training Guide Now ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-28T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YjLXQW4hfhTNbIvXHJwzxOWTVCc",
    "id": {
      "kind": "youtube#video",
      "videoId": "u1imJo9x-00"
    },
    "snippet": {
      "publishedAt": "2023-01-11T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Foam Rolling #shorts",
      "description": "Foam rolling is a great way to ease sore muscles and aid in recovery. This type of session is a great way to end each workout or to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-11T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8GnLFqPrdUUDj-mekWGRJlGzl2I",
    "id": {
      "kind": "youtube#video",
      "videoId": "5FL5HQzqGoY"
    },
    "snippet": {
      "publishedAt": "2022-05-23T15:03:43Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10MIN Standing Abs Workout",
      "description": "Time for a new Standing Abs Workout! This quick 10 minute ab workout targets the core with a series of standing ab exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5FL5HQzqGoY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5FL5HQzqGoY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5FL5HQzqGoY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-23T15:03:43Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iF1xVhgylgySe2SLTC2ZJjeG9gM",
    "id": {
      "kind": "youtube#video",
      "videoId": "l653nSf-I1I"
    },
    "snippet": {
      "publishedAt": "2022-07-11T13:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 3: Cardio AMRAP",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP Day Three of the Glow Up 2.0 Challenge is going to be seriously sweaty!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/l653nSf-I1I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/l653nSf-I1I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/l653nSf-I1I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-11T13:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wKv3vZMIAmXU9jik99QHB1Ffhdc",
    "id": {
      "kind": "youtube#video",
      "videoId": "GLhqf2jE6bU"
    },
    "snippet": {
      "publishedAt": "2022-07-15T13:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 7: Total Body Tabata + Stretch",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP This is it team - Day 7 of the Glow Up 2.0 Challenge!!! What a sweaty week.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GLhqf2jE6bU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GLhqf2jE6bU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GLhqf2jE6bU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-15T13:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kzRQunWivA50mA4zmziY95d6LZE",
    "id": {
      "kind": "youtube#video",
      "videoId": "2e8SB36CyWA"
    },
    "snippet": {
      "publishedAt": "2022-06-15T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "16MIN Fat Burning Tabata",
      "description": "Get ready for a serious sweat in today's Total Body Fat Burning Tabata! It's a quick 16 minute traditional Tabata style workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2e8SB36CyWA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2e8SB36CyWA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2e8SB36CyWA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-15T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ohsw8vuxybPpcfrYbHlZkEXGTx0",
    "id": {
      "kind": "youtube#video",
      "videoId": "CjnPlDWrGvs"
    },
    "snippet": {
      "publishedAt": "2022-04-18T12:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer Kickboxing HIIT Workout",
      "description": "Get ready to sweat with this quick, intense Kickboxing HIIT Workout! We are going to punch, kick and jump our way through ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CjnPlDWrGvs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CjnPlDWrGvs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CjnPlDWrGvs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-18T12:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M50BCOQBlTf9HSTGmA2O_PeJoQ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "_9kgJrQZBtI"
    },
    "snippet": {
      "publishedAt": "2022-05-30T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15MIN Killer Cardio Workout // AMRAP",
      "description": "Today's quick cardio AMRAP Workout is a killer! Three cardio circuits to power through - You will be performing 8 reps of each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-30T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fS0iUmAd91priNgEf93IY1VcKWY",
    "id": {
      "kind": "youtube#video",
      "videoId": "XzeLxAwXWhA"
    },
    "snippet": {
      "publishedAt": "2022-04-11T12:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51Mini Band Booty Builder // Glute Activation",
      "description": "This mini band workout includes a series of booty building exercises designed to activate the glutes. Use it as a quick workout on ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XzeLxAwXWhA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XzeLxAwXWhA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XzeLxAwXWhA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-11T12:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0kxtSIjyWc5Pj_Xpas9jqW8LmWM",
    "id": {
      "kind": "youtube#video",
      "videoId": "cDNY_gYzCFs"
    },
    "snippet": {
      "publishedAt": "2022-08-23T14:04:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "8Min Tabata // Get ready to SWEAT!",
      "description": "This 8MIN Tabata Workout is a killer sweat sesh on it's own if you are short on time and have no equipment but it also makes an ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cDNY_gYzCFs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cDNY_gYzCFs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cDNY_gYzCFs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-23T14:04:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RpUnRMIBbfUqM9DGzprg5VNf-4c",
    "id": {
      "kind": "youtube#video",
      "videoId": "BauLVjG4Vq4"
    },
    "snippet": {
      "publishedAt": "2022-03-22T03:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "No Equipment Total Body HIIT + Abs // Day 57 HR12WEEK 2.0",
      "description": "Today's Total Body HIIT Workout is a serious sweat fest A no equipment full body workout to begin followed by a killer ab finisher ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BauLVjG4Vq4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BauLVjG4Vq4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BauLVjG4Vq4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-22T03:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3MoDprd8zHw5pfAgHQ3ly8i3iaQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "lRgeHmUCc44"
    },
    "snippet": {
      "publishedAt": "2022-07-14T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 6: Glutes &amp; Thighs Workout",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP Day 6! Today's Glow Up Challenge 2.0 Workout is a Glutes and Thighs workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lRgeHmUCc44/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lRgeHmUCc44/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lRgeHmUCc44/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-14T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "spPKWPNPcSO0PFZWK5jD4MhTSOU",
    "id": {
      "kind": "youtube#video",
      "videoId": "bnXsq-119Jo"
    },
    "snippet": {
      "publishedAt": "2022-03-25T03:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Tabata Cardio &amp; Abs: No Equipment // Day 59 HR12WEEK 2.0",
      "description": "Tabata time!! This tabata style workout is a cardio and abs session that is both intense and fun. Each tabata circuit combines both ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bnXsq-119Jo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bnXsq-119Jo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bnXsq-119Jo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-25T03:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uWutFGntjXRIgNcvzpEslZVMlzY",
    "id": {
      "kind": "youtube#video",
      "videoId": "xdLxMzb7g5c"
    },
    "snippet": {
      "publishedAt": "2022-05-03T13:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body STRETCH &amp; FLEXIBILTY // Fusion Flow: DAY 4",
      "description": "Time for a relaxing yet energizing Full Body Stretch! This stretching routine will aid in the recovery of sore, stiff muscles, help ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xdLxMzb7g5c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xdLxMzb7g5c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xdLxMzb7g5c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-03T13:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DWo2MiY8RjClIMYfSk0ewe16E2o",
    "id": {
      "kind": "youtube#video",
      "videoId": "pmnnEQMCBIM"
    },
    "snippet": {
      "publishedAt": "2022-07-13T13:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 5: No Repeats HIIT Workout",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP Time for a No Repeats HIIT Workout!! It's Day 5 of the Glow Up 2.0 and things ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pmnnEQMCBIM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pmnnEQMCBIM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pmnnEQMCBIM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-13T13:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RGBrnF6YV0fMFmd2x7l-iqR4wcM",
    "id": {
      "kind": "youtube#video",
      "videoId": "SSeO5EkHiL8"
    },
    "snippet": {
      "publishedAt": "2022-05-16T13:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Kettlebell HIIT Workout",
      "description": "Today's Kettlebell HIIT workout is a total body body workout that you can do with either kettlebells or easily modify with dumbbells.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SSeO5EkHiL8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SSeO5EkHiL8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SSeO5EkHiL8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-16T13:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eDBO4VMTBT9Z08toTaao5MBPDtY",
    "id": {
      "kind": "youtube#video",
      "videoId": "juJ3Fg-qljk"
    },
    "snippet": {
      "publishedAt": "2022-07-12T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 4: Upper Body Shred",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP Day 4 of the Glow Up 2.0 Challenge and we are getting in an Upper Body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/juJ3Fg-qljk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/juJ3Fg-qljk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/juJ3Fg-qljk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-12T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "L7E0tD_SkR381YlhVdtk-QXkpB0",
    "id": {
      "kind": "youtube#video",
      "videoId": "QRWs22V1Z7Q"
    },
    "snippet": {
      "publishedAt": "2022-08-02T14:02:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10MIN No Repeats Abs Workout",
      "description": "Today's quick 10MIN Ab Workout is a no equipment, no repeats workout that you can do do from home. It's great on it's own for a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QRWs22V1Z7Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QRWs22V1Z7Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QRWs22V1Z7Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-02T14:02:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "G_7dqDrhhuIqm_FDZq0tuYiyJ8s",
    "id": {
      "kind": "youtube#video",
      "videoId": "aGnkGuwrJuI"
    },
    "snippet": {
      "publishedAt": "2022-07-20T13:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Kickboxing Cardio Workout!",
      "description": "Today's Kickboxing Cardio Workout is a killer! Get ready to sweat as we punch, kick and jump our way through a series of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aGnkGuwrJuI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aGnkGuwrJuI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aGnkGuwrJuI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-20T13:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "15K6u8jTDALXKKfvcYF59Yw1eng",
    "id": {
      "kind": "youtube#video",
      "videoId": "EGRNAUMFEpY"
    },
    "snippet": {
      "publishedAt": "2022-09-22T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd14 Kettlebell Tabata Workout",
      "description": "It's time for a quick Kettlebell Tabata Workout! This Tabata workout uses kettlebells for resistance (you can always sub in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EGRNAUMFEpY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EGRNAUMFEpY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EGRNAUMFEpY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-22T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "82Nrft48xDdfiJm7zVKsMSNv-p0",
    "id": {
      "kind": "youtube#video",
      "videoId": "3f7_LO_Efq4"
    },
    "snippet": {
      "publishedAt": "2022-07-10T15:59:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 2: Core &amp; Booty Bootcamp",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP It's day 2 of the Glow Up 2.0 Challenge and today we have a Core & Booty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3f7_LO_Efq4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3f7_LO_Efq4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3f7_LO_Efq4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-10T15:59:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tiDOg7lFopgYtdYQi7KNQJxCrJA",
    "id": {
      "kind": "youtube#video",
      "videoId": "a-DU0fVJ_I8"
    },
    "snippet": {
      "publishedAt": "2022-04-20T12:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body HIIT Workout // Supersets",
      "description": "This Total Body HIIT Workout is loaded with six different supersets. Each superset combines dumbbell, resistance exercises with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/a-DU0fVJ_I8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/a-DU0fVJ_I8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/a-DU0fVJ_I8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-20T12:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Zei_JYFULWiNn5gyhnwi0aGoxa0",
    "id": {
      "kind": "youtube#video",
      "videoId": "CpxrVo1TGJw"
    },
    "snippet": {
      "publishedAt": "2022-12-05T14:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "12MIN Total Body TABATA Workout",
      "description": "It's Tabata time! This 12 min total body Tabata workout is quick and intense and will have you sweating and feeling the burn in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CpxrVo1TGJw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CpxrVo1TGJw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CpxrVo1TGJw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-05T14:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IQOGgCxv2PHqNXvSxbLUtLlMiVg",
    "id": {
      "kind": "youtube#video",
      "videoId": "KR-KXCDg5X4"
    },
    "snippet": {
      "publishedAt": "2022-12-27T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Quick &amp; Intense Cardio Finisher",
      "description": "This is a quick and intense cardio workout that is great on it's own if you are short on time or, use it as a workout finisher for a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KR-KXCDg5X4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KR-KXCDg5X4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KR-KXCDg5X4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-27T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "slBmzhp98gAKYJiZ-3Xm7ZSSgbs",
    "id": {
      "kind": "youtube#video",
      "videoId": "9Ow-VidcbTE"
    },
    "snippet": {
      "publishedAt": "2022-06-06T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd2515MIN No Repeats HIIT Cardio",
      "description": "Get ready to sweat with today's No Repeats HIIT Cardio Workout! This is a quick 15min workout that will get that heart rate up and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9Ow-VidcbTE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9Ow-VidcbTE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9Ow-VidcbTE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-06T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JGA0r0gVgzCdhlNHcsH_Bnfqmc0",
    "id": {
      "kind": "youtube#video",
      "videoId": "RQZDLyh1QfE"
    },
    "snippet": {
      "publishedAt": "2022-03-26T03:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf89NO REPEATS Full Body HIIT Workout // DAY 60 HR12WEEK 2.0",
      "description": "This is it!!! Our final workout team! And this NO REPEATS Full Body Workout is the perfect way to celebrate. I have loved following ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RQZDLyh1QfE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RQZDLyh1QfE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RQZDLyh1QfE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-26T03:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3hezFqdzuu5SCgH1CpeZckKRkr8",
    "id": {
      "kind": "youtube#video",
      "videoId": "_CbC7WCJdAM"
    },
    "snippet": {
      "publishedAt": "2022-05-06T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body HIIT Yoga Fusion // Fusion Flow: DAY 7",
      "description": "It's the final day of my Fusion Flow workout series and we are wrapping up with a Total Body HIIT Yoga Workout. These yoga ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_CbC7WCJdAM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_CbC7WCJdAM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_CbC7WCJdAM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-06T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gx1rBIsUTkUqQQm597-1W1wWhzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "j507cNqXJ4o"
    },
    "snippet": {
      "publishedAt": "2022-07-18T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN METCON Workout // Metabolic Conditioning",
      "description": "Let's go team! We have a full body Metcon workout to get through today. Metabolic conditioning that includes compound exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-18T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yb6qC2wIKOrVtX0taFI_mlgPjrE",
    "id": {
      "kind": "youtube#video",
      "videoId": "aedn4SSc3Ww"
    },
    "snippet": {
      "publishedAt": "2022-12-08T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Dumbbell Shoulder Press #shorts",
      "description": "Let's learn how to do a dumbbell shoulder press #workouttips #howto #shoulderpress #exercisetips #properform Get the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-08T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mxWt2YRKlwsZ3rhzjj0h36GBf0U",
    "id": {
      "kind": "youtube#video",
      "videoId": "KGh6YsNGZ-k"
    },
    "snippet": {
      "publishedAt": "2022-07-09T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2728Glow Up 2.0 // Day 1: Total Body Metcon",
      "description": "Get the Free Glow Up Guide! https://bit.ly/3ySVzyP It's day one of my. new Glow Up 2.0 Challenge! We are starting off with a killer ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KGh6YsNGZ-k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KGh6YsNGZ-k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KGh6YsNGZ-k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-09T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Us8uc1HEhFM44L6nJvNF2f6oMgY",
    "id": {
      "kind": "youtube#video",
      "videoId": "2xQuW33uccs"
    },
    "snippet": {
      "publishedAt": "2022-05-01T13:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Barre Arms &amp; Abs // Fusion Flow: DAY 2",
      "description": "Welcome to day 2 of my Fusion Flow workout series. Today's Barre Arms and Abs workout uses light dumbbells and barre ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2xQuW33uccs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2xQuW33uccs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2xQuW33uccs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-01T13:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cM6b1NBBK8z1ToIiHnsm9PYcP-s",
    "id": {
      "kind": "youtube#video",
      "videoId": "k1yRAzTAXMg"
    },
    "snippet": {
      "publishedAt": "2022-04-25T13:51:20Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 No Repeats HIIT Cardio",
      "description": "Sign up for an Epidemic Sound annual personal plan here http://share.epidemicsound.com/zj9WV and use code HEATHER50 to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/k1yRAzTAXMg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/k1yRAzTAXMg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/k1yRAzTAXMg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-25T13:51:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KbmEm1BGuXDKeYv1-tkcCvt4FD0",
    "id": {
      "kind": "youtube#video",
      "videoId": "tM3bXrzQyEw"
    },
    "snippet": {
      "publishedAt": "2022-06-27T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20MIN Total Body METCON // Metabolic Conditioning",
      "description": "I am loving these METCON workouts! Total Body compound movements, quick rest breaks and full body conditioning make for a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tM3bXrzQyEw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tM3bXrzQyEw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tM3bXrzQyEw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-27T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tghGTBXoZ0OEggDwnHZuCznrptU",
    "id": {
      "kind": "youtube#video",
      "videoId": "3AxV_Iq-hh4"
    },
    "snippet": {
      "publishedAt": "2022-05-02T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS Low Impact HIIT // Fusion Flow: DAY 3",
      "description": "It's Day 3 of the Fusion Flow workout series and we are picking up the intensity with this No Repeats Low Impact HIIT. No Jumping ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3AxV_Iq-hh4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3AxV_Iq-hh4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3AxV_Iq-hh4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-02T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "L_wx4BSbY6P24WCZ6NevFpVABL8",
    "id": {
      "kind": "youtube#video",
      "videoId": "mozRLDHXJT4"
    },
    "snippet": {
      "publishedAt": "2022-05-31T18:58:59Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN METCON // Total Body Metabolic Conditioning",
      "description": "Who's ready for a new METCON Workout!? This total body Metabolic Conditioning workout uses a series of compound exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mozRLDHXJT4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mozRLDHXJT4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mozRLDHXJT4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-31T18:58:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OBJtfVuWMJRklbUsI3mq5zFtr-c",
    "id": {
      "kind": "youtube#video",
      "videoId": "oFFKXDWRcOU"
    },
    "snippet": {
      "publishedAt": "2022-08-09T14:08:36Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Fat Burning HIIT Cardio!",
      "description": "This quick 12min cardio workout is no joke. Get that heart rate up and feel the burn with today's fat burning HIIT cardio Workout!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oFFKXDWRcOU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oFFKXDWRcOU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oFFKXDWRcOU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-09T14:08:36Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "S4jAonbikwNZ2Ah7mLh8LW8AKX8",
    "id": {
      "kind": "youtube#video",
      "videoId": "eHEOOKU_hsE"
    },
    "snippet": {
      "publishedAt": "2022-05-04T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS Low Impact HIIT CARDIO // Fusion Flow: DAY 5",
      "description": "Today's Low Impact HIIT Cardio Workout is going to get that heart rate up! No jumping, no equipment and no repeats!! Perform ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eHEOOKU_hsE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eHEOOKU_hsE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eHEOOKU_hsE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-04T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2pWoWmklH3gavfLYDD-KZ86Lijw",
    "id": {
      "kind": "youtube#video",
      "videoId": "IKZqcf4EHB0"
    },
    "snippet": {
      "publishedAt": "2022-12-19T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10MIN No Equipment Arm Toning Workout",
      "description": "No equipment needed in today's 10 minute arm toning workout! We are feeling the burn using just our body-weight for this series ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-19T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "NZcAud30HgyarCZUJj-IKMiNyRA",
    "id": {
      "kind": "youtube#video",
      "videoId": "yu-ozByssIQ"
    },
    "snippet": {
      "publishedAt": "2022-04-27T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20 MIN HIIT Abs // Serious Core Strength",
      "description": "This quick HIIT Abs Workout is designed to strengthen the core and set fire to those abs!! No equipment needed as we power ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yu-ozByssIQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yu-ozByssIQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yu-ozByssIQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-27T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_3C8tlSRYdO01wYr2E4bhYAGsHo",
    "id": {
      "kind": "youtube#video",
      "videoId": "nitM-Iv1aWM"
    },
    "snippet": {
      "publishedAt": "2022-05-05T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Powerful Pilates Core Workout // Fusion Flow: DAY 6",
      "description": "Get ready to set those abs on fire with today's Powerful Pilates Core Workout! This mat based workout requires no equipment and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nitM-Iv1aWM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nitM-Iv1aWM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nitM-Iv1aWM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-05T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VjE29RmLZUSbB7mZoZAgw-0d21w",
    "id": {
      "kind": "youtube#video",
      "videoId": "J_Zr3f9m3qk"
    },
    "snippet": {
      "publishedAt": "2022-10-25T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15MIN N0 REPEATS HIIT",
      "description": "This 15MIn No Repeats HIIT workout is a quick sweat sesh that is perfect as a workout finisher but is equally effective on its own ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/J_Zr3f9m3qk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/J_Zr3f9m3qk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/J_Zr3f9m3qk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-25T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Zi11rR1xyCjFjd_xqDnex9pkpEQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Nla6Zc3Cwpo"
    },
    "snippet": {
      "publishedAt": "2022-04-13T12:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30 MIN Full Body HIIT Workout",
      "description": "Today's 30 minute full body HIIT Workout is quick, intense and super sweaty! Using dumbbells for added resistance combined ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Nla6Zc3Cwpo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Nla6Zc3Cwpo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Nla6Zc3Cwpo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-13T12:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "bsK2DbTZZUS0pSWLe7dMMnb64PI",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZH-Fi8H0-Kg"
    },
    "snippet": {
      "publishedAt": "2022-04-04T12:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20MIN No Repeats HIIT Workout // No Equipment",
      "description": "We are back with a brand new NO REPEATS HIIT workout with no equipment. Using just your body-weight to power through each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZH-Fi8H0-Kg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZH-Fi8H0-Kg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZH-Fi8H0-Kg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-04T12:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rHN3KinYWhsITZbY7n4SLb360zk",
    "id": {
      "kind": "youtube#video",
      "videoId": "dEOMog-oJBM"
    },
    "snippet": {
      "publishedAt": "2023-02-07T14:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 28 //  Lower Body MetCon HR12WEEK 3.0",
      "description": "It's leg day!! Today's lower body MetCon workout includes compound exercises that target multiple muscles at the same time to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dEOMog-oJBM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dEOMog-oJBM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dEOMog-oJBM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-07T14:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QkBi4Gydap_FCs2ZAOQvrUfoLnc",
    "id": {
      "kind": "youtube#video",
      "videoId": "yPJiXGoj2uw"
    },
    "snippet": {
      "publishedAt": "2022-07-25T13:59:59Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact, No Repeats HIIT // No Jumping Workout",
      "description": "Time for a Low Impact (No Jumping) and No Repeats HIIT Workout!!! Remember low impact does not have to mean low intensity.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yPJiXGoj2uw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yPJiXGoj2uw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yPJiXGoj2uw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-25T13:59:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "r4raZRyJqD2WtB5dN_2-SQQvf6I",
    "id": {
      "kind": "youtube#video",
      "videoId": "TVlemtvv5h4"
    },
    "snippet": {
      "publishedAt": "2022-07-31T14:54:44Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body METCON // Metabolic Conditioning Workout",
      "description": "Let's get it team! This Full Body METCON Workout is a metabolic conditioning session packed with compound exercises to get in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TVlemtvv5h4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TVlemtvv5h4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TVlemtvv5h4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-31T14:54:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BK-srzUX7R_Jqm5zi27qQfYbraQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "gGxMIPY2NX4"
    },
    "snippet": {
      "publishedAt": "2022-05-09T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Metabolic Workout // METCON",
      "description": "This Total Body Metabolic Workout or Metabolic Conditioning (METCON) is one of my favorite ways to get in an effective full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "im1-XU7q3ut9OV9DaqNqvl9apbU",
    "id": {
      "kind": "youtube#video",
      "videoId": "h7lDxRJLCXU"
    },
    "snippet": {
      "publishedAt": "2022-06-20T14:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body // Strength &amp; Cardio Workout",
      "description": "Today's total body session is a strength and cardio workout combining dumbbell exercises with cardio bursts for a solid full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-20T14:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_sLoEm1rm2UpgTSeJX7cwP3OT6o",
    "id": {
      "kind": "youtube#video",
      "videoId": "HOM1UZ7NWIU"
    },
    "snippet": {
      "publishedAt": "2023-02-06T14:59:57Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 27 // HIIT Cardio HR12WEEK 3.0",
      "description": "In today's HIIT Cardio Workout we are getting our sweat on with two different intense cardio circuits. Whether you are jumping ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HOM1UZ7NWIU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HOM1UZ7NWIU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HOM1UZ7NWIU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-06T14:59:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jXPenmqrhKbepFDIAYxhf2_ZLlU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hJOYAb8oOc8"
    },
    "snippet": {
      "publishedAt": "2022-05-25T13:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Leg Workout // Strength &amp; Power",
      "description": "Welcome to today's HIIT Legs Workout!!! This lower body workout will help build strength and power in the legs. We will perform ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-25T13:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CkVaypa9-Yfz_AVkIGeE0hI2eFM",
    "id": {
      "kind": "youtube#video",
      "videoId": "gYzvxUiqVaI"
    },
    "snippet": {
      "publishedAt": "2022-04-30T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Legs &amp; Glutes // Fusion Flow: DAY 1",
      "description": "Welcome to my new 7 day workout series: FUSION FLOW! This workout program combines yoga, Pilates and barre inspired ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-30T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "F95Q4qyHknJ7Y83Lpj0N4REgQJs",
    "id": {
      "kind": "youtube#video",
      "videoId": "upm-Zu83Fj8"
    },
    "snippet": {
      "publishedAt": "2023-02-12T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 31 // Leg Day MetCon HR12WEEK 3.0",
      "description": "Today's leg day MetCon comes packed with lower body movement including strength, unilateral and plyometric type exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/upm-Zu83Fj8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/upm-Zu83Fj8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/upm-Zu83Fj8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-12T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ls3WSbpffDb51kba6htTU7uYOEg",
    "id": {
      "kind": "youtube#video",
      "videoId": "B_A6usQ3l6A"
    },
    "snippet": {
      "publishedAt": "2022-09-04T14:19:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20MIN Strength &amp; Cardio Workout // Supersets",
      "description": "Todays 20MIN Strength & Cardio Workout is a full body workout with dumbbells designed to build muscle and burn fat with a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/B_A6usQ3l6A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/B_A6usQ3l6A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/B_A6usQ3l6A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-04T14:19:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Pb15HUeeu8_Q2kFQdl2i_ZS_-ro",
    "id": {
      "kind": "youtube#video",
      "videoId": "euEi7TTmoIA"
    },
    "snippet": {
      "publishedAt": "2022-10-16T14:00:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "8MIN Total Body Tabata Workout",
      "description": "This saucy little Tabata Workout is quick but it's intense! Just two different Tabata circuits to get through - 4 rounds of each! Tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-16T14:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xpsEwp3pfMFwbC7Gb9YfUfnpErQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "xv1vNvmpITk"
    },
    "snippet": {
      "publishedAt": "2023-02-09T14:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 29 // Shoulder Sculpting Workout HR12WEEK 3.0",
      "description": "Today's shoulder workout starts with movement designed to improve Mobility range of motion and flexibility. We will then move ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xv1vNvmpITk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xv1vNvmpITk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xv1vNvmpITk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-09T14:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-260B-F0m_yw_KZBitqnMnESfYs",
    "id": {
      "kind": "youtube#video",
      "videoId": "T4iLtE6VXTs"
    },
    "snippet": {
      "publishedAt": "2022-12-21T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf81 Merry HIITMAS! A Christmas Workout",
      "description": "'Tis the season for a Christmas Workout! Today's HIIT workout is a fun way to break a sweat and get in a quick workout during the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T4iLtE6VXTs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T4iLtE6VXTs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T4iLtE6VXTs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-21T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JLR0wjE4XySANnOW160sAx6_qEM",
    "id": {
      "kind": "youtube#video",
      "videoId": "DffN6J0sMEs"
    },
    "snippet": {
      "publishedAt": "2023-01-23T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 17 //  Unilateral Arm Strength HR12WEEK 3.0",
      "description": "Welcome to today's Arm Strength workout This is another unilateral workout training one side of the body at a time. Many of us are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DffN6J0sMEs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DffN6J0sMEs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DffN6J0sMEs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-23T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sM7vU9E_M1c9U7zI8ICrSvE1uqw",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZxEYo7M2kMY"
    },
    "snippet": {
      "publishedAt": "2022-10-30T14:54:29Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "25MIN Total Body TABATA (No Equipment)",
      "description": "It's time for an intense 25min Tabata Workout! No equipment needed for this one - we are using just our body-weight for resistance ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZxEYo7M2kMY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZxEYo7M2kMY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZxEYo7M2kMY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-30T14:54:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-DsGCyqIT7wDxm2Mdxp8rVY7Loc",
    "id": {
      "kind": "youtube#video",
      "videoId": "2fNrSPiMXAU"
    },
    "snippet": {
      "publishedAt": "2023-02-14T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 33 // Tabata Cardio &amp; Abs HR12WEEK 3.0",
      "description": "Today's body-weight Tabata Cardio & Abs Workout requires no equipment just a positive attitude! Tabata workouts are quick and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2fNrSPiMXAU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2fNrSPiMXAU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2fNrSPiMXAU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-14T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ad8yIMWToyjfo7wt7AGauLCa1Y0",
    "id": {
      "kind": "youtube#video",
      "videoId": "wXYwHPCcgWU"
    },
    "snippet": {
      "publishedAt": "2022-09-27T13:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Strength &amp; Cardio",
      "description": "Today's Total Body Strength and Cardio Workout is a quick session that will have you feeling strong and sweaty! We will power ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wXYwHPCcgWU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wXYwHPCcgWU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wXYwHPCcgWU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-27T13:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BhRuj0h7o3e9RKU_BDWh62Jw3VI",
    "id": {
      "kind": "youtube#video",
      "videoId": "kXfK3FYFPbw"
    },
    "snippet": {
      "publishedAt": "2022-09-08T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Abs &amp; Booty Workout",
      "description": "Get ready for a serious Abs & Booty Workout! This workout starts with a mat based abs circuit using no equipment and then we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kXfK3FYFPbw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kXfK3FYFPbw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kXfK3FYFPbw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-08T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qGe1S7vOrr58KPk16SV4o7rCVoA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Zi6tBh6ipFw"
    },
    "snippet": {
      "publishedAt": "2022-10-13T13:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 13: HIIT Cardio &amp; Abs // FIERCE 2.0",
      "description": "The intensity is going to be high in today's HIIT CARDIO and ABS Workout! It's day 13 of Fierce 2.0 and we will be starting with a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Zi6tBh6ipFw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Zi6tBh6ipFw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Zi6tBh6ipFw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-13T13:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nDJdcP2jJ4ttB19-AIswVPhe2O8",
    "id": {
      "kind": "youtube#video",
      "videoId": "IFY9sQqno8E"
    },
    "snippet": {
      "publishedAt": "2023-01-22T14:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 16 //  Lower Body METCON HR12WEEK 3.0",
      "description": "It's the start of week 4 and we have a spicy little MetCon workout today with a strong focus on the lower body. Three circuits, two ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IFY9sQqno8E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IFY9sQqno8E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IFY9sQqno8E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-22T14:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oBOB7lFXYwS3QfBx4S7NDxFC0Tg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WfGXwyUVGSU"
    },
    "snippet": {
      "publishedAt": "2023-02-02T14:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 24 // Back Strength Workout HR12WEEK 3.0",
      "description": "Today with we have a strength workout for the back! Six different supersets ahead of us loaded with dumbbell back exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-02T14:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HGtrk2DLzrfpQNTapDsZVqsV3Ss",
    "id": {
      "kind": "youtube#video",
      "videoId": "UZKJwySNZW0"
    },
    "snippet": {
      "publishedAt": "2023-02-20T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 37 // HIIT Cardio Workout HR12WEEK 3.0",
      "description": "Okay it's time to pick up the pace and reach new levels of intensity with this HIIT CARDIO workout. No equipment is needed today, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-20T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5fft0zYL2O79pFB5orEo7uUWZlI",
    "id": {
      "kind": "youtube#video",
      "videoId": "_OeYmK_bVZs"
    },
    "snippet": {
      "publishedAt": "2017-06-28T18:27:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "The Best At Home Butt Workout",
      "description": "Visit: https://www.heatherrobertson.com This butt workout includes some of my favourite exercises for toning, sculpting and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_OeYmK_bVZs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_OeYmK_bVZs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_OeYmK_bVZs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-06-28T18:27:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EE3p674D-fbaTwQqwaJGJqa-e9M",
    "id": {
      "kind": "youtube#video",
      "videoId": "GL22bQcxYo8"
    },
    "snippet": {
      "publishedAt": "2023-02-03T14:59:57Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Day 25 // No Repeats MetCon HR12WEEK 3.0",
      "description": "We are bringing the energy in today's no repeats MetCon workout. Two metabolic conditioning circuits today jam-packed with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GL22bQcxYo8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GL22bQcxYo8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GL22bQcxYo8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-03T14:59:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ahB8kQm_sboXiVprCQVgjIl9QfE",
    "id": {
      "kind": "youtube#video",
      "videoId": "oO1OQh9bNbc"
    },
    "snippet": {
      "publishedAt": "2022-10-03T13:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 3: Back &amp; Biceps Pull Workout // FIERCE 2.0",
      "description": "Welcome to day 3 of Fierce 2.0! Today's Upper body pull workout focuses on building strength and definition in the back and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oO1OQh9bNbc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oO1OQh9bNbc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oO1OQh9bNbc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-03T13:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qUy3SGOZGZJqFcaH7zxF9YZmo7A",
    "id": {
      "kind": "youtube#video",
      "videoId": "4YynFFiRAk0"
    },
    "snippet": {
      "publishedAt": "2023-01-31T14:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 23 // Lower Body Strength HR12WEEK 3.0",
      "description": "Get ready for another leg strength workout! Heavy lifts combined with explosive body weight movements make for one serious leg ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4YynFFiRAk0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4YynFFiRAk0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4YynFFiRAk0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-31T14:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7bYkCYai-SXC3puuksbFhWjBDVU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ervBDaMJW_w"
    },
    "snippet": {
      "publishedAt": "2023-02-10T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 30 // Full Body METCON HR12WEEK 3.0",
      "description": "Welcome to your full body MetCon workout and the halfway mark of the HR 12 week 3.0 workout series!!!! #HR12WEEK ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-10T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MHaFIDt5U4w0Dhj6an-1zsD2L0U",
    "id": {
      "kind": "youtube#video",
      "videoId": "R2fvu4KeQkw"
    },
    "snippet": {
      "publishedAt": "2023-01-29T14:59:59Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 21 // Upper Body MetCon HR12WEEK 3.0",
      "description": "Today we have a upper body MetCon Workout ahead of us! Each metabolic conditioning circuit consists of compound exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/R2fvu4KeQkw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/R2fvu4KeQkw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/R2fvu4KeQkw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-29T14:59:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qESiVR81hKHANOSwv-w4VQUv8sc",
    "id": {
      "kind": "youtube#video",
      "videoId": "zeYa8tQ251A"
    },
    "snippet": {
      "publishedAt": "2023-01-27T14:59:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 20 // Plank Challenge, Tabata &amp; Mobility HR12WEEK 3.0",
      "description": "We have a fun mix today team! Starting with a plank challenge to test that core strength (and mental strength!) followed by a quick ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zeYa8tQ251A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zeYa8tQ251A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zeYa8tQ251A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-27T14:59:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lUOxSgfgHAx6xdNcn-E8o1khfC0",
    "id": {
      "kind": "youtube#video",
      "videoId": "DCuPxe1TANc"
    },
    "snippet": {
      "publishedAt": "2023-01-26T14:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 19 //  Total Body MetCon HR12WEEK 3.0",
      "description": "Today's total body MetCon workout combines strength and cardio while focusing on effective compound exercises to make the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DCuPxe1TANc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DCuPxe1TANc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DCuPxe1TANc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-26T14:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Nu58HcktXYvH19AgfxCT1mexegY",
    "id": {
      "kind": "youtube#video",
      "videoId": "69LplyPbrEY"
    },
    "snippet": {
      "publishedAt": "2022-12-07T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body MetCon // Metabolic Conditioning",
      "description": "Full Body MetCon! Get ready to sweat team. This Metabolic conditioning workout is a total body sweat sesh packed with effective ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-07T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1ItujXKme02X84VoXHw2FXEuLmE",
    "id": {
      "kind": "youtube#video",
      "videoId": "qeBaosuU9oo"
    },
    "snippet": {
      "publishedAt": "2023-01-30T14:59:57Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 22 // Cardio &amp; Abs HR12WEEK 3.0",
      "description": "Okay team it's time for a Cardio and Abs Workout - get ready to sweat and bring that intensity! We are starting things off with two ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qeBaosuU9oo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qeBaosuU9oo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qeBaosuU9oo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-30T14:59:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kHytIu9FyXTnt5RiWCrs3W6bypU",
    "id": {
      "kind": "youtube#video",
      "videoId": "07HoVTDQkbY"
    },
    "snippet": {
      "publishedAt": "2022-10-10T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 10: Arm Toning &amp; Strength // FIERCE 2.0",
      "description": "It's day 10 of Fierce 2.0 and we have an Arm STRENGTH AND TONING workout to do today. Remember to choose dumbbells that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/07HoVTDQkbY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/07HoVTDQkbY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/07HoVTDQkbY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-10T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cfwxoAvwpsqgmMrO8xiCTH7x170",
    "id": {
      "kind": "youtube#video",
      "videoId": "cMSZ62q-IuM"
    },
    "snippet": {
      "publishedAt": "2023-01-24T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 18 // NO REPEATS Cardio Workout HR12WEEK 3.0",
      "description": "It's cardio time!! Today we have a no repeats cardio workout ahead of us. Two intense cardio circuits to push through today.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cMSZ62q-IuM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cMSZ62q-IuM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cMSZ62q-IuM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-24T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AbC-68TomwkvPsVeBMg_B6-Q5-0",
    "id": {
      "kind": "youtube#video",
      "videoId": "zZyosxYeTSE"
    },
    "snippet": {
      "publishedAt": "2022-10-11T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 11: Mobility &amp; Dynamic Stretch // FIERCE 2.0",
      "description": "For today's active recovery workout we have a Full Body MOBILITY & STRETCHING Routine. Sometimes the most challenging ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-11T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "B7xzt4w814LJXmXNDgHKIEVr7v8",
    "id": {
      "kind": "youtube#video",
      "videoId": "J1XyxRUiiAo"
    },
    "snippet": {
      "publishedAt": "2022-09-15T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN No Repeats HIIT Workout",
      "description": "This 30MIN No Repeats HIIT workout is quick and intense! We will power through a full body hiit session loaded with both ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/J1XyxRUiiAo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/J1XyxRUiiAo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/J1XyxRUiiAo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-15T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6MaBvv35WSPAXfH5h2L2VktyXfI",
    "id": {
      "kind": "youtube#video",
      "videoId": "0arpNtjXuLY"
    },
    "snippet": {
      "publishedAt": "2022-10-09T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 9: Abs &amp; Core Strength // FIERCE 2.0",
      "description": "Ok team it's time to focus on that core strength! It's day 9 of my Fierce 2.0 program and we are about to set those ABS on fire!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-09T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DXBU57UIQVUxwStmCKQpG2RPP_I",
    "id": {
      "kind": "youtube#video",
      "videoId": "dhla340qlV4"
    },
    "snippet": {
      "publishedAt": "2023-01-17T14:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 13 //  Unilateral Leg &amp; Glute Strength HR12WEEK 3.0",
      "description": "In today's leg and glute strength workout we will be performing unilateral exercises meaning that we will train one side of the body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dhla340qlV4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dhla340qlV4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dhla340qlV4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-17T14:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "STCPrAGou2ieQlBy6F2tIrbq6Ak",
    "id": {
      "kind": "youtube#video",
      "videoId": "nOTIHKfUsXA"
    },
    "snippet": {
      "publishedAt": "2023-01-10T14:59:57Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 8 // 30MIN Ab Shredder HR12WEEK 3.0",
      "description": "It's time for a serious Ab Workout! Today's Ab shredder is a core strengthening, ab defining sweat sesh. We are adding dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nOTIHKfUsXA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nOTIHKfUsXA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nOTIHKfUsXA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-10T14:59:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1JPNb9R9xizaGr7YTbfFqXGU-Vs",
    "id": {
      "kind": "youtube#video",
      "videoId": "f5SuHY_WGBk"
    },
    "snippet": {
      "publishedAt": "2023-03-14T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 53 // Strength Pyramid Workout HR12WEEK 3.0",
      "description": "Get ready for today's STRENGTH Pyramid Workout! It's time to switch things up a bit and try something new!.This pyramid workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/f5SuHY_WGBk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/f5SuHY_WGBk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/f5SuHY_WGBk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-14T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EKEMcGxRwkfzW0PygeprcCzOdKo",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZZDYk61Ymew"
    },
    "snippet": {
      "publishedAt": "2022-08-14T14:08:39Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Workout // Fusion Workout",
      "description": "I love a good Pilates workout! This HIIT Pilates fusion combines a series of Pilates inspired exercises designed to give you a full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-14T14:08:39Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FnI4G-BFPJAIx7u2rmWM0NFamzM",
    "id": {
      "kind": "youtube#video",
      "videoId": "pdZm1kqY0bc"
    },
    "snippet": {
      "publishedAt": "2023-01-19T14:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 14 //  Total Body METCON HR12WEEK 3.0",
      "description": "We are back with another sweaty Total Body MetCon Workout! We are getting in a full body workout with dumbbells today.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pdZm1kqY0bc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pdZm1kqY0bc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pdZm1kqY0bc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-19T14:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fDz0gUwjZ55SBTuFq_PRo3ebuY4",
    "id": {
      "kind": "youtube#video",
      "videoId": "_jQbpSL1fgk"
    },
    "snippet": {
      "publishedAt": "2023-03-08T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Sore wrists during push ups or planks? #shorts",
      "description": "Do you have sore wrists during push ups or planks? Here are a few tips on how to reduce wrist pain during you workouts! Tension ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_jQbpSL1fgk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-08T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cKYbWwmRHaMj3lk3qMdMjzkBCgc",
    "id": {
      "kind": "youtube#video",
      "videoId": "47onZogZMHk"
    },
    "snippet": {
      "publishedAt": "2022-09-11T13:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Knee Friendly Tabata // No Jumping, No Squats, No Lunges",
      "description": "Tabata time! This tabata is knee friendly - designed with no Jumping, No Squats and No lunges. This may be a low impact workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/47onZogZMHk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/47onZogZMHk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/47onZogZMHk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-11T13:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AuhJwBOglSQt7K6rjpFIs2h1lFM",
    "id": {
      "kind": "youtube#video",
      "videoId": "tqGmAhcGeE4"
    },
    "snippet": {
      "publishedAt": "2023-02-05T14:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 26 // Full Body Workout HR12WEEK 3.0",
      "description": "We are not messing around today my friends! We have a FULL BODY Workout to power through - Three different circuits today.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tqGmAhcGeE4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tqGmAhcGeE4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tqGmAhcGeE4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-05T14:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Nu58HcktXYvH19AgfxCT1mexegY",
    "id": {
      "kind": "youtube#video",
      "videoId": "69LplyPbrEY"
    },
    "snippet": {
      "publishedAt": "2022-12-07T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body MetCon // Metabolic Conditioning",
      "description": "Full Body MetCon! Get ready to sweat team. This Metabolic conditioning workout is a total body sweat sesh packed with effective ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/69LplyPbrEY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-07T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8pxeyeVyNyItRn-ghae3ZMt5SBA",
    "id": {
      "kind": "youtube#video",
      "videoId": "poLX0kqpXqE"
    },
    "snippet": {
      "publishedAt": "2022-10-18T14:35:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact HIIT // No Equipment",
      "description": "Today's LOW IMPACT HIIT is loaded with No Jumping Exercises that will get that heart rate up and use just your body-weight to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/poLX0kqpXqE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/poLX0kqpXqE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/poLX0kqpXqE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-18T14:35:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7bYkCYai-SXC3puuksbFhWjBDVU",
    "id": {
      "kind": "youtube#video",
      "videoId": "ervBDaMJW_w"
    },
    "snippet": {
      "publishedAt": "2023-02-10T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 30 // Full Body METCON HR12WEEK 3.0",
      "description": "Welcome to your full body MetCon workout and the halfway mark of the HR 12 week 3.0 workout series!!!! #HR12WEEK ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ervBDaMJW_w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-10T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oBOB7lFXYwS3QfBx4S7NDxFC0Tg",
    "id": {
      "kind": "youtube#video",
      "videoId": "WfGXwyUVGSU"
    },
    "snippet": {
      "publishedAt": "2023-02-02T14:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 24 // Back Strength Workout HR12WEEK 3.0",
      "description": "Today with we have a strength workout for the back! Six different supersets ahead of us loaded with dumbbell back exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WfGXwyUVGSU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-02T14:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2pWoWmklH3gavfLYDD-KZ86Lijw",
    "id": {
      "kind": "youtube#video",
      "videoId": "IKZqcf4EHB0"
    },
    "snippet": {
      "publishedAt": "2022-12-19T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10MIN No Equipment Arm Toning Workout",
      "description": "No equipment needed in today's 10 minute arm toning workout! We are feeling the burn using just our body-weight for this series ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IKZqcf4EHB0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-19T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M50BCOQBlTf9HSTGmA2O_PeJoQ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "_9kgJrQZBtI"
    },
    "snippet": {
      "publishedAt": "2022-05-30T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15MIN Killer Cardio Workout // AMRAP",
      "description": "Today's quick cardio AMRAP Workout is a killer! Three cardio circuits to power through - You will be performing 8 reps of each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_9kgJrQZBtI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-30T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gx1rBIsUTkUqQQm597-1W1wWhzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "j507cNqXJ4o"
    },
    "snippet": {
      "publishedAt": "2022-07-18T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN METCON Workout // Metabolic Conditioning",
      "description": "Let's go team! We have a full body Metcon workout to get through today. Metabolic conditioning that includes compound exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j507cNqXJ4o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-07-18T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WveiL16hzE9VYS4OiXrgBvuZsTE",
    "id": {
      "kind": "youtube#video",
      "videoId": "dEk3edU50o0"
    },
    "snippet": {
      "publishedAt": "2022-10-23T15:00:25Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Ab Burner \ud83d\udd25",
      "description": "It's time for another 30MIN AB BURNER! This abs workout will target the abdominal muscles and help build serious core strength.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dEk3edU50o0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dEk3edU50o0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dEk3edU50o0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-23T15:00:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ex2I68AL_1CgL8aHFDhTcKTV9L8",
    "id": {
      "kind": "youtube#video",
      "videoId": "Wqo9mnF6DXE"
    },
    "snippet": {
      "publishedAt": "2022-11-28T14:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Total Body HIIT",
      "description": "Get ready for today's Total Body HIIT workout! This full body sweat sesh has a warm up, 2 different HIIT circuits and a cool down to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Wqo9mnF6DXE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Wqo9mnF6DXE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Wqo9mnF6DXE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-28T14:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CkVaypa9-Yfz_AVkIGeE0hI2eFM",
    "id": {
      "kind": "youtube#video",
      "videoId": "gYzvxUiqVaI"
    },
    "snippet": {
      "publishedAt": "2022-04-30T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Legs &amp; Glutes // Fusion Flow: DAY 1",
      "description": "Welcome to my new 7 day workout series: FUSION FLOW! This workout program combines yoga, Pilates and barre inspired ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gYzvxUiqVaI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-30T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BK-srzUX7R_Jqm5zi27qQfYbraQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "gGxMIPY2NX4"
    },
    "snippet": {
      "publishedAt": "2022-05-09T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Metabolic Workout // METCON",
      "description": "This Total Body Metabolic Workout or Metabolic Conditioning (METCON) is one of my favorite ways to get in an effective full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gGxMIPY2NX4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-09T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EKEMcGxRwkfzW0PygeprcCzOdKo",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZZDYk61Ymew"
    },
    "snippet": {
      "publishedAt": "2022-08-14T14:08:39Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Workout // Fusion Workout",
      "description": "I love a good Pilates workout! This HIIT Pilates fusion combines a series of Pilates inspired exercises designed to give you a full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZZDYk61Ymew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-14T14:08:39Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HGtrk2DLzrfpQNTapDsZVqsV3Ss",
    "id": {
      "kind": "youtube#video",
      "videoId": "UZKJwySNZW0"
    },
    "snippet": {
      "publishedAt": "2023-02-20T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 37 // HIIT Cardio Workout HR12WEEK 3.0",
      "description": "Okay it's time to pick up the pace and reach new levels of intensity with this HIIT CARDIO workout. No equipment is needed today, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UZKJwySNZW0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-20T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "O1d_U-zwKEjrmAL3MRJnIv-83bo",
    "id": {
      "kind": "youtube#video",
      "videoId": "Mj4qigKvT8E"
    },
    "snippet": {
      "publishedAt": "2022-10-04T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 4: Active Recovery Yoga // FIERCE 2.0",
      "description": "Welcome to day 4 of Fierce 2.0! We've had an intense few days of strength training and it's time to show that body some love with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Mj4qigKvT8E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Mj4qigKvT8E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Mj4qigKvT8E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-04T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0QCCiYQg4O-WliULBvH7llOxjYA",
    "id": {
      "kind": "youtube#video",
      "videoId": "VmJKuOlsCwg"
    },
    "snippet": {
      "publishedAt": "2023-02-17T14:59:57Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 35 // Total Body MetCon HR12WEEK 3.0",
      "description": "Today's total body MetCon workout is made up of two quick circuits using full body compound exercises to make the very most out ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VmJKuOlsCwg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VmJKuOlsCwg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VmJKuOlsCwg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-17T14:59:57Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6MaBvv35WSPAXfH5h2L2VktyXfI",
    "id": {
      "kind": "youtube#video",
      "videoId": "0arpNtjXuLY"
    },
    "snippet": {
      "publishedAt": "2022-10-09T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 9: Abs &amp; Core Strength // FIERCE 2.0",
      "description": "Ok team it's time to focus on that core strength! It's day 9 of my Fierce 2.0 program and we are about to set those ABS on fire!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0arpNtjXuLY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-09T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YjLXQW4hfhTNbIvXHJwzxOWTVCc",
    "id": {
      "kind": "youtube#video",
      "videoId": "u1imJo9x-00"
    },
    "snippet": {
      "publishedAt": "2023-01-11T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Foam Rolling #shorts",
      "description": "Foam rolling is a great way to ease sore muscles and aid in recovery. This type of session is a great way to end each workout or to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/u1imJo9x-00/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-11T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "im1-XU7q3ut9OV9DaqNqvl9apbU",
    "id": {
      "kind": "youtube#video",
      "videoId": "h7lDxRJLCXU"
    },
    "snippet": {
      "publishedAt": "2022-06-20T14:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body // Strength &amp; Cardio Workout",
      "description": "Today's total body session is a strength and cardio workout combining dumbbell exercises with cardio bursts for a solid full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/h7lDxRJLCXU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-06-20T14:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AbC-68TomwkvPsVeBMg_B6-Q5-0",
    "id": {
      "kind": "youtube#video",
      "videoId": "zZyosxYeTSE"
    },
    "snippet": {
      "publishedAt": "2022-10-11T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 11: Mobility &amp; Dynamic Stretch // FIERCE 2.0",
      "description": "For today's active recovery workout we have a Full Body MOBILITY & STRETCHING Routine. Sometimes the most challenging ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zZyosxYeTSE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-11T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_kxaYw5kCmy8hbvwmE_VXDwMJDk",
    "id": {
      "kind": "youtube#video",
      "videoId": "V_rjVzGf8m8"
    },
    "snippet": {
      "publishedAt": "2023-03-06T14:59:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 47 // Cardio &amp; Abs Workout HR12WEEK 3.0",
      "description": "Today's CARDIO & ABS workout will get that heart rate pumping and that six pack burning! Starting off with a high intensity cardio ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V_rjVzGf8m8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V_rjVzGf8m8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V_rjVzGf8m8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-06T14:59:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tiDFf8LmbnvD8ljmaMXu5ooothI",
    "id": {
      "kind": "youtube#video",
      "videoId": "asrcSwN3CR0"
    },
    "snippet": {
      "publishedAt": "2023-03-10T14:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 50 // NO REPEATS MetCon HR12WEEK 3.0",
      "description": "Team, I am so excited to wrap up week 10 with this No Repeats METCON workout! Metabolic training has been a big part of this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/asrcSwN3CR0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/asrcSwN3CR0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/asrcSwN3CR0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-10T14:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WLZ0ugWJK_GFwV4qWKkaIgvzLbQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "iYjrjCflJT8"
    },
    "snippet": {
      "publishedAt": "2023-02-21T14:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 38 // Lower Body MetCon HR12WEEK 3.0",
      "description": "Get ready for today's Lower Body MetCon workout! We are combining strength and cardio for one intense session that will target ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iYjrjCflJT8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iYjrjCflJT8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iYjrjCflJT8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-21T14:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ehOPPpY-eKdtJRqThOty6pNHyZw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qymz1xYuyYw"
    },
    "snippet": {
      "publishedAt": "2023-02-24T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 40 // No REPEATS MetCon HR12WEEK 3.0",
      "description": "Today's NO REPEATS MetCon is the perfect way to make sure that we hit all the major muscle groups this week. No repeats ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-24T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ovUTJjW3z2SepFBCPEACwSJtlt4",
    "id": {
      "kind": "youtube#video",
      "videoId": "9-Le2gJoIvY"
    },
    "snippet": {
      "publishedAt": "2022-10-20T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chest &amp; Back // Strength Supersets",
      "description": "Let's work on that upper body strength with todays Chest and Back Strength Supersets Workout! Using dumbbells only we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9-Le2gJoIvY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9-Le2gJoIvY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9-Le2gJoIvY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-20T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ViY1AR79CJ5oOuCPyU8hYtYi6Lw",
    "id": {
      "kind": "youtube#video",
      "videoId": "RAsoTHpsKuQ"
    },
    "snippet": {
      "publishedAt": "2022-10-28T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Glute Bridge #shorts",
      "description": "Let's learn how to do a Glute Bridge! #shorts #glutebridge #workouttips #exercise #form Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RAsoTHpsKuQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-28T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OIG88vQO723kdFI6XgdKm89Abcs",
    "id": {
      "kind": "youtube#video",
      "videoId": "6qdxayweUoo"
    },
    "snippet": {
      "publishedAt": "2023-02-26T15:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 41 // Full Body HIIT HR12WEEK 3.0",
      "description": "This Full Body HIIT is an intense, sweaty & powerful workout designed to help you build strength and endurance. The shorter work ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-26T15:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yb6qC2wIKOrVtX0taFI_mlgPjrE",
    "id": {
      "kind": "youtube#video",
      "videoId": "aedn4SSc3Ww"
    },
    "snippet": {
      "publishedAt": "2022-12-08T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Dumbbell Shoulder Press #shorts",
      "description": "Let's learn how to do a dumbbell shoulder press #workouttips #howto #shoulderpress #exercisetips #properform Get the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aedn4SSc3Ww/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-08T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z3sZ8KARFFQOo2EYObctKxTqGLU",
    "id": {
      "kind": "youtube#video",
      "videoId": "YvfvKbUHZ2Q"
    },
    "snippet": {
      "publishedAt": "2022-10-26T15:49:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do T-Push Ups #shorts",
      "description": "Let's learn how to do T-Push Ups! #shorts #form #workouttips #howto #exercise #fitness Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YvfvKbUHZ2Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-26T15:49:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Wl3_tVhuHIoN_nnkkxej7rIYF_s",
    "id": {
      "kind": "youtube#video",
      "videoId": "seCJCYmAr34"
    },
    "snippet": {
      "publishedAt": "2023-03-07T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 48 // Full Body Strength Workout HR12WEEK 3.0",
      "description": "Welcome to your full body STRENGTH workout. Resistance training is on the menu today and we are hitting all the major muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-07T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PCBOZO6ZEKgREJSybFyyFWdevrs",
    "id": {
      "kind": "youtube#video",
      "videoId": "j7BQ0Qu_eHY"
    },
    "snippet": {
      "publishedAt": "2023-03-05T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 46 // Full Body METCON HR12WEEK 3.0",
      "description": "Welcome to todays Full Body MetCon Workout and the start of week 10! What better way to start a new week than with a total body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-05T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vkodxHMk9camcqHB0Jfylt5bvNc",
    "id": {
      "kind": "youtube#video",
      "videoId": "7sJmxASUd-0"
    },
    "snippet": {
      "publishedAt": "2023-03-02T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 44 // No Equipment HIIT Cardio HR12WEEK 3.0",
      "description": "We are getting back into cardiovascular training with today's HIIT cardio workout! No equipment today and we are performing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-02T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OC0FHYj_hY3p9j5dyfmGHpbMhoM",
    "id": {
      "kind": "youtube#video",
      "videoId": "m8A1UClnWwE"
    },
    "snippet": {
      "publishedAt": "2023-02-23T14:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 39 // Core Strength Workout HR12WEEK 3.0",
      "description": "Core strength training is an important part of our workout routine. It helps us build a strong foundation for all of our daily activities.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-23T14:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jXPenmqrhKbepFDIAYxhf2_ZLlU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hJOYAb8oOc8"
    },
    "snippet": {
      "publishedAt": "2022-05-25T13:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Leg Workout // Strength &amp; Power",
      "description": "Welcome to today's HIIT Legs Workout!!! This lower body workout will help build strength and power in the legs. We will perform ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hJOYAb8oOc8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-05-25T13:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Pb15HUeeu8_Q2kFQdl2i_ZS_-ro",
    "id": {
      "kind": "youtube#video",
      "videoId": "euEi7TTmoIA"
    },
    "snippet": {
      "publishedAt": "2022-10-16T14:00:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "8MIN Total Body Tabata Workout",
      "description": "This saucy little Tabata Workout is quick but it's intense! Just two different Tabata circuits to get through - 4 rounds of each! Tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/euEi7TTmoIA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-16T14:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dE7fOE5QAvkI-D6nXFbL7cajfRY",
    "id": {
      "kind": "youtube#video",
      "videoId": "RdPHcoVsP84"
    },
    "snippet": {
      "publishedAt": "2022-10-01T13:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 1: Upper Body Push Workout // FIERCE 2.0",
      "description": "Welcome to day one of my new Fierce 2.0 program!! I've had so many requests for this one because you all loved my first fierce ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RdPHcoVsP84/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RdPHcoVsP84/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RdPHcoVsP84/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-01T13:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FLkSKcGGe8wbQ8o2tfyzvnPnGIg",
    "id": {
      "kind": "youtube#video",
      "videoId": "YQ30rw9A0kg"
    },
    "snippet": {
      "publishedAt": "2023-01-06T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 5 // Core + Full Body Mobility HR12WEEK 3.0",
      "description": "Today we are working on core strength and targeting the abs with a series of bodyweight exercises. No equipment needed as we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YQ30rw9A0kg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YQ30rw9A0kg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YQ30rw9A0kg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-06T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fSui7VZQbml9vtaQYrw6zZswtsA",
    "id": {
      "kind": "youtube#video",
      "videoId": "mczLKvVb3DE"
    },
    "snippet": {
      "publishedAt": "2023-02-27T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 42 // Abs &amp; Booty Workout HR12WEEK 3.0",
      "description": "Today's ABS & BOOTY Workout is working both the core and the glutes! We will be utilizing that mini booty band to add resistance ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-27T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z2gbf1ks4WH_1S44TMcvTKUNK_o",
    "id": {
      "kind": "youtube#video",
      "videoId": "ooIvVksvuqM"
    },
    "snippet": {
      "publishedAt": "2023-02-28T14:59:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 43 // Total Body METABOLIC Training HR12WEEK 3.0",
      "description": "Get ready for today's Total Body Metabolic Training! Through this MetCon series we will power through compound exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-28T14:59:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "81yfqVgSDGwMbbxe5xydiHTEVq0",
    "id": {
      "kind": "youtube#video",
      "videoId": "ljZA17b52FE"
    },
    "snippet": {
      "publishedAt": "2022-11-04T13:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do Reverse Lunges #shorts",
      "description": "Let's learn how to do Reverse Lunges with dumbbells! #workouttips #form #lunges #reverselunges #exercisetips #howto #shorts ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ljZA17b52FE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-04T13:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6uIo6KraWXU9SDxykDt8nPAR6Lo",
    "id": {
      "kind": "youtube#video",
      "videoId": "bqARwDzLixs"
    },
    "snippet": {
      "publishedAt": "2022-11-09T14:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40MIN No Equipment HIIT Workout",
      "description": "Let's HIIT it team! We have a 40MIN Full Body HIIT workout to get through today. NO equipment needed as we power through two ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/bqARwDzLixs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/bqARwDzLixs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/bqARwDzLixs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-09T14:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zetWq_ucWfQ0EUVBA1m98VTgoQI",
    "id": {
      "kind": "youtube#video",
      "videoId": "htqqk_uojIs"
    },
    "snippet": {
      "publishedAt": "2022-11-08T14:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do A Plank #shorts",
      "description": "Let's learn how to do a plank! #plank #workouttips #form #exercisetips #howto Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/htqqk_uojIs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-08T14:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "wjC8m1yJbGiMSrJAtML3msq4vHQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "jRcFX-d-dPw"
    },
    "snippet": {
      "publishedAt": "2022-11-01T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Do The Crab Dance #shorts",
      "description": "Let's lean how to do the Crab Dance Exercise! #workouttips #exercise #form #crabdance #howto Get the Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jRcFX-d-dPw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-01T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DipCvNY3hpUAvI-aMRQLock0q00",
    "id": {
      "kind": "youtube#video",
      "videoId": "PFxRdrY6KWQ"
    },
    "snippet": {
      "publishedAt": "2022-10-12T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 12: Booty &amp; Thigh Workout // FIERCE 2.0",
      "description": "It's day 12 of my Fierce 2.0 workout series and today we are targeting the lower body specifically the BOOTY & THIGHS. We are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PFxRdrY6KWQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PFxRdrY6KWQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PFxRdrY6KWQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-10-12T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HNT9XV9-HT54gcNPB9q84jdcInA",
    "id": {
      "kind": "youtube#video",
      "videoId": "RDbUuA8u7Ck"
    },
    "snippet": {
      "publishedAt": "2022-09-18T15:48:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "25MIN Total Body Workout // Supersets",
      "description": "Todays 25MIN Total Body workout is designed with a four different supersets that include exercises to target each major muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RDbUuA8u7Ck/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RDbUuA8u7Ck/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RDbUuA8u7Ck/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-18T15:48:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GRO2CJTpmHG3HGxG5JsrRy6I7Og",
    "id": {
      "kind": "youtube#video",
      "videoId": "7x94KpeBSoI"
    },
    "snippet": {
      "publishedAt": "2023-03-12T13:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 51 // Total Body HIIT HR12WEEK 3.0",
      "description": "This Total Body dumbbell HIIT has quick work periods plus quick rest breaks to help keep you on your toes and keep that body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7x94KpeBSoI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7x94KpeBSoI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7x94KpeBSoI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-12T13:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UIM4T7WlAX2bzU7XrxOg8IlgKcA",
    "id": {
      "kind": "youtube#video",
      "videoId": "XbM4ghTNP-I"
    },
    "snippet": {
      "publishedAt": "2022-04-06T12:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Knee Friendly HIIT Cardio // No Squats, No Lunges, No Jumping",
      "description": "This Knee Friendly HIIT Cardio Workout has No Squats, No Lunges and No Jumping so you can get in a serious sweat session ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XbM4ghTNP-I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XbM4ghTNP-I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XbM4ghTNP-I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-04-06T12:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WRKHA4-_q_AJX4owCOhI1Ep9Svw",
    "id": {
      "kind": "youtube#video",
      "videoId": "zoGkKqhdBOI"
    },
    "snippet": {
      "publishedAt": "2022-12-28T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HR12WEEK 3.0 / Free 12 Week Workout Series",
      "description": "The HR12WEEK 3.0 Begins January 2nd 2023!! Download your Calendar & Free Ultimate Training Guide Now ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zoGkKqhdBOI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-12-28T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ehOPPpY-eKdtJRqThOty6pNHyZw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qymz1xYuyYw"
    },
    "snippet": {
      "publishedAt": "2023-02-24T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 40 // No REPEATS MetCon HR12WEEK 3.0",
      "description": "Today's NO REPEATS MetCon is the perfect way to make sure that we hit all the major muscle groups this week. No repeats ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qymz1xYuyYw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-24T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OIG88vQO723kdFI6XgdKm89Abcs",
    "id": {
      "kind": "youtube#video",
      "videoId": "6qdxayweUoo"
    },
    "snippet": {
      "publishedAt": "2023-02-26T15:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 41 // Full Body HIIT HR12WEEK 3.0",
      "description": "This Full Body HIIT is an intense, sweaty & powerful workout designed to help you build strength and endurance. The shorter work ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6qdxayweUoo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-26T15:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hvom2_wVo2KZTiB0mMVBw0oT87M",
    "id": {
      "kind": "youtube#video",
      "videoId": "-fqIeQ6dBTQ"
    },
    "snippet": {
      "publishedAt": "2022-11-30T14:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51 Booty Bootcamp",
      "description": "Today's Booty Bootcamp is a serious glute workout that uses dumbbells, a stability ball and resistance bands for a killer booty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-fqIeQ6dBTQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-fqIeQ6dBTQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-fqIeQ6dBTQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-30T14:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GJ3ySvt7qaqb6lCl7CzCzlxzV3M",
    "id": {
      "kind": "youtube#video",
      "videoId": "VQC0UyHSRDw"
    },
    "snippet": {
      "publishedAt": "2023-01-01T14:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 1 // Full Body MetCon Workout HR12WEEK 3.0",
      "description": "The HR12WEEK 3.0 Free Workout Series starts now! Day 1 is a full body MetCon and it is the perfect way to get this party started!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VQC0UyHSRDw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VQC0UyHSRDw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VQC0UyHSRDw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-01-01T14:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PCBOZO6ZEKgREJSybFyyFWdevrs",
    "id": {
      "kind": "youtube#video",
      "videoId": "j7BQ0Qu_eHY"
    },
    "snippet": {
      "publishedAt": "2023-03-05T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 46 // Full Body METCON HR12WEEK 3.0",
      "description": "Welcome to todays Full Body MetCon Workout and the start of week 10! What better way to start a new week than with a total body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j7BQ0Qu_eHY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-05T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OC0FHYj_hY3p9j5dyfmGHpbMhoM",
    "id": {
      "kind": "youtube#video",
      "videoId": "m8A1UClnWwE"
    },
    "snippet": {
      "publishedAt": "2023-02-23T14:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 39 // Core Strength Workout HR12WEEK 3.0",
      "description": "Core strength training is an important part of our workout routine. It helps us build a strong foundation for all of our daily activities.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/m8A1UClnWwE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-23T14:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vkodxHMk9camcqHB0Jfylt5bvNc",
    "id": {
      "kind": "youtube#video",
      "videoId": "7sJmxASUd-0"
    },
    "snippet": {
      "publishedAt": "2023-03-02T14:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 44 // No Equipment HIIT Cardio HR12WEEK 3.0",
      "description": "We are getting back into cardiovascular training with today's HIIT cardio workout! No equipment today and we are performing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7sJmxASUd-0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-02T14:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Wl3_tVhuHIoN_nnkkxej7rIYF_s",
    "id": {
      "kind": "youtube#video",
      "videoId": "seCJCYmAr34"
    },
    "snippet": {
      "publishedAt": "2023-03-07T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 48 // Full Body Strength Workout HR12WEEK 3.0",
      "description": "Welcome to your full body STRENGTH workout. Resistance training is on the menu today and we are hitting all the major muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/seCJCYmAr34/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-07T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UAHCC6uJ2PF0WAW4F0N2tgDujE4",
    "id": {
      "kind": "youtube#video",
      "videoId": "-dmGCc7jLyw"
    },
    "snippet": {
      "publishedAt": "2017-07-25T04:47:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Easy Breakfast Recipe: Baked Egg &amp; Veggies",
      "description": "I'm always looking for healthy breakfast recipes that are easy and help save time in the morning! This baked egg & veggie recipe ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-dmGCc7jLyw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-dmGCc7jLyw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-dmGCc7jLyw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-25T04:47:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fSui7VZQbml9vtaQYrw6zZswtsA",
    "id": {
      "kind": "youtube#video",
      "videoId": "mczLKvVb3DE"
    },
    "snippet": {
      "publishedAt": "2023-02-27T14:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 42 // Abs &amp; Booty Workout HR12WEEK 3.0",
      "description": "Today's ABS & BOOTY Workout is working both the core and the glutes! We will be utilizing that mini booty band to add resistance ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mczLKvVb3DE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-27T14:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Z2gbf1ks4WH_1S44TMcvTKUNK_o",
    "id": {
      "kind": "youtube#video",
      "videoId": "ooIvVksvuqM"
    },
    "snippet": {
      "publishedAt": "2023-02-28T14:59:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 43 // Total Body METABOLIC Training HR12WEEK 3.0",
      "description": "Get ready for today's Total Body Metabolic Training! Through this MetCon series we will power through compound exercises to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ooIvVksvuqM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-02-28T14:59:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QKk_ebvdcVpia7NYuCktOxGDxK0",
    "id": {
      "kind": "youtube#video",
      "videoId": "BS9BEZDz7l8"
    },
    "snippet": {
      "publishedAt": "2023-03-16T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 54 // No Equipment Cardio HR12WEEK 3.0",
      "description": "This fat burning CARDIO session will get that heart pumping and will have you sweating in no time! No equipment needed - we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BS9BEZDz7l8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BS9BEZDz7l8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BS9BEZDz7l8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-16T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kWLlB7bf3pix0jjJS-KANZJ5rcY",
    "id": {
      "kind": "youtube#video",
      "videoId": "AdPjZZQdQOc"
    },
    "snippet": {
      "publishedAt": "2023-03-09T14:59:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 49 // TABATA + Glute Finisher HR12WEEK 3.0",
      "description": "Another good old Tabata to work that cardiovascular system and keep us on our toes!! Once we power through the Tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AdPjZZQdQOc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AdPjZZQdQOc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AdPjZZQdQOc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-09T14:59:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tZU4N5Y7bPTnnB8g0cmLUweKIhA",
    "id": {
      "kind": "youtube#video",
      "videoId": "kpWnPpZc1Qs"
    },
    "snippet": {
      "publishedAt": "2023-03-03T14:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 45 // POWER HOUR 60min Full Body Workout HR12WEEK 3.0",
      "description": "This 60MIN Full Body Workout is going to put you to the test and just may push you out of that comfort zone! It's all about ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kpWnPpZc1Qs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kpWnPpZc1Qs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kpWnPpZc1Qs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-03T14:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Yoxaz0OJjtCCRIlktGarf7jqBeo",
    "id": {
      "kind": "youtube#video",
      "videoId": "dPi0K30OJTk"
    },
    "snippet": {
      "publishedAt": "2017-08-03T07:01:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HOW TO GET A SIX PACK - 12 minute At Home Workout",
      "description": "Visit: https://www.heatherrobertson.com Wondering how to get a six pack? Well doing a variety of ab exercises will definitely help ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dPi0K30OJTk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dPi0K30OJTk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dPi0K30OJTk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-08-03T07:01:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KpwbmAapekejKNqmpZKqBgsx0XU",
    "id": {
      "kind": "youtube#video",
      "videoId": "3ELbsd5tJjE"
    },
    "snippet": {
      "publishedAt": "2019-09-18T01:10:41Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Advanced 4 Minute FULL BODY TABATA Workout // No Equipment",
      "description": "Today's 4 minute Full Body Tabata workout may be short but it's going to be a sweat fest my friends! lol 8 different Tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3ELbsd5tJjE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3ELbsd5tJjE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3ELbsd5tJjE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-09-18T01:10:41Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FOUDqXMXLNxl2f-iCPMvDmge8NI",
    "id": {
      "kind": "youtube#video",
      "videoId": "U8P1-uicVCs"
    },
    "snippet": {
      "publishedAt": "2018-10-03T23:45:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "5 Minute SixPack ABS Challenge",
      "description": "Visit: https://www.heatherrobertson.com We all know you can't achieve a sixpack in five minutes but we can target all the separate ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U8P1-uicVCs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U8P1-uicVCs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U8P1-uicVCs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-10-03T23:45:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IVdGIq5PFI9JXSN6HB9Rtz0DCPE",
    "id": {
      "kind": "youtube#video",
      "videoId": "5-ig14lZUn8"
    },
    "snippet": {
      "publishedAt": "2018-11-01T00:48:43Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer PLANK CHALLENGE // 6 Minute Core Workout",
      "description": "Visit: https://www.heatherrobertson.com Shop my favorite protein, supplements + more: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5-ig14lZUn8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5-ig14lZUn8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5-ig14lZUn8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-11-01T00:48:43Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MaAcm6p45nbMGYdk5kcCS-tEAlE",
    "id": {
      "kind": "youtube#video",
      "videoId": "9giIbVUqPIQ"
    },
    "snippet": {
      "publishedAt": "2023-03-17T13:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 55 // Power Hour! 60Min Workout HR12WEEK 3.0",
      "description": "It's time for another Power Hour Workout! We are hitting it hard with a 60Min head to toe sweat sesh loaded with total body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9giIbVUqPIQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9giIbVUqPIQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9giIbVUqPIQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-17T13:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IgF38o6PzIre40BxHsjRHWhHAvU",
    "id": {
      "kind": "youtube#video",
      "videoId": "TfOqIPVDH7c"
    },
    "snippet": {
      "publishedAt": "2020-02-18T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 2 //Full Body HIIT Cardio + Abs",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TfOqIPVDH7c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TfOqIPVDH7c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TfOqIPVDH7c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-18T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "448TmOP1-0vUMPVyGOPT9_Su_00",
    "id": {
      "kind": "youtube#video",
      "videoId": "JMRfGPLGB0w"
    },
    "snippet": {
      "publishedAt": "2018-06-09T02:12:29Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Weighted Abs - SIX PACK Workout From Home",
      "description": "Visit: https://www.heatherrobertson.com Join me for today's weighted abs workout! In this 5 minute workout we are going to get fit ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JMRfGPLGB0w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JMRfGPLGB0w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JMRfGPLGB0w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-06-09T02:12:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nSgFiUAndm86tSrT7HF2iHWGlvY",
    "id": {
      "kind": "youtube#video",
      "videoId": "24mPkoLzPeA"
    },
    "snippet": {
      "publishedAt": "2020-01-23T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 3 Day 4 // HIIT Cardio + Strength// + Abs challenge!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/24mPkoLzPeA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/24mPkoLzPeA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/24mPkoLzPeA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-23T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vCEcHtrPlwkD6qjC3x-ErKmfAkg",
    "id": {
      "kind": "youtube#video",
      "videoId": "9lRWcyZv_9w"
    },
    "snippet": {
      "publishedAt": "2020-03-10T03:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 10 Day 2 // TABATA + BOOTY Building Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9lRWcyZv_9w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9lRWcyZv_9w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9lRWcyZv_9w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-10T03:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "K2J0JrbNb8S3YHBcgWnR_SITm9Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "7s8lzYNFOIU"
    },
    "snippet": {
      "publishedAt": "2023-03-20T13:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 57 // No Repeats CARDIO HR12WEEK 3.0",
      "description": "It's CARDIO TIME! This No Repeats Cardio workout is a serious sweat fest that your cardiovascular system will thank you for!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7s8lzYNFOIU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7s8lzYNFOIU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7s8lzYNFOIU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-20T13:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "F1AjovOmvOreC7RE1BAhY6_uJzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "tYoytDeZR7U"
    },
    "snippet": {
      "publishedAt": "2020-02-04T04:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 5 Day 2 // Full Body HIIT Workout + Abs Finisher",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tYoytDeZR7U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tYoytDeZR7U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tYoytDeZR7U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-04T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9-JPN_LeUrPGtCPNxSRhdU2luRs",
    "id": {
      "kind": "youtube#video",
      "videoId": "sK26KnYV7cw"
    },
    "snippet": {
      "publishedAt": "2020-01-29T04:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 4 Day 3 // Full Body Strength + Cardio Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sK26KnYV7cw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sK26KnYV7cw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sK26KnYV7cw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-29T04:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xl0QBMS8ky0mZSkctPfuAR-EcBc",
    "id": {
      "kind": "youtube#video",
      "videoId": "tbFZx_1mZD4"
    },
    "snippet": {
      "publishedAt": "2020-02-06T04:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 5 Day 4 // Body-weight Tabata Workout // PLUS Core + Glutes!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tbFZx_1mZD4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tbFZx_1mZD4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tbFZx_1mZD4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-06T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Cg9x-HlqXdVmpu73wPLOluxW2FA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ev4fkzuOqV4"
    },
    "snippet": {
      "publishedAt": "2020-01-31T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 4 Day 5 // Killer Total Body Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ev4fkzuOqV4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ev4fkzuOqV4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ev4fkzuOqV4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-31T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iNC_rb4sUwEqfIQhMh6w-rDKZ-M",
    "id": {
      "kind": "youtube#video",
      "videoId": "AS7t3doAEdw"
    },
    "snippet": {
      "publishedAt": "2021-11-02T13:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 3 // THE DAILY10: 10 Min Cardio &amp; Abs Workout",
      "description": "Get ready for a quick 10 minute Cardio and Abs Workout! We're going to get that heart rate up with a series of no equipment, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AS7t3doAEdw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AS7t3doAEdw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AS7t3doAEdw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-02T13:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IEEkzM3d4Cct-qftwav29ICIeAs",
    "id": {
      "kind": "youtube#video",
      "videoId": "D6hWFP4cf0I"
    },
    "snippet": {
      "publishedAt": "2020-01-21T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 3 Day 2 // Intense HIIT Cardio + Abs Workout",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D6hWFP4cf0I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D6hWFP4cf0I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D6hWFP4cf0I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-21T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vqcKBGl34Ytee9ebRWsAHPyCQtA",
    "id": {
      "kind": "youtube#video",
      "videoId": "QMKWMquwbTk"
    },
    "snippet": {
      "publishedAt": "2023-03-21T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 58 // Full Body Strength Workout HR12WEEK 3.0",
      "description": "Time to dive into a Full Body STRENGTH workout! We have had a number of strength workouts throughout the past 12 weeks and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QMKWMquwbTk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QMKWMquwbTk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QMKWMquwbTk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-21T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ez3rVFxEzMlm9aqjInk2zyD_LMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "wDc1bzXpgu8"
    },
    "snippet": {
      "publishedAt": "2020-02-27T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 4 //Brutal TABATA Workout + ABS and BOOTY",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wDc1bzXpgu8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wDc1bzXpgu8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wDc1bzXpgu8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-27T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tpks274xinswHBqKI0YJ-LmI_3Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "D4AXNcm9g14"
    },
    "snippet": {
      "publishedAt": "2021-11-01T13:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 2 // THE DAILY10: 10 Min Lower Body HIIT Workout",
      "description": "Let's HIIT it! Today's quick 10 minute workout is a no repeats, lower body HIIT workout sure to have you feeling the burn! #Daily10 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D4AXNcm9g14/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D4AXNcm9g14/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D4AXNcm9g14/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-01T13:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sst7xoethpWIRqzndX0l68aRnd4",
    "id": {
      "kind": "youtube#video",
      "videoId": "M-b9oQ-8FTU"
    },
    "snippet": {
      "publishedAt": "2020-02-19T04:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 3 //At Home Lean Legs Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M-b9oQ-8FTU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M-b9oQ-8FTU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M-b9oQ-8FTU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-19T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sRu_dAPm9-xOl9V7R8qW0hDcd2I",
    "id": {
      "kind": "youtube#video",
      "videoId": "GoXGOtz6ft0"
    },
    "snippet": {
      "publishedAt": "2019-01-30T22:20:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 Minute HIIT AB WORKOUT // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com Time to HIIT those abs ;) Today's 10 minute HIIT ab workout includes some of my favorite ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GoXGOtz6ft0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GoXGOtz6ft0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GoXGOtz6ft0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-01-30T22:20:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "muW7pH5LU3zMyp-qnCPi9K1RGwc",
    "id": {
      "kind": "youtube#video",
      "videoId": "shkL0WR2erw"
    },
    "snippet": {
      "publishedAt": "2020-02-03T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 5 Day 1 // Upper Body Strength Workout: Chest + Shoulders",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/shkL0WR2erw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/shkL0WR2erw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/shkL0WR2erw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-03T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W-VB64vCSeDfh_VDS8X9sAYBGCA",
    "id": {
      "kind": "youtube#video",
      "videoId": "4lOmsqubmEI"
    },
    "snippet": {
      "publishedAt": "2020-02-05T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 5 Day 3 // Legs + Biceps Strength Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4lOmsqubmEI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4lOmsqubmEI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4lOmsqubmEI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-05T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5G8B4Kf_5LTDXprppFCvxqQNKzM",
    "id": {
      "kind": "youtube#video",
      "videoId": "r1f8y_sqxc4"
    },
    "snippet": {
      "publishedAt": "2020-02-07T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 5 Day 5 // Sculpted Back + Triceps Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/r1f8y_sqxc4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/r1f8y_sqxc4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/r1f8y_sqxc4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-07T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VlRTAf5GkhsZyGLGLXxbHCJE3GQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "_ozkbpstWS8"
    },
    "snippet": {
      "publishedAt": "2020-03-05T04:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 4 //Killer HIIT Cardio + Abs Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-05T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tD6m-lBtt2ff53NlpTgQ9WmcZXY",
    "id": {
      "kind": "youtube#video",
      "videoId": "e6b9YkzTZ1A"
    },
    "snippet": {
      "publishedAt": "2020-02-14T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 6 Day 5 // Upper Body Strength // Back + Triceps",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/e6b9YkzTZ1A/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/e6b9YkzTZ1A/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/e6b9YkzTZ1A/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-14T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zKgw8x6jnychEhqgeeQcgBZeNR4",
    "id": {
      "kind": "youtube#video",
      "videoId": "DU9X-xMqg8s"
    },
    "snippet": {
      "publishedAt": "2020-03-11T03:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 10 Day 3 //NO REPEAT HIIT // Total Body HIIT Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DU9X-xMqg8s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DU9X-xMqg8s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DU9X-xMqg8s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-11T03:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6PqXczY2gXwUSyQFwRcrzJpD5so",
    "id": {
      "kind": "youtube#video",
      "videoId": "-nkd3xT2Mr0"
    },
    "snippet": {
      "publishedAt": "2018-10-14T02:36:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15 Minute BOOTY BAND Workout for STRONG Glutes",
      "description": "Visit: https://www.heatherrobertson.com Today's booty band workout is going to help you build strong glutes and thighs and all ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-nkd3xT2Mr0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-nkd3xT2Mr0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-nkd3xT2Mr0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-10-14T02:36:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "giXNB4B53c_V5tPz_vJb-JR77w8",
    "id": {
      "kind": "youtube#video",
      "videoId": "LnJ021gf4co"
    },
    "snippet": {
      "publishedAt": "2020-03-24T03:00:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 2 //Fierce TABATA // Plus LEGS + BOOTY Workout!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-24T03:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-vt5jcWuaLaVUlMVGAwjnCoo5I4",
    "id": {
      "kind": "youtube#video",
      "videoId": "7VlVZ6Is3yE"
    },
    "snippet": {
      "publishedAt": "2020-03-02T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 1 //INTENSE Full Body HIIT Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7VlVZ6Is3yE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7VlVZ6Is3yE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7VlVZ6Is3yE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-02T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XKZJBZP7_qkYv-gfVZd8G6v-ruI",
    "id": {
      "kind": "youtube#video",
      "videoId": "0qER2oJUa6E"
    },
    "snippet": {
      "publishedAt": "2020-02-24T03:58:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 1 //SCULPTED ARMS Workout (Biceps, Triceps + Shoulders)",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0qER2oJUa6E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0qER2oJUa6E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0qER2oJUa6E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-24T03:58:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hbac6LCSGObFyzvDzjAde55BvoY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Z935wdY3hCc"
    },
    "snippet": {
      "publishedAt": "2020-03-12T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 10 Day 4 //Crazy At-Home CARDIO + ABS Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-12T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nR4nnEOOIzt6ZPqGco23UCriHnU",
    "id": {
      "kind": "youtube#video",
      "videoId": "NW19cOyfvBs"
    },
    "snippet": {
      "publishedAt": "2020-03-23T03:00:40Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 1 //ARM TONING Workout // Upper Body HIIT",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NW19cOyfvBs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NW19cOyfvBs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NW19cOyfvBs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-23T03:00:40Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2ZeDVK3enBsrZBenaupUACGzDjo",
    "id": {
      "kind": "youtube#video",
      "videoId": "H8ufVdb0BBA"
    },
    "snippet": {
      "publishedAt": "2020-01-24T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 3 Day 5 // Full Body Circuit Workout with Weights",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H8ufVdb0BBA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H8ufVdb0BBA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H8ufVdb0BBA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-24T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CGxVqG4I56RJ-w0FK9uni1o0Fj4",
    "id": {
      "kind": "youtube#video",
      "videoId": "N7tD_DK4gG8"
    },
    "snippet": {
      "publishedAt": "2017-07-19T21:00:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Quick Daily Stretching Routine",
      "description": "This full body stretching routine is a great way to start your day or end your workout! Stretch out sore muscles, improve flexibility ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/N7tD_DK4gG8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/N7tD_DK4gG8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/N7tD_DK4gG8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-07-19T21:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mmjyHCaMC23mLiwEANyFxCJZBTk",
    "id": {
      "kind": "youtube#video",
      "videoId": "AvRufO6owEw"
    },
    "snippet": {
      "publishedAt": "2020-01-28T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 4 Day 2 // No Repeats HIIT Workout // Full Body Circuit with Weights",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AvRufO6owEw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AvRufO6owEw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AvRufO6owEw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-28T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GlGlXiU1aJ5kK4ipNj4bN7TBvBw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ai-_BXQTQxw"
    },
    "snippet": {
      "publishedAt": "2020-02-28T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 5 //At Home Upper Body Workout // CHEST + BACK",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-28T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YyESqmjoCubHzQUkhMXoTK8-Wvk",
    "id": {
      "kind": "youtube#video",
      "videoId": "80svoVWB3L4"
    },
    "snippet": {
      "publishedAt": "2020-02-21T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 5 // At Home Chest + Back Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-21T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dDYoMruxKalW8Vyhv6Htcm05u1I",
    "id": {
      "kind": "youtube#video",
      "videoId": "r5_AI7zrB44"
    },
    "snippet": {
      "publishedAt": "2020-02-12T04:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 6 Day 3 // Legs + Biceps //Superset Strength Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/r5_AI7zrB44/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/r5_AI7zrB44/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/r5_AI7zrB44/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-12T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tYLlWjEWZajZRpEDqhpNsGjpGyg",
    "id": {
      "kind": "youtube#video",
      "videoId": "ZEBFNBpi0Nk"
    },
    "snippet": {
      "publishedAt": "2021-11-03T13:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 4 // THE DAILY10: 10 Min HIIT Yoga Workout",
      "description": "This 10 minute Yoga inspired HIIT workout is great for mobility, flexibility and building strength. Get that body moving and feeling ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ZEBFNBpi0Nk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ZEBFNBpi0Nk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ZEBFNBpi0Nk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-03T13:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "a-QIgxdTyPr23a_17sc4ruyUHAg",
    "id": {
      "kind": "youtube#video",
      "videoId": "r0LJ5-QiXUE"
    },
    "snippet": {
      "publishedAt": "2019-05-01T01:48:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 Minute Flat Tummy // TABATA Abs Workout",
      "description": "Visit: https://www.heatherrobertson.com We all know a flat tummy truly comes from following a clean, balanced diet but we can ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/r0LJ5-QiXUE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/r0LJ5-QiXUE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/r0LJ5-QiXUE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-05-01T01:48:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IIjgy9PpFA082dk_lp8KmZdtu9s",
    "id": {
      "kind": "youtube#video",
      "videoId": "CTHnETqUq1U"
    },
    "snippet": {
      "publishedAt": "2021-11-07T14:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 8 // THE DAILY10: 10 Min Strong Core Workout",
      "description": "Today's 10 minute ab workout is all about building core strength. No repeats and no weights needed as you power through a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CTHnETqUq1U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CTHnETqUq1U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CTHnETqUq1U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-07T14:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zt6znBxqSmr6I_P6LdjyefZPsKQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ij1zWuJPH-4"
    },
    "snippet": {
      "publishedAt": "2020-01-22T03:53:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 3 Day 3 // Total Body Workout // Upper + Lower Body Exercises",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ij1zWuJPH-4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ij1zWuJPH-4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ij1zWuJPH-4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-22T03:53:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZfxHma55CWjaPVAwC4musxHdli4",
    "id": {
      "kind": "youtube#video",
      "videoId": "qFgc3zJx5Pw"
    },
    "snippet": {
      "publishedAt": "2020-02-20T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 4 //Intense Total Body TABATA Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-20T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "g03KZhhYkFO_W_3kj-HuUv0Jye4",
    "id": {
      "kind": "youtube#video",
      "videoId": "_leAtY3Wqgg"
    },
    "snippet": {
      "publishedAt": "2020-01-16T04:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 2 Day 4 // Full Body HIIT Workout + Abs (With Weights)",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_leAtY3Wqgg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_leAtY3Wqgg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_leAtY3Wqgg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-16T04:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kptRGsWwuixcB3MiHxMNqVGp45k",
    "id": {
      "kind": "youtube#video",
      "videoId": "omQkfn-l73o"
    },
    "snippet": {
      "publishedAt": "2020-03-25T03:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 3 //Full Body FAT BURNING HIIT + Plank Challenge!!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-25T03:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zdhiCSneiWv1k-iBIercdKgxRm8",
    "id": {
      "kind": "youtube#video",
      "videoId": "0nAQL1EN-x4"
    },
    "snippet": {
      "publishedAt": "2020-02-26T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 3 //At Home LEG WORKOUT // Sculpt + Tone",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-26T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zMoDm9jCY9oPWjm22x9dkr2vbGc",
    "id": {
      "kind": "youtube#video",
      "videoId": "MtjvwiMp2Vc"
    },
    "snippet": {
      "publishedAt": "2020-01-17T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 2 Day 5 // Full Body Workout - Strength + Cardio",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MtjvwiMp2Vc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MtjvwiMp2Vc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MtjvwiMp2Vc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-17T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yG7Qw9Nb9Mue7YpPxSn3bN89IRo",
    "id": {
      "kind": "youtube#video",
      "videoId": "iMmge2OrC-M"
    },
    "snippet": {
      "publishedAt": "2020-01-15T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 2 Day 3 // Total Body Workout with Weights",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iMmge2OrC-M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iMmge2OrC-M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iMmge2OrC-M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-15T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "a8DZ6gBEQZkVdDUl4e6WY-phZY0",
    "id": {
      "kind": "youtube#video",
      "videoId": "5OmE0Uu5jec"
    },
    "snippet": {
      "publishedAt": "2020-01-20T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 3 Day 1 // Complete Full Body Circuit Workout // Strength + Cardio",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5OmE0Uu5jec/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5OmE0Uu5jec/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5OmE0Uu5jec/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-20T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rtcKInus6a6FTxYYY6sswI0Etfo",
    "id": {
      "kind": "youtube#video",
      "videoId": "eKxoD2u3Eco"
    },
    "snippet": {
      "publishedAt": "2020-03-13T03:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 10 Day 5 //Killer LEG DAY WORKOUT!!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eKxoD2u3Eco/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eKxoD2u3Eco/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eKxoD2u3Eco/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-13T03:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mVIFxdffC-srJDZwXD4qIeKGVek",
    "id": {
      "kind": "youtube#video",
      "videoId": "6zxwQ4RPfvs"
    },
    "snippet": {
      "publishedAt": "2021-11-10T14:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 11 // THE DAILY10: 10 Min Low Impact HIIT Workout",
      "description": "Get ready for a quick 10 minute, Low Impact HIIT Workout! We are using light dumbbells for added resistance and intensity and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-10T14:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "26_K6iYDqVTf5d7lNgNRJ1XGf2k",
    "id": {
      "kind": "youtube#video",
      "videoId": "uJb29Jd-hAU"
    },
    "snippet": {
      "publishedAt": "2018-07-13T00:54:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15 Minute FAT BURNING HIIT: No Equipment At Home Workout",
      "description": "Get fit with this 15 minute fat burning HIIT workout. No equipment is needed so you can workout from home - or from anywhere!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uJb29Jd-hAU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uJb29Jd-hAU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uJb29Jd-hAU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-07-13T00:54:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VfXX__5-HEnH8kYCAENBMKGAV7M",
    "id": {
      "kind": "youtube#video",
      "videoId": "KN50BT11PSg"
    },
    "snippet": {
      "publishedAt": "2018-09-07T18:03:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 Minute Shoulder Workout for Beginners",
      "description": "Visit: https://www.heatherrobertson.com Today's shoulder workout is great for beginners or if you are looking to improve shoulder ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KN50BT11PSg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KN50BT11PSg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KN50BT11PSg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-09-07T18:03:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QNiLhjT3q-3wwrmPYu9qiChO6sQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "0kWs7fo9SiE"
    },
    "snippet": {
      "publishedAt": "2020-03-26T03:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 4 // LEG DAY Workout // Lower Body HIIT",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-26T03:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AupmhaL3e5_Ve1LDgBGdfFwDXmc",
    "id": {
      "kind": "youtube#video",
      "videoId": "U4stqUC-4U0"
    },
    "snippet": {
      "publishedAt": "2020-02-11T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 6 Day 2 // No Repeat Total Body HIIT Workout!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U4stqUC-4U0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U4stqUC-4U0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U4stqUC-4U0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-11T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TlxuF6PtRXlzxMNIH0z7Tl0nmIM",
    "id": {
      "kind": "youtube#video",
      "videoId": "1Mgq1zU3Raw"
    },
    "snippet": {
      "publishedAt": "2020-03-17T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 2 //TABATA + Lower Body Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-17T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JhqGDzsnf5Ab0sPmHky9qiFO-Q8",
    "id": {
      "kind": "youtube#video",
      "videoId": "tgZgWYtkoek"
    },
    "snippet": {
      "publishedAt": "2021-11-06T13:00:24Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 7 // THE DAILY10: 10 Min Lower Body HIIT Workout",
      "description": "This 10 minute Lower Body HIIT Workout will target the legs and glutes with a series of HIIT Exercises designed for strength, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-06T13:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WNCtXVxgcqABzNvy7-as2hSAgww",
    "id": {
      "kind": "youtube#video",
      "videoId": "CDdaY7Pp1KM"
    },
    "snippet": {
      "publishedAt": "2020-03-27T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 5 //Epic HIIT Workout! NO REPEATS!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-27T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "MyZq51OM6fN-N4hjnlTww4GDRI4",
    "id": {
      "kind": "youtube#video",
      "videoId": "zfuqM-lxEeI"
    },
    "snippet": {
      "publishedAt": "2020-02-25T04:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 2 // No Repeat HIIT Workout! FULL BODY HIIT + ABS",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zfuqM-lxEeI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zfuqM-lxEeI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zfuqM-lxEeI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-25T04:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rEbhC8su6bKVHi_2ss5pp5Nwybg",
    "id": {
      "kind": "youtube#video",
      "videoId": "vbL3CenpIUY"
    },
    "snippet": {
      "publishedAt": "2020-03-19T03:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 4 //Full Body HIIT with Weights //PLUS Ab Finisher!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-19T03:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TCjKTTHaIrKHmq0qRYYWm41F5Ls",
    "id": {
      "kind": "youtube#video",
      "videoId": "6J-PJoZJt4k"
    },
    "snippet": {
      "publishedAt": "2020-02-13T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 6 Day 4 // Intense TABATA + Booty Workout!!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6J-PJoZJt4k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6J-PJoZJt4k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6J-PJoZJt4k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-13T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h8-kH7fTowJSDUw4SaG_o2nyvy0",
    "id": {
      "kind": "youtube#video",
      "videoId": "F8Hk2_llp8M"
    },
    "snippet": {
      "publishedAt": "2019-04-21T00:49:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Quick ABS + GLUTES Workout // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com Two workouts in one today with this quick Abs and Glutes workout! This is a great workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-21T00:49:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c0QR9L6mB94TWTHFY5CNhSpnn6g",
    "id": {
      "kind": "youtube#video",
      "videoId": "DaTo1OLnleU"
    },
    "snippet": {
      "publishedAt": "2020-03-20T03:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 5 //Full Body HIIT // Cardio + Strength At Home Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-20T03:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-Ek1XUb-JkTCeAWP_SLUEjfL1l0",
    "id": {
      "kind": "youtube#video",
      "videoId": "HyX2vU59Npw"
    },
    "snippet": {
      "publishedAt": "2017-08-24T19:16:35Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To Get Toned Arms - At Home Workout",
      "description": "Visit: https://www.heatherrobertson.com Learn how to get toned arms with this quick at home workout! All you need is a couple of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HyX2vU59Npw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HyX2vU59Npw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HyX2vU59Npw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-08-24T19:16:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1FfBK5P-1GS5-0nu0L07V2WCH1E",
    "id": {
      "kind": "youtube#video",
      "videoId": "GDY8g5KME9E"
    },
    "snippet": {
      "publishedAt": "2019-02-19T01:15:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "15 Minute Full Body HIIT + ABS Workout",
      "description": "Visit: https://www.heatherrobertson.com 15 Minute Full Body HIIT and ABS Workout // We are starting things off today with a 15 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GDY8g5KME9E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GDY8g5KME9E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GDY8g5KME9E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-02-19T01:15:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "45GPYmeI-RUkJb6qkNDrNnyHOZM",
    "id": {
      "kind": "youtube#video",
      "videoId": "jb3npwXsrpA"
    },
    "snippet": {
      "publishedAt": "2020-03-03T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 2 //TABATA, ABS + BOOTY Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-03T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YQib4Uh3-3ICZq-XdJ6V9XYoDTU",
    "id": {
      "kind": "youtube#video",
      "videoId": "moc4fb2IVuU"
    },
    "snippet": {
      "publishedAt": "2021-04-18T14:54:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "12 Minute TABATA Workout // Total Body Burn!",
      "description": "Feeling the burn all over after this quick 12 minute Tabata workout! Sometimes all you need is an quick and intense sweat sesh.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/moc4fb2IVuU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/moc4fb2IVuU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/moc4fb2IVuU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-18T14:54:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "nuyZKBtmsXVHakr83otwf6H9cBA",
    "id": {
      "kind": "youtube#video",
      "videoId": "G09rBFAh3rI"
    },
    "snippet": {
      "publishedAt": "2020-03-16T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 1 //AT-HOME Full Body HIIT Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/G09rBFAh3rI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/G09rBFAh3rI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/G09rBFAh3rI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-16T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OkfY--_FGCymF9JYEqJ1cntr1LE",
    "id": {
      "kind": "youtube#video",
      "videoId": "17brX8I_yts"
    },
    "snippet": {
      "publishedAt": "2020-03-04T04:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 3 //TOTAL BODY HIIT Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/17brX8I_yts/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/17brX8I_yts/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/17brX8I_yts/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-04T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hl6P3rlJenvU7i5AjEA_wnu4I98",
    "id": {
      "kind": "youtube#video",
      "videoId": "QRxy56s8e5Y"
    },
    "snippet": {
      "publishedAt": "2019-05-21T06:59:45Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body HIIT WORKOUT // With Weights",
      "description": "Visit: https://www.heatherrobertson.com Today's Total Body HIIT Workout with weights is going to give you a killer full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QRxy56s8e5Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QRxy56s8e5Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QRxy56s8e5Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-05-21T06:59:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kRDHh9xMtriZuAmmZ8aTdI0uGzI",
    "id": {
      "kind": "youtube#video",
      "videoId": "NiZWzQttuYw"
    },
    "snippet": {
      "publishedAt": "2020-01-13T03:41:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 2 Day 1 // Full Body Workout with Weights // Strength + Cardio",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NiZWzQttuYw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NiZWzQttuYw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NiZWzQttuYw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-13T03:41:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "covNf01L6kULga7MUzdR97hEKjU",
    "id": {
      "kind": "youtube#video",
      "videoId": "Xr30NxKAgD8"
    },
    "snippet": {
      "publishedAt": "2018-05-25T01:45:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Traveling to JAPAN: Time to Get Uncomfortable",
      "description": "What better way to get out of your comfort zone and find balance than by traveling to Japan! This trip was all about staying active ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Xr30NxKAgD8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Xr30NxKAgD8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Xr30NxKAgD8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-05-25T01:45:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "k5wAuAEdPKDjBLE2U5n22PDIWEI",
    "id": {
      "kind": "youtube#video",
      "videoId": "BtKje1m613w"
    },
    "snippet": {
      "publishedAt": "2018-12-16T23:17:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LOW IMPACT HIIT Workout // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com LOW IMPACT HIIT WORKOUT // In today's 15 minute workout I\"m running you through 5 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BtKje1m613w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BtKje1m613w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BtKje1m613w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-12-16T23:17:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c9-aQPdbnoClH7R9bnX-gI47nwQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "I7OIDCgzD4U"
    },
    "snippet": {
      "publishedAt": "2019-01-24T02:08:25Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "At Home ARM TONING Workout // With Weights",
      "description": "At Home ARM TONING Workout // With Weights Visit: https://www.heatherrobertson.com Grab a set of dumbbells and follow along ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I7OIDCgzD4U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I7OIDCgzD4U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I7OIDCgzD4U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-01-24T02:08:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c5iAEJyarCNes80XfXYnHklRpdw",
    "id": {
      "kind": "youtube#video",
      "videoId": "P-YUF3y9VSE"
    },
    "snippet": {
      "publishedAt": "2020-01-09T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 1 Day 4 // Full Body HIIT + Abs Workout (No Equipment)",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/P-YUF3y9VSE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/P-YUF3y9VSE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/P-YUF3y9VSE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-09T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "O5JxJgT4rpju9fSH0UVf9e7In1s",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ev6YjxwSpIY"
    },
    "snippet": {
      "publishedAt": "2019-04-16T00:19:28Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact TABATA WORKOUT // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com It's tabata time! Today's low impact tabata workout is still high in intensity and because it ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-16T00:19:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ea_aunnrGbEF85C9irlOtXXUsXw",
    "id": {
      "kind": "youtube#video",
      "videoId": "d0bUXIJ3mew"
    },
    "snippet": {
      "publishedAt": "2021-06-30T13:15:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udcaa\ud83c\udffc No Equipment ARM TONING Workout",
      "description": "This Arm Toning workout with no equipment uses your own body weight to create slim, defined, strong and toned arms. Feel the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/d0bUXIJ3mew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/d0bUXIJ3mew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/d0bUXIJ3mew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-30T13:15:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "W6SH_HhiU49STma5UVTmTTzZGkc",
    "id": {
      "kind": "youtube#video",
      "videoId": "GONKC8Egze4"
    },
    "snippet": {
      "publishedAt": "2021-10-14T13:00:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u26a1Brutal HIIT Cardio Workout  // Quick Workout Finisher",
      "description": "This HIIT cardio workout may be quick but it is BRUTAL! In the best way possible of course ;) We are going to power through each, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GONKC8Egze4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GONKC8Egze4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GONKC8Egze4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-14T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5lhGBN-ott4MVu13iPVxV1iU1i8",
    "id": {
      "kind": "youtube#video",
      "videoId": "XOivdhy7BDI"
    },
    "snippet": {
      "publishedAt": "2021-10-31T13:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 1 // THE DAILY10: 10 Min Upper Body Workout",
      "description": "My new Daily10 workout program is just a quick 10 minute workout a day over the next 30 days and today is day one! Wooohoo!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XOivdhy7BDI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XOivdhy7BDI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XOivdhy7BDI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-31T13:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yD3qyzlMEcitD5KgqAVDnScsARo",
    "id": {
      "kind": "youtube#video",
      "videoId": "Hk25D509S_M"
    },
    "snippet": {
      "publishedAt": "2019-06-05T22:11:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LEG DAY // WARM UP Routine: Glute Activation + Hip Openers",
      "description": "Visit: https://www.heatherrobertson.com It's time for a quick leg day warm up routine! These exercises are a great way to warm up, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Hk25D509S_M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Hk25D509S_M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Hk25D509S_M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-06-05T22:11:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xCrRU6FWGdRSAKKKW9PyyCmoZzA",
    "id": {
      "kind": "youtube#video",
      "videoId": "auaC_Ir4C3o"
    },
    "snippet": {
      "publishedAt": "2021-10-03T13:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 Minute Ab Workout // Core Strength Training",
      "description": "It's a quick burn in today's 10 minute Ab Workout. The focus is on core strength and training the abdominal muscles with a series ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/auaC_Ir4C3o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/auaC_Ir4C3o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/auaC_Ir4C3o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-03T13:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LgIJFiT9JdSyf8ZlFTHWcjCOkZI",
    "id": {
      "kind": "youtube#video",
      "videoId": "lDr81pKPWIM"
    },
    "snippet": {
      "publishedAt": "2020-09-26T15:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "No Repeats HIIT workout // Total Body MASH UP",
      "description": "Time for another No Repeats HIIT workout!! This total body mash up workout combines some of my favorite body-weighted and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-26T15:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RHXvU38C8jMicvL31fAxOu6K9nk",
    "id": {
      "kind": "youtube#video",
      "videoId": "Vic485EwpLs"
    },
    "snippet": {
      "publishedAt": "2020-10-09T20:59:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Exciting News // Updates and Q&amp;A!",
      "description": "Hey Friends! It's time for a little Q&A to cover some of my most frequently asked questions :) I'm also touching on some life updates ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Vic485EwpLs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Vic485EwpLs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Vic485EwpLs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-09T20:59:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YXJ-fJKf8H7MPrgrf-zaH9Cwbj0",
    "id": {
      "kind": "youtube#video",
      "videoId": "oe3sD96gHGE"
    },
    "snippet": {
      "publishedAt": "2020-01-08T03:52:35Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 1 Day 3 // Total Body Workout (No equipment)",
      "description": "Download your free 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oe3sD96gHGE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oe3sD96gHGE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oe3sD96gHGE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-08T03:52:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h0xestQoilTihb0RqmcDkj2X8yM",
    "id": {
      "kind": "youtube#video",
      "videoId": "impiNN5EUew"
    },
    "snippet": {
      "publishedAt": "2021-11-29T14:00:32Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 30 // THE DAILY10: 10 Min HIIT Strength &amp; Cardio Workout",
      "description": "This is it! team Day 30/30 and we are going to finish strong with a HIIT Strength & Cardio Workout. Get ready to sweat and feel a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/impiNN5EUew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/impiNN5EUew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/impiNN5EUew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-29T14:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "p0DQjYd0A9j-2YXo5gQ4krRAn40",
    "id": {
      "kind": "youtube#video",
      "videoId": "2zQ89vkNnNg"
    },
    "snippet": {
      "publishedAt": "2020-08-12T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "FIERCE Full Body HIIT Workout // NO REPEATS + No Equipment",
      "description": "Today's full body HIIT is going to be fierce!! No repeats and no equipment in today's home workout. We will only be performing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-12T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "giXNB4B53c_V5tPz_vJb-JR77w8",
    "id": {
      "kind": "youtube#video",
      "videoId": "LnJ021gf4co"
    },
    "snippet": {
      "publishedAt": "2020-03-24T03:00:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 2 //Fierce TABATA // Plus LEGS + BOOTY Workout!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LnJ021gf4co/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-24T03:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jLuZgvKlc-yCrgdRQdCadVr1dJY",
    "id": {
      "kind": "youtube#video",
      "videoId": "GuW2DKONZBU"
    },
    "snippet": {
      "publishedAt": "2021-11-22T14:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 23 // THE DAILY10: 10 Min Kickboxing Tabata Workout",
      "description": "Tabata time! This intense Kickboxing Tabata workout is a just a quick 10 minute workout that will get that heart rate up and have ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-22T14:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eN8nAlMu7WNBXxC34FmMnwTSaDE",
    "id": {
      "kind": "youtube#video",
      "videoId": "A2qnRh93An4"
    },
    "snippet": {
      "publishedAt": "2020-03-30T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact NO JUMPING HIIT Workout",
      "description": "Back at it today with this low impact, no jumping, apartment friendly workout! We are targeting the entire body with these ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-30T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YD9wKSCJb-K-Hwj7OR1WR42lFU4",
    "id": {
      "kind": "youtube#video",
      "videoId": "pEnbQQsC7_8"
    },
    "snippet": {
      "publishedAt": "2021-01-20T15:00:20Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "SWEAT FEST!!! Total Body TABATA Workout // No Equipment",
      "description": "Get ready to sweat in today's total body Tabata Workout!!! We have 4 Different TABATA Circuits to power through, 4 sets of each.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pEnbQQsC7_8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pEnbQQsC7_8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pEnbQQsC7_8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-20T15:00:20Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GlGlXiU1aJ5kK4ipNj4bN7TBvBw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ai-_BXQTQxw"
    },
    "snippet": {
      "publishedAt": "2020-02-28T04:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 5 //At Home Upper Body Workout // CHEST + BACK",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ai-_BXQTQxw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-28T04:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hbac6LCSGObFyzvDzjAde55BvoY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Z935wdY3hCc"
    },
    "snippet": {
      "publishedAt": "2020-03-12T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 10 Day 4 //Crazy At-Home CARDIO + ABS Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Z935wdY3hCc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-12T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mU5GQ3N-OnzMpKz7ZvVH02i_Wxg",
    "id": {
      "kind": "youtube#video",
      "videoId": "MSmHYnIm5ZU"
    },
    "snippet": {
      "publishedAt": "2020-10-18T14:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Workout // Total Body Fusion Workout (No Equipment)",
      "description": "This HIIT Pilates Workout is a fun fusion of HIIT style training and pilates inspired exercises. No equipment needed today - we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-18T14:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "pQJzyZJgzYZvaejNIcvD3KcwQdE",
    "id": {
      "kind": "youtube#video",
      "videoId": "1kaL8EdaUdE"
    },
    "snippet": {
      "publishedAt": "2021-10-21T13:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51 10 Minute Squat Challenge!",
      "description": "Get ready to squat! This 10 minute squat challenge will set the legs and booty on fire! We will be performing 10 different no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1kaL8EdaUdE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1kaL8EdaUdE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1kaL8EdaUdE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-21T13:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YyESqmjoCubHzQUkhMXoTK8-Wvk",
    "id": {
      "kind": "youtube#video",
      "videoId": "80svoVWB3L4"
    },
    "snippet": {
      "publishedAt": "2020-02-21T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 5 // At Home Chest + Back Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/80svoVWB3L4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-21T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kptRGsWwuixcB3MiHxMNqVGp45k",
    "id": {
      "kind": "youtube#video",
      "videoId": "omQkfn-l73o"
    },
    "snippet": {
      "publishedAt": "2020-03-25T03:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 3 //Full Body FAT BURNING HIIT + Plank Challenge!!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/omQkfn-l73o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-25T03:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "h8-kH7fTowJSDUw4SaG_o2nyvy0",
    "id": {
      "kind": "youtube#video",
      "videoId": "F8Hk2_llp8M"
    },
    "snippet": {
      "publishedAt": "2019-04-21T00:49:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Quick ABS + GLUTES Workout // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com Two workouts in one today with this quick Abs and Glutes workout! This is a great workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/F8Hk2_llp8M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-21T00:49:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "H4flcLrw1h-my83rKfk54Qc_4sg",
    "id": {
      "kind": "youtube#video",
      "videoId": "U3DParHgSMc"
    },
    "snippet": {
      "publishedAt": "2019-08-20T23:06:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Back Stretches + Exercises // For LOWER BACK PAIN",
      "description": "Help relieve lower back pain by improving mobility, and flexibility. These back stretches and exercises may help reduce back pain ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/U3DParHgSMc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/U3DParHgSMc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/U3DParHgSMc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-20T23:06:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zdhiCSneiWv1k-iBIercdKgxRm8",
    "id": {
      "kind": "youtube#video",
      "videoId": "0nAQL1EN-x4"
    },
    "snippet": {
      "publishedAt": "2020-02-26T04:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 8 Day 3 //At Home LEG WORKOUT // Sculpt + Tone",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0nAQL1EN-x4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-26T04:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8ig8s9XZMwZnf9OiRQrJ1AfPPAs",
    "id": {
      "kind": "youtube#video",
      "videoId": "W8wLcGdQQ30"
    },
    "snippet": {
      "publishedAt": "2019-07-12T17:22:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20 Minute // FULL BODY HIIT Workout With Weights",
      "description": "Visit: https://www.heatherrobertson.com Ok guys 6 different exercises in today's full body HIIT workout with weights. Grab those ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-12T17:22:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I67YfmsuYS9K_LgVkbgiVDrOVhU",
    "id": {
      "kind": "youtube#video",
      "videoId": "TlmbAAb9-Uo"
    },
    "snippet": {
      "publishedAt": "2018-09-28T00:25:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full length Booty Building Workout",
      "description": "Visit: https://www.heatherrobertson.com Today's booty building workout uses added resistance to build lean muscle and build ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TlmbAAb9-Uo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TlmbAAb9-Uo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TlmbAAb9-Uo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-09-28T00:25:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WNCtXVxgcqABzNvy7-as2hSAgww",
    "id": {
      "kind": "youtube#video",
      "videoId": "CDdaY7Pp1KM"
    },
    "snippet": {
      "publishedAt": "2020-03-27T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 5 //Epic HIIT Workout! NO REPEATS!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CDdaY7Pp1KM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-27T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HmMU5fHXALg2iUcWHV1kwWGRz5w",
    "id": {
      "kind": "youtube#video",
      "videoId": "0tQfCuElKU8"
    },
    "snippet": {
      "publishedAt": "2021-10-19T13:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25No Repeats HIIT Workout (with Weights)",
      "description": "Who's up for a No Repeats HIIT Workout!!? These non-repetitive workouts fly by and add variety to your sweat sesh which can be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-19T13:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6Num0eIQ6yFsXcRAFa3xL37UTCg",
    "id": {
      "kind": "youtube#video",
      "videoId": "jCzf5g3hkCs"
    },
    "snippet": {
      "publishedAt": "2021-08-30T13:00:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 Minute PLANK CHALLENGE! \ud83d\udd25",
      "description": "Who's up for a new PLANK CHALLENGE!!? This 10 minute plank challenge will challenge your upper body and core strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jCzf5g3hkCs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jCzf5g3hkCs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jCzf5g3hkCs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-30T13:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mVIFxdffC-srJDZwXD4qIeKGVek",
    "id": {
      "kind": "youtube#video",
      "videoId": "6zxwQ4RPfvs"
    },
    "snippet": {
      "publishedAt": "2021-11-10T14:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 11 // THE DAILY10: 10 Min Low Impact HIIT Workout",
      "description": "Get ready for a quick 10 minute, Low Impact HIIT Workout! We are using light dumbbells for added resistance and intensity and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/6zxwQ4RPfvs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-10T14:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TlxuF6PtRXlzxMNIH0z7Tl0nmIM",
    "id": {
      "kind": "youtube#video",
      "videoId": "1Mgq1zU3Raw"
    },
    "snippet": {
      "publishedAt": "2020-03-17T03:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 2 //TABATA + Lower Body Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1Mgq1zU3Raw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-17T03:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZfxHma55CWjaPVAwC4musxHdli4",
    "id": {
      "kind": "youtube#video",
      "videoId": "qFgc3zJx5Pw"
    },
    "snippet": {
      "publishedAt": "2020-02-20T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 7 Day 4 //Intense Total Body TABATA Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qFgc3zJx5Pw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-02-20T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3dO9B4L2VyhBM4v7N2RhIRz0GgU",
    "id": {
      "kind": "youtube#video",
      "videoId": "HLpZhfgv-5Y"
    },
    "snippet": {
      "publishedAt": "2021-07-21T14:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Six Pack Standing Abs Workout",
      "description": "Working on that six pack with today's ab workout. This standing only workout targets the core with a series of standing ab ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-21T14:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AFO5dja45Qizqqe-6ZhpFThzszY",
    "id": {
      "kind": "youtube#video",
      "videoId": "uHtfk8U-03s"
    },
    "snippet": {
      "publishedAt": "2019-12-04T00:15:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "MINI BAND Workout for Strong Glutes // No Repeats BOOTY WORKOUT",
      "description": "This mini band workout is designed to activate that booty and help build strong glutes. Three different circuits to get through today ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uHtfk8U-03s/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uHtfk8U-03s/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uHtfk8U-03s/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-12-04T00:15:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VlRTAf5GkhsZyGLGLXxbHCJE3GQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "_ozkbpstWS8"
    },
    "snippet": {
      "publishedAt": "2020-03-05T04:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 4 //Killer HIIT Cardio + Abs Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_ozkbpstWS8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-05T04:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RhPPYs-syyKGG8Qg0SZjhA4NdnQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "-s4mgdTjOwI"
    },
    "snippet": {
      "publishedAt": "2019-11-11T17:00:37Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full UPPER BODY Workout // TONING + STRENGTH",
      "description": "Today we are powering through an upper body workout designed to tone and strengthen the arms, shoulders, chest and back.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-11T17:00:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xbmk1FqT2U9oFEI2jPRTqm7WDWE",
    "id": {
      "kind": "youtube#video",
      "videoId": "g-i3S1fnQbQ"
    },
    "snippet": {
      "publishedAt": "2021-08-11T15:58:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf1f Low Impact NO REPEATS Total Body HIIT",
      "description": "No jumping and no repeats in today's Low Impact HIIT workout! We are using dumbbells in today's sweat sesh for added ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-11T15:58:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GB3DFBtHPoFT00bVsUB-WTFjygE",
    "id": {
      "kind": "youtube#video",
      "videoId": "_1aWo0IX0h4"
    },
    "snippet": {
      "publishedAt": "2021-03-21T13:30:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Cardio Kickboxing // Fat Burning Workout",
      "description": "Nutrition Guide: https://heatherrobertson.com/shop/ Getting that heart rate up in today's cardio kickboxing workout! This fat burning ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-21T13:30:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M8rn8bY2IZxaAwxAySKdoJ76ZtQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "vXvAS4Xf1dA"
    },
    "snippet": {
      "publishedAt": "2019-11-03T23:35:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Inner + Outer THIGH SLIMMING Workout // No Equipment",
      "description": "This thigh slimming workout is going to target both the inner and outer thighs using just your own bodyweight. No equipment is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-03T23:35:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "haKvfHLJffcNPtY3GGRUx_qg_Bc",
    "id": {
      "kind": "youtube#video",
      "videoId": "QJ-f1jsaAko"
    },
    "snippet": {
      "publishedAt": "2021-06-09T13:00:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer Kettlebell TABATA Workout",
      "description": "It's Tabata time and today we are throwing a kettlebell in the mix. Each tabata circuit has 2 exercises that we will repeat 4 times for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/QJ-f1jsaAko/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/QJ-f1jsaAko/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/QJ-f1jsaAko/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-09T13:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rEbhC8su6bKVHi_2ss5pp5Nwybg",
    "id": {
      "kind": "youtube#video",
      "videoId": "vbL3CenpIUY"
    },
    "snippet": {
      "publishedAt": "2020-03-19T03:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 4 //Full Body HIIT with Weights //PLUS Ab Finisher!",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vbL3CenpIUY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-19T03:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DwBs6VSAShi8dr8xSiUg7XSIp3s",
    "id": {
      "kind": "youtube#video",
      "videoId": "IiLnZd1MQtQ"
    },
    "snippet": {
      "publishedAt": "2021-07-19T14:41:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 No Repeats HIIT // Dumbbell Workout",
      "description": "Time for a no repeats HIIT workout with dumbbells for added resistance and Intensity! You will only be performing each HIIT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-19T14:41:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JhqGDzsnf5Ab0sPmHky9qiFO-Q8",
    "id": {
      "kind": "youtube#video",
      "videoId": "tgZgWYtkoek"
    },
    "snippet": {
      "publishedAt": "2021-11-06T13:00:24Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 7 // THE DAILY10: 10 Min Lower Body HIIT Workout",
      "description": "This 10 minute Lower Body HIIT Workout will target the legs and glutes with a series of HIIT Exercises designed for strength, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tgZgWYtkoek/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-06T13:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HNvdks9f3jsfJZO8Sfc-rdvPQ4Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qff2gyaBa4g"
    },
    "snippet": {
      "publishedAt": "2020-08-23T14:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Yoga Inspired // FULL BODY WORKOUT // No Equipment, No Jumping!",
      "description": "Get ready for a total body, fusion workout! I'm using some yoga inspired moves today in this full body home workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-23T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "80xtnwWWk2zsyFxvQPHhfWxnV7o",
    "id": {
      "kind": "youtube#video",
      "videoId": "PINAsSpbZpk"
    },
    "snippet": {
      "publishedAt": "2019-10-25T22:11:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "TOTAL BODY HIIT Workout // Strength + Cardio",
      "description": "Today's TOTAL BODY HIIT Workout combines strength and cardio exercises design to help build lean muscle and burn fat fast.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PINAsSpbZpk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PINAsSpbZpk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PINAsSpbZpk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-25T22:11:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "c0QR9L6mB94TWTHFY5CNhSpnn6g",
    "id": {
      "kind": "youtube#video",
      "videoId": "DaTo1OLnleU"
    },
    "snippet": {
      "publishedAt": "2020-03-20T03:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 11 Day 5 //Full Body HIIT // Cardio + Strength At Home Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DaTo1OLnleU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-20T03:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QNiLhjT3q-3wwrmPYu9qiChO6sQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "0kWs7fo9SiE"
    },
    "snippet": {
      "publishedAt": "2020-03-26T03:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 12 Day 4 // LEG DAY Workout // Lower Body HIIT",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0kWs7fo9SiE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-26T03:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LgIJFiT9JdSyf8ZlFTHWcjCOkZI",
    "id": {
      "kind": "youtube#video",
      "videoId": "lDr81pKPWIM"
    },
    "snippet": {
      "publishedAt": "2020-09-26T15:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "No Repeats HIIT workout // Total Body MASH UP",
      "description": "Time for another No Repeats HIIT workout!! This total body mash up workout combines some of my favorite body-weighted and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lDr81pKPWIM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-26T15:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "O5JxJgT4rpju9fSH0UVf9e7In1s",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ev6YjxwSpIY"
    },
    "snippet": {
      "publishedAt": "2019-04-16T00:19:28Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact TABATA WORKOUT // No Equipment",
      "description": "Visit: https://www.heatherrobertson.com It's tabata time! Today's low impact tabata workout is still high in intensity and because it ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ev6YjxwSpIY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-16T00:19:28Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gyeu-UyuUDnZQBoXWDu9i9UVCQ8",
    "id": {
      "kind": "youtube#video",
      "videoId": "s3arMZ7ASA4"
    },
    "snippet": {
      "publishedAt": "2021-01-17T14:00:30Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Fat Burning HIIT Cardio Workout // No Equipment",
      "description": "Cardio cardio cardio! Love it or hate it, we've got to get it in! This fat burning HIIT cardio workout will get that heart rate up and will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-17T14:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QWsAk6_EzjlIcd9xPLPk1xK4xU4",
    "id": {
      "kind": "youtube#video",
      "videoId": "XzP1A2nGHyQ"
    },
    "snippet": {
      "publishedAt": "2022-01-06T13:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Unilateral Leg Workout // Day 4 HR12WEEK 2.0",
      "description": "This Unilateral leg workout isolates one side of the body at a time to help improve balance and symmetry as we build strength.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-06T13:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1RyBdQDO4ch0qkv-g8du0yhT_a4",
    "id": {
      "kind": "youtube#video",
      "videoId": "NszpvE0kClM"
    },
    "snippet": {
      "publishedAt": "2019-04-09T23:52:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "CRAZY Full Body HIIT Workout // NO REPEATS + No Equipment",
      "description": "Visit: https://www.heatherrobertson.com CRAZY Full Body HIIT Workout // NO REPEATS + No Equipment 40 different bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-09T23:52:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XaQaDnjNJa-RBCIyep5JjjEpbGo",
    "id": {
      "kind": "youtube#video",
      "videoId": "gThC40XCHd4"
    },
    "snippet": {
      "publishedAt": "2022-02-07T05:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Bicep, Tricep &amp; Shoulders Workout // Day 26 HR12WEEK 2.0",
      "description": "Today's Biceps, Triceps & Shoulders Workout is an upper body strength session that will have you feeling fierce! A little shorter ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-07T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "63CcW0eiw2DEydkTf-AII1Noyms",
    "id": {
      "kind": "youtube#video",
      "videoId": "xzcHZcbPplk"
    },
    "snippet": {
      "publishedAt": "2021-09-23T14:00:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 No Repeats HIIT Cardio Workout (No Equipment)",
      "description": "Get ready to tackle 45 different HIIT Cardio exercises in today's sweat fest of a workout! No Repeats and No Equipment needed so ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-23T14:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "45GPYmeI-RUkJb6qkNDrNnyHOZM",
    "id": {
      "kind": "youtube#video",
      "videoId": "jb3npwXsrpA"
    },
    "snippet": {
      "publishedAt": "2020-03-03T04:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 9 Day 2 //TABATA, ABS + BOOTY Workout",
      "description": "Download your free program guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/jb3npwXsrpA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-03T04:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LEm5ENpw-DCTUkzdN958ofQ2j0I",
    "id": {
      "kind": "youtube#video",
      "videoId": "Xch5F1X7HKU"
    },
    "snippet": {
      "publishedAt": "2021-11-04T13:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 5 // THE DAILY10: 10 Min Tabata Workout",
      "description": "It's TABATA Time! You'll be happy this one is only 10 minutes! These no equipment, Tabata exercises are intense and will give ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-04T13:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4DrZZad0XAqmHj8-MMF5UjY1-fI",
    "id": {
      "kind": "youtube#video",
      "videoId": "ylO4nKa4vkQ"
    },
    "snippet": {
      "publishedAt": "2021-11-09T14:00:34Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 10 // THE DAILY10: 10 Min HIIT Pilates Workout",
      "description": "This 10 minute HIIT Pilates workout is a fusion of hiit style training and pilates inspired exercises giving you a full body workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ylO4nKa4vkQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ylO4nKa4vkQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ylO4nKa4vkQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-09T14:00:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SQcw0wZa_lxnv_0teZxlh6T1AuI",
    "id": {
      "kind": "youtube#video",
      "videoId": "CZAtl3Kjv90"
    },
    "snippet": {
      "publishedAt": "2022-01-31T04:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chest &amp; Shoulders SUPERSETS Workout // Day 21 HR12WEEK 2.0",
      "description": "Diving into week 5 with a Chest & Shoulders Workout for upper body strength. Six different supersets in this workout loaded with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CZAtl3Kjv90/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CZAtl3Kjv90/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CZAtl3Kjv90/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-31T04:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "We9R181LvtmcWuiPVxq5s6xmWTs",
    "id": {
      "kind": "youtube#video",
      "videoId": "IbU-7yVAoTM"
    },
    "snippet": {
      "publishedAt": "2020-05-13T14:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Day 4 Home Workout Challenge // CARDIO + ABS (No Equipment)",
      "description": "It's time to get in the cardio my friends! Day 4 of my 7 day home workout challenge is all about cardio and core work. We are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-13T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RCgsd5QZYkJfpNx4bxBd1VTHj3o",
    "id": {
      "kind": "youtube#video",
      "videoId": "JzN-LA6J-kc"
    },
    "snippet": {
      "publishedAt": "2021-04-04T13:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Yoga Fusion Workout",
      "description": "Today's fusion HIIT workout includes a series of total body, yoga inspired exercises that will help improve strength, mobility, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-04T13:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "We9R181LvtmcWuiPVxq5s6xmWTs",
    "id": {
      "kind": "youtube#video",
      "videoId": "IbU-7yVAoTM"
    },
    "snippet": {
      "publishedAt": "2020-05-13T14:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Day 4 Home Workout Challenge // CARDIO + ABS (No Equipment)",
      "description": "It's time to get in the cardio my friends! Day 4 of my 7 day home workout challenge is all about cardio and core work. We are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IbU-7yVAoTM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-13T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sN5pr2P63hXdqfdT6wlxax8JDqc",
    "id": {
      "kind": "youtube#video",
      "videoId": "BuCQjDWbz_E"
    },
    "snippet": {
      "publishedAt": "2022-02-18T04:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS Workout: Cardio, Core &amp; Booty // Day 34 HR12WEEK 2.0",
      "description": "This NO REPEATS Workout is intense!! We are starting off with a booty circuit to target those glutes and then a seriously sweaty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BuCQjDWbz_E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BuCQjDWbz_E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BuCQjDWbz_E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-18T04:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "kBjW_Voxjn1mgenSia7SRg3ADJ4",
    "id": {
      "kind": "youtube#video",
      "videoId": "8AR1LQzJgMU"
    },
    "snippet": {
      "publishedAt": "2022-03-11T04:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Tabata Cardio &amp; Abs: No Equipment // Day 49 HR12WEEK 2.0",
      "description": "It's TABATA TIME!!! This Tabata style Cardio workout is sure to make you sweat! And just to be sure you are feeling the burn we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8AR1LQzJgMU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8AR1LQzJgMU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8AR1LQzJgMU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-11T04:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RhIIBL5PxsQE5EdipghJR_sqBJk",
    "id": {
      "kind": "youtube#video",
      "videoId": "AgpsyygblXo"
    },
    "snippet": {
      "publishedAt": "2022-03-04T04:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "POWER CARDIO Workout // Day 44 HR12WEEK 2.0",
      "description": "It's POWER CARDIO Time!!! We are getting that heart rate up and diving into full sweat mode with this cardio workout. Clear some ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AgpsyygblXo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AgpsyygblXo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AgpsyygblXo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-04T04:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SRptTNzqY_Pn6RhHFfZhb7Vahxs",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ey7KAjprvHw"
    },
    "snippet": {
      "publishedAt": "2022-02-26T04:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS Workout: Full Body Strength &amp; Cardio // Day 40 HR12WEEK 2.0",
      "description": "Who's ready for a NO REPEATS Workout!!!? This 40 MIN full body strength and cardio workout with no repeats is a killer. Loaded ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ey7KAjprvHw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ey7KAjprvHw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ey7KAjprvHw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-26T04:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LEm5ENpw-DCTUkzdN958ofQ2j0I",
    "id": {
      "kind": "youtube#video",
      "videoId": "Xch5F1X7HKU"
    },
    "snippet": {
      "publishedAt": "2021-11-04T13:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 5 // THE DAILY10: 10 Min Tabata Workout",
      "description": "It's TABATA Time! You'll be happy this one is only 10 minutes! These no equipment, Tabata exercises are intense and will give ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Xch5F1X7HKU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-04T13:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PsOsAbSvld9i1YScIG816v74qLE",
    "id": {
      "kind": "youtube#video",
      "videoId": "fGI3_dQ9MiQ"
    },
    "snippet": {
      "publishedAt": "2021-11-08T14:00:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 9 // THE DAILY10: 10 Min No Repeats Cardio Workout",
      "description": "It's Cardio Time!! This quick, 10 minute, no repeats cardio workout will have you feeling the heat in just a short amount of time.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fGI3_dQ9MiQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fGI3_dQ9MiQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fGI3_dQ9MiQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-08T14:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M8rn8bY2IZxaAwxAySKdoJ76ZtQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "vXvAS4Xf1dA"
    },
    "snippet": {
      "publishedAt": "2019-11-03T23:35:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Inner + Outer THIGH SLIMMING Workout // No Equipment",
      "description": "This thigh slimming workout is going to target both the inner and outer thighs using just your own bodyweight. No equipment is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vXvAS4Xf1dA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-03T23:35:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OG1m7CoRbNFkzdlC65yp-Xd91eA",
    "id": {
      "kind": "youtube#video",
      "videoId": "fxT_7IFOLCg"
    },
    "snippet": {
      "publishedAt": "2022-02-04T05:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "No Equipment HIIT CARDIO &amp; ABS // Day 24 HR12WEEK 2.0",
      "description": "Get ready to sweat! This no equipment HIIT Cardio and Abs workout is a killer. Using just our body-weight today we will start of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fxT_7IFOLCg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fxT_7IFOLCg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fxT_7IFOLCg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-04T05:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "WmyWM2wFBx9T8Pc3SJA0j2hvAyo",
    "id": {
      "kind": "youtube#video",
      "videoId": "j0UX8gDj6ag"
    },
    "snippet": {
      "publishedAt": "2022-02-10T05:00:44Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body Strength: Chest &amp; Back Workout // Day 28 HR12WEEK 2.0",
      "description": "This Upper Body Strength workout targets the Chest & Back with dumbbell exercises for serious strength and power. Sculpt out ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j0UX8gDj6ag/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j0UX8gDj6ag/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j0UX8gDj6ag/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-10T05:00:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TJg1Jc2HPlel1QfVceEbWYqjs0A",
    "id": {
      "kind": "youtube#video",
      "videoId": "XIc4groxzS4"
    },
    "snippet": {
      "publishedAt": "2019-07-16T23:36:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT BOOTY BOOTCAMP// Butt Workout + Glute Activation \ud83c\udf51",
      "description": "Visit: https://www.heatherrobertson.com Ok team - Three circuits to get through in today's HIIT Booty Bootcamp. We are getting in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XIc4groxzS4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XIc4groxzS4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XIc4groxzS4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-16T23:36:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "eN8nAlMu7WNBXxC34FmMnwTSaDE",
    "id": {
      "kind": "youtube#video",
      "videoId": "A2qnRh93An4"
    },
    "snippet": {
      "publishedAt": "2020-03-30T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact NO JUMPING HIIT Workout",
      "description": "Back at it today with this low impact, no jumping, apartment friendly workout! We are targeting the entire body with these ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/A2qnRh93An4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-03-30T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ityxPubrBQrPt6fjJMjvj-DP6NA",
    "id": {
      "kind": "youtube#video",
      "videoId": "8oei9ypXrWQ"
    },
    "snippet": {
      "publishedAt": "2023-03-19T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 56 // Metabolic Training HR12WEEK 3.0",
      "description": "Welcome to today's full body METABOLIC TRAINING session using dumbbells for resistance. We will be performing compound ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8oei9ypXrWQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8oei9ypXrWQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8oei9ypXrWQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2023-03-19T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0S865KdderR0kqJo8L_mj0UF08Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "dq0uqRoNvfU"
    },
    "snippet": {
      "publishedAt": "2022-02-24T04:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chiseled Chest &amp; Back: STRENGTH Workout // Day 38",
      "description": "This Chest and Back workout is an upper body strength workout that will have you feeling the BURN!! Three straight sets of one ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dq0uqRoNvfU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dq0uqRoNvfU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dq0uqRoNvfU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-24T04:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "g1nSLOYEC2ozEWjrM4hzgRJSgk4",
    "id": {
      "kind": "youtube#video",
      "videoId": "EmJU7AWYK6Y"
    },
    "snippet": {
      "publishedAt": "2022-02-17T04:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Back &amp; Arms Workout: Tri-sets // Day 33 HR12WEEK 2.0",
      "description": "This Back and Arms Workout is loaded with Tri-Sets to target the back, biceps and triceps. Each upper body circuit includes three ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/EmJU7AWYK6Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/EmJU7AWYK6Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/EmJU7AWYK6Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-17T04:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9M1f0zd36kjls6OjlhDnUat65mU",
    "id": {
      "kind": "youtube#video",
      "videoId": "etb-BElKah8"
    },
    "snippet": {
      "publishedAt": "2022-02-12T05:02:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body STRENGTH &amp; CARDIO Workout // Day 30 HR12WEEK 2.0",
      "description": "This Total Body STRENGTH & CARDIO Workout is the perfect way to wrap up week 6 and hit the halfway mark of HR12WEEK 2.0!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/etb-BElKah8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/etb-BElKah8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/etb-BElKah8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-12T05:02:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hemW3kGaPBP7S36JdY5bNDIWsnU",
    "id": {
      "kind": "youtube#video",
      "videoId": "I558NaTxiLs"
    },
    "snippet": {
      "publishedAt": "2022-02-28T04:59:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "FULL BODY HIIT Workout + Abs // Day 41 HR12WEEK 2.0",
      "description": "This Full Body HIIT Workout is the perfect way to start off week 9!! No equipment needed as we use just our body-weight to power ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I558NaTxiLs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I558NaTxiLs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I558NaTxiLs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-28T04:59:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xHC3NHRzOUqj6Qp6ll6or2T6Erw",
    "id": {
      "kind": "youtube#video",
      "videoId": "Yjf3PNKT8CU"
    },
    "snippet": {
      "publishedAt": "2021-12-16T14:00:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 Day 13: Abs Workout + Plank Challenge // Core Strength",
      "description": "Day 13 is all about Abs and Core Strength! We will be running through a weighted ab workout using dumbbells to increase the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Yjf3PNKT8CU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Yjf3PNKT8CU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Yjf3PNKT8CU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-16T14:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1mKaHpSLObaIjNf-ejkMVcpTK4E",
    "id": {
      "kind": "youtube#video",
      "videoId": "L6R_nFVO52U"
    },
    "snippet": {
      "publishedAt": "2022-03-17T03:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Strength Workout // Day 53 HR12WEEK 2.0",
      "description": "Today we are taking on a Full Body Strength workout. Plenty of compound exercises using dumbells for resistance that will give ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/L6R_nFVO52U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/L6R_nFVO52U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/L6R_nFVO52U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-17T03:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YcDqRmUkIg83O2vXYdHl1FRB30Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "wjzTdEZuW_o"
    },
    "snippet": {
      "publishedAt": "2019-10-21T04:54:35Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "THE BEST At Home BOOTY Workout // No equipment",
      "description": "This at home booty workouts includes many of my favorite bodyweighted glute exercises. You can tone and define that booty with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wjzTdEZuW_o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wjzTdEZuW_o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wjzTdEZuW_o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-21T04:54:35Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Yx1xkG0o9LfQA_vFJ1zAgXO9_U0",
    "id": {
      "kind": "youtube#video",
      "videoId": "kRitkMPL2ys"
    },
    "snippet": {
      "publishedAt": "2021-02-28T14:00:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact // STRENGTH &amp; CARDIO Workout",
      "description": "Low Impact and high intensity in today's Strength and cardio workout oww oww! This no jumping workout is an intense sweat ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kRitkMPL2ys/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kRitkMPL2ys/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kRitkMPL2ys/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-28T14:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yUv8NtVqWzLnvCmXLqM3XJ3BHgA",
    "id": {
      "kind": "youtube#video",
      "videoId": "V6-_067MwDc"
    },
    "snippet": {
      "publishedAt": "2021-06-17T13:30:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2b50GLOW UP CHALLENGE // Day 6: Total Body SHRED Strength &amp; Cardio Supersets Workout",
      "description": "Get ready for a total body SHRED! This Strength and Cardio supersets workout is going to have you seriously sweating....I mean ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V6-_067MwDc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V6-_067MwDc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V6-_067MwDc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-17T13:30:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YnlpkiwQHrzsiUVWt7tqsbNjUf0",
    "id": {
      "kind": "youtube#video",
      "videoId": "GptnFoV1u4w"
    },
    "snippet": {
      "publishedAt": "2020-07-29T15:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Dumbbell Only // FULL BODY HIIT Workout",
      "description": "Today's Dumbbell Only, FULL BODY HIIT Workout uses weights for added resistance and intensity! Each circuit includes total ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GptnFoV1u4w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GptnFoV1u4w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GptnFoV1u4w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-29T15:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JL1mn0Yp_6TDMcHfOSNCGapGxAI",
    "id": {
      "kind": "youtube#video",
      "videoId": "sj-cCdkSLlo"
    },
    "snippet": {
      "publishedAt": "2016-05-02T01:33:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Bodyweight Booty Workout",
      "description": "You don't need any equipment to power through this 20 minute bodyweight booty workout. High reps and targeted exercises are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sj-cCdkSLlo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sj-cCdkSLlo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sj-cCdkSLlo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2016-05-02T01:33:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QWsAk6_EzjlIcd9xPLPk1xK4xU4",
    "id": {
      "kind": "youtube#video",
      "videoId": "XzP1A2nGHyQ"
    },
    "snippet": {
      "publishedAt": "2022-01-06T13:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Unilateral Leg Workout // Day 4 HR12WEEK 2.0",
      "description": "This Unilateral leg workout isolates one side of the body at a time to help improve balance and symmetry as we build strength.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XzP1A2nGHyQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-06T13:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xi1PVVjMv4HVVTmBNs7d_ThFck0",
    "id": {
      "kind": "youtube#video",
      "videoId": "kTvHJe6_h1c"
    },
    "snippet": {
      "publishedAt": "2019-07-08T21:37:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "12 Minute Full Body TABATA Workout // INTENSE No equipment workout",
      "description": "FREE 12 Week Workout Plan: https://www.heatherrobertson.com Today's we are getting through an Intense full body tabata ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kTvHJe6_h1c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kTvHJe6_h1c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kTvHJe6_h1c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-08T21:37:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AiaN9d_fZHDGcOTajSDtES23ToQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "AjrnD1NCl-I"
    },
    "snippet": {
      "publishedAt": "2022-02-21T05:02:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body Supersets: Bi&#39;s, Tri&#39;s &amp; Shoulders // Day 36 HR12WEEK 2.0",
      "description": "Welcome to week 8!! Today's upper body supersets is an arm workout that will target the biceps, triceps and shoulders using ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AjrnD1NCl-I/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AjrnD1NCl-I/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AjrnD1NCl-I/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-21T05:02:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yasgVQIC3ODY7QDmikma3eo3j4Q",
    "id": {
      "kind": "youtube#video",
      "videoId": "kklcGRcH74w"
    },
    "snippet": {
      "publishedAt": "2021-08-09T13:00:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25Cardio &amp; Abs AMRAP Workout // No Equipment",
      "description": "This Cardio and abs AMRAP workout is sure to have you SWEATING in just 20 minutes. This no equipment workout includes ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kklcGRcH74w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kklcGRcH74w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kklcGRcH74w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-09T13:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GB3DFBtHPoFT00bVsUB-WTFjygE",
    "id": {
      "kind": "youtube#video",
      "videoId": "_1aWo0IX0h4"
    },
    "snippet": {
      "publishedAt": "2021-03-21T13:30:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Cardio Kickboxing // Fat Burning Workout",
      "description": "Nutrition Guide: https://heatherrobertson.com/shop/ Getting that heart rate up in today's cardio kickboxing workout! This fat burning ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_1aWo0IX0h4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-21T13:30:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XaQaDnjNJa-RBCIyep5JjjEpbGo",
    "id": {
      "kind": "youtube#video",
      "videoId": "gThC40XCHd4"
    },
    "snippet": {
      "publishedAt": "2022-02-07T05:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Bicep, Tricep &amp; Shoulders Workout // Day 26 HR12WEEK 2.0",
      "description": "Today's Biceps, Triceps & Shoulders Workout is an upper body strength session that will have you feeling fierce! A little shorter ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/gThC40XCHd4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-07T05:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LUXI021EtXs9n86N08nqfIXJL3g",
    "id": {
      "kind": "youtube#video",
      "videoId": "o2_VXQc6zUo"
    },
    "snippet": {
      "publishedAt": "2019-10-06T01:54:45Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "FULL BODY Workout",
      "description": "Full Body WORKOUT Access the Full 28 Day Challenge Playlist Here: https://goo.gl/DFjo4d L I N K S  Website: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/o2_VXQc6zUo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/o2_VXQc6zUo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/o2_VXQc6zUo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-06T01:54:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jfJialX-iPgE0H527rLx9eh6tiI",
    "id": {
      "kind": "youtube#video",
      "videoId": "sXJwVU-QOiY"
    },
    "snippet": {
      "publishedAt": "2015-12-04T18:55:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "At Home Lower Body Workout - Legs &amp; Booty",
      "description": "This lower body workout targets the glutes, quads, hamstrings and inner/outer thighs! Visit: https://www.heatherrobertson.com ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sXJwVU-QOiY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sXJwVU-QOiY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sXJwVU-QOiY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2015-12-04T18:55:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "b10wISXl10MOkb239INrLfqfmHQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "xm9fF0KDGVg"
    },
    "snippet": {
      "publishedAt": "2022-01-13T07:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Unilateral Upper Body: Back &amp; Biceps Workout // Day 8 HR12WEEK 2.0",
      "description": "This back and biceps workout combines both bilateral and unilateral exercises to target each side of the body at a time and each ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xm9fF0KDGVg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xm9fF0KDGVg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xm9fF0KDGVg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-13T07:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ac8Ex8fVu9IajcsK1u2QnxY0tT4",
    "id": {
      "kind": "youtube#video",
      "videoId": "zJWj_ZbgVj8"
    },
    "snippet": {
      "publishedAt": "2022-01-18T04:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer HIIT Legs Workout // Day 12 HR12WEEK 2.0",
      "description": "This HIIT Legs Workout is a killer!! Lower body dumbbell exercises plus explosive body-weight exercises combined for one ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zJWj_ZbgVj8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zJWj_ZbgVj8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zJWj_ZbgVj8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-18T04:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AybdsrfM1brugC-2UdsxsgaOgWI",
    "id": {
      "kind": "youtube#video",
      "videoId": "djTsqYr-GaQ"
    },
    "snippet": {
      "publishedAt": "2022-02-05T05:00:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS Total Body Strength Workout // Day 25 HR12WEEK 2.0",
      "description": "It's a NO REPEATS Total Body Strength Workout kinda day! What a way to wrap up week 5! This dumbbell only resistance workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/djTsqYr-GaQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/djTsqYr-GaQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/djTsqYr-GaQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-05T05:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Gy2SLLxyJp764EQrDNf_wf488J4",
    "id": {
      "kind": "youtube#video",
      "videoId": "c0RSig31TGE"
    },
    "snippet": {
      "publishedAt": "2020-04-25T20:29:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "TONED ARMS // Home Workout",
      "description": "Today's arm toning workout uses light weight for added resistance to tone up and strengthen the shoulders, biceps and triceps.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/c0RSig31TGE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/c0RSig31TGE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/c0RSig31TGE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-25T20:29:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CP4iNZIYFR7sFJcpFe66HJ9wBeM",
    "id": {
      "kind": "youtube#video",
      "videoId": "eHTK-OEG_Mo"
    },
    "snippet": {
      "publishedAt": "2022-01-09T13:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Shoulders, Chest &amp; Triceps Superset Workout // Day 6 HR12WEEK 2.0",
      "description": "Today's strength workout is a series of upper body supersets with dumbbell exercises for the shoulders, chest and triceps.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eHTK-OEG_Mo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eHTK-OEG_Mo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eHTK-OEG_Mo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-09T13:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "9RPZ4IPYqBnPuFAMmLeJjSTtEto",
    "id": {
      "kind": "youtube#video",
      "videoId": "I4PWzi7mlW0"
    },
    "snippet": {
      "publishedAt": "2022-02-25T04:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Serious Ab BURNER: Core Workout // Day 39 HR12WEEK 2.0",
      "description": "Get ready to feel the heat with this serious AB BURNER!! This core strength workout uses dumbbells and your own body-weight to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/I4PWzi7mlW0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/I4PWzi7mlW0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/I4PWzi7mlW0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-25T04:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3EB-8Ic2OIZwesa6B6eoiZAUZqQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "AwvBucX6pfg"
    },
    "snippet": {
      "publishedAt": "2021-08-02T15:17:44Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd14 Kettlebell HIIT Workout",
      "description": "It's time for another killer Kettlebell HIIT Workout! This full body workout includes a series of different kettlebell exercises designed ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AwvBucX6pfg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AwvBucX6pfg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AwvBucX6pfg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-02T15:17:44Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GWFxrMxA9RRmIv9uOKAI9q52-as",
    "id": {
      "kind": "youtube#video",
      "videoId": "y4UIYv8XJu0"
    },
    "snippet": {
      "publishedAt": "2022-01-21T04:59:52Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Core Strength &amp; Booty Workout // Day 14 HR12WEEK 2.0",
      "description": "Today's Core Strength and Booty Workout is intense! It's a no equipment, at home workout to target the abs and glutes using ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/y4UIYv8XJu0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/y4UIYv8XJu0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/y4UIYv8XJu0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-21T04:59:52Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ucSgpRKC1rfD2qp064uc3km_5Q4",
    "id": {
      "kind": "youtube#video",
      "videoId": "_gzVFpFa7hM"
    },
    "snippet": {
      "publishedAt": "2022-03-15T03:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Power Cardio &amp; Abs Workout // Day 52 HR12WEEK 2.0",
      "description": "Get ready for an intense Cardio and Abs Workout my friends!! Our first circuit is a power cardio workout loaded with heart pumping ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_gzVFpFa7hM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_gzVFpFa7hM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_gzVFpFa7hM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-15T03:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EPwUu4fLCR9-Vhe8TqQFN96UyjM",
    "id": {
      "kind": "youtube#video",
      "videoId": "S-mrm0YW6BA"
    },
    "snippet": {
      "publishedAt": "2021-12-09T10:00:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 6: Full Body Strength Workout (Low Impact)",
      "description": "We are feeling the burn head to toe with this Full Body Strength Workout! This dumbbell only workout is loaded with strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S-mrm0YW6BA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S-mrm0YW6BA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S-mrm0YW6BA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-09T10:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "AojiTf1RKzhNilE55P_DZ6nerZw",
    "id": {
      "kind": "youtube#video",
      "videoId": "HpKRKd3R8A0"
    },
    "snippet": {
      "publishedAt": "2021-12-17T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 Day 14: Total Body Strength &amp; Cardio Workout!",
      "description": "It's the final day and today's Total Body Strength & Cardio Workout is going to leave you feeling FIERCE!! Six different total body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HpKRKd3R8A0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HpKRKd3R8A0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HpKRKd3R8A0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-17T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tpXaeli8Sh-iwcrehMFPbQae6fc",
    "id": {
      "kind": "youtube#video",
      "videoId": "MeGLywchaT0"
    },
    "snippet": {
      "publishedAt": "2022-01-07T13:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Cardio + Core Workout // Day 5 HR12WEEK 2.0",
      "description": "This HIIT Cardio and Core Workout is going to make you SWEAT! Picking up the pace today as we round off week one of my free ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MeGLywchaT0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MeGLywchaT0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MeGLywchaT0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-07T13:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8gxz3BFTH8S1PEMjC4guvp163Qs",
    "id": {
      "kind": "youtube#video",
      "videoId": "R5OBamXXQA8"
    },
    "snippet": {
      "publishedAt": "2022-01-22T04:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "45MIN No Equipment HIIT Cardio Workout // Day 15  HR12WEEK 2.0",
      "description": "Let's HIIT it! This no equipment HIIT cardio Workout is going to make you sweat! Using just our body-weight to push through a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/R5OBamXXQA8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/R5OBamXXQA8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/R5OBamXXQA8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-22T04:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "mU5GQ3N-OnzMpKz7ZvVH02i_Wxg",
    "id": {
      "kind": "youtube#video",
      "videoId": "MSmHYnIm5ZU"
    },
    "snippet": {
      "publishedAt": "2020-10-18T14:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Pilates Workout // Total Body Fusion Workout (No Equipment)",
      "description": "This HIIT Pilates Workout is a fun fusion of HIIT style training and pilates inspired exercises. No equipment needed today - we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MSmHYnIm5ZU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-18T14:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DxxbJ0oSpP0zyf_AEcFaugd1eYE",
    "id": {
      "kind": "youtube#video",
      "videoId": "3HbN4M81Lls"
    },
    "snippet": {
      "publishedAt": "2021-06-15T13:30:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2b50GLOW UP CHALLENGE // Day 4: Upper Body Tone + Sculpt workout",
      "description": "Today we are hitting that upper body with this toning + sculpting workout. These upper body exercises are going to target the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3HbN4M81Lls/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3HbN4M81Lls/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3HbN4M81Lls/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-15T13:30:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "K2NCI9WWoX7qYs5bQrZAGk02s_s",
    "id": {
      "kind": "youtube#video",
      "videoId": "DPpuaPfAYsM"
    },
    "snippet": {
      "publishedAt": "2022-02-19T04:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body STRENGTH Workout: Supersets // Day 35 HR12WEEK 2.0",
      "description": "Today's Total Body Strength workout uses dumbbells and supersets to give you that full body burn! Six different superset circuits to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/DPpuaPfAYsM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/DPpuaPfAYsM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/DPpuaPfAYsM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-19T04:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RCgsd5QZYkJfpNx4bxBd1VTHj3o",
    "id": {
      "kind": "youtube#video",
      "videoId": "JzN-LA6J-kc"
    },
    "snippet": {
      "publishedAt": "2021-04-04T13:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "HIIT Yoga Fusion Workout",
      "description": "Today's fusion HIIT workout includes a series of total body, yoga inspired exercises that will help improve strength, mobility, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JzN-LA6J-kc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-04T13:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3dO9B4L2VyhBM4v7N2RhIRz0GgU",
    "id": {
      "kind": "youtube#video",
      "videoId": "HLpZhfgv-5Y"
    },
    "snippet": {
      "publishedAt": "2021-07-21T14:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Six Pack Standing Abs Workout",
      "description": "Working on that six pack with today's ab workout. This standing only workout targets the core with a series of standing ab ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HLpZhfgv-5Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-21T14:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uAfNQ5aQG7CwwEkbepAngfyDqJs",
    "id": {
      "kind": "youtube#video",
      "videoId": "R1HR5_KK5ac"
    },
    "snippet": {
      "publishedAt": "2022-01-05T13:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Upper Body PULL Workout // Day 3 HR12WEEK 2.0",
      "description": "Today's upper body PULL workout targets the back and triceps. Using dumbbells only for this home workout we will build upper ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/R1HR5_KK5ac/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/R1HR5_KK5ac/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/R1HR5_KK5ac/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-05T13:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SOCEA3VlTAPPQZ0euqYwd_8qdXU",
    "id": {
      "kind": "youtube#video",
      "videoId": "Gtf1CjZv5vg"
    },
    "snippet": {
      "publishedAt": "2021-09-18T13:00:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51 Inner/Outer Thighs + Booty Workout (No Repeats / No Equipment)",
      "description": "Today's workout is all about the thighs and booty! We are going to target the inner and outer thighs and those glutes with a series ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Gtf1CjZv5vg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Gtf1CjZv5vg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Gtf1CjZv5vg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-18T13:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Iv9z6yCCowH3zOtSyNtM1pbuc9c",
    "id": {
      "kind": "youtube#video",
      "videoId": "eANo1HYQ94g"
    },
    "snippet": {
      "publishedAt": "2022-01-17T04:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Sculpted Back &amp; Biceps Workout // Day 11 HR12WEEK 2.0",
      "description": "This sculpted Back & Biceps Workout uses dumbbells to build strength in the upper body and create a strong sculpted back, and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eANo1HYQ94g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eANo1HYQ94g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eANo1HYQ94g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-17T04:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KvuZSpGlOYJRHID-aAw0mWm18Mo",
    "id": {
      "kind": "youtube#video",
      "videoId": "2hWItx-SxqA"
    },
    "snippet": {
      "publishedAt": "2022-01-15T04:59:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "45 MIN No Repeats HIIT Cardio Workout // Day 10 HR12WEEK 2.0",
      "description": "Who's ready for a No Repeats HIIT Cardio Workout!!? Picking up the pace and getting in that cardio today. Each body-weight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2hWItx-SxqA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2hWItx-SxqA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2hWItx-SxqA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-15T04:59:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tEXkMXM-47gdWAvWSzhpYS66z_A",
    "id": {
      "kind": "youtube#video",
      "videoId": "caKKIShoywA"
    },
    "snippet": {
      "publishedAt": "2019-11-23T05:38:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Fierce TABATA Workout Finisher // No equipment Tabata",
      "description": "Use this TABATA workout as a killer workout finisher or just on it's own for a quick, intense sweat session. Today's Tabata includes ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/caKKIShoywA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/caKKIShoywA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/caKKIShoywA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-23T05:38:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "r6NhMSweMYeGUQZoIrXIuPNdJTw",
    "id": {
      "kind": "youtube#video",
      "videoId": "5B7q_7I4VFY"
    },
    "snippet": {
      "publishedAt": "2021-04-22T16:15:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer KETTLEBELL HIIT Workout // Total Body Sculpt",
      "description": "Get ready to dive into today's killer Kettlebell HIIT workout! This total body HIIT workout uses a kettlebell for resistance and targets ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5B7q_7I4VFY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5B7q_7I4VFY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5B7q_7I4VFY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-22T16:15:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "z6zPxl3t5GcUu3syiXci1eGBlYc",
    "id": {
      "kind": "youtube#video",
      "videoId": "mmE2xpIStTo"
    },
    "snippet": {
      "publishedAt": "2022-03-03T04:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Lower Body Strength Workout // Day 43 HR12WEEK 2.0",
      "description": "It's leg day and this Lower Body Strength Workout is going to be FIRE!!! WE have 4 different lower body training circuits that ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mmE2xpIStTo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mmE2xpIStTo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mmE2xpIStTo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-03T04:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_9HQjC69q6HIkGEQ4yikn0mNS-Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "nk4nBvfu9Gs"
    },
    "snippet": {
      "publishedAt": "2022-03-10T04:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Lower Body Supersets Workout // Day 48 HR12WEEK 2.0",
      "description": "Today's lower body supersets workout will target those legs and glutes with 8 different superset circuits. Alernating between ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/nk4nBvfu9Gs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/nk4nBvfu9Gs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/nk4nBvfu9Gs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-10T04:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "KuPDbNnP87JCP_5ty6G_LoNqou8",
    "id": {
      "kind": "youtube#video",
      "videoId": "elECoZIRkvU"
    },
    "snippet": {
      "publishedAt": "2021-09-05T13:30:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact TABATA // No Jumping",
      "description": "This low impact, no jumping TABATA workout is quick and intense and uses a light set of dumbbells for a little extra spice!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/elECoZIRkvU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/elECoZIRkvU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/elECoZIRkvU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-05T13:30:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5eF9J96uGy24_3vkcLJIdly5Rss",
    "id": {
      "kind": "youtube#video",
      "videoId": "WQfYllsPirM"
    },
    "snippet": {
      "publishedAt": "2022-01-28T04:59:55Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEATS WORKOUT: Cardio, Abs &amp; Booty // Day 19 HR12WEEK 2.0",
      "description": "This NO REPEATS Workout is a killer combo of cardio, abs and booty exercises that will help build strength and burn fat.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WQfYllsPirM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WQfYllsPirM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WQfYllsPirM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-28T04:59:55Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UHnDHnlEt9HSlylh5A1mAnIktG4",
    "id": {
      "kind": "youtube#video",
      "videoId": "1rjE2zh3Luc"
    },
    "snippet": {
      "publishedAt": "2021-01-06T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30 MINUTE HIIT Workout // With Weights",
      "description": "Get my New NUTRITION GUIDE! https://heatherrobertson.com/shop/ We are adding weights to todays TOTAL BODY HIIT workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1rjE2zh3Luc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1rjE2zh3Luc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1rjE2zh3Luc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-06T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VqYk2d7ekfn1lgRD_nXTSx6biY8",
    "id": {
      "kind": "youtube#video",
      "videoId": "2xBCsi6PR6M"
    },
    "snippet": {
      "publishedAt": "2021-10-26T13:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 25Min Total Body AMRAP Workout",
      "description": "Get ready for a spicy Total Body AMRAP Workout! This is a You vs You workout meaning that you are working at YOUR own pace, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2xBCsi6PR6M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2xBCsi6PR6M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2xBCsi6PR6M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-26T13:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HNvdks9f3jsfJZO8Sfc-rdvPQ4Y",
    "id": {
      "kind": "youtube#video",
      "videoId": "Qff2gyaBa4g"
    },
    "snippet": {
      "publishedAt": "2020-08-23T14:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Yoga Inspired // FULL BODY WORKOUT // No Equipment, No Jumping!",
      "description": "Get ready for a total body, fusion workout! I'm using some yoga inspired moves today in this full body home workout.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Qff2gyaBa4g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-23T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "r0_sHBOTuwuV-XHUAJcLcRZMuCI",
    "id": {
      "kind": "youtube#video",
      "videoId": "d1s-nkC44dg"
    },
    "snippet": {
      "publishedAt": "2022-03-21T04:00:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body Toning Workout // Day 56 HR12WEEK 2.0",
      "description": "Hello Week 12!!! IT's our final week of the HR12WEEK 2.0 and we are starting off with an Upper Body Toning Workout. Strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/d1s-nkC44dg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/d1s-nkC44dg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/d1s-nkC44dg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-21T04:00:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "S8vvQfjHmjOjlw3YH6mrDexOoyg",
    "id": {
      "kind": "youtube#video",
      "videoId": "uvDkLAAJo6o"
    },
    "snippet": {
      "publishedAt": "2022-01-25T04:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Brutal LEG STRENGTH Workout // Day 17 HR12WEEK 2.0",
      "description": "Today's LEG STRENGTH workout is brutal - in the best way possible of course! 4 different strength training circuits loaded with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/uvDkLAAJo6o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/uvDkLAAJo6o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/uvDkLAAJo6o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-25T04:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dx_AC18NWQ8ou_IBLBhtIoPWfr4",
    "id": {
      "kind": "youtube#video",
      "videoId": "oWUNkZrlkho"
    },
    "snippet": {
      "publishedAt": "2021-12-14T14:00:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 Day 11: Yoga Fusion // Strength &amp; Mobility Workout",
      "description": "Today's Yoga Fusion workout is a combo of Yoga inspired movements designed for strength and mobility. Using no equipment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oWUNkZrlkho/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oWUNkZrlkho/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oWUNkZrlkho/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-14T14:00:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1RyBdQDO4ch0qkv-g8du0yhT_a4",
    "id": {
      "kind": "youtube#video",
      "videoId": "NszpvE0kClM"
    },
    "snippet": {
      "publishedAt": "2019-04-09T23:52:53Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "CRAZY Full Body HIIT Workout // NO REPEATS + No Equipment",
      "description": "Visit: https://www.heatherrobertson.com CRAZY Full Body HIIT Workout // NO REPEATS + No Equipment 40 different bodyweight ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NszpvE0kClM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-09T23:52:53Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YBjS4ZGqBUKAepL-WkIFa0ct_nk",
    "id": {
      "kind": "youtube#video",
      "videoId": "yugCf8-uVMk"
    },
    "snippet": {
      "publishedAt": "2020-09-09T14:00:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact HIIT // Strength + Cardio Workout (with weights)",
      "description": "Today's low Impact HIIT workout focuses on both strength and cardio! We are using dumbbells in this home workout to build lean ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yugCf8-uVMk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yugCf8-uVMk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yugCf8-uVMk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-09T14:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "A_JB8-z1nkeOECHW4SZwUXqcwkw",
    "id": {
      "kind": "youtube#video",
      "videoId": "AhLwpiy3AP4"
    },
    "snippet": {
      "publishedAt": "2020-12-30T15:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact STRENGTH &amp; CARDIO Workout // Dumbbell Workout",
      "description": "Fuel your workouts with my NEW NUTRITION GUIDE! https://heatherrobertson.com/product/nutrition-guide/ Today's Low Impact ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/AhLwpiy3AP4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/AhLwpiy3AP4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/AhLwpiy3AP4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-30T15:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GB9oWnNTq-yZmaCHc9tagsa1ajM",
    "id": {
      "kind": "youtube#video",
      "videoId": "WADreATRr-w"
    },
    "snippet": {
      "publishedAt": "2021-09-09T13:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd14 Killer Kettlebell AMRAP Workout",
      "description": "It's AMRAP time! This Kettlebell AMRAP is all about doing YOUR best, working at YOUR own pace and getting in as many rounds ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WADreATRr-w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WADreATRr-w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WADreATRr-w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-09T13:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_hT28zVzgqXvjz5rZIfUn35hzGQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "oZdJrzNaXPE"
    },
    "snippet": {
      "publishedAt": "2020-10-29T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "No Jumping HIIT Workout // With Dumbbells",
      "description": "Today's No Jumping HIIT Workout is extra special with some brand new tracks from Epidemic Sound that you can download and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/oZdJrzNaXPE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/oZdJrzNaXPE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/oZdJrzNaXPE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-29T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RJ_54xouotONULK-XcXBkIyMFTg",
    "id": {
      "kind": "youtube#video",
      "videoId": "q46cmWgPRuc"
    },
    "snippet": {
      "publishedAt": "2021-05-20T14:41:59Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Stability Ball Abs Workout",
      "description": "Time for an ab workout with the good ol' stability ball! It is such a handy, inexpensive piece of equipment that can really increase ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/q46cmWgPRuc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/q46cmWgPRuc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/q46cmWgPRuc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-20T14:41:59Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4fZyHRgr1mYmvmFfIriOy32Phmc",
    "id": {
      "kind": "youtube#video",
      "videoId": "9UXhFOPdDRU"
    },
    "snippet": {
      "publishedAt": "2022-01-20T04:59:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chest, Tri&#39;s &amp; Shoulders: Upper Body Strength Workout // Day 13 HR12WEEK 2.0",
      "description": "Today's Upper Body Strength Workout targets the Chest, Triceps and shoulders using dumbbells only. Six different upper body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9UXhFOPdDRU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9UXhFOPdDRU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9UXhFOPdDRU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-20T04:59:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BosIIsX_FQkGF6UP1tQf90PQC0g",
    "id": {
      "kind": "youtube#video",
      "videoId": "7WQRjMjCkvI"
    },
    "snippet": {
      "publishedAt": "2020-05-11T14:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Day 2 Home Workout Challenge // Leg Workout (No Equipment)",
      "description": "It's leg day!!! Day two of my 7 day home workout challenge is all about creating strong, lean legs. We're targeting the hamstrings, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/7WQRjMjCkvI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/7WQRjMjCkvI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/7WQRjMjCkvI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-11T14:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zxK_BDrrlBEbiDv-wAzpc-frfiU",
    "id": {
      "kind": "youtube#video",
      "videoId": "FLgdz0kwkH8"
    },
    "snippet": {
      "publishedAt": "2021-12-10T10:00:12Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 7: HIIT Cardio &amp; Abs // No Repeats Workout",
      "description": "Picking up the pace today with an intense HIIT cardio and abs workout! A no repeats workout sure to get that heart rate up, set ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FLgdz0kwkH8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FLgdz0kwkH8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FLgdz0kwkH8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-10T10:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4GVhxDMHc_inKTa3wnjqYFja6XU",
    "id": {
      "kind": "youtube#video",
      "videoId": "SZ7ItOPzZJ8"
    },
    "snippet": {
      "publishedAt": "2022-02-08T05:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Leg Day Shred Workout // Day 27 HR12WEEK 2.0",
      "description": "This Leg Day Workout is going to be fire!!! Three straight rounds of each Lower body Strength exercise followed by a quick cardio ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SZ7ItOPzZJ8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SZ7ItOPzZJ8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SZ7ItOPzZJ8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-08T05:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jLuZgvKlc-yCrgdRQdCadVr1dJY",
    "id": {
      "kind": "youtube#video",
      "videoId": "GuW2DKONZBU"
    },
    "snippet": {
      "publishedAt": "2021-11-22T14:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 23 // THE DAILY10: 10 Min Kickboxing Tabata Workout",
      "description": "Tabata time! This intense Kickboxing Tabata workout is a just a quick 10 minute workout that will get that heart rate up and have ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GuW2DKONZBU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-22T14:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "tEgw0NSOYBbn8pk2QVGo_ShN3OM",
    "id": {
      "kind": "youtube#video",
      "videoId": "D2zG_we-PLc"
    },
    "snippet": {
      "publishedAt": "2022-03-01T04:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body Supersets Workout // Day 42 HR12WEEK 2.0",
      "description": "Today's Upper Body Supersets Workout is loaded with arm, chest, back and shoulder exercises for a complete upper body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/D2zG_we-PLc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/D2zG_we-PLc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/D2zG_we-PLc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-01T04:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3ydio1AAQcm2kHl6PsoD5JqXFos",
    "id": {
      "kind": "youtube#video",
      "videoId": "KUQ5eWKQDkk"
    },
    "snippet": {
      "publishedAt": "2021-06-13T14:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2b50GLOW UP CHALLENGE // DAY 2: Cardio &amp; Abs Workout",
      "description": "It's day 2 of the 7 Day Glow Up challenge and today's Cardio And Abs workouts is a serious sweat sesh. No equipment needed in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KUQ5eWKQDkk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KUQ5eWKQDkk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KUQ5eWKQDkk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-13T14:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I0wYXf6s8cK7o1qTQv4OQPVtaI4",
    "id": {
      "kind": "youtube#video",
      "videoId": "s_39i2XmyM8"
    },
    "snippet": {
      "publishedAt": "2022-01-09T13:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Tabata Legs &amp; Abs Workout // Day 7 HR12WEEK 2.0",
      "description": "Woohoo it's TABATA Time!!! This legs and abs workout is intense with 8 different tabata circuits performed for 4 rounds each!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s_39i2XmyM8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s_39i2XmyM8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s_39i2XmyM8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-09T13:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vMnD_o-rbpMBaVLV9bV2XLssvhs",
    "id": {
      "kind": "youtube#video",
      "videoId": "ei7erdqFkXU"
    },
    "snippet": {
      "publishedAt": "2022-02-01T05:00:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Lower Body Stength: Tri-Sets Leg Workout // Day 22 HR12WEEK 2.0",
      "description": "It's Leg Day!! This lower body strength workout includes a series of tri-sets that combine leg strength exrcises with plymotric, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/ei7erdqFkXU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/ei7erdqFkXU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/ei7erdqFkXU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-01T05:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XUnVNTir8PnKoA2l1GkFYjak100",
    "id": {
      "kind": "youtube#video",
      "videoId": "fifVowt0NlY"
    },
    "snippet": {
      "publishedAt": "2022-01-14T05:00:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Booty Builder: Lower Body Strength Workout // Day 9 HR12WEEK 2.0",
      "description": "Today's booty builder is a lower body strength workout with a focus on the glutes. Four different circuits loaded with leg and booty ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fifVowt0NlY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fifVowt0NlY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fifVowt0NlY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-14T05:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YJnrWhFNnwLVVszZzNZLs4GyoGI",
    "id": {
      "kind": "youtube#video",
      "videoId": "NFz_Z2WuO44"
    },
    "snippet": {
      "publishedAt": "2019-10-06T01:40:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LEGS + BICEPS Workout",
      "description": "LEGS + BICEPS Workout Access the Full 28 Day Challenge Playlist Here: https://goo.gl/DFjo4d L I N K S  Website: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NFz_Z2WuO44/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NFz_Z2WuO44/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NFz_Z2WuO44/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-06T01:40:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4HFXJEs-pwsxkxW1VC_SqZXhmLg",
    "id": {
      "kind": "youtube#video",
      "videoId": "u607ILcxmTY"
    },
    "snippet": {
      "publishedAt": "2022-03-12T04:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Strength Workout // Day 50 HR12WEEK 2.0",
      "description": "It's day 50 and this Full Body Strength Workout is just what we need to finish off the week strong. A total body workout using ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/u607ILcxmTY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/u607ILcxmTY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/u607ILcxmTY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-12T04:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zu-sKNDXiFWO5UPTNSjfT1kKvyU",
    "id": {
      "kind": "youtube#video",
      "videoId": "C6MyDJMddYE"
    },
    "snippet": {
      "publishedAt": "2021-12-05T14:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 2: Lower Body Strength Workout",
      "description": "It's day two and todays Lower body strength workout is nothing short of Fierce! This series of dumbbell exercises will set those ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C6MyDJMddYE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C6MyDJMddYE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C6MyDJMddYE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-05T14:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LciwGgQKLtDDX0ICeitd8rd3_vI",
    "id": {
      "kind": "youtube#video",
      "videoId": "V45sxyJBEbI"
    },
    "snippet": {
      "publishedAt": "2021-03-17T13:30:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body HIIT Workout // No Equipment",
      "description": "Nutrition Guide: https://heatherrobertson.com/shop/ My legs were on fire by the end of today's total body HIIT Workout oww oww!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/V45sxyJBEbI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/V45sxyJBEbI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/V45sxyJBEbI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-17T13:30:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4zvyuuFuzT8lZMWQ2-QlQSMGoBE",
    "id": {
      "kind": "youtube#video",
      "videoId": "R0K6f_0Xxl8"
    },
    "snippet": {
      "publishedAt": "2020-12-27T14:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "25 MIN NO REPEATS HIIT // Workout with Dumbbells",
      "description": "Get my NEW NUTRITION GUIDE! https://heatherrobertson.com/shop/ In this 25 minute no repeat HIIT workout we are going to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/R0K6f_0Xxl8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/R0K6f_0Xxl8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/R0K6f_0Xxl8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-27T14:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "egQ5Rqu7S1JPf7EK6SoD7Damilw",
    "id": {
      "kind": "youtube#video",
      "videoId": "0zN_yeZdY1E"
    },
    "snippet": {
      "publishedAt": "2020-12-06T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30 Minute Low Impact HIIT Workout (No Equipment)",
      "description": "We are getting in a full body workout with today's low impact HIIT! No equipment needed for this one and no jumping which is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0zN_yeZdY1E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0zN_yeZdY1E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0zN_yeZdY1E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-06T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ISBVLPYeXsqiDeFA9D0-o3TcMAg",
    "id": {
      "kind": "youtube#video",
      "videoId": "BkOCzxS37aw"
    },
    "snippet": {
      "publishedAt": "2021-12-15T14:00:12Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 Day 12: Back &amp; Arms Sculpt // Upper Body Strength",
      "description": "Get ready for an intense Upper Body Strength Workout. This Back and Arms Sculpting workout uses dumbbells for a series burn in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/BkOCzxS37aw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/BkOCzxS37aw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/BkOCzxS37aw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-15T14:00:12Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "YwqJZs28x_Wzxc3Hphuoo-mC8Eg",
    "id": {
      "kind": "youtube#video",
      "videoId": "fs_1rvQrIck"
    },
    "snippet": {
      "publishedAt": "2022-03-24T03:59:45Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Glute Activation + Lower Body Strength  // Day 58 HR12WEEK 2.0",
      "description": "It's LEG DAY!! We are starting with some glute activation - feel free to add in a band or ankle weights for an extra spicy series!",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/fs_1rvQrIck/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/fs_1rvQrIck/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/fs_1rvQrIck/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-24T03:59:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DghBbOf7MOeGvCJswxyiNnR65Nw",
    "id": {
      "kind": "youtube#video",
      "videoId": "UvBLAJDnqao"
    },
    "snippet": {
      "publishedAt": "2021-05-23T15:00:24Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "TABATA // Total Body Workout (No Weights)",
      "description": "Today's total body TABATA workout is quick, sweaty and requires no equipment. Make sure you spend time warming up on your ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UvBLAJDnqao/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UvBLAJDnqao/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UvBLAJDnqao/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-23T15:00:24Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5-f7LzoH6oKIRjFaUiTwgMRltS4",
    "id": {
      "kind": "youtube#video",
      "videoId": "Oibkao7yMSA"
    },
    "snippet": {
      "publishedAt": "2021-05-16T15:25:34Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEAT HIIT Workout // with dumbbells",
      "description": "New No Repeats HIIT workout!! Using dumbbells only for resistance in today's quick and intense workout. We are going to power ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Oibkao7yMSA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Oibkao7yMSA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Oibkao7yMSA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-16T15:25:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-gcsdvX7-AMOhFk04UG6dFBBPY0",
    "id": {
      "kind": "youtube#video",
      "videoId": "zxzXKepjvlo"
    },
    "snippet": {
      "publishedAt": "2021-03-03T14:00:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Legs &amp; Booty // Workout with Ankle Weights",
      "description": "Get ready to feel the burn! This leg and booty workout uses ankle weights for added resistance. If you don't have ankle weights ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zxzXKepjvlo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zxzXKepjvlo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zxzXKepjvlo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-03T14:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "PWHd46euFbcNkjXQ2mNmVzWcat0",
    "id": {
      "kind": "youtube#video",
      "videoId": "FZKiXSXkSbk"
    },
    "snippet": {
      "publishedAt": "2021-07-07T13:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact HIIT Workout (No Equipment)",
      "description": "No jumping and no equipment needed in today's Low Impact HIIT workout! This total body workout is quiet, easy on the joints and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/FZKiXSXkSbk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/FZKiXSXkSbk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/FZKiXSXkSbk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-07T13:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "fd61GSE4w15Kc5PFconZ3uIfO1I",
    "id": {
      "kind": "youtube#video",
      "videoId": "1nGysZNUBTk"
    },
    "snippet": {
      "publishedAt": "2019-05-17T03:28:40Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "TONED ARMS + SHOULDERS // Arm Day Finisher Workout",
      "description": "Visit: https://www.heatherrobertson.com Tone, tighten and define those arms and shoulders by adding this quick workout as an ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1nGysZNUBTk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1nGysZNUBTk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1nGysZNUBTk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-05-17T03:28:40Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uVHSMd-aj-TGcxxkGS226D-hsBY",
    "id": {
      "kind": "youtube#video",
      "videoId": "CzvyHDUFgho"
    },
    "snippet": {
      "publishedAt": "2022-02-15T07:11:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Lower Body Supersets: Leg Workout // Day 32 HR12WEEK 2.0",
      "description": "Today's Leg Workout includes 6 lower body supersets to target the legs and glutes. The goal today is to challenge yourself with ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CzvyHDUFgho/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CzvyHDUFgho/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CzvyHDUFgho/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-15T07:11:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "x0I05U6ehL-al5R1u0HQZPEHwU0",
    "id": {
      "kind": "youtube#video",
      "videoId": "VGpfOm84YMA"
    },
    "snippet": {
      "publishedAt": "2020-01-12T02:58:29Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "How To: Low Impact Modifications",
      "description": "Today I'm running you through some low impact modifications of some of my favorite exercises! I love jumping, explosive, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VGpfOm84YMA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VGpfOm84YMA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VGpfOm84YMA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-12T02:58:29Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "oITi7otJVaJbmLJH40gJmgBf2aE",
    "id": {
      "kind": "youtube#video",
      "videoId": "LCOy-6BcRkI"
    },
    "snippet": {
      "publishedAt": "2020-05-24T21:41:30Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "10 minute POWER CARDIO Workout // No Equipment",
      "description": "It's cardio time!! Today's quick 10 minute power cardio is intense and sweaty! 10 different cardio focused moves to get that heart ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LCOy-6BcRkI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LCOy-6BcRkI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LCOy-6BcRkI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-24T21:41:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "TdsbKOklcPW1xzqtGNh9pibNQXM",
    "id": {
      "kind": "youtube#video",
      "videoId": "WBQ2_vxr9-g"
    },
    "snippet": {
      "publishedAt": "2015-11-27T18:54:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "ABS + BOOTY Workout",
      "description": "Abs + Booty Workout Access the Full 28 Day Challenge Playlist Here: https://goo.gl/DFjo4d L I N K S  Website: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WBQ2_vxr9-g/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WBQ2_vxr9-g/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WBQ2_vxr9-g/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2015-11-27T18:54:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6OMLeHmVewGR7LXrOIs-Xbi28C4",
    "id": {
      "kind": "youtube#video",
      "videoId": "GoiZK4AsizM"
    },
    "snippet": {
      "publishedAt": "2022-02-11T05:01:22Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Abs &amp; Core Strength Workout // Day 29 HR12WEEK 2.0",
      "description": "A killer Abs & Core Strength Workout is on the menu today! This weighted abs circuit is all about building a strong, functional and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/GoiZK4AsizM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/GoiZK4AsizM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/GoiZK4AsizM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-11T05:01:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "3TS-nC8GGu5p1QBYA2sgFsGECVM",
    "id": {
      "kind": "youtube#video",
      "videoId": "VRs-dyW_ljE"
    },
    "snippet": {
      "publishedAt": "2018-09-22T20:49:26Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "The BEST Exercises to Target Back Fat //10 Minute At Home Workout",
      "description": "Had a few request for this one! Target back fat with these 5 best exercises. This at home workout will help you lose back fat by ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VRs-dyW_ljE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VRs-dyW_ljE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VRs-dyW_ljE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2018-09-22T20:49:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uz1GCv0qLP0xTRCZL7pWhBEDlnU",
    "id": {
      "kind": "youtube#video",
      "videoId": "dS9IvutXV2U"
    },
    "snippet": {
      "publishedAt": "2022-03-07T05:02:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Arms &amp; Abs Workout // Day 46 HR12WEEK 2.0",
      "description": "Today's Arms and Abs Workout is a perfect way to start off Week 10!! Two Circuits loaded with arm and ab exercises using ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dS9IvutXV2U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dS9IvutXV2U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dS9IvutXV2U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-03-07T05:02:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yFw_BgIlx3v9GGbVPLJltAPOLNU",
    "id": {
      "kind": "youtube#video",
      "videoId": "hveDWFQoTew"
    },
    "snippet": {
      "publishedAt": "2022-01-03T14:00:26Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Abs &amp; Glutes Workout // Day 2 HR12WEEK 2.0",
      "description": "Abs and Glutes workout today - get ready to feel the burn! No equipment needed for this one but you can always add in a ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/hveDWFQoTew/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/hveDWFQoTew/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/hveDWFQoTew/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-03T14:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2VpooCqHyGYhuSjrhmDPMw1GCMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "Jl4L648n-jc"
    },
    "snippet": {
      "publishedAt": "2021-12-29T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20 MIN Abs &amp; Booty Workout \ud83c\udf51",
      "description": "We are targeting the Abs & Booty with today's 20 min workout from home. No equipment needed - we will be using just our ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Jl4L648n-jc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Jl4L648n-jc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Jl4L648n-jc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-29T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1ASToGCZ0HhGo-e9hf6BRepIpU4",
    "id": {
      "kind": "youtube#video",
      "videoId": "qfyn4LD9SY8"
    },
    "snippet": {
      "publishedAt": "2020-04-03T13:00:26Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEAT Full Body Workout",
      "description": "Today's no repeat workout includes strength and cardio focused exercises that are sure to get you sweating! These no repeat ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/qfyn4LD9SY8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/qfyn4LD9SY8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/qfyn4LD9SY8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-04-03T13:00:26Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aZjP8GBI-Zy19rPGVA8GgtSfLdI",
    "id": {
      "kind": "youtube#video",
      "videoId": "p3K0c3cGuq0"
    },
    "snippet": {
      "publishedAt": "2021-02-14T14:00:13Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Extreme HIIT Cardio Workout // No Repeats (No Equipment)",
      "description": "OK team this extreme HIIT cardio workout is no joke! We are getting that heart rate up and working on our cardiovascular ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/p3K0c3cGuq0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/p3K0c3cGuq0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/p3K0c3cGuq0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-14T14:00:13Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_ydNI07fR6hd8z3KRInGw5tqFco",
    "id": {
      "kind": "youtube#video",
      "videoId": "M60AVSKVqUA"
    },
    "snippet": {
      "publishedAt": "2019-10-08T00:14:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer HIIT CARDIO Workout // No Equipment",
      "description": "Ok guys get ready for a quick and sweaty session! Today's HIIT Cardio Workout is a killer! We will be powering through 6 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/M60AVSKVqUA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/M60AVSKVqUA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/M60AVSKVqUA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-08T00:14:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VmNSW8JpJeQr3Cp7SUkI28sdRqg",
    "id": {
      "kind": "youtube#video",
      "videoId": "31BiQiXk8jQ"
    },
    "snippet": {
      "publishedAt": "2020-05-10T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 1 Home Workout Challenge // Total Body HIIT (No Equipment)",
      "description": "Today is day ONE of my 7 day at home workout challenge! We are powering through a full body HIIT workout. No equipment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/31BiQiXk8jQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/31BiQiXk8jQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/31BiQiXk8jQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-10T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-BDA64YxxaANTUm_tPRipGIN2KQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "XgRAFwuCjuw"
    },
    "snippet": {
      "publishedAt": "2021-01-13T15:00:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Arm Toning &amp; Strength // Dumbbell Only Workout",
      "description": "Nutrition Guide: https://heatherrobertson.com/shop/ This Arm Toning workout uses dumbbells and supersets to build strength, tone ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/XgRAFwuCjuw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/XgRAFwuCjuw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/XgRAFwuCjuw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-13T15:00:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CJBcQHrhSn60IOYrawYnrk3QmJ8",
    "id": {
      "kind": "youtube#video",
      "videoId": "tu1wC6uzB34"
    },
    "snippet": {
      "publishedAt": "2021-11-19T14:00:34Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "DAY 20 // THE DAILY10: 10 Min Full Body Pilates Workout",
      "description": "This full body pilates workout is great for toning, sculpting and strengthening the body through pilates inspired exercises.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tu1wC6uzB34/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tu1wC6uzB34/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tu1wC6uzB34/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-11-19T14:00:34Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jXvMGBWaiM1OFWeic-MKuz-kg2E",
    "id": {
      "kind": "youtube#video",
      "videoId": "yYXo9fwfGQ8"
    },
    "snippet": {
      "publishedAt": "2021-10-05T15:27:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body HIIT Workout // With Weights",
      "description": "Today's Full Body HIIT Workout is going to bring the heat!! This workout is loaded with plenty of total body compound exercises ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yYXo9fwfGQ8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yYXo9fwfGQ8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yYXo9fwfGQ8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-05T15:27:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JruUZwGXfxue0Fhki30tcSM1ZDw",
    "id": {
      "kind": "youtube#video",
      "videoId": "PEIQKel5MHQ"
    },
    "snippet": {
      "publishedAt": "2022-02-22T04:59:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Lean Legs Bootcamp // Day 37 HR12WEEK 2.0",
      "description": "Today's lean legs bootcamp is a killer combo of strength exercises and powerful cardio bursts to build lower body strength and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PEIQKel5MHQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PEIQKel5MHQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PEIQKel5MHQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-02-22T04:59:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "EErEV_Ew6pFWCLu_qUHWG1hYq2A",
    "id": {
      "kind": "youtube#video",
      "videoId": "H7eFjFoGkHY"
    },
    "snippet": {
      "publishedAt": "2021-12-08T10:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 5: Active Recovery // Full Body Stretch + Mobility",
      "description": "Time to slow it down with this Active recovery workout. That body has put in some serious work over the past few days so we are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H7eFjFoGkHY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H7eFjFoGkHY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H7eFjFoGkHY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-08T10:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4M3M5yBk-9ysaUboKSvHTiMdCdA",
    "id": {
      "kind": "youtube#video",
      "videoId": "Q13Gebj3f5c"
    },
    "snippet": {
      "publishedAt": "2021-04-13T13:00:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "ARM Toning &amp; Strength // Dumbbell Only Workout",
      "description": "Today's arm workout will target the biceps, triceps and shoulders with a series of upper body exercises that will have you feeling ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Q13Gebj3f5c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Q13Gebj3f5c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Q13Gebj3f5c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-13T13:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "uaUCF2_av1vCNAJIdQKRJu0TUrk",
    "id": {
      "kind": "youtube#video",
      "videoId": "B2bHnKeBcYQ"
    },
    "snippet": {
      "publishedAt": "2021-10-10T15:12:56Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LOW IMPACT HIIT Workout (With Weights)",
      "description": "Today's Low Impact HIIT Workout with weights will challenge you, get that heart rate up and have you sweating in no time.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/B2bHnKeBcYQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/B2bHnKeBcYQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/B2bHnKeBcYQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-10T15:12:56Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "CRVW_fm4sHXTEZDUrWSssxluGjs",
    "id": {
      "kind": "youtube#video",
      "videoId": "LF-fA0g9KNg"
    },
    "snippet": {
      "publishedAt": "2021-12-04T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 1: Arms &amp; Shoulders // Upper Body Strength Workout",
      "description": "We are Starting off Day 1 of my Fierce14 workout program with an upper body workout using just dumbbells. Focusing on arms ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/LF-fA0g9KNg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/LF-fA0g9KNg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/LF-fA0g9KNg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-04T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "iz8tx-2uotMGdUfK1v3R2GtGYl4",
    "id": {
      "kind": "youtube#video",
      "videoId": "kQTxdfOpzd8"
    },
    "snippet": {
      "publishedAt": "2021-04-27T15:07:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Mini Band ABS &amp; BOOTY Workout",
      "description": "Feel the BURN in today's mini band Abs & Booty Workout. Using just a mini band for resistance we will be getting through a series ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/kQTxdfOpzd8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/kQTxdfOpzd8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/kQTxdfOpzd8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-27T15:07:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "E6wW56snHj86PqK50g9TfOLVLvM",
    "id": {
      "kind": "youtube#video",
      "videoId": "15xJnnqlJ34"
    },
    "snippet": {
      "publishedAt": "2020-08-26T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Sculpted Upper Body // Dynamic + Isometric Workout",
      "description": "We are sculpting out that upper body with today's superset dumbbell workout! Combining both dynamic and isometric exercises in ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/15xJnnqlJ34/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/15xJnnqlJ34/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/15xJnnqlJ34/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-26T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "OPbxmelFGs2-2qZuFh428oY4JxY",
    "id": {
      "kind": "youtube#video",
      "videoId": "MIPC31kYPW4"
    },
    "snippet": {
      "publishedAt": "2019-06-26T00:17:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Intense FULL BODY STRENGTH Workout",
      "description": "Visit: https://www.heatherrobertson.com Today's full body strength workout is intense! We are completing each strength exercise 4 ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/MIPC31kYPW4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/MIPC31kYPW4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/MIPC31kYPW4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-06-26T00:17:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "usatvF00oyNMeoqAdem69ZUKGaU",
    "id": {
      "kind": "youtube#video",
      "videoId": "_rLEvOllG0k"
    },
    "snippet": {
      "publishedAt": "2020-11-22T15:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Intense LOW IMPACT HIIT // Total body No Jumping Workout",
      "description": "We are getting in a total body workout with this intense Low Impact HIIT! No jumping exercises makes this a perfect apartment ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_rLEvOllG0k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_rLEvOllG0k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_rLEvOllG0k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-22T15:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ogq-MvBaAwbLmhki6ab4JoF1AbU",
    "id": {
      "kind": "youtube#video",
      "videoId": "0TfTFNpYvvE"
    },
    "snippet": {
      "publishedAt": "2020-09-06T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer FULL BODY HIIT // Kettlebell (or Dumbbell) Workout",
      "description": "Today's full body Kettlebell HIIT workout is a killer!! Two HIIT style circuits to get through full of some intense total body kettlebell ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0TfTFNpYvvE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0TfTFNpYvvE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0TfTFNpYvvE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-06T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "SJz8FkzWUSTpCjYIDyMLhFo2ZzQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "g5_FM9kameA"
    },
    "snippet": {
      "publishedAt": "2021-04-15T13:30:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "FIERCE Full Body Workout // Strength + Cardio",
      "description": "Get ready to challenge yourself with today's Fierce Full Body Workout. Combining both strength and cardio exercises will have ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g5_FM9kameA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g5_FM9kameA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g5_FM9kameA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-15T13:30:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zXU9LaOjK4WTVtbTDJXUjbMtJWQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "frwuCgRcekU"
    },
    "snippet": {
      "publishedAt": "2021-12-27T14:00:30Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30 MIN No Repeats HIIT Workout With Weights / Total Body",
      "description": "Today's 30 min No Repeats HIIT Workout with weights is a killer! Only one round of each exercise to keep you on your toes.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/frwuCgRcekU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/frwuCgRcekU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/frwuCgRcekU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-27T14:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ozcqXv7Yubu8oPcD1MtKlGAPqks",
    "id": {
      "kind": "youtube#video",
      "videoId": "yHg0xSd8t7M"
    },
    "snippet": {
      "publishedAt": "2021-08-04T16:59:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chest &amp; Back Supersets // STRENGTH Workout",
      "description": "Grab those dumbbells! Today we are working on upper body strength with this chest and back supersets workout! Each superset ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yHg0xSd8t7M/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yHg0xSd8t7M/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yHg0xSd8t7M/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-04T16:59:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "p0DQjYd0A9j-2YXo5gQ4krRAn40",
    "id": {
      "kind": "youtube#video",
      "videoId": "2zQ89vkNnNg"
    },
    "snippet": {
      "publishedAt": "2020-08-12T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "FIERCE Full Body HIIT Workout // NO REPEATS + No Equipment",
      "description": "Today's full body HIIT is going to be fierce!! No repeats and no equipment in today's home workout. We will only be performing ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2zQ89vkNnNg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-08-12T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "QqginocHFAcz62MOQp_0wSi-h3E",
    "id": {
      "kind": "youtube#video",
      "videoId": "8fJk2ZLRoIE"
    },
    "snippet": {
      "publishedAt": "2020-10-07T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body YOGA FUSION // HIIT Workout (No Equipment)",
      "description": "This full Body YOGA FUSION HIIT Workout includes a series of yoga inspired exercises that will help build strength, mobility, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/8fJk2ZLRoIE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/8fJk2ZLRoIE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/8fJk2ZLRoIE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-07T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vWaogwLthurCQw1xWPaIyE1QEy8",
    "id": {
      "kind": "youtube#video",
      "videoId": "4jKjv1e1ohI"
    },
    "snippet": {
      "publishedAt": "2021-07-14T13:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body MINI BAND Workout",
      "description": "This total body workout uses a mini band to target the legs, upper body and core. We're going to get that heart rate up and focus ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4jKjv1e1ohI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4jKjv1e1ohI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4jKjv1e1ohI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-14T13:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BP-Qy97CiLuVlU4N_jWVuicdNDE",
    "id": {
      "kind": "youtube#video",
      "videoId": "-h6t_ZFvDGk"
    },
    "snippet": {
      "publishedAt": "2020-09-30T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LOW IMPACT // Total Body HIIT Workout with Weights (No Jumping)",
      "description": "No jumping in today's total body workout with weights! This low impact HIIT workout is apartment friendly and easy on the knees.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-h6t_ZFvDGk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-h6t_ZFvDGk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-h6t_ZFvDGk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-30T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "DwBs6VSAShi8dr8xSiUg7XSIp3s",
    "id": {
      "kind": "youtube#video",
      "videoId": "IiLnZd1MQtQ"
    },
    "snippet": {
      "publishedAt": "2021-07-19T14:41:16Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 No Repeats HIIT // Dumbbell Workout",
      "description": "Time for a no repeats HIIT workout with dumbbells for added resistance and Intensity! You will only be performing each HIIT ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/IiLnZd1MQtQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-19T14:41:16Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8ig8s9XZMwZnf9OiRQrJ1AfPPAs",
    "id": {
      "kind": "youtube#video",
      "videoId": "W8wLcGdQQ30"
    },
    "snippet": {
      "publishedAt": "2019-07-12T17:22:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20 Minute // FULL BODY HIIT Workout With Weights",
      "description": "Visit: https://www.heatherrobertson.com Ok guys 6 different exercises in today's full body HIIT workout with weights. Grab those ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W8wLcGdQQ30/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-12T17:22:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-ghpYt79jfN2ENkGFGFg_OHRc1o",
    "id": {
      "kind": "youtube#video",
      "videoId": "Fgf_5K4lmLU"
    },
    "snippet": {
      "publishedAt": "2021-05-02T18:02:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body STRENGTH Workout // Low Impact + Dumbbells Only",
      "description": "Today is all about controlled reps and heavy weight in this Full Body Strength Workout. Using dumbbells only for resistance and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Fgf_5K4lmLU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Fgf_5K4lmLU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Fgf_5K4lmLU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-02T18:02:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HmMU5fHXALg2iUcWHV1kwWGRz5w",
    "id": {
      "kind": "youtube#video",
      "videoId": "0tQfCuElKU8"
    },
    "snippet": {
      "publishedAt": "2021-10-19T13:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25No Repeats HIIT Workout (with Weights)",
      "description": "Who's up for a No Repeats HIIT Workout!!? These non-repetitive workouts fly by and add variety to your sweat sesh which can be ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/0tQfCuElKU8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-19T13:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P7TaAI2a7OeNniCA8HOgYHhx-j8",
    "id": {
      "kind": "youtube#video",
      "videoId": "Ml3QvzAIv3k"
    },
    "snippet": {
      "publishedAt": "2021-06-07T13:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact No REPEATS HIIT Workout (No Equipment)",
      "description": "Bringing the heat in today's epic low impact, no repeats HIIT workout!! This workout requires no equipment and has no jumping ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Ml3QvzAIv3k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Ml3QvzAIv3k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Ml3QvzAIv3k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-07T13:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "xbmk1FqT2U9oFEI2jPRTqm7WDWE",
    "id": {
      "kind": "youtube#video",
      "videoId": "g-i3S1fnQbQ"
    },
    "snippet": {
      "publishedAt": "2021-08-11T15:58:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf1f Low Impact NO REPEATS Total Body HIIT",
      "description": "No jumping and no repeats in today's Low Impact HIIT workout! We are using dumbbells in today's sweat sesh for added ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/g-i3S1fnQbQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-11T15:58:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "P9-OpN5SPKJxz76W-_HrE_yGkKc",
    "id": {
      "kind": "youtube#video",
      "videoId": "aFBRopKNGfw"
    },
    "snippet": {
      "publishedAt": "2019-07-04T15:46:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact FULL BODY HIIT Workout // No Equipment + No Jumping",
      "description": "FREE 12 Week Workout Plan: https://www.heatherrobertson.com I'm running you through a low impact full body HIIT workout ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aFBRopKNGfw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aFBRopKNGfw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aFBRopKNGfw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-07-04T15:46:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "aQNmgI7o155ba7tfvuIC_l4NEiA",
    "id": {
      "kind": "youtube#video",
      "videoId": "3Kp0fasmI6E"
    },
    "snippet": {
      "publishedAt": "2021-05-08T20:22:36Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u2b50\ufe0f NEW Low Impact Workout Program!!",
      "description": "It's here! My new curated 6 week LOW IMPACT WORKOUT PROGRAM! This new workout schedule was created using some of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/3Kp0fasmI6E/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/3Kp0fasmI6E/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/3Kp0fasmI6E/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-08T20:22:36Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "2ARL6tlZGj1QXnrVXMDGFgK7y0E",
    "id": {
      "kind": "youtube#video",
      "videoId": "_t8fJffsZzk"
    },
    "snippet": {
      "publishedAt": "2019-04-13T02:43:14Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body STRENGTH workout // With Dumbbells",
      "description": "Visit: https://www.heatherrobertson.com Today we are focusing on building strength in the upper body! This dumbbell workout will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_t8fJffsZzk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_t8fJffsZzk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_t8fJffsZzk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-13T02:43:14Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "igpui7L4ixQRPgixykLgNWmTBkg",
    "id": {
      "kind": "youtube#video",
      "videoId": "2h6OMO5V1ng"
    },
    "snippet": {
      "publishedAt": "2021-05-30T14:52:22Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body // Strength &amp; Cardio HIIT Workout",
      "description": "This total body hiit workout combines strength and cardio exercises combining controlled, strength building circuits with high ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2h6OMO5V1ng/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2h6OMO5V1ng/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2h6OMO5V1ng/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-30T14:52:22Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "vns10zTigDNUlDNKZh0FQADqTRg",
    "id": {
      "kind": "youtube#video",
      "videoId": "iqj48B2B-Pw"
    },
    "snippet": {
      "publishedAt": "2020-12-22T19:48:46Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MINUTE KETTLEBELL WORKOUT // Full Body Strength &amp; Cardio",
      "description": "Today's kettlebell workout will target the full body with a mix of strength and cardio exercises. Using just one kettlebell (or modify ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/iqj48B2B-Pw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/iqj48B2B-Pw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/iqj48B2B-Pw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-22T19:48:46Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "RhPPYs-syyKGG8Qg0SZjhA4NdnQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "-s4mgdTjOwI"
    },
    "snippet": {
      "publishedAt": "2019-11-11T17:00:37Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full UPPER BODY Workout // TONING + STRENGTH",
      "description": "Today we are powering through an upper body workout designed to tone and strengthen the arms, shoulders, chest and back.",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/-s4mgdTjOwI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-11T17:00:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jns8dpNxqmOXmtnLhLGg_3eyRBM",
    "id": {
      "kind": "youtube#video",
      "videoId": "Bc6G41KutHE"
    },
    "snippet": {
      "publishedAt": "2020-11-18T14:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20 Minute Core Strength // No Repeats AB Workout!",
      "description": "It's all about core strength in today's 20 minute no repeats ab workout! We are powering through a series of core exercises that will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Bc6G41KutHE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Bc6G41KutHE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Bc6G41KutHE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-18T14:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "BWVBcah7d09R-5b5JUDXCmYIbaw",
    "id": {
      "kind": "youtube#video",
      "videoId": "9qqnYOcSpY8"
    },
    "snippet": {
      "publishedAt": "2020-07-06T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Mini RESISTANCE BAND Workout",
      "description": "Today's full body mini resistance band workout will target all the major muscle groups giving you a total body workout with just one ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/9qqnYOcSpY8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/9qqnYOcSpY8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/9qqnYOcSpY8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-06T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "qHO5-iu_JxIvez33Fe_Xy98JQus",
    "id": {
      "kind": "youtube#video",
      "videoId": "T6Q1J1zCa0c"
    },
    "snippet": {
      "publishedAt": "2021-12-12T14:00:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36 Day 9: Lower Body Strength // Unilateral Leg Workout",
      "description": "Today's strength workout is a unilateral leg workout meaning we are working one side of the body at a time. Unilateral training ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T6Q1J1zCa0c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T6Q1J1zCa0c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T6Q1J1zCa0c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-12T14:00:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "gyeu-UyuUDnZQBoXWDu9i9UVCQ8",
    "id": {
      "kind": "youtube#video",
      "videoId": "s3arMZ7ASA4"
    },
    "snippet": {
      "publishedAt": "2021-01-17T14:00:30Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Fat Burning HIIT Cardio Workout // No Equipment",
      "description": "Cardio cardio cardio! Love it or hate it, we've got to get it in! This fat burning HIIT cardio workout will get that heart rate up and will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/s3arMZ7ASA4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-17T14:00:30Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rwpwkErszpmi_hQh6RohxUrLp8E",
    "id": {
      "kind": "youtube#video",
      "videoId": "USi3LmPlj_0"
    },
    "snippet": {
      "publishedAt": "2021-10-07T13:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body STRENGTH Workout // Dumbbells Only",
      "description": "I'm feeling the burn from head to toe after this Total Body Strength Workout! Using dumbbells only today we are going to target ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/USi3LmPlj_0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/USi3LmPlj_0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/USi3LmPlj_0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-07T13:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8n84TL2E1a_aNv13KT2KBLS-iJg",
    "id": {
      "kind": "youtube#video",
      "videoId": "RzuJsxW13Co"
    },
    "snippet": {
      "publishedAt": "2021-03-24T13:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Strength &amp; Toning // Dumbbell Workout",
      "description": "Strength and toning is the focus in today's total body workout. Using dumbbells for resistance, compound exercises and multiple ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/RzuJsxW13Co/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/RzuJsxW13Co/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/RzuJsxW13Co/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-24T13:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "63CcW0eiw2DEydkTf-AII1Noyms",
    "id": {
      "kind": "youtube#video",
      "videoId": "xzcHZcbPplk"
    },
    "snippet": {
      "publishedAt": "2021-09-23T14:00:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 No Repeats HIIT Cardio Workout (No Equipment)",
      "description": "Get ready to tackle 45 different HIIT Cardio exercises in today's sweat fest of a workout! No Repeats and No Equipment needed so ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/xzcHZcbPplk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-23T14:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Cf5AtsEpf11-KbJzpehwQ0ffe04",
    "id": {
      "kind": "youtube#video",
      "videoId": "YN-Gy35YZwI"
    },
    "snippet": {
      "publishedAt": "2020-12-16T14:00:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body HIIT Pilates Workout // Fusion",
      "description": "Time for another Full Body HIIT Pilates workout! This fusion style workout includes a series of pilates inspired exercises using no ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/YN-Gy35YZwI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/YN-Gy35YZwI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/YN-Gy35YZwI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-16T14:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0UJQ3_eW2OpC0OKOp2RAwOTPk9g",
    "id": {
      "kind": "youtube#video",
      "videoId": "sa8RJQy8kps"
    },
    "snippet": {
      "publishedAt": "2022-01-02T14:00:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "40 MIN Upper Body Push Workout // DAY 1 HR12WEEK 2.0",
      "description": "This Upper Body Push Workout is just what we need to get my new 12 week workout program started! The focus today is on the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sa8RJQy8kps/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sa8RJQy8kps/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sa8RJQy8kps/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-01-02T14:00:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "1rC01Zpi_PkpsM3goy7BSlcvhYU",
    "id": {
      "kind": "youtube#video",
      "videoId": "SOsVjNUf1OI"
    },
    "snippet": {
      "publishedAt": "2020-09-02T13:40:45Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Pilates Fusion // Full Body HIIT Workout (No Equipment, No Jumping)",
      "description": "Ok I'm mixing it up again today woohoo! This Pilates Fusion workout is a full body HIIT that incorporate some Pilates inspired ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SOsVjNUf1OI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SOsVjNUf1OI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SOsVjNUf1OI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-02T13:40:45Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VrrzJPuTQ8Xhbwub223dV9jilHQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "aTMdDDnY-Ow"
    },
    "snippet": {
      "publishedAt": "2019-10-11T14:00:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact FAT BURNING HIIT Workout // No Jumping Workout!",
      "description": "Today's low impact fat burning HIIT workout will target every major muscle group and will get that heart rate up with no jumping ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aTMdDDnY-Ow/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aTMdDDnY-Ow/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aTMdDDnY-Ow/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-11T14:00:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "hCM_seKHz5D3lOEtJxPTjgldOUE",
    "id": {
      "kind": "youtube#video",
      "videoId": "irv7XgPkrvs"
    },
    "snippet": {
      "publishedAt": "2020-10-21T14:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Upper Body Toning // No Repeats Workout",
      "description": "Today we are focusing on Upper Body Toning with this No REPEAT workout! Using only dumbbells we are going to target the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/irv7XgPkrvs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/irv7XgPkrvs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/irv7XgPkrvs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-21T14:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sqjfOjrwk4p4gMOE42HsyGa6s10",
    "id": {
      "kind": "youtube#video",
      "videoId": "1V64QSE-96Y"
    },
    "snippet": {
      "publishedAt": "2020-07-09T14:41:25Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer ABS WORKOUT // Advanced 30 Minute Ab Burner",
      "description": "Ok guys get ready to feel the burn!! Today's killer ab workout includes 10 different ab exercises. A little different format today - I'm ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/1V64QSE-96Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/1V64QSE-96Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/1V64QSE-96Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-07-09T14:41:25Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "I5vSfqzEXOkGf8RoUnzLjzKsVQ0",
    "id": {
      "kind": "youtube#video",
      "videoId": "Hri2rYgOLKI"
    },
    "snippet": {
      "publishedAt": "2020-12-13T14:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Cardio Kickboxing Workout // Get Ready To SWEAT!",
      "description": "I've had so many requests for a cardio kickboxing workout - so here it is!! To be honest I was a little out of my comfort zone with this ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Hri2rYgOLKI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Hri2rYgOLKI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Hri2rYgOLKI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-13T14:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "0YLIOb7FjzWVFijAxOog2x0hOFY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Wlsd4Ponhz0"
    },
    "snippet": {
      "publishedAt": "2020-11-15T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body STRENGTH Workout // Dumbbell Supersets",
      "description": "Today's Total Body STRENGTH Workout is made up of 8 killer supersets. The focus is on building strength and lean muscle tone ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Wlsd4Ponhz0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Wlsd4Ponhz0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Wlsd4Ponhz0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-15T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "7Mi6g_dHa4hRDXN0HD_p6SQhlyA",
    "id": {
      "kind": "youtube#video",
      "videoId": "2ID_gBKqQwM"
    },
    "snippet": {
      "publishedAt": "2021-06-02T14:58:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Intense TOTAL BODY Workout // Strength Training",
      "description": "Grab those dumbbells and get ready for an intense total body workout. We are combining strength training with quick cardio bursts ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/2ID_gBKqQwM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/2ID_gBKqQwM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/2ID_gBKqQwM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-02T14:58:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "_8yVBd3dczRuOBjJXUbmdfMlBOs",
    "id": {
      "kind": "youtube#video",
      "videoId": "lkt22sW5x7w"
    },
    "snippet": {
      "publishedAt": "2021-04-01T14:00:27Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact High Intensity Cardio Workout // No Jumping",
      "description": "Today's low impact cardio workout has no jumping exercises but that does not mean we are lowering the intensity! This full body ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/lkt22sW5x7w/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/lkt22sW5x7w/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/lkt22sW5x7w/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-01T14:00:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Dd5PWSPAuqgLysJT07_WfA5POe8",
    "id": {
      "kind": "youtube#video",
      "videoId": "_t_DwpxWjyo"
    },
    "snippet": {
      "publishedAt": "2019-11-27T00:44:27Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Low Impact CARDIO + ABS Workout // No Repeats, No Jumping, No Equipment",
      "description": "Today's low impact cardio and abs workout requires No jumping. No equipment and there will be No repeats! 20 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/_t_DwpxWjyo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/_t_DwpxWjyo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/_t_DwpxWjyo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-11-27T00:44:27Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "y6oErZMViyVvZlFzp6gTVLUG7dA",
    "id": {
      "kind": "youtube#video",
      "videoId": "dMfzbe7INNE"
    },
    "snippet": {
      "publishedAt": "2021-09-16T18:20:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "ARM TONING WORKOUT // Biceps, Triceps &amp; Shoulders",
      "description": "Today we are targeting the biceps, triceps and shoulders with this arm toning workout. Using dumbbells only for resistance we will ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/dMfzbe7INNE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/dMfzbe7INNE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/dMfzbe7INNE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-16T18:20:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Mo7iewVPHnIX7bEt1oC5KCNYPyM",
    "id": {
      "kind": "youtube#video",
      "videoId": "VM67duRnhMI"
    },
    "snippet": {
      "publishedAt": "2020-11-01T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEAT Leg Workout // With dumbbells",
      "description": "It's LEG DAY!! We are working on lower body strength and sculpting in this NO REPEATS leg workout with dumbbells. The goal is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/VM67duRnhMI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/VM67duRnhMI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/VM67duRnhMI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-01T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "5aJkjo8j78iZZSAQWS5Ji9Jc-84",
    "id": {
      "kind": "youtube#video",
      "videoId": "WcswMYBx2rA"
    },
    "snippet": {
      "publishedAt": "2020-11-29T15:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "1 Hour No Repeat Mash Up// Strength + Cardio Workout",
      "description": "It's time for another epic Mash Up! This 1 hour total body workout combines some of my favorite strength and cardio exercises from ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/WcswMYBx2rA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/WcswMYBx2rA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/WcswMYBx2rA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-29T15:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "n4PgcEXMD62_8yxFGKPz--bohDY",
    "id": {
      "kind": "youtube#video",
      "videoId": "yRtcwWMcjmg"
    },
    "snippet": {
      "publishedAt": "2020-12-09T14:00:15Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Powerful Full Body Strength // Dumbbell Only Workout",
      "description": "Today's full body strength workout is fierce!! It is loaded with compound exercises that will target every major muscle group and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yRtcwWMcjmg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yRtcwWMcjmg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yRtcwWMcjmg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-09T14:00:15Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sR09GZTEe0BzShYilnfIM914VAQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "C6MVl6owXk8"
    },
    "snippet": {
      "publishedAt": "2021-08-25T13:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udcaa\ud83c\udffc Super Shred // Strength &amp; Cardio Workout (Dumbbells Only)",
      "description": "Sign up for your free 30 day Epidemic Sound trial here http://share.epidemicsound.com/zj9WV plus use code HEATHER3FREE ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/C6MVl6owXk8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/C6MVl6owXk8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/C6MVl6owXk8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-25T13:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Ow7iQciMSiPNwddTLUU2qyCKdLs",
    "id": {
      "kind": "youtube#video",
      "videoId": "TeN-TATnwyI"
    },
    "snippet": {
      "publishedAt": "2021-05-06T13:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51 LEGS &amp; BOOTY Workout // No Equipment",
      "description": "It's leg day and we are targeting the legs and booty with no equipment! That's right no weights needed for this sweat sesh, we are ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TeN-TATnwyI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TeN-TATnwyI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TeN-TATnwyI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-06T13:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GiicZaybRSXbcaqF9kG7uzYE1VE",
    "id": {
      "kind": "youtube#video",
      "videoId": "5YBEgVchv8U"
    },
    "snippet": {
      "publishedAt": "2021-05-11T16:29:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25HIIT CARDIO Workout (No Equipment)",
      "description": "Time to sneak in a quick and dirty HIIT Cardio workout! This no equipment workout can be done at home, at the park or at the gym ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/5YBEgVchv8U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/5YBEgVchv8U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/5YBEgVchv8U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-11T16:29:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XgDaGmcRU08E9gRHEiOrVTAsSo4",
    "id": {
      "kind": "youtube#video",
      "videoId": "JLM3MKut7nE"
    },
    "snippet": {
      "publishedAt": "2021-10-24T17:42:38Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Pilates Workout // No Repeats HIIT Fusion",
      "description": "Time for a Total Body Pilates Workout! This HIIT fusion workout includes a series of Pilates inspired exercises that are great for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/JLM3MKut7nE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/JLM3MKut7nE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/JLM3MKut7nE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-24T17:42:38Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "v-mL4eN5ZS9vtBMU1Kv3YvjEUuk",
    "id": {
      "kind": "youtube#video",
      "videoId": "HN0VOVOhFJk"
    },
    "snippet": {
      "publishedAt": "2021-09-14T13:00:07Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 Fat Burning CARDIO KICKBOXING Workout",
      "description": "Feel the burn with today's Cardio Kickboxing Workout! This killer fat burning session is sure to challenge you and keep you on ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HN0VOVOhFJk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HN0VOVOhFJk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HN0VOVOhFJk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-14T13:00:07Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "FdY8V9nGv3vyi2BM8ipryafimgI",
    "id": {
      "kind": "youtube#video",
      "videoId": "S4WqG01PN0U"
    },
    "snippet": {
      "publishedAt": "2021-03-28T12:30:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "20min AMRAP // Total Body Workout",
      "description": "Switching things up today with a fun 20 minute AMRAP workout. This total body workout includes 4 different circuits. Each circuit ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S4WqG01PN0U/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S4WqG01PN0U/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S4WqG01PN0U/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-28T12:30:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "dh-48_b9uMrEzZHqk_gjVHlBU_s",
    "id": {
      "kind": "youtube#video",
      "videoId": "UMJDzMf2IMM"
    },
    "snippet": {
      "publishedAt": "2020-10-11T13:00:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "1 Hour // No Repeat HIIT Workout",
      "description": "Ok, you asked for it! It's time to power through todays 1 hour No Repeat HIIT in the new studio whoop whoop!! 60 different ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/UMJDzMf2IMM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/UMJDzMf2IMM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/UMJDzMf2IMM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-11T13:00:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Twdo6EMSfqppmigTZ3Z_RE9i8Ao",
    "id": {
      "kind": "youtube#video",
      "videoId": "H5fTnViho5k"
    },
    "snippet": {
      "publishedAt": "2021-06-21T16:05:19Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Yoga HIIT Fusion Workout // No Equipment, No Jumping",
      "description": "Mixing it up today with a Yoga HIIT Fusion workout! This no equipment, no jumping low impact workout combines HIIT with Yoga ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/H5fTnViho5k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/H5fTnViho5k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/H5fTnViho5k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-06-21T16:05:19Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "sUTLnSjyd_qj9Og8V_pw4FUHbWA",
    "id": {
      "kind": "youtube#video",
      "videoId": "KgxZiHPBKiM"
    },
    "snippet": {
      "publishedAt": "2021-09-02T19:29:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "LEG DAY! // Strength Workout (Dumbbells Only)",
      "description": "This Leg Day workout is all about building strength and endurance in those legs. Your quads, hamstrings and glutes are going to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KgxZiHPBKiM/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KgxZiHPBKiM/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KgxZiHPBKiM/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-02T19:29:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "j1yLmUHzrqo8IODDXszb6pfqw7A",
    "id": {
      "kind": "youtube#video",
      "videoId": "cuHwoCWFLIw"
    },
    "snippet": {
      "publishedAt": "2019-08-17T22:42:51Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "NO REPEAT WORKOUT // Full body HIIT Workout with Weights",
      "description": "FREE 12 Week Workout Plan: www.heatherrobertson.com 30 exercises in 30 minutes in this No Repeat workout. We are targeting ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/cuHwoCWFLIw/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/cuHwoCWFLIw/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/cuHwoCWFLIw/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-08-17T22:42:51Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ByDADRVycV9HedP2-1do2I-3kJQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "CUoA12fBagE"
    },
    "snippet": {
      "publishedAt": "2021-04-06T13:56:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Extreme Lean Legs Bootcamp // Strength &amp; Toning",
      "description": "Get ready for today's extreme Leg day workout! Using dumbbells we are going to focus on strength and toning the legs by ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/CUoA12fBagE/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/CUoA12fBagE/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/CUoA12fBagE/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-04-06T13:56:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "Jy5PN75J0bozSWbdJOvGevXFBhY",
    "id": {
      "kind": "youtube#video",
      "videoId": "Fu1EcSaMZMY"
    },
    "snippet": {
      "publishedAt": "2020-05-12T14:02:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Day 3 Home Workout Challenge // UPPER BODY HIIT (No Equipment)",
      "description": "Welcome to day 3 of my 7 day home workout challenge! We are leaving those legs alone today because I know the burn was ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/Fu1EcSaMZMY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/Fu1EcSaMZMY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/Fu1EcSaMZMY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-05-12T14:02:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cEDkNq0vKwSYAEwyw-O3d-3CrtE",
    "id": {
      "kind": "youtube#video",
      "videoId": "sinkIlViPG8"
    },
    "snippet": {
      "publishedAt": "2020-10-04T14:00:08Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Full Body Strength Workout With Dumbbells",
      "description": "Today we are slowing things down and increasing our weights in this Full Body Strength Workout with Dumbbells. Added ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/sinkIlViPG8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/sinkIlViPG8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/sinkIlViPG8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-10-04T14:00:08Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "lxowIQU2VxwZcKu331d2y-FjMMA",
    "id": {
      "kind": "youtube#video",
      "videoId": "HLDRwI4OnQU"
    },
    "snippet": {
      "publishedAt": "2021-02-24T14:00:04Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Power Hour!! 60MIN NO REPEAT Workout (Full Body // Dumbbells)",
      "description": "Welcome to the Power Hour!! Whoop whoop! This 1 hour No Repeats Workout combines strength and cardio exercises to give ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/HLDRwI4OnQU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/HLDRwI4OnQU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/HLDRwI4OnQU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-24T14:00:04Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZGHnbXO1VyYW95ATP5JITUShueQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "d8BFKmkmxV4"
    },
    "snippet": {
      "publishedAt": "2020-09-23T14:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "INTENSE Fat Burning Tabata // No Equipment Home Workout",
      "description": "Ok get ready to sweat! This Fat Burning Tabata workout is intense! No equipment needed so you can easily workout from home ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/d8BFKmkmxV4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/d8BFKmkmxV4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/d8BFKmkmxV4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-23T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "M0gBbKSp8GDUFH0GALLT_P0vzm4",
    "id": {
      "kind": "youtube#video",
      "videoId": "W273f7MYt2Y"
    },
    "snippet": {
      "publishedAt": "2021-03-10T14:00:18Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Chest, Back &amp; Shoulders Workout // Strength Supersets",
      "description": "This powerful low impact workout is all about building strength and sculpting out the chest, back and shoulders using dumbbells ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/W273f7MYt2Y/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/W273f7MYt2Y/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/W273f7MYt2Y/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-03-10T14:00:18Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "JLDjkt25cQMSUyWiKfV3EDj16VY",
    "id": {
      "kind": "youtube#video",
      "videoId": "zn8XH_YGriA"
    },
    "snippet": {
      "publishedAt": "2021-10-17T13:00:32Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Arms &amp; Abs Workout // Low Impact Strength &amp; Toning",
      "description": "This Arms and Abs Workout is all about building strength and toning the upper body and abs. With compound, low impact ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/zn8XH_YGriA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/zn8XH_YGriA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/zn8XH_YGriA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-17T13:00:32Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "O3P6vtcHGcIZjxtYjmGkjOHGCj0",
    "id": {
      "kind": "youtube#video",
      "videoId": "eemRXHKsGIc"
    },
    "snippet": {
      "publishedAt": "2021-01-10T15:00:11Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Killer LEG DAY!! // Lower Body STRENGTH WORKOUT",
      "description": "Get Your Nutrition Guide Here: https://heatherrobertson.com/shop/ It's LEG DAY!!! Working on lower body strength today and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eemRXHKsGIc/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eemRXHKsGIc/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eemRXHKsGIc/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-01-10T15:00:11Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "LxFFMpeUw2xbXPpJiTDZ3CDm0t8",
    "id": {
      "kind": "youtube#video",
      "videoId": "wre08em-9k4"
    },
    "snippet": {
      "publishedAt": "2021-05-27T14:43:58Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Power Pilates Workout // Pilates HIIT Fusion",
      "description": "Today's HIIT fusion workout is a powerful pilates inspired sweat sesh. Pilates inspired exercises are great for improving mobility, ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/wre08em-9k4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/wre08em-9k4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/wre08em-9k4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-05-27T14:43:58Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "UgBelSdiIefXFI5rGyVHEZjxxaI",
    "id": {
      "kind": "youtube#video",
      "videoId": "vE9CBjpNjPs"
    },
    "snippet": {
      "publishedAt": "2021-02-10T15:00:23Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "POWER Pilates Workout // HIIT Fusion",
      "description": "Today's HIIT fusion workout is inspired by some of my favorite Pilates exercises. No equipment is needed to get in today's ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/vE9CBjpNjPs/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/vE9CBjpNjPs/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/vE9CBjpNjPs/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-02-10T15:00:23Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "ZKZzml6FOBST1QqfQm2uQZbac5o",
    "id": {
      "kind": "youtube#video",
      "videoId": "4zCCeDWIoS8"
    },
    "snippet": {
      "publishedAt": "2021-09-21T13:00:03Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Intense Strength Supersets // Full Body Unilateral Workout",
      "description": "Get ready for today's Strength Supersets!! This full body, unilateral workout isolates each side of the body to help build strength ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4zCCeDWIoS8/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4zCCeDWIoS8/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4zCCeDWIoS8/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-21T13:00:03Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "f-AEOQXzpeDAwFbCMw7NkdLttWA",
    "id": {
      "kind": "youtube#video",
      "videoId": "KHAUSLob0cA"
    },
    "snippet": {
      "publishedAt": "2021-08-16T13:15:00Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Barre Sculpt // HIIT Fusion Workout",
      "description": "Throwing a little Barre Sculpt workout into the mix today! This HIIT Barre fusion workout begins with upper body barre inspired ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/KHAUSLob0cA/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/KHAUSLob0cA/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/KHAUSLob0cA/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-16T13:15:00Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "cnWcMQwNDT_ybOvT2_ZMp2GuONQ",
    "id": {
      "kind": "youtube#video",
      "videoId": "azF7RsPXB2k"
    },
    "snippet": {
      "publishedAt": "2020-01-06T05:00:02Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Week 1 Day 1 // Full Body Workout - No Equipment",
      "description": "Download your FREE 12 week guide: https://www.heatherrobertson.com Get The Nutrition Guide: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/azF7RsPXB2k/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/azF7RsPXB2k/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/azF7RsPXB2k/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-01-06T05:00:02Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "alvZek14OdSlYGTgdX4d6z1cl58",
    "id": {
      "kind": "youtube#video",
      "videoId": "4c8mNETuCcg"
    },
    "snippet": {
      "publishedAt": "2021-07-29T03:27:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51Strong Glutes &amp; Toned Thighs Workout",
      "description": "We are building strong glutes and toned thighs In today's leg workout! Starting out with glute activation using a mini band and then ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/4c8mNETuCcg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/4c8mNETuCcg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/4c8mNETuCcg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-07-29T03:27:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "o2APHYDtGwnP58d94Y9awgcKVkI",
    "id": {
      "kind": "youtube#video",
      "videoId": "PGltyb3Ga8Q"
    },
    "snippet": {
      "publishedAt": "2021-09-12T15:00:17Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\u26a1 Knee Friendly HIIT (NO SQUATS NO LUNGES NO JUMPING)",
      "description": "This HIIT workout is Knee Friendly with no squats, no lunges and no jumping. These low impact exercises will give you a solid full ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/PGltyb3Ga8Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/PGltyb3Ga8Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/PGltyb3Ga8Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-09-12T15:00:17Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "-zuWtCIiB8CO2hLkUThutXVzOy0",
    "id": {
      "kind": "youtube#video",
      "videoId": "SEb4B7iW3dY"
    },
    "snippet": {
      "publishedAt": "2020-11-04T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Back Sculpting // Upper Body Strength Workout",
      "description": "Today's Back Sculpting Upper Body Workout uses dumbbells and controlled movement to build upper body strength and lean ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/SEb4B7iW3dY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/SEb4B7iW3dY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/SEb4B7iW3dY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-11-04T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rwSkuBOuIltvaoogHwCJ0mHc_zg",
    "id": {
      "kind": "youtube#video",
      "videoId": "tt9SwcgDsXU"
    },
    "snippet": {
      "publishedAt": "2020-12-20T14:00:10Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Total Body Stability Ball Workout",
      "description": "The stability ball is back! We are getting in a total body workout today using just a stability ball aka exercise ball aka physio ball ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/tt9SwcgDsXU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/tt9SwcgDsXU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/tt9SwcgDsXU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-12-20T14:00:10Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XRUesB7QXC5vHgSuHo0WJhvwJfI",
    "id": {
      "kind": "youtube#video",
      "videoId": "mbZeDTzqibQ"
    },
    "snippet": {
      "publishedAt": "2017-08-12T18:29:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "At Home HIIT Workout For Beginners",
      "description": "Visit: https://www.heatherrobertson.com I'm back at it with another HIIT workout! This is a great workout for beginners as it includes ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mbZeDTzqibQ/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mbZeDTzqibQ/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mbZeDTzqibQ/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2017-08-12T18:29:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "IblQxr9fe2rwlhRresabn6JnUI8",
    "id": {
      "kind": "youtube#video",
      "videoId": "eQ-F2ib9vcg"
    },
    "snippet": {
      "publishedAt": "2016-05-15T03:24:42Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "At-Home Thigh Slimming Workout",
      "description": "Not gonna lie I was feeling this one for a few days. This at home thigh slimming workout is guaranteed to target those thighs and ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/eQ-F2ib9vcg/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/eQ-F2ib9vcg/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/eQ-F2ib9vcg/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2016-05-15T03:24:42Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "8GW-QZH46GXN5PkboqpS1iT6IPA",
    "id": {
      "kind": "youtube#video",
      "videoId": "yunfgDz8n2c"
    },
    "snippet": {
      "publishedAt": "2019-04-25T19:09:21Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "At Home STRENGTH + CARDIO Workout // Total Body",
      "description": "Visit: https://www.heatherrobertson.com Today we are getting in both a strength and cardio workout that will help build lean ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/yunfgDz8n2c/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/yunfgDz8n2c/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/yunfgDz8n2c/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-04-25T19:09:21Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "HlndoJoITpfzCG0Tl4lLTaWjLYI",
    "id": {
      "kind": "youtube#video",
      "videoId": "S_My1SVINoI"
    },
    "snippet": {
      "publishedAt": "2019-10-06T02:00:37Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30 Minute CARDIO WORKOUT",
      "description": "30 Minute CARDIO Workout Access the Full 28 Day Challenge Playlist Here: https://goo.gl/DFjo4d L I N K S  Website: ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/S_My1SVINoI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/S_My1SVINoI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/S_My1SVINoI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2019-10-06T02:00:37Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "jHHXr-pWNXSBmrLvG4nQJP42Uv8",
    "id": {
      "kind": "youtube#video",
      "videoId": "T9AlsZwPwG0"
    },
    "snippet": {
      "publishedAt": "2020-09-16T14:00:05Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Back Fat Blaster!!! // Upper Body Workout with Weights",
      "description": "Building strength and lean muscle in the back will help to tone and burn fat throughout the entire body - ultimately helping to ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/T9AlsZwPwG0/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/T9AlsZwPwG0/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/T9AlsZwPwG0/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-16T14:00:05Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "VkrWL18IBaQc0ChwXPv7Np3pBUM",
    "id": {
      "kind": "youtube#video",
      "videoId": "TOD6HojGsr4"
    },
    "snippet": {
      "publishedAt": "2022-08-21T13:59:54Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf51 No Equipment Booty Workout! (Unilateral Glute Exercises)",
      "description": "This quick workout is a no equipment booty workout from home - or anywhere as it requires no equipment! Targeting the glutes we ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/TOD6HojGsr4/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/TOD6HojGsr4/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/TOD6HojGsr4/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-21T13:59:54Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "yWbq9K7fdo5ZEKLoIIrO9BykLMU",
    "id": {
      "kind": "youtube#video",
      "videoId": "mvMEl9_nCZo"
    },
    "snippet": {
      "publishedAt": "2022-08-07T13:59:50Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Total Body Strength // Supersets Workout (No Jumping)",
      "description": "Feeling the burn with today's Total Body Strength Workout! We have a series of strength supersets to power through using ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mvMEl9_nCZo/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mvMEl9_nCZo/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mvMEl9_nCZo/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-08-07T13:59:50Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "GYZV8_NSD6UXZMDZXUpUlcX77KE",
    "id": {
      "kind": "youtube#video",
      "videoId": "mVvXZiH6DEI"
    },
    "snippet": {
      "publishedAt": "2022-09-06T13:59:49Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Total Body HIIT Workout",
      "description": "It's time for another sweaty HIIT Workout! This 30MIN total body HIIT workout starts off with a warm up and then we will move into ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mVvXZiH6DEI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mVvXZiH6DEI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mVvXZiH6DEI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-06T13:59:49Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "4FOkw1LGkO9_wuAknx4Utglg6yk",
    "id": {
      "kind": "youtube#video",
      "videoId": "z8-7vQm9rnY"
    },
    "snippet": {
      "publishedAt": "2022-09-13T13:59:48Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Total Body METCON Workout // Metabolic Conditioning",
      "description": "It's Metcon time! This Total Body Metcon workout is packed with compound exercises that will help burn fat and build muscle ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/z8-7vQm9rnY/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/z8-7vQm9rnY/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/z8-7vQm9rnY/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-09-13T13:59:48Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "XbqIfkd0L8ogjLEnINlc_-LeCT8",
    "id": {
      "kind": "youtube#video",
      "videoId": "mhpo6ARhh_Q"
    },
    "snippet": {
      "publishedAt": "2022-11-07T14:59:47Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "30MIN Full Body Strength Workout",
      "description": "Today's 30MIN Full Body Strength Workout has 2 different strength training circuits to power through. We are using dumbbells for ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/mhpo6ARhh_Q/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/mhpo6ARhh_Q/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/mhpo6ARhh_Q/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2022-11-07T14:59:47Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "rK0zsV_vJgDGm63jz0Mi4v6YOZw",
    "id": {
      "kind": "youtube#video",
      "videoId": "NynLJqp0-0o"
    },
    "snippet": {
      "publishedAt": "2021-08-18T16:11:06Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83d\udd25 Brutal Body-Weight TABATA Workout",
      "description": "Today's body-weight TABATA is brutal - but in the best way possible ;) No equipment needed as we power through a series of ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/NynLJqp0-0o/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/NynLJqp0-0o/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/NynLJqp0-0o/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-08-18T16:11:06Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "6dbNBhcoQ2YMrTjRiAkrEmAK7Wo",
    "id": {
      "kind": "youtube#video",
      "videoId": "pSjTkrWuZJk"
    },
    "snippet": {
      "publishedAt": "2021-10-12T14:00:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Leg Workout // Bench &amp; Dumbbell Workout",
      "description": "It's leg day and this leg workout will have you feeling the burn with a series of lower body strength exercises! I am using an ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pSjTkrWuZJk/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pSjTkrWuZJk/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pSjTkrWuZJk/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-10-12T14:00:31Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "zwYtGnHih8i7BWlmwy2bKSiImPo",
    "id": {
      "kind": "youtube#video",
      "videoId": "aLPgXo_7FRI"
    },
    "snippet": {
      "publishedAt": "2021-12-06T14:00:01Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 3: Chest &amp; Back Workout",
      "description": "Today's Chest & back workout is a strength workout for building lean muscle and definition in the upper body. Each circuit is ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/aLPgXo_7FRI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/aLPgXo_7FRI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/aLPgXo_7FRI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-06T14:00:01Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "b99NvSlFK3x2g7sBGHsFviLno9U",
    "id": {
      "kind": "youtube#video",
      "videoId": "pcJsP4gogsI"
    },
    "snippet": {
      "publishedAt": "2020-09-20T14:00:09Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "Brutal Booty + Leg Slimming Workout! // No Equipment + No Repeats",
      "description": "Today's Brutal Booty + Leg Slimming Workout is a real burner!!! No equipment needed today so this is a great way to target the ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/pcJsP4gogsI/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/pcJsP4gogsI/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/pcJsP4gogsI/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2020-09-20T14:00:09Z"
    }
  },
  {
    "kind": "youtube#searchResult",
    "etag": "S01g_co8ffEmVJAU77Iee34d9cY",
    "id": {
      "kind": "youtube#video",
      "videoId": "j8gRhE5vMzU"
    },
    "snippet": {
      "publishedAt": "2021-12-07T10:00:31Z",
      "channelId": "UCOpsZxrmeDARilha1uq4slA",
      "title": "\ud83c\udf36Day 4: Booty Builder // Glute Workout",
      "description": "It's booty time my friends! Todays focus is on building glute strength. Starting off with a mini band series for glute activation (if you ...",
      "thumbnails": {
        "default": {
          "url": "https://i.ytimg.com/vi/j8gRhE5vMzU/default.jpg",
          "width": 120,
          "height": 90
        },
        "medium": {
          "url": "https://i.ytimg.com/vi/j8gRhE5vMzU/mqdefault.jpg",
          "width": 320,
          "height": 180
        },
        "high": {
          "url": "https://i.ytimg.com/vi/j8gRhE5vMzU/hqdefault.jpg",
          "width": 480,
          "height": 360
        }
      },
      "channelTitle": "Heather Robertson",
      "liveBroadcastContent": "none",
      "publishTime": "2021-12-07T10:00:31Z"
    }
  }
];

export{mergedVids};